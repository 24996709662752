import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";


import { BackendApiService } from '../../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../../services/util/util.service';
import { RequestService } from '../../../../services/request/request.service';
import { AlertConfirmationComponent } from '../../../alert-confirmation/alert-confirmation.component';
import { SaveQueryNameComponent } from '../../save-query-name/save-query-name.component';
import { AlertMessageComponent } from '../../alert-message/alert-message.component';

import { ExcelService } from '../../../../services/excel.service';
import { DatePipe } from '@angular/common';
import { MatomoTracker } from 'ngx-matomo';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-create-private-group',
  templateUrl: './create-private-group.component.html',
  styleUrls: ['./create-private-group.component.scss']
})
export class CreatePrivateGroupComponent implements OnInit {
  @ViewChild('fileUpload', { static: false })
  myFileInput: ElementRef;
  profile = this.utilService.getUserProfile();
  reqActive;
  isShowHiddenList;
  isPublic: boolean = false;
  isPrivate: boolean = true;
  enableButton: boolean = false;
  selectedPrivateQueryName: string = '';
  selectedPublicQueryName: string = '';
  queryType: string = '';
  zid: string = '';
  queryPublicList = [];
  queryPrivateList = [];
  roleBasedGroupList;
  fileContent: any;
  fileType = '.txt';
  fileData = [];

  alertMsg = [{ 'EUROPE': [] },
  { 'MERCOSUR': [] }];
  tableValues = {
    "ipn": undefined, "region": undefined, "responseData": undefined,
    "tableData": undefined, "selectedRegion": undefined, "selectedTable": undefined, "selectedTableDetails": undefined,
    "dateDiff": undefined, "requestDatas": undefined, "tableMenuOpen": false, "roleBasedTableList": undefined
  };

  criteriaValues = {
    "selectedCategory": "Tous",
    "filterCategory": [], "displayGroupingForm": false, "groupingSelectedList": [], "searchText": undefined,
    "filterPrivateCategory": [], "mnuOpen": false, "selectedGroupItemValue": undefined, "selectedCharacterList": undefined, "blnGroupingEdit": undefined,
    "categoryList": [], "displayCriteriaList": [], "criteriaList": [], "criteriaListOringinal": [], "blnMoveRight": false, "GCOName": undefined,
    "splitChar": "§", "lang": 'fr', "selectedItemValue": undefined, "selectedItemType4Color": undefined, "selectedItemType": undefined, "selectedItemIndex": undefined
  }

  vueValues = {
    "isVueDetailSave": undefined, "selectedVueIPNDetail": undefined, "vueEditIndex": undefined, "vueEditValue": undefined, "vueList": [],
    "previousVueList": undefined, "vuePrivateIPNDetails": undefined, "privateMenuOpen": false, "groupingEditValue": undefined
  }
  dateGroupValue = ['flag', 'jours', 'programmes', 'mois industriels', 'mois calendaires', 'année', 'jours', 'jours', 'jour/prog./année'];

  criteriaOptions = [{ name: "Champs privés", value: "private" },
    // { name: "Public", value: "public" }
  ];
  selectedCriteriaOptions;
  screenName;
  columnNames = [];
  columnDateType = [];
  queryExecutedData = [];

  // screenName = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];


  sorter = this.utilService.MySort(' ?*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz');
  splitChar = "§";
  lang = "fr";

  blnVueIPNOpen;
  selectedVueIPNDetail;
  vuePrivateIPNDetails;

  toastOptions: ToastOptions = {
    title: "Pilotage",
    msg: "The message",
    showClose: true,
    timeout: 8000,
    theme: 'default'
  };
  isPrivateQuery: boolean;
  isExecute: boolean;

  constructor(readonly router: Router, readonly apiService: BackendApiService, readonly utilService: UtilService, readonly requestService: RequestService, public dialog: MatDialog,
    readonly toastyService: ToastyService, readonly translateService: TranslateService, readonly sExcelService: ExcelService, private datePipe: DatePipe, private matomoTracker: MatomoTracker,
    readonly loaderService: LoaderService) {
    this.criteriaValues.lang = requestService.getSelectedLang();
    this.utilService.$showIcon$.next(false);
    this.screenName = this.router.url.split('/')[this.router.url.split('/').length - 1];
    this.lang = requestService.getSelectedLang();
    // console.log('fggggggggg', this.screenName);
  }

  ngOnInit() {
    const role = this.utilService.getUserProfile()['role-acc-pil-irn6804'];
    let regionRole = role.replace('[', '').replace(']', '');
    regionRole = regionRole.split(',');
    if (regionRole.length > 1) {
      this.tableValues.region = ['Europe', 'Mercosur'];
      this.tableValues.selectedRegion = "Europe";
    } else {
      if (regionRole[0] === 'acc_pil_europe') {
        this.tableValues.region = ['Europe'];
        this.tableValues.selectedRegion = "Europe";
      } else {
        this.tableValues.region = ['Mercosur'];
        this.tableValues.selectedRegion = "Mercosur";
      }
    }

    setTimeout(() => {
      this.criteriaOptions = [{ name: this.utilService.getTranslateName(`NAVBAR.PRIVATE`), value: "private" }]
      if (this.criteriaOptions.length < 2) {
        this.getChampsList();
      }
    }, 0);

    // console.log('test', window.location.pathname.split('/')[window.location.pathname.split('/').length - 1], window.location.pathname.split('/').length);
    this.tableValues.ipn = this.profile['uid']; // sessionStorage.getItem("ipn");
    this.zid = this.tableValues.ipn;
    setTimeout(() => {
      this.criteriaValues.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    }, 0);
    if (sessionStorage.getItem("request1") === null) {
      sessionStorage.setItem("request1", undefined);
      sessionStorage.setItem("request2", undefined);
      sessionStorage.setItem("request3", undefined);
    }
    this.tableValues.requestDatas = this.requestService.getAllRequestData();

    if (this.screenName !== 'vue') {
      this.selectedCriteriaOptions = 'private';
    }
    this.getChoiceTableDetails(this.tableValues.selectedRegion);
    // this.getTableNamesList();
    this.matomoTracker.trackPageView(`${this.screenName}`);
  }

  getChampsList() {
    if (this.tableValues.selectedRegion === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_save_public') > -1) {
        setTimeout(() => {
          this.criteriaOptions.push({ name: this.utilService.getTranslateName(`NAVBAR.PUBLIC`), value: "public" });
        }, 0);
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_save_public') > -1) {
        setTimeout(() => {
          this.criteriaOptions.push({ name: this.utilService.getTranslateName(`NAVBAR.PUBLIC`), value: "public" });
        }, 0);
      }
    }
  }

  showHiddenCriteriaList() {
    if (this.tableValues.selectedRegion === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_obj_syst') > -1) {
        this.isShowHiddenList = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_obj_syst') > -1) {
        this.isShowHiddenList = true;
      }
    }
  }

  getUserRole4TabeList() {
    let rtnValue;
    if (this.tableValues.selectedRegion === "Europe") {
      rtnValue = this.profile['role-gr-pil-irn6804'];
    } else {
      rtnValue = this.profile['role-gr-pim-irn9207'];
    }
    return rtnValue;
  }

  getRoleBasedTableNamesList() {
    let role;
    this.tableValues.roleBasedTableList = [];
    if (this.getUserRole4TabeList()) {
      if (this.getUserRole4TabeList().split("_")[2].toLowerCase() !== 'tout') {
        role = this.getUserRole4TabeList().toLowerCase().replace(`gr_${this.getFavoriteRegion().toLowerCase()}_`, '');
        const queryParam1 = { "input": `PROFIL@system@profil@${role.toLocaleUpperCase()}@`, "region": this.getFavoriteRegion() };
        this.apiService.getObjectGet(queryParam1).subscribe(res => {
          if (res && res.data) {
            this.roleBasedGroupList = res.data;
            for (const item of res.data.split('§')) {
              if (item.split("@")[1] === "0") {
                this.tableValues.roleBasedTableList.push(item.split("@")[0]);
              }
            }
          }
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
            console.log("error", error.message);
          });
      } else {
        this.roleBasedGroupList = '';
      }
    }
  }

  getLangIndex(lang) {
    if (lang === 'en') {
      return 1;
    } else {
      return 0;
    }
  }

  getFavoriteRegion() {
    if (this.tableValues.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getChoiceTableDetails(item) {
    this.tableValues.tableData = undefined;
    this.tableValues.selectedRegion = item;
    this.vueValues.vueList = [];
    this.criteriaValues.criteriaList = [];
    this.criteriaValues.criteriaListOringinal = [];
    this.criteriaValues.displayCriteriaList = [];
    this.vueValues.vuePrivateIPNDetails = [];
    this.criteriaValues.selectedItemValue = undefined;

    this.getRoleBasedTableNamesList();

    const queryParam = { "input": `MAJ@${this.tableValues.ipn}@@@0@11@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam).subscribe(res => {

      this.showHiddenCriteriaList();
      if (this.criteriaOptions.length < 2) {
        this.getChampsList();
      }

      this.tableValues.responseData = res;
      if (this.tableValues.responseData.lists && this.tableValues.responseData.lists.length > 0) {
        this.tableValues.selectedTable = String(this.tableValues.responseData.lists[0].table.toLocaleUpperCase());
        let date = this.tableValues.responseData.lists[0].fileUpdtdDate.split(' ');
        this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + String(date[1]) + this.utilService.getTranslateName(`NEW_QUERY.AT`) + String(date[3]);
        if (this.screenName === 'open-request' || this.screenName === 'import-request') {
          this.changeNameList();
        }
        const strUpdDate = String(this.tableValues.responseData.lists[0].fileUpdtdDate).substring(3, 13);
        const strRecDate = String(this.tableValues.responseData.lists[0].fileRecvdDate).substring(3, 13);
        const updDate = new Date(`${strUpdDate.substring(3, 5)}/${strUpdDate.substring(0, 2)}/${strUpdDate.substring(6, 10)}`);
        const recDate = new Date(`${strRecDate.substring(3, 5)}/${strRecDate.substring(0, 2)}/${strRecDate.substring(6, 10)}`);

        const diff = Math.abs(updDate.getTime() - new Date().getTime());
        const diffDays = Math.floor(diff / (1000 * 3600 * 24));
        this.tableValues.dateDiff = diffDays;
        if (this.tableValues.responseData.noOfLists > 0) {
          this.getTableNamesList();
          // this.loadCriteriaListDetails();
          this.getFilterCategoryDetails();
          if (this.screenName === 'vue') {
            this.getPrivateFilterCategoryDetails();
            this.getPrivateVueDetailsIPNs();
          } else if (this.screenName === 'extraction-tool') {
            this.getPrivateVueDetailsIPNs();
          } else {
            // this.getChampsPrivateFilterCategoryDetails();
            this.selectChampsOption(this.selectedCriteriaOptions);
          }
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_LIST`));
        console.log("error", error.message);
      });
  }

  getTableNamesList() {
    this.tableValues.tableData = undefined;
    const queryParam1 = { "input": `TABGEN@${this.tableValues.ipn}@@@0@100@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam1).subscribe(res => {
      let tabData = [];
      tabData = res.lists;
      if (tabData) {
        this.tableValues.selectedTable = String(tabData[0].toLocaleUpperCase());
        this.tableValues.tableData = [];
        let i = 0;
        for (const item of tabData) {
          // this.tableValues.tableData.push({ "name": String(item).toLocaleUpperCase() })
          const td = this.tableValues.responseData.lists.filter(a => String(a.table).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          const roleTable = this.tableValues.roleBasedTableList.filter(a => String(a).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          // if (td.length > 0) {
          if (td.length > 0 && roleTable.length === 0) {
            this.tableValues.tableData.push({ "name": String(item).toLocaleUpperCase(), "fileRecvdDate": td[0].fileRecvdDate, "fileUpdtdDate": td[0].fileUpdtdDate })
          }
          i = i + 1;
        }
        this.tableValues.tableData = this.tableValues.tableData.sort();
        if (this.tableValues.tableData.length > 0) {
          if (this.tableValues.tableData.indexOf('BCV') < 0) {
            this.onTableChange(this.tableValues.tableData[0]);
          }
        }
        if (this.screenName === 'extraction-tool') {
          this.tableValues.tableData = this.tableValues.tableData.filter(item => {
            return (item.name !== 'CARNETS' && item.name !== 'LEX')
          })
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_LIST`));
        console.log("error", error.message);
      });
  }

  onTableChange(item) {
    this.tableValues.dateDiff = undefined;

    this.vueValues.vueList = [];
    this.criteriaValues.criteriaList = [];
    this.criteriaValues.criteriaListOringinal = [];
    this.criteriaValues.displayCriteriaList = [];
    this.vueValues.vuePrivateIPNDetails = [];
    this.criteriaValues.selectedItemValue = undefined;
    this.criteriaValues.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);

    this.tableValues.selectedTable = item.name;
    this.utilService.$favoriteConnecton$.next(this.tableValues.selectedRegion);
    // let region = this.requestService.getRegionMsg();
    // if (region['europe'] === 'false' || region['mercosur'] === 'false') {
    //   this.displayMessage(region);
    // }

    this.loadCriteriaListDetails();
    this.getFilterCategoryDetails();
    if (this.screenName === 'open-request' || this.screenName === 'import-request') {
      this.queryPrivateList = [];
      this.queryPublicList = [];
      this.changeNameList();
    }
    if (this.screenName === 'vue') {
      this.getPrivateFilterCategoryDetails();
      this.getPrivateVueDetailsIPNs();
    } else if (this.screenName === 'extraction-tool') {
      this.getPrivateVueDetailsIPNs();
    } else {
      // this.getChampsPrivateFilterCategoryDetails();
      this.selectChampsOption(this.selectedCriteriaOptions);
    }
    let date = item.fileUpdtdDate.split(' ');
    this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + String(date[1]) + this.utilService.getTranslateName(`NEW_QUERY.AT`) + String(date[3]);
    const strUpdDate = String(item.fileUpdtdDate).substring(3, 13);
    const strRecDate = String(item.fileRecvdDate).substring(3, 13);
    const updDate = new Date(`${strUpdDate.substring(3, 5)}/${strUpdDate.substring(0, 2)}/${strUpdDate.substring(6, 10)}`);
    const recDate = new Date(`${strRecDate.substring(3, 5)}/${strRecDate.substring(0, 2)}/${strRecDate.substring(6, 10)}`);

    const diff = Math.abs(updDate.getTime() - new Date().getTime());
    const diffDays = Math.floor(diff / (1000 * 3600 * 24));
    this.tableValues.dateDiff = diffDays;
  }

  getFilterCategoryDetails() {
    this.criteriaValues.filterCategory = [];
    const queryParam = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaValues.filterCategory = res1.lists;
          this.criteriaValues.filterCategory = this.criteriaValues.filterCategory.sort(this.utilService.sortArray);
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });
  }

  getChampsFilterCategoryDetails() {
    this.vueValues.vuePrivateIPNDetails = undefined;
    const queryParam = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.vueValues.vuePrivateIPNDetails = res1.lists;
          this.vueValues.vuePrivateIPNDetails = this.vueValues.vuePrivateIPNDetails.sort(this.utilService.sortArray);
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });

  }

  getPrivateFilterCategoryDetails() {
    this.criteriaValues.filterPrivateCategory = undefined;
    const queryParam = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaValues.filterPrivateCategory = res1.lists;
          this.criteriaValues.filterPrivateCategory = this.criteriaValues.filterPrivateCategory.sort(this.utilService.sortArray)
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });

  }

  getChampsPrivateFilterCategoryDetails() {
    this.vueValues.vuePrivateIPNDetails = undefined;
    const queryParam = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.vueValues.vuePrivateIPNDetails = res1.lists;
          this.vueValues.vuePrivateIPNDetails = this.vueValues.vuePrivateIPNDetails.sort(this.utilService.sortArray)
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });

  }

  // onTableChange(item) {
  //   this.tableValues.selectedTable = item;
  // }

  loadCriteriaListDetails() {
    this.criteriaValues.displayCriteriaList = [];
    this.criteriaValues.criteriaList = [];
    this.criteriaValues.criteriaListOringinal = [];
    const queryParam = { "input": `CHAMP ETENDU@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `CHAMP ETENDU@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaValues.criteriaListOringinal = res1.lists;
          for (const item of this.criteriaValues.criteriaListOringinal) {
            if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) === 'undefined') {
              item.longLabel = `${item.longLabel}${this.criteriaValues.splitChar}${item.longLabel}`;

              if (this.isShowHiddenList) {
                this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              } else {
                if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
                  this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                  this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                }
              }

              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            } else {
              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);

              if (this.isShowHiddenList) {
                this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              } else {
                if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
                  this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                  this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                }
              }
            }
          }
          this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });
  }

  onCategoryChange(val, type) {
    this.criteriaValues.selectedCategory = val;
    if (type === 'private') {
      this.getSearchCategory(this.tableValues.ipn);
    } else {
      this.getSearchCategory('system');
    }
  }

  getSearchCategory(ipnValue) {
    this.criteriaValues.categoryList = [];
    this.criteriaValues.displayCriteriaList = [];
    this.criteriaValues.criteriaList = [];
    if (this.criteriaValues.selectedCategory === 'Tous' || this.criteriaValues.selectedCategory === 'All') {
      for (const item of this.criteriaValues.criteriaListOringinal) {
        // if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) !== 'undefined') {
        //   this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)])
        //   this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)])
        // }

        if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) === 'undefined') {
          item.longLabel = `${item.longLabel}${this.criteriaValues.splitChar}${item.longLabel}`;

          if (this.isShowHiddenList) {
            this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          } else {
            if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
              this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            }
          }

          // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
        } else {
          // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);

          if (this.isShowHiddenList) {
            this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
          } else {
            if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
              this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            }
          }
        }

      }
      this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);
    } else {
      const queryParam = { "input": `FILTRE@${ipnValue}@${this.tableValues.selectedTable}@${this.criteriaValues.selectedCategory}@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectGet(queryParam).subscribe(res => {

        const dataRes = res.data.split('@');
        for (let i = 0; i < dataRes.length; i = i + 4) {
          if (i > 0) {
            this.criteriaValues.categoryList.push(dataRes[i - 1]);
          }
        }

        for (const iterator of this.criteriaValues.categoryList) {
          const filterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === iterator.substring(0, iterator.length - 2).toUpperCase() && a.typeOfField == iterator.slice(-1));
          for (const item of filterVal) {
            // if (typeof (iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) !== 'undefined') {
            //   this.criteriaValues.displayCriteriaList.push(iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            //   this.criteriaValues.criteriaList.push(iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            // }

            if (typeof (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) === 'undefined') {
              item.longLabel = `${item.longLabel}${this.criteriaValues.splitChar}${item.longLabel}`;

              if (this.isShowHiddenList) {
                this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              } else {
                if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
                  this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                  this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                }
              }

              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            } else {
              // this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              // this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);

              if (this.isShowHiddenList) {
                this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
              } else {
                if (item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)][0] !== '_') {
                  this.criteriaValues.displayCriteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                  this.criteriaValues.criteriaList.push(item.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
                }
              }
            }


          }
        }
        this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);

      },
        error => {
          this.toastyService.error(this.utilService.getTranslateName(`ERROR.SEARCH_LIST`));
          console.log("error", error.message);
        });
    }
  }

  onChampsCategoryChange(type) {
    if (type === 'private') {
      this.getChampsSearchCategory(this.tableValues.ipn);
    } else {
      this.getChampsSearchCategory('system');
    }
  }

  getChampsSearchCategory(ipnValue) {
    this.vueValues.vueList = [];
    this.vueValues.previousVueList = [];
    let champsCategoryList = [];
    const queryParam = { "input": `FILTRE@${ipnValue}@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectGet(queryParam).subscribe(res => {

      const dataRes = res.data.split('@');
      for (let i = 0; i < dataRes.length; i = i + 4) {
        if (i > 0) {
          champsCategoryList.push(dataRes[i - 1]);
        }
      }

      for (const item of champsCategoryList) {
        const filterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === item.substring(0, item.length - 2).toUpperCase() && a.typeOfField == item.slice(-1));
        for (const iterator of filterVal) {
          if (typeof (iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]) !== 'undefined') {
            // this.criteriaValues.displayCriteriaList.push(iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            // this.criteriaValues.criteriaList.push(iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)]);
            this.vueValues.vueList.push({ "name": iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)], "type": iterator.typeOfField, 'GCOName': iterator.GCOName, 'size': iterator.size, visible: true });
            this.vueValues.previousVueList.push({ "name": iterator.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)], "type": iterator.typeOfField, 'GCOName': iterator.GCOName, 'size': iterator.size, visible: true });
          }
        }
      }
      this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);

    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.SEARCH_LIST`));
        console.log("error", error.message);
      });
  }

  clickCriteriaList(item, index) {
    this.criteriaValues.selectedItemValue = item;
    this.criteriaValues.selectedItemIndex = index;
    this.criteriaValues.selectedItemType = undefined;
    // console.log('this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedItemValue)[0]', this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === this.criteriaValues.selectedItemValue)[0])
    this.criteriaValues.selectedItemType4Color = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === this.criteriaValues.selectedItemValue)[0].typeOfField;
  }

  selectChampsOption(event) {
    this.selectedCriteriaOptions = event;
    this.vueValues.vueList = [];
    this.vueValues.previousVueList = [];
    if (event === 'private') {
      this.getChampsPrivateFilterCategoryDetails();
    } else {
      this.getChampsFilterCategoryDetails();
    }
  }

  getPrivateVueDetailsIPNs() {
    this.vueValues.vuePrivateIPNDetails = undefined;
    const queryParam = { "input": `AFFICH@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `AFFICH@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.vueValues.vuePrivateIPNDetails = res1.lists;
          // const sorter = this.utilService.MySort(' ?*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz');
          this.vueValues.vuePrivateIPNDetails = this.vueValues.vuePrivateIPNDetails.sort(this.utilService.sortArray);
          if (this.vueValues.selectedVueIPNDetail) {
            if (this.vueValues.vuePrivateIPNDetails.indexOf(this.vueValues.selectedVueIPNDetail) === -1) {
              this.vueValues.selectedVueIPNDetail = undefined;
              this.vueValues.vueList = [];
              this.vueValues.previousVueList = [];
            }
          }
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
            console.log("error", error.message);
          });
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TECHNICAL_ERROR`));
        console.log("error", error.message);
      });

  }

  openDialog(item): void {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '350px',
      data: { 'display': this.utilService.getTranslateName(`NEW_QUERY.DISPLAY_NAME`), 'isText': true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let queryParam;
        if (this.vueValues.vuePrivateIPNDetails) {
          const isExistsPrivateIPN = this.vueValues.vuePrivateIPNDetails.filter(a => a.toUpperCase() === result.toUpperCase());
          // console.log('resultresultresult', isExistsPrivateIPN);
          if (isExistsPrivateIPN.length > 0) {
            this.toastyService.warning(this.utilService.getTranslateName(`ERROR.NAME_EXIST`));
            return;
          }
        }
        if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
          queryParam = { "input": `AFFICH@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${result}@${this.saveVueDetailsData()}`, "region": this.getFavoriteRegion() };
        } else {
          if (this.selectedCriteriaOptions === 'private') {
            queryParam = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${result}@${this.saveChampsDetailsData()}`, "region": this.getFavoriteRegion() };
          } else {
            queryParam = { "input": `FILTRE@system@${this.tableValues.selectedTable}@${result}@${this.saveChampsDetailsData()}`, "region": this.getFavoriteRegion() };
          }
        }
        this.apiService.saveObject(queryParam).subscribe(resp => {
          if (!this.vueValues.isVueDetailSave) {
            if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
              this.loadVuePrivateIPNData(item);
            } else {
              // this.getChampsPrivateFilterCategoryDetails();
              this.selectChampsOption(this.selectedCriteriaOptions);
              this.vueValues.selectedVueIPNDetail = result;
            }
          } else {
            this.vueValues.isVueDetailSave = false;
            this.vueValues.selectedVueIPNDetail = result;
            if (this.screenName === 'vue') {
              this.getPrivateVueDetailsIPNs();
              this.loadVuePrivateIPNData(this.vueValues.selectedVueIPNDetail);
            } else if (this.screenName === 'extraction-tool') {
              this.getPrivateVueDetailsIPNs();
            } else {
              // this.getChampsPrivateFilterCategoryDetails();
              this.selectChampsOption(this.selectedCriteriaOptions);
              this.onChampsCategoryChange(this.selectedCriteriaOptions);

            }
          }
        },
          error => {
            this.toastyService.error("Error getting save");
            console.log("error", error.message);
          });
      } else {
        // if (!this.vueValues.isVueDetailSave) {
        //   if (this.screenName === 'vue') {
        //     this.loadVuePrivateIPNData(item);
        //   } else {
        //     this.onChampsCategoryChange(this.selectedCriteriaOptions);
        //   }
        // } else {
        //   if (this.screenName === 'vue') {
        //     this.loadVuePrivateIPNData(this.vueValues.selectedVueIPNDetail);
        //   } else {
        //     this.onChampsCategoryChange(this.selectedCriteriaOptions);

        //   }
        // }

        this.vueValues.vueList = [];
        this.vueValues.previousVueList = [];
        this.vueValues.isVueDetailSave = false;
        if (this.vueValues.selectedVueIPNDetail || item !== '') {
          if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
            if (this.vueValues.selectedVueIPNDetail) {
              this.loadVuePrivateIPNData(this.vueValues.selectedVueIPNDetail);
            } else {
              this.loadVuePrivateIPNData(item);
            }
          } else {
            this.onChampsCategoryChange(this.selectedCriteriaOptions);
          }
        }
      }
    });
  }

  openDialogUpdate(item): void {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '350px',
      // data: { 'display': this.utilService.getTranslateName(`ERROR.VALID_CHANGES`), 'isText': false }
      data: { 'display': `${this.utilService.getTranslateName(`NAVBAR.VUEMODIFY`)}"${this.vueValues.selectedVueIPNDetail}"${this.utilService.getTranslateName(`NAVBAR.VUEUPDATE`)}`, 'isText': false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let queryParam;
        if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
          queryParam = { "input": `AFFICH@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@${this.saveVueDetailsData()}`, "region": this.getFavoriteRegion() };
        } else {
          if (this.selectedCriteriaOptions === 'private') {
            queryParam = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@${this.saveChampsDetailsData()}`, "region": this.getFavoriteRegion() };
          } else {
            queryParam = { "input": `FILTRE@system@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@${this.saveChampsDetailsData()}`, "region": this.getFavoriteRegion() };
          }
        }
        this.apiService.saveObject(queryParam).subscribe(resp => {
          if (!this.vueValues.isVueDetailSave) {
            if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
              this.loadVuePrivateIPNData(item);
            } else {
              this.vueValues.previousVueList = this.vueValues.vueList;
              // this.getChampsPrivateFilterCategoryDetails();
              // this.vueValues.selectedVueIPNDetail = result;
              this.vueValues.selectedVueIPNDetail = item;
              this.onChampsCategoryChange(this.selectedCriteriaOptions);
            }
          }
          this.vueValues.isVueDetailSave = false;
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.SAVE`));
            console.log("error", error.message);
          });
      } else {
        if (!this.vueValues.isVueDetailSave && item !== this.vueValues.selectedVueIPNDetail) {
          // this.loadVuePrivateIPNData(item);
          this.vueValues.selectedVueIPNDetail = undefined;
          this.vueValues.vueList = [];
          this.vueValues.previousVueList = [];
          this.vueValues.isVueDetailSave = false;
          if (this.vueValues.selectedVueIPNDetail || item !== '') {
            if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
              // console.log('this.vueValues.selectedVueIPNDetail', this.vueValues.selectedVueIPNDetail, item, this.selectedCriteriaOptions)
              if (this.vueValues.selectedVueIPNDetail) {
                this.loadVuePrivateIPNData(this.vueValues.selectedVueIPNDetail);
              } else {
                this.loadVuePrivateIPNData(item);
              }
            } else {
              // console.log('this.vueValues.selectedVueIPNDetail', this.vueValues.selectedVueIPNDetail, item, this.selectedCriteriaOptions)
              this.vueValues.selectedVueIPNDetail = item;
              this.onChampsCategoryChange(this.selectedCriteriaOptions);
            }
          }


        }
        this.vueValues.isVueDetailSave = false;
      }
    });
  }

  loadVuePrivateIPNData(item) {
    this.vueValues.selectedVueIPNDetail = item;
    if (this.vueValues.selectedVueIPNDetail !== '') {
      this.vueValues.vueList = [];
      this.vueValues.previousVueList = [];
      const queryParam = { "input": `AFFICH@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectGet(queryParam).subscribe(res => {
        // console.log("loadVuePrivateIPNData", res.data);
        const data = this.dataSplitFromViewSavedQuery(res.data);
        for (const item of data) {
          if (typeof (item.value) === 'undefined') {
            this.vueValues.vueList.push({ 'name': item[this.criteriaValues.lang], 'type': item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
            this.vueValues.previousVueList.push({ 'name': item[this.criteriaValues.lang], 'type': item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
          } else {
            this.vueValues.vueList.push({ "name": item[this.criteriaValues.lang] + `[${item['value']}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
            this.vueValues.previousVueList.push({ "name": item[this.criteriaValues.lang] + `[${item['value']}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
          }
        }
      },
        error => {
          this.toastyService.error(this.utilService.getTranslateName(`ERROR.LOADING_LIST`));
          console.log("error", error.message);
        });
    } else if (this.vueValues.selectedVueIPNDetail === '') {
      this.vueValues.vueList = [];
    }
  }

  selectVuePrivateIPN(item) {
    this.criteriaValues.selectedItemType = undefined;
    this.criteriaValues.selectedItemValue = undefined;
    this.criteriaValues.selectedItemIndex = undefined;
    this.vueValues.vueEditIndex = undefined;
    this.vueValues.vueEditValue = undefined;
    this.selectedVueIPNDetail = item;
    if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
      if (this.vueValues.selectedVueIPNDetail !== item && this.vueValues.vueList.length > 0) {
        if (!_.isEqual(this.vueValues.vueList, this.vueValues.previousVueList)) {
          if (!this.vueValues.selectedVueIPNDetail) {
            this.vueValues.isVueDetailSave = true;
            this.vueValues.selectedVueIPNDetail = item;
            this.openDialog('');
          } else {
            this.openDialogUpdate(item)
          }
        } else if (this.vueValues.selectedVueIPNDetail) {
          this.loadVuePrivateIPNData(item);
        }
      } else {
        this.loadVuePrivateIPNData(item);
      }
    } else {

      if (this.vueValues.selectedVueIPNDetail !== item && this.vueValues.vueList.length > 0) {
        if (!_.isEqual(this.vueValues.vueList, this.vueValues.previousVueList)) {
          // console.log('_.isEqual(this.vueValues.vueList, this.vueValues.previousVueList)', _.isEqual(this.vueValues.vueList, this.vueValues.previousVueList), this.vueValues.vueList, this.vueValues.previousVueList)
          if (!this.vueValues.selectedVueIPNDetail) {
            this.vueValues.isVueDetailSave = true;
            this.vueValues.selectedVueIPNDetail = item;
            this.openDialog('');
          } else {
            this.openDialogUpdate(item)
          }
        } else if (this.vueValues.selectedVueIPNDetail) {
          this.vueValues.selectedVueIPNDetail = item;
          this.onChampsCategoryChange(this.selectedCriteriaOptions);
        }
      } else {
        this.vueValues.selectedVueIPNDetail = item;
        this.onChampsCategoryChange(this.selectedCriteriaOptions);
      }
    }
  }

  getCriteriaDetailsFromriteriaListOriginal(data, type) {
    const filterData = this.criteriaValues.criteriaListOringinal.filter(z => z.GCOName === data && z.typeOfField.toString() === type.toString());
    if (filterData.length > 0) {
      return filterData[0];
    }
  }

  dataSplitFromViewSavedQuery(queryData) {
    let i: number;
    let returnVal = [];
    if (queryData) {
      const splitData = queryData.split("@");
      for (i = 1; i < splitData.length; i++) {
        if (splitData[i] === '1') {
          i = i + 3;
          let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
          returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
          returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[1];
          returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[0];
          returnVal[returnVal.length - 1]['value'] = undefined;
          returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
          i = i + 1;
        }
        else if (splitData[i] === '2') {

          i = i + 2;
          let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
          returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
          returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[1];
          returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[0];
          let listVal = undefined;
          returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
          i = i + 2;
          const subType = splitData[i];
          if (subType === '0') {
            i = i + 1;
            listVal = [];
            const loopVal = splitData[i];
            criteriaListVal['value'] = [];
            for (let k = 0; k < loopVal; k++) {
              i = i + 3;
              listVal.push(splitData[i].trim());
            }
            returnVal[returnVal.length - 1]['value'] = listVal;
          } else if (subType === '1') {
            returnVal[returnVal.length - 1]['value'] = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`) + `,${splitData[i + 1].trim()}`;
          } else if (subType === '2') {
            returnVal[returnVal.length - 1]['value'] = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`);
          } else if (subType === '3') {
            returnVal[returnVal.length - 1]['value'] = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`);
          }
        }
        else if (splitData[i] === '3') {
          i = i + 1;
          const dateFormat = splitData[i];
          if (dateFormat === '9') {
            i = i + 2;
            let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
            returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
            returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[1];
            returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[0];
            returnVal[returnVal.length - 1]['value'] = splitData[i - 1];
            returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
            i = i + 1;
          } else {
            i = i + 2;
            let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
            returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
            returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[1];
            returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[0];
            returnVal[returnVal.length - 1]['value'] = this.dateGroupValue[dateFormat];
            returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
            i = i + 1;
          }
        }
        else if (splitData[i] === '4') {
          i = i + 2;
          let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
          returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
          returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[1];
          returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.criteriaValues.splitChar)[0];
          returnVal[returnVal.length - 1]['value'] = undefined;
          returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
          i = i + 1;
        }
      }
    }
    return returnVal;
  }

  saveVueDetailsData(): string {

    let saveQuery: string;
    if (this.vueValues.vueList.length > 0) {
      const listCount = this.vueValues.vueList.length;
      saveQuery = listCount.toString();
      for (const item of this.vueValues.vueList) {
        const affichDataType = item.type === 1 ? "2" : item.type === 3 ? item.type.toString() : item.type === 4 ? item.type.toString() : "1";
        if (affichDataType === '1') {
          saveQuery = `${saveQuery}@${affichDataType}@0@${item.size}@${item.GCOName}_${item.type}@${item.type}`;
        } else if (affichDataType === '2') {
          let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
          if (itemValue === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`)) {
            saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@2@0`;
          } else if (itemValue === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`)) {
            saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@3@0`;
          } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)) {
            saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@1@${itemValue.split(',')[1]}`;
          } else {
            if (itemValue.split(',').length > 0) {
              saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@0@${itemValue.split(',').length}`;
              for (const valItem of itemValue.split(',')) {
                if (valItem.length !== 6) {
                  let emptySpace = '';
                  for (let x = 0; x < (6 - valItem.length); x++) {
                    emptySpace = `${emptySpace} `;
                  }
                  saveQuery = `${saveQuery}@0@0@${valItem}${emptySpace}`;
                } else {
                  saveQuery = `${saveQuery}@0@0@${valItem}`;
                }
              }
            }
          }
        } else if (affichDataType === '4') {
          saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_4@4`;
        } else if (affichDataType === '3') {
          let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2))
          const indexval = this.dateGroupValue.indexOf(itemValue);
          if (indexval !== -1) {
            saveQuery = `${saveQuery}@${affichDataType}@${indexval}@0@${item.GCOName}_3@3`;
          } else {
            saveQuery = `${saveQuery}@${affichDataType}@9@${itemValue}@${item.GCOName}_3@3`;
          }
        }
      }
      if (saveQuery.length > 5) {
        saveQuery = `${saveQuery}@`;
        return saveQuery;
      }
    }
  }

  saveChampsDetailsData(): string {
    let saveQuery: string;
    if (this.vueValues.vueList.length > 0) {
      const listCount = this.vueValues.vueList.length;
      saveQuery = listCount.toString();
      for (const item of this.vueValues.vueList) {
        saveQuery = `${saveQuery}@4@${item.size}@${item.GCOName}_${item.type}@${item.type}`;
      }
      if (saveQuery.length > 5) {
        saveQuery = `${saveQuery}@`;
        return saveQuery;
      }
    }
  }


  removeSelectedItem(index, id) {
    var selectList = document.getElementById(id);
    var selectOptions = selectList.getElementsByTagName('mat-list-option');
    for (var i = 0; i < selectOptions.length; i++) {
      if (i !== index && selectOptions[i].getAttribute('aria-selected') === 'true') {
        let div = selectOptions[i].getAttribute('id');
        document.getElementById(div).click()
      } else if (i === index && selectOptions[i].getAttribute('aria-selected') === 'false') {
        let div = selectOptions[i].getAttribute('id');
        document.getElementById(div).click()
      }
    }
  }

  moveRight() {

    setTimeout(() => {
      var objDiv = document.getElementById('vueDetails');
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 10);
    if (typeof (this.criteriaValues.selectedItemValue) !== 'undefined') {
      this.criteriaValues.selectedGroupItemValue = this.criteriaValues.selectedItemValue;
      this.criteriaValues.blnMoveRight = true;
      const criteriaFilterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === this.criteriaValues.selectedItemValue)[0];
      this.criteriaValues.selectedItemType = criteriaFilterVal.typeOfField;
      this.criteriaValues.GCOName = criteriaFilterVal.GCOName;
      this.getCharacterData(criteriaFilterVal);
      if (this.screenName === 'vue' || this.screenName === 'extraction-tool') {
        if (this.criteriaValues.selectedItemType !== 3 && this.criteriaValues.selectedItemType !== 1) {

          this.vueValues.vueList.push({ "name": this.criteriaValues.selectedItemValue, "type": this.criteriaValues.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, "size": criteriaFilterVal.size, "visible": true, "longName": this.criteriaValues.selectedItemValue, "shortName": criteriaFilterVal.shortLabel });
          // console.log("this.vueValues.vueList", this.vueValues.vueList, criteriaFilterVal);
          // this.selectedItemValue = undefined;
          // this.selectedItemType = undefined;
        }
        this.displayGrouping();
      } else {
        if (this.vueValues.vueList.filter(x => x.name.toUpperCase() === this.criteriaValues.selectedItemValue.toUpperCase() && x.type === this.criteriaValues.selectedItemType).length <= 0) {
          this.vueValues.vueList.push({ "name": this.criteriaValues.selectedItemValue, "type": this.criteriaValues.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, "size": criteriaFilterVal.size, "visible": true });
        }
      }
    }
    // console.log(this.vueValues.vueList);
    this.vueValues.groupingEditValue = undefined;
    this.criteriaValues.selectedItemValue = undefined;
    this.criteriaValues.selectedItemIndex = undefined;
  }

  moveLeft() {
    if (this.criteriaValues.groupingSelectedList.length > 0) {
      for (const item of this.criteriaValues.groupingSelectedList) {
        this.vueValues.vueList.splice(this.vueValues.vueList.indexOf(item), 1);
      }
      // if (typeof (this.selectedGroupItemValue) !== 'undefined') {
      //   if (this.groupList.indexOf(this.selectedGroupItemValue) > -1) {
      //     this.groupList.splice(this.groupList.indexOf(this.selectedGroupItemValue), 1);
      //     this.selectedGroupItemValue = undefined;
      //   }
      // }
      this.criteriaValues.selectedGroupItemValue = undefined;
      this.vueValues.groupingEditValue = undefined;
      this.criteriaValues.selectedItemValue = undefined;
      this.criteriaValues.selectedItemIndex = undefined;
      this.criteriaValues.selectedItemType = undefined;
      this.criteriaValues.groupingSelectedList = [];

    }
  }

  displayGrouping() {
    if (this.criteriaValues.selectedItemType === 1) {
      this.criteriaValues.displayGroupingForm = true;
    }
    return this.criteriaValues.displayGroupingForm;
  }

  getCharacterData(criteriaFilterVal) {
    this.criteriaValues.selectedCharacterList = {
      'longLabel': criteriaFilterVal.longLabel,
      'size': criteriaFilterVal.size,
      'selectedTable': this.tableValues.selectedTable,
      'ipn': this.tableValues.ipn,
      'region': this.tableValues.region,
      'selectedGroupItemValue': this.criteriaValues.selectedGroupItemValue
    };
  }

  onGroupDateCancel(event) {
    this.criteriaValues.selectedItemType = undefined;
    this.criteriaValues.selectedItemValue = undefined;
    this.criteriaValues.selectedGroupItemValue = undefined;
    this.criteriaValues.blnMoveRight = false;
    this.vueValues.vueEditValue = undefined
  }

  onGroupDateOk(event, section) {
    if (event.value !== '') {
      const criteriaFilterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split(this.criteriaValues.splitChar, 2)[this.getLangIndex(this.criteriaValues.lang)] === this.criteriaValues.selectedGroupItemValue)[0];
      if (section === 'vue') {
        if (!this.criteriaValues.blnMoveRight) {
          if (typeof (event.value) !== "undefined") {
            this.vueValues.vueList[this.vueValues.vueEditIndex].name = this.criteriaValues.selectedGroupItemValue + `[${event.value}]`;
          } else {
            this.vueValues.vueList[this.vueValues.vueEditIndex].name = this.criteriaValues.selectedGroupItemValue + `[${event}]`;
          }
        } else {
          if (typeof (event.value) !== "undefined") {
            this.vueValues.vueList.push({ "name": this.criteriaValues.selectedGroupItemValue + `[${event.value}]`, "type": this.criteriaValues.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size, "visible": true, "longName": this.criteriaValues.selectedGroupItemValue, "shortLabel": criteriaFilterVal.shortLabel });
          } else {
            this.vueValues.vueList.push({ "name": this.criteriaValues.selectedGroupItemValue + `[${event}]`, "type": this.criteriaValues.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size, "visible": true, "longName": this.criteriaValues.selectedGroupItemValue, "shortLabel": criteriaFilterVal.shortLabel });
          }
        }
      }
    }
    this.criteriaValues.selectedItemType = undefined;
    this.criteriaValues.selectedItemValue = undefined;
    this.criteriaValues.selectedGroupItemValue = undefined;
    this.criteriaValues.blnMoveRight = false;
    this.vueValues.vueEditValue = undefined
  }

  dblClickVue(indexval) {
    this.criteriaValues.selectedItemType = undefined;
    setTimeout(() => {
      var objDiv = document.getElementById('vueDetails');
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 10);
    this.removeSelectedItem(indexval, 'vueDetails')
    if (this.vueValues.vueList[indexval].name.indexOf('[') > -1) {
      this.criteriaValues.selectedGroupItemValue = this.vueValues.vueList[indexval].name.substr(0, this.vueValues.vueList[indexval].name.indexOf('['));
      this.vueValues.vueEditValue = this.vueValues.vueList[indexval].name.substr(this.vueValues.vueList[indexval].name.indexOf('[') + 1, (this.vueValues.vueList[indexval].name.length - this.vueValues.vueList[indexval].name.indexOf('[') - 2));
      // this.selectedItemType = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue)[0].typeOfField;
      this.criteriaValues.selectedItemType = this.vueValues.vueList[indexval].type;
      this.vueValues.vueEditIndex = indexval;
      this.displayGrouping();
    } else {
      this.criteriaValues.selectedGroupItemValue = this.vueValues.vueList[indexval].name;
      this.vueValues.vueEditValue = undefined;
      // this.selectedItemType = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue)[0].typeOfField;
      this.criteriaValues.selectedItemType = this.vueValues.vueList[indexval].type;
      if (this.criteriaValues.selectedItemType === 4) {
        this.criteriaValues.selectedItemType = undefined;
        this.vueValues.vueEditIndex = indexval;
      }
    }
  }

  add() {
    if (this.vueValues.vueList.length > 0) {
      this.vueValues.isVueDetailSave = true;
      this.openDialog('');
    }
  }

  modify() {
    if (this.vueValues.selectedVueIPNDetail && this.vueValues.vueList.length > 0) {
      this.vueValues.isVueDetailSave = false;
      this.openDialogUpdate(this.vueValues.selectedVueIPNDetail);
    }
  }

  reset() {
    this.selectedVueIPNDetail = undefined;
    this.vueValues.vueList = [];
    this.vueValues.selectedVueIPNDetail = undefined;
    this.selectedCriteriaOptions = 'private';
  }

  delete() {
    if (this.vueValues.selectedVueIPNDetail) {
      const dialogRef = this.dialog.open(AlertConfirmationComponent, {
        disableClose: true,
        width: '350px',
        data: { 'display': this.utilService.getTranslateName(`NEW_QUERY.DELETE`), 'isText': false }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let queryParam;
          if (this.screenName === 'vue') {
            queryParam = { "input": `AFFICH@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@`, "region": this.getFavoriteRegion() };
          } else {
            if (this.selectedCriteriaOptions === 'private') {
              queryParam = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@`, "region": this.getFavoriteRegion() };
            } else {
              queryParam = { "input": `FILTRE@system@${this.tableValues.selectedTable}@${this.vueValues.selectedVueIPNDetail}@`, "region": this.getFavoriteRegion() };
            }
          }
          this.apiService.deleteObject(queryParam).subscribe(resp => {
            if (this.screenName === 'vue') {
              this.getPrivateVueDetailsIPNs();
            } else if (this.screenName === 'extraction-tool') {
              this.getPrivateVueDetailsIPNs();
            } else {
              // this.getChampsPrivateFilterCategoryDetails();
              this.selectChampsOption(this.selectedCriteriaOptions);
            }
            this.vueValues.selectedVueIPNDetail = undefined;
            this.vueValues.vueList = [];
          },
            error => {
              this.toastyService.error(this.utilService.getTranslateName(`ERROR.DELETE`));
              console.log("error", error.message);
            });
        }
      });
    }
  }

  clickList(item, index) {
    this.criteriaValues.selectedItemType = undefined;
    if (this.criteriaValues.groupingSelectedList.length === 0) {
      this.criteriaValues.groupingSelectedList.push(item);
    } else {
      if (this.criteriaValues.groupingSelectedList.indexOf(item) < 0) {
        this.criteriaValues.groupingSelectedList.push(item);
      } else {
        this.criteriaValues.groupingSelectedList.splice(this.criteriaValues.groupingSelectedList.indexOf(item), 1);
      }
    }
    // console.log(this.criteriaValues.groupingSelectedList);
    // this.criteriaValues.selectedItemValue = item.name;
  }

  moveUp(id, section_id) {
    if (this.criteriaValues.groupingSelectedList.length > 0) {
      let array;
      array = this.vueValues.vueList;

      document.getElementById(id).scrollBy(0, -10);
      var selectList = document.getElementById(section_id);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      for (var i = 0; i < array.length; i++) {
        if (i > 0) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let old_value = array.splice(i, 1)[0];
            array.splice(i - 1, 0, old_value);
          }
        } else if (i === 0 && selectOptions[i].getAttribute('aria-selected') === 'true') {
          let old_value = array.splice(i, 1)[0];
          array.splice(i, 0, old_value);
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click();
        }
      }
    }
  }

  moveDown(id, section_id) {
    if (this.criteriaValues.groupingSelectedList.length > 0) {
      let array;
      array = this.vueValues.vueList;
      document.getElementById(id).scrollBy(0, 10);
      var selectList = document.getElementById(section_id);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      for (var i = array.length - 1; i >= 0; i--) {
        if (i < array.length - 1) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let old_value = array.splice(i, 1)[0];
            array.splice(i + 1, 0, old_value);
          }
        } else if (i === array.length - 1 && selectOptions[i].getAttribute('aria-selected') === 'true') {
          let old_value = array.splice(i, 1)[0];
          array.splice(i, 0, old_value);
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click();
        }
      }
    }
  }

  changeNameList() {
    let ipn;
    if (this.isPublic) {
      ipn = 'system';
      let queryParam = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
      this.apiService.getSaveQueryName(queryParam).subscribe(res => {
        if (res.noObjects > 0) {
          ipn = 'system';
          let queryList = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
          this.apiService.getSaveQueryNameList(queryList).subscribe(elem => {
            this.queryPublicList = elem.lists;
            this.queryPublicList = this.queryPublicList.sort(this.utilService.sortArray);
          }, error => {
            console.log("error", error.message);
          });
        }
      }, error => {
        console.log("error", error.message);
      });
    }
    if (this.isPrivate) {
      ipn = this.zid;
      let queryParam = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
      this.apiService.getSaveQueryName(queryParam).subscribe(res => {
        if (res.noObjects > 0) {
          ipn = this.zid;
          let queryList = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
          this.apiService.getSaveQueryNameList(queryList).subscribe(elem => {
            this.queryPrivateList = elem.lists;
            this.queryPrivateList = this.queryPrivateList.sort(this.utilService.sortArray);
          }, error => {
            console.log("error", error.message);
          });
        }
      }, error => {
        console.log("error", error.message);
      });
    }
  }

  clickQueryName(item, type) {
    if (type === 'private') {
      this.selectedPrivateQueryName = item;
      this.selectedPublicQueryName = '';
      this.isPrivateQuery = true;
    } else {
      this.selectedPublicQueryName = item;
      this.selectedPrivateQueryName = '';
      this.isPrivateQuery = false;
    }
    this.queryType = type;
    this.enableButton = false;
    if (this.tableValues.selectedRegion === "Europe") {
      if (this.profile['role-pil-irn6804'].includes('pil_save_public') && this.getFavoriteRegion() === 'PIL') {
        this.enableButton = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].includes('pim_save_public') && this.getFavoriteRegion() === 'PIM') {
        this.enableButton = true;
      }
    }
    // if ((this.profile['role-pil-irn6804'].includes('pil_save_public') && this.getFavoriteRegion() === 'PIL') || (this.profile['role-pim-irn9207'].includes('pim_save_public') && this.getFavoriteRegion() === 'PIM')) {
    //   this.enableButton = true;
    // } else {
    //   this.enableButton = false;
    // }
  }

  renameQuery() {
    if (this.selectedPrivateQueryName || this.selectedPublicQueryName) {
      let ipn, queryName;
      if (this.queryType === 'public') {
        ipn = 'system';
        queryName = this.selectedPublicQueryName;
        this.selectedPrivateQueryName = '';
      } else {
        ipn = this.tableValues.ipn;
        queryName = this.selectedPrivateQueryName;
        this.selectedPublicQueryName = '';
      }
      const dialogRef = this.dialog.open(SaveQueryNameComponent, {
        width: '450px',
        data: { value: queryName, type: 'private' }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res.name !== '') {
          let queryList = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@${queryName}@`, "region": this.getFavoriteRegion() };
          this.apiService.getObjectGet(queryList).subscribe(elem => {
            let query = elem.data;
            if (this.screenName === 'open-request') {
              this.apiService.deleteObject(queryList).subscribe(elem => {
                if (elem.status === 200) {
                  let queryList = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@${res.name}@${query}`, "region": this.getFavoriteRegion() };
                  this.apiService.saveObject(queryList).subscribe(elem => {
                    this.changeNameList();
                  });
                }
              })
            } else {
              let queryList = { "input": `REQU@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${res.name}@${query}`, "region": this.getFavoriteRegion() };
              this.apiService.saveObject(queryList).subscribe(elem => {
                this.changeNameList();
              });
            }
          })
        }
      });
    } else {
      this.toastyService.warning(this.utilService.getTranslateName(`ERROR.SELECT_QUERY`))
    }
  }

  deleteQuery() {
    if (this.selectedPrivateQueryName || this.selectedPublicQueryName) {
      if (this.tableValues.ipn !== this.zid) {
        this.toastyService.warning(this.utilService.getTranslateName(`ERROR.DELETE_OTHER_USER_QUERY`))
      } else {
        const dialogRef = this.dialog.open(AlertConfirmationComponent, {
          disableClose: true,
          width: '350px',
          data: { 'display': this.utilService.getTranslateName(`NEW_QUERY.DELETE_QUERY`), 'isText': false }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            let ipn, queryName;
            if (this.queryType === 'public') {
              ipn = 'system';
              queryName = this.selectedPublicQueryName;
              this.selectedPrivateQueryName = '';
            } else {
              ipn = this.tableValues.ipn;
              queryName = this.selectedPrivateQueryName;
              this.selectedPublicQueryName = '';
            }
            let queryList = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@${queryName}@`, "region": this.getFavoriteRegion() };
            this.apiService.deleteObject(queryList).subscribe(elem => {
              this.changeNameList();
            });
          }
        })
      }
    } else {
      this.toastyService.warning(this.utilService.getTranslateName(`ERROR.SELECT_QUERY`))
    }
  }

  openQuery() {
    if (this.selectedPrivateQueryName || this.selectedPublicQueryName) {
      if (this.tableValues.selectedTable !== 'CARNETS' && this.tableValues.selectedTable !== 'LEX' && this.tableValues.dateDiff > 0) {
        this.openDialogTable();
      } else {
        this.openQueryData();
        this.utilService.$showQuickLink$.next(true);
      }
    } else {
      this.toastyService.warning(this.utilService.getTranslateName(`ERROR.SELECT_QUERY`))
    }
  }

  setOpenedWindow(windowPathName) {
    this.utilService.addOpenedWindowName(windowPathName);
  }

  openQueryData() {
    let ipn, queryName;
    if (this.queryType === 'public') {
      ipn = 'system';
      queryName = this.selectedPublicQueryName
      this.selectedPrivateQueryName = '';
    } else {
      ipn = this.zid;
      queryName = this.selectedPrivateQueryName
      this.selectedPublicQueryName = '';
    }
    this.matomoTracker.trackEvent(`${this.screenName}`, `Open ${this.tableValues.selectedRegion} - ${this.tableValues.selectedTable} ${queryName} ${ipn} query`, this.profile['uid']);
    let queryList = { "input": `REQU@${ipn}@${this.tableValues.selectedTable}@${queryName}@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectGet(queryList).subscribe(elem => {
      
     
        if (this.tableValues.requestDatas) {
          if (this.tableValues.requestDatas.length < 3) {
            this.requestService.addRequestData(this.tableValues.selectedRegion, this.tableValues.selectedTable, this.tableValues.requestDatas);
            // this.removeQueryWindowNameInList();
            this.setOpenedWindow('/home/query');
            this.router.navigateByUrl('/home/query');
          } else {
            // this.removeQueryWindowNameInList();
            this.setOpenedWindow('/home/query');
            this.router.navigateByUrl('/home/query');
            this.toastyService.info(this.utilService.getTranslateName(`ERROR.MAX_REQ`));
            // alert("User can create request maximum 3.")
          }
        } else {
          this.requestService.addRequestData(this.tableValues.selectedRegion, this.tableValues.selectedTable, this.tableValues.requestDatas);
          // this.removeQueryWindowNameInList();
          this.setOpenedWindow('/home/query');
          this.router.navigateByUrl('/home/query');
        }
        if ((this.tableValues.requestDatas.length < 4)) {
          if (this.tableValues.requestDatas[this.tableValues.requestDatas.length - 1] != null) {
            this.reqActive = this.tableValues.requestDatas.length;
          } else {
            this.reqActive = 0;
          }
        }
        if (this.roleBasedGroupList !== '') {
          let tablesList = this.roleBasedGroupList.split('§');
          tablesList.forEach(item => {
            if (item.split('@')[0] === this.tableValues.selectedTable) {
              if (item.split('@')[1] !== '0') {
                this.requestService.roleBasedQuery(item, this.reqActive);
              } else {
                this.requestService.roleBasedQuery('', this.reqActive);
              }
            }
          })
        }
        this.requestService.selectedQuery(elem.data, this.reqActive);
        this.requestService.setTabName(queryName, this.reqActive);
      
      
    })
  }

  openDialogTable() {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`ERROR.OLD_DATA`), 'isText': false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openQueryData();
        this.utilService.$showQuickLink$.next(true);
      }
    });
  }

  onEnter() {
    let queryList = { "input": `REQU@${this.zid}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryList).subscribe(res => {
      if (res.noObjects > 0) {
        let queryList = { "input": `REQU@${this.zid}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getSaveQueryNameList(queryList).subscribe(elem => {
          this.changeNameList();
        })
      }
    });
  }


  displayMessage(region) {
    if (this.tableValues.selectedRegion.toLowerCase() === 'europe') {
      if (region['mercosur'] === 'false') {
        this.requestService.addRegionMsg('true', 'false');
      } else {
        this.requestService.addRegionMsg('true', 'true');
      }
      if (region['europe'] === 'false') {
        this.getEuropeMsg()
      }
    }
    if (this.tableValues.selectedRegion.toLowerCase() === 'mercosur') {
      if (region['europe'] === 'false') {
        this.requestService.addRegionMsg('false', 'true');
      } else {
        this.requestService.addRegionMsg('true', 'true');
      }
      if (region['mercosur'] === 'false') {
        this.getMercosurMsg()
      }
    }
  }

  linkIndex = -1;
  getEuropeMsg() {
    this.linkIndex = -1;
    let queryParam = { "input": "MESS@system@Message@@0@1@", "region": 'PIL' };
    this.apiService.getMessageObjectList(queryParam).subscribe(res => {
      if (res.lists !== undefined && res.lists.length > 0) {
        let queryParam1 = { "input": `MESS@system@Message@${res.lists[0]}@`, "region": 'PIL' };
        this.apiService.getMessageObject(queryParam1).subscribe(res => {
          if (res.data !== '') {
            this.alertMsg[0].EUROPE[0] = res.data;
            if (this.alertMsg[0].EUROPE[0].includes('<BR>')) {
              const splitVal = this.alertMsg[0].EUROPE[0].split('<BR>');
              for (let index = 0; index < splitVal.length; index++) {
                const element = splitVal[index];
                if (element !== "") {
                  if (this.linkIndex === -1) {
                    this.linkIndex = element.indexOf("http") === -1 ? -1 : index;
                  }
                }
                // console.log("element", element, element.indexOf("http"));
                if (index === splitVal.length - 1) {
                  if (element !== "") {
                    this.alertMsg[0].EUROPE[index] = element;
                  }
                } else {
                  this.alertMsg[0].EUROPE[index] = element;
                }
              }
              // this.alertMsg[0].EUROPE = this.alertMsg[0].EUROPE[0].split('<BR>');
              // this.alertMsg[0].EUROPE[1] = this.alertMsg[0].EUROPE[1];
            }
            this.displayPopup();
          }
        });
      }
    });
  }

  getMercosurMsg() {
    this.linkIndex = -1;
    let queryParam1 = { "input": "MESS@system@Message@@0@1@", "region": 'PIM' };
    this.apiService.getMessageObjectList(queryParam1).subscribe(res => {
      if (res.lists !== undefined && res.lists.length > 0) {
        let queryParam1 = { "input": `MESS@system@Message@${res.lists[0]}@`, "region": 'PIM' };
        this.apiService.getMessageObject(queryParam1).subscribe(res => {
          if (res.data !== '') {
            this.alertMsg[1].MERCOSUR[0] = res.data;
            if (this.alertMsg[1].MERCOSUR[0].includes('<BR>')) {

              const splitVal = this.alertMsg[1].MERCOSUR[0].split('<BR>');
              for (let index = 0; index < splitVal.length; index++) {
                const element = splitVal[index];
                if (element !== "") {
                  if (this.linkIndex === -1) {
                    this.linkIndex = element.indexOf("http") === -1 ? -1 : index;
                  }
                }
                if (index === splitVal.length - 1) {
                  if (element !== "") {
                    this.alertMsg[1].MERCOSUR[index] = element;
                  }
                } else {
                  this.alertMsg[1].MERCOSUR[index] = element;
                }
                // this.alertMsg[1].MERCOSUR[index] = element;
              }
              // this.alertMsg[1].MERCOSUR = this.alertMsg[1].MERCOSUR[0].split('<BR>');
              // this.alertMsg[1].MERCOSUR[1] = this.alertMsg[1].MERCOSUR[1];
            }
            this.displayPopup();
          }
        });
      }
    });
  }

  displayPopup() {
    if (this.alertMsg.length > 0) {
      const dialogRef = this.dialog.open(AlertMessageComponent, {
        disableClose: true,
        width: '800px',
        data: { 'display': this.alertMsg, "linkIndex": this.linkIndex, region: this.tableValues.selectedRegion.toLowerCase(), type: 'region' }
        // data: { 'display': this.alertMsg, region: this.tableValues.selectedRegion.toLowerCase(), type: 'region' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
        }
      })
    }
  }

  clickClose() {
    const pathName = window.location.pathname;
    this.utilService.removeOpenedWindowName(pathName);
    this.utilService.$showIcon$.next(true);
    const openedWindowNameList = this.utilService.getOpenedWindowNameList()
    if (openedWindowNameList.length > 0) {
      this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
    } else {
      this.router.navigate(['home']);
    }
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {

      let fileReader: FileReader = new FileReader();
      fileReader.onload = (event) => {
        var data = fileReader.result.toString().replace(/\r\n/g, '\n').split('\n');
        this.assignValue(data);
      }
      fileReader.readAsText(file);

    }
  }
  assignValue(data) {
    this.fileData = data;
  }

  execute() {

    if (this.fileData.length && this.fileData.length > 0) {
      if (this.vueValues.vueList.length && this.vueValues.vueList.length > 0) {
        let firstElem = this.fileData[0].length;
        if (firstElem === 12 || firstElem === 15 || firstElem === 17) {
          let resultArr = this.fileData.filter(item => {
            return firstElem !== item.length;
          });
          if (resultArr.length === 0 || resultArr[0] === '') {
            if (this.tableValues.selectedTable !== 'PDFV') {
              if (this.fileData.length > 100) {
                let slicedArr = this.fileData.slice(0, 100);
                // this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.LARGE_SIZE`));

                const dialogRef = this.dialog.open(AlertConfirmationComponent, {
                  disableClose: true,
                  width: '550px',
                  data: { 'display': this.utilService.getTranslateName(`ERROR.LARGE_SIZE`), 'isText': false }
                });

                dialogRef.afterClosed().subscribe(result => {
                  if (result === true) {
                    this.getExtractedData(slicedArr);
                  }
                })
                // this.getExtractedData(slicedArr);
              } else {
                this.getExtractedData(this.fileData);
              }
            } else {
              if (this.fileData.length > 50) {
                let slicedArr = this.fileData.slice(0, 50);
                // this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.PDFV_LARGE_SIZE`));
                // this.getExtractedData(slicedArr);

                const dialogRef = this.dialog.open(AlertConfirmationComponent, {
                  disableClose: true,
                  width: '550px',
                  data: { 'display': this.utilService.getTranslateName(`ERROR.PDFV_LARGE_SIZE`), 'isText': false }
                });

                dialogRef.afterClosed().subscribe(result => {
                  if (result === true) {
                    this.getExtractedData(slicedArr);
                  }
                })
              } else {
                this.getExtractedData(this.fileData);
              }
            }
          } else {
            if (this.tableValues.selectedTable === 'BCV') {
              if (firstElem === 12) {
                this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.BCV_12_INVALID_FORMAT`));
              } else if (firstElem === 15) {
                this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.BCV_15_INVALID_FORMAT`));
              } else if (firstElem === 17) {
                this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.BCV_17_INVALID_FORMAT`));
              }
            } else if (this.tableValues.selectedTable === 'CDE') {
              this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.CDE_INVALID_FORMAT`));
            } else {
              this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.PDFV_INVALID_FORMAT`));
            }
          }
        } else {
          if (this.tableValues.selectedTable === 'CDE') {
            this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.CDE_INVALID_FORMAT`));
          } else if (this.tableValues.selectedTable === 'PDFV' || this.tableValues.selectedTable === 'PDFT') {
            this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.PDFV_INVALID_FORMAT`));
          } else {
            this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.INVALID_FORMAT`));
          }
        }
      } else {
        this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.GROUPBY_MISSING`));
      }
    } else {
      this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.FILE_UPLOAD_MISSING`));
    }
  }

  getExtractedData(array) {
    let data, firstElem;

    this.columnNames = [];

    const timeStamp = Date.now();
    const randomNumber = Math.floor(100000 + Math.random() * 900000)
    data = `${timeStamp}-${randomNumber}`;
    firstElem = array[0].length;
    if (this.tableValues.selectedTable === 'CDE') {
      if (firstElem !== 15) {
        this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.CDE_INVALID_FORMAT`));
      } else {
        data += "\n" + this.splitDataValues('CDE', array);
        data += this.generateGroupExecute('CDE', firstElem);
        data += this.generateCommonLastExecute();
      }
      // console.log(data);
    } else if (this.tableValues.selectedTable === 'PDFV' || this.tableValues.selectedTable === 'PDFT') {
      if (firstElem !== 12) {
        this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.PDFV_INVALID_FORMAT`));
      } else {
        data += "\n" + this.splitDataValues('PDFV', array);
        data += this.generateGroupExecute('PDFV', firstElem);
        data += this.generateCommonLastExecute()
      }
    } else {
      let resultArr = this.fileData.filter(item => {
        return firstElem !== item.length;
      });
      if (resultArr.length === 0 || resultArr[0] === '') {
        data += "\n" + this.splitDataValues('BCV', array);
        data += this.generateGroupExecute('BCV', firstElem);
        data += this.generateCommonLastExecute()
      } else {
        if (firstElem === 12) {
          this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.BCV_12_INVALID_FORMAT`));
        } else if (firstElem === 15) {
          this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.BCV_15_INVALID_FORMAT`));
        } else if (firstElem === 17) {
          this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.BCV_17_INVALID_FORMAT`));
        }
      }
    }
    this.num = 0;
    if (this.fileData.length && this.fileData.length > 0) {
      const queryParam = { "inputLists": `${data}`.split('\n'), "region": this.getFavoriteRegion() };
      // console.log(Math.floor(100000 + Math.random() * 900000));
      this.extractExecution(queryParam, `${timeStamp}-${randomNumber}`);
    }
    // label1: do {
    //   alert(numJumb)
    //   const rr = this.extract(queryParam);
    //   this.apiService.getExecuteData(queryParam).subscribe(res => {
    //     numJumb = numJumb + 1;
    //     resp = res;
    //   });
    //   if (resp) {
    //     console.log("resp", resp);
    //     if (resp.errors[0].errorCode === 500) {
    //       numJumb = numJumb + 1;
    //       continue label1;
    //     } else {
    //       numJumb = 5;
    //     }
    //   }
    // } while (numJumb < 5)
    // beginning: while (blnJumb) {
    //   blnJumb = false
    //   let resp;
    //   this.apiService.getExecuteData(queryParam).subscribe(res => {
    //     resp = res;

    //     console.log('rrrrrrrrrr', res, res.errors, res.errors[0], res.errors[0].errorCode);
    //     if (res) {
    //       if (res.errors[0].errorCode === 500 && numJumb < 5) {
    //         alert('hiiiiiiii')
    //         blnJumb = true;
    //         numJumb = numJumb + 1;
    //       }
    //     }
    //   },
    //     error => {
    //       console.log("api service error", error, error.message);
    //     });


    //   label1 : if (resp) {
    //     if (blnJumb) {
    //       alert('hi')
    //       continue beginning;
    //     }
    //   }

    // }


  }
  num = 0;

  extractExecution(queryParamValue, randonValue) {
    this.isExecute = true;
    this.loaderService.show();
    let queryParam = queryParamValue;
    if (this.num > 0) {
      queryParam = { "inputLists": `${randonValue}`.split('\n'), "region": this.getFavoriteRegion() };
    }
    this.apiService.getExecuteData(queryParam).subscribe(res => {
      if (res) {
        this.num = this.num + 1
        if (res.errors) {
          this.loaderService.hide();
          this.isExecute = false;
          if (res.errors[0].errorCode === 502 || res.errors[0].errorCode === 504) {
            if (this.num < 30) {
              this.extractExecution(queryParamValue, randonValue);
            }
          } else {
            // console.log("error", res.errors, res.errors[0].errorMessage)
            const dialogRef = this.dialog.open(AlertConfirmationComponent, {
              disableClose: true,
              width: '550px',
              data: { 'display': res.errors[0].errorMessage, isAlert: 'true', type: "group" }
            });

            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
              }
            })
          }
        } else {
          this.queryExecutedData = [];
          if (res.queryResults) {
            res.queryResults.forEach(item => {
              let obj = {}, objArr = [];
              for (let key in item) {
                if (Number.parseInt(key.split("_")[1]) > 1) {
                  const isJours = this.columnDateType.filter(cc => cc === Number.parseInt(key.split("_")[1]) - 2)
                  if (isJours.length > 0) {
                    if (item[key].length > 9) {
                      if (Number.parseInt(item[key].split("/")[2]) > 0) {
                        // objArr.push(new Date(item[key].split("/")[2], Number.parseInt(item[key].split("/")[1]) - 1, Number.parseInt(item[key].split("/")[0]) + 1));
                        objArr.push(this.datePipe.transform(new Date(`${item[key].split("/")[2]}-${Number.parseInt(item[key].split("/")[1])}-${Number.parseInt(item[key].split("/")[0])} 23:59:00`), 'dd/MM/yyyy'));
                      } else {
                        objArr.push(item[key]);
                      }
                    } else {
                      objArr.push(item[key]);
                    }
                  } else {
                    objArr.push(item[key]);
                  }
                }
              }
              this.queryExecutedData.push(objArr);
            })
            console.log("result", res.queryResults, this.queryExecutedData);
          }
          this.sExcelService.generateExcel(this.columnNames, this.queryExecutedData, this.columnNames.length);
          this.matomoTracker.trackEvent(`${this.screenName}`, `Execute ${this.tableValues.selectedRegion} - ${this.tableValues.selectedTable} query`, this.profile['uid']);
          this.loaderService.hide();
          this.isExecute = false;
          // let columns = [];
          // if (res.headers) {
          //   res.headers.forEach(item => {
          //     let titlelabel;
          //     let id = item.id;
          //     if (item.id.split("_")[1] > 1) {
          //       // console.log("item.columnLabel", item.columnLabel, this.criteriaValues.criteriaListOringinal.filter(xx => xx.shortLabel == item.columnLabel))
          //       // let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
          //       // console.log("000this.criteriaValues.criteriaListOringinal.filter(xx => xx.shortLabel == item.columnLabel)[0]", this.criteriaValues.criteriaListOringinal.filter(xx => xx.shortLabel == item.columnLabel)[0])
          //       if (typeof (this.criteriaValues.criteriaListOringinal.filter(xx => xx.shortLabel == item.columnLabel)[0]) !== 'undefined') {
          //         titlelabel = this.criteriaValues.criteriaListOringinal.filter(xx => xx.shortLabel == item.columnLabel)[0].longLabel.split(this.splitChar)[this.getLangIndex(this.lang)];
          //         columns.push(titlelabel);
          //       } else {
          //         let blnLoop = true;
          //         this.vueValues.vueList.forEach(vueItem => {
          //           let vueName = vueItem.name.substr(vueItem.name.indexOf('[') + 1, (vueItem.name.length - vueItem.name.indexOf('[') - 2)); // vueItem.name.replace("[", "");
          //           let itemValue = item.columnLabel;
          //           itemValue = itemValue.replace(vueName, "");
          //           itemValue = itemValue.replace(vueName, "");
          //           vueName = vueName.replace("]", "");
          //           // console.log("bbbbbbbbbb", vueName, vueItem.shortLabel, itemValue, item.columnLabel);
          //           if (blnLoop) {
          //             if (vueName.split(",").length > 1) {
          //               for (let cc = 0; cc < vueName.split(",").length; cc++) {
          //                 const element = vueName.split(",")[cc];
          //                 // console.log("item.columnLabel.substring(item.columnLabel.length - vueName.length, vueName.length)", vueName.split(",").length, vueName.split(","), typeof (element), element, item.columnLabel, item.columnLabel.substr(-element.length))
          //                 if (item.columnLabel.substr(-element.length) === element) {
          //                   columns.push(element);
          //                 }
          //               }
          //             } else {
          //               // console.log("1111item.columnLabel.substring(item.columnLabel.length - vueName.length, vueName.length)", typeof (vueName), vueName, item.columnLabel, item.columnLabel.substring(item.columnLabel.length - vueName.length, vueName.length))
          //               if (item.columnLabel.substr(-vueName.length) === vueName) {
          //                 columns.push(vueName);
          //               }
          //             }
          //             // if (vueItem.shortLabel === itemValue) {
          //             //   // if(item.columnLabel.substring(item.columnLabel.length - vueName.length, vueName.length))
          //             //   blnLoop = false;
          //             //   titlelabel = `${this.criteriaValues.criteriaListOringinal.filter(xx => xx.shortLabel == itemValue)[0].longLabel.split(this.splitChar)[this.getLangIndex(this.lang)]} - ${vueName}`;
          //             //   // console.log("vvvvvvvvvvvv", titlelabel);
          //             //   columns.push(titlelabel);
          //             // }
          //           }
          //         });
          //         // console.log("vvvvvvvvvvvv", this.vueValues.vueList.filter(z => z.name === `${item.columnLabel}[${item}]`));
          //         // titlelabel = this.criteriaValues.criteriaListOringinal.filter(xx => xx.shortLabel == item.columnLabel)[0].longLabel.split(this.splitChar)[this.getLangIndex(this.lang)];
          //       }
          //       // if (item.columnLabel.includes(this.splitChar)) {
          //       //   titlelabel = item.columnLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
          //       // } else {
          //       //   titlelabel = item.columnLabel
          //       // }
          //       // let data = {
          //       //   'field': titlelabel,
          //       //   'id': item.id
          //       // };
          //       // columns.push(data);
          //     }
          //   });
          // }


          // if (columns.length > 0) {
          //   if (res.queryResults) {
          //     res.queryResults.forEach(item => {
          //       let obj = {}, objArr = [];
          //       for (let key in item) {

          //         // if (key.includes(this.splitChar)) {
          //         //   dataLabel = key.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
          //         // } else {
          //         //   dataLabel = key;
          //         // }

          //         // objArr.push({ [key]: item[key] });
          //         if (Number.parseInt(key.split("_")[1]) > 1) {
          //           // objArr.push({ [columns.filter(cc => cc.id === key)[0].field]: item[key] });
          //           objArr.push(item[key]);
          //         }
          //       }
          //       // obj = Object.assign({}, objArr);
          //       // executedData = executedData.concat(obj);
          //       executedData.push(objArr);
          //     })
          //   }
          //   // console.log("columns", columns, executedData)
          //   this.columnNames = columns;
          //   this.queryExecutedData = executedData;
          //   this.sExcelService.generateExcel(columns, executedData, columns.length);
          // }


          // // if(res.queryResults){
          // //   for (const item of res.queryResults) {
          // //     for (let index = 3; index < item.lenth; index++) {
          // //       const element = item[index];

          // //     }
          // //   }
          // // }
          // // this.sExcelService.generateExcel();
          return res;
        }
      }
    },
      error => {
        if (error)
          this.loaderService.hide();
        this.isExecute = false;
        this.num = this.num + 1
        if (error.status === 502 || error.status === 504) {
          // console.log(this.num, res.errors[0]);
          if (this.num < 30) {
            // this.extractExecution(queryParam);
            this.extractExecution(queryParamValue, randonValue);
          }
        }
      });
  }

  splitDataValues(table, array) {
    let formattedArr = [], index = 0, startText, string = '';
    let valueStr = '';
    startText = `TEMP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.EXP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.EXP@2@(@)@0@`
    startText += "\n";
    startText += `TPO@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@${this.tableValues.ipn}.EXP@`
    startText += "\n";
    // startText += `TEMP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.CND@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.CND@`;
    startText += `TEMP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.EXP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.EXP@`;
    string += `@(@(`;
    if (table === 'CDE') {
      array.forEach((item) => {
        let data = item.slice(0, 3) + ' ' + item.slice(3, 9) + ' ' + item.slice(9, 15);
        let splittedData = data.split(' ');
        string += `@(@(@(@(@${index}@)@E@(@${index + 1}@)@E@(@${index + 2}@)@)@)@)@O`;
        index = index + 3;
        let obj = {
          'perim': splittedData[0],
          'numCTE': splittedData[1],
          'numCDE': splittedData[2]
        }
        formattedArr.push(obj);
      })
      if (string.charAt(string.length - 1) === 'O') {
        string = string.slice(0, -2);
      }
      string += `@)@)`;
      // console.log('gggggg', string);
      formattedArr.forEach(item => {
        valueStr += `@1@1@1@A_PerimGest@0@E@0@${item.perim}@0@1@1@1@A_NumCptePropCde@0@E@0@${item.numCTE}@0@1@1@1@A_NumCdeSedre@0@E@0@${item.numCDE}@0`
      })
      this.columnNames.push("Perimetre gestion§Perimeter of Management (PG)".split(this.splitChar)[this.getLangIndex(this.lang)])
      this.columnNames.push("Numero compte proprietaire commande§Account _ Owner Order Account".split(this.splitChar)[this.getLangIndex(this.lang)])
      this.columnNames.push("Numero commande§Order identification number".split(this.splitChar)[this.getLangIndex(this.lang)])
    } else if (table === 'PDFV') {
      string += `@(`;
      array.forEach((item) => {
        let data = item.slice(0, 3) + ' ' + item.slice(3, 12);
        let splittedData = data.split(' ');
        string += `@(@(@(@${index}@)@E@(@${index + 1}@)@)@)@O`;
        index = index + 2;
        let obj = {
          'dept': splittedData[0],
          'pji': splittedData[1]
        }
        formattedArr.push(obj);
      })
      if (string.charAt(string.length - 1) === 'O') {
        string = string.slice(0, -2);
      }
      string += `@)@)@)`;
      formattedArr.forEach(item => {
        valueStr += `@1@1@1@A_DptMont@0@E@0@${item.dept}@0@1@1@1@A_PJI@0@E@0@${item.pji}@0`
      })
      this.columnNames.push("Departement monteur§Departement monteur".split(this.splitChar)[this.getLangIndex(this.lang)])
      if (this.tableValues.selectedTable === 'PDFV') {
        this.columnNames.push("PJI du vehicule (AASSJNNNN)§PJI du vehicule (AASSJNNNN)".split(this.splitChar)[this.getLangIndex(this.lang)])
      } else {
        this.columnNames.push("PJI§PJI".split(this.splitChar)[this.getLangIndex(this.lang)])
      }
    } else {
      string = `@(@(@(`;
      let shortName = '';
      if (array[0].length === 12) {
        shortName = 'A_NumIdent';
        this.columnNames.push("Numero Identification§Identification Number".split(this.splitChar)[this.getLangIndex(this.lang)])
      } else if (array[0].length === 15) {
        shortName = 'A_InvarBCV';
        this.columnNames.push("Invariant BCV§Invariant BCV".split(this.splitChar)[this.getLangIndex(this.lang)])
      } else if (array[0].length === 17) {
        shortName = 'A_NumChassis'
        this.columnNames.push("Numero Chassis§VIN".split(this.splitChar)[this.getLangIndex(this.lang)])
      }
      array.forEach((item) => {
        // string += `@(@(@(@(@${index}@)@)@)@)@O`;
        string += `@(@(@(@${index}@)@)@)@O`;
        index = index + 1;
        let obj = {
          'numChassis': item
        }
        formattedArr.push(obj);
      })
      if (string.charAt(string.length - 1) === 'O') {
        string = string.slice(0, -2);
      }
      string += `@)@)@)`;
      formattedArr.forEach(item => {
        valueStr += `@1@1@1@${shortName}@0@E@0@${item.numChassis}@0`
      })
    }
    let strAtChar = (string.match(new RegExp("@", "g")) || []).length;
    if (table === 'CDE') {
      string += `@${formattedArr.length * 3}${valueStr}@`;
    } else if (table === 'PDFV') {
      string += `@${formattedArr.length * 2}${valueStr}@`;
    } else {
      string += `@${formattedArr.length}${valueStr}@`;
    }
    let returnStr = `${startText}${strAtChar}${string}`;
    returnStr += '\n';
    returnStr += `TPO@${this.tableValues.ipn}@${this.tableValues.selectedTable}@un100000@${this.tableValues.ipn}.EXP@`;
    returnStr += '\n';
    returnStr += `TEMP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.CND@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.CND@2@(@)@0@`;
    return returnStr;
  }

  getCountandAddedValues(table, element) {
    let noOfValues, addedValues;
    if (table === 'CDE') {
      noOfValues = this.vueValues.vueList.length + 3;
      addedValues = `1@1@1@A_PerimGest@0@?@0@?@0@1@1@1@A_NumCptePropCde@0@?@0@?@0@1@1@1@A_NumCdeSedre@0@?@0@?@0`;
    } else if (table === 'PDFV') {
      noOfValues = this.vueValues.vueList.length + 2;
      addedValues = `1@1@1@A_DptMont@0@?@0@?@0@1@1@1@A_PJI@0@?@0@?@0`;
    } else {
      noOfValues = this.vueValues.vueList.length + 1;
      if (element === 12) {
        addedValues = `1@1@1@A_NumIdent@0@?@0@?@0`;
      } else if (element === 15) {
        addedValues = `1@1@1@A_InvarBCV@0@?@0@?@0`;
      } else if (element === 17) {
        addedValues = `1@1@1@A_NumChassis@0@?@0@?@0`;
      }
    }
    let obj = {
      no: noOfValues,
      value: addedValues
    }
    return obj;
  }

  generateGroupExecute(table, element) {
    let itemId = 0;
    let rtnValue, listForm = "";
    rtnValue = "\n";
    rtnValue += `TEMP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.GRP@${this.tableValues.ipn}@${this.tableValues.selectedTable}@${this.tableValues.ipn}.GRP@${this.getCountandAddedValues(table, element).no}@${this.getCountandAddedValues(table, element).value}`;
    rtnValue += `@`;
    this.columnDateType = [];
    for (const item of this.vueValues.vueList) {
      itemId = itemId + 1;
      const affichDataType = item.type === 1 ? "2" : item.type === 3 ? item.type.toString() : (item.type === 4 || item.type === 5) ? item.type.toString() : item.type === 99 ? "5" : "1";
      if (affichDataType === '1') {
        listForm = `${listForm}${affichDataType}@1@1@${item.GCOName}@0@?@0@?@0@`;
        this.columnNames.push(`${item.name}`)
      } else if (affichDataType === '2') {
        let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
        if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`)) {
          listForm = `${listForm}${affichDataType}@2@1@${item.GCOName}@1@A_MilBE@?@0@007@0@`;
          this.columnNames.push(`${item.name.substr(0, item.name.indexOf('['))}`);
        } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`)) {
          listForm = `${listForm}${affichDataType}@3@1@${item.GCOName}@0@?@0@@0@`;
          this.columnNames.push(`${item.name.substr(0, item.name.indexOf('['))}`);
        } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)) {

          if (itemValue.split(',').length > 1) {
            listForm = `${listForm}${affichDataType}@1@1@${item.GCOName}@0@?@${itemValue.split(",")[1]}@@0@`;
          } else {
            listForm = `${listForm}${affichDataType}@1@1@${item.GCOName}@0@?@0@@0@`;
          }
          this.columnNames.push(`${item.name.substr(0, item.name.indexOf('['))}`);
        } else {
          if (itemValue.split(',').length > 0) {
            let ll = 0;
            listForm = `${listForm}${affichDataType}@0@1@${item.GCOName}@0@?@0@`;
            for (const valItem of itemValue.split(',')) {
              if (valItem.length !== 6) {
                let emptySpace = '';
                for (let x = 0; x < (6 - valItem.length); x++) {
                  emptySpace = `${emptySpace} `;
                }
                if (ll === 0) {
                  listForm = `${listForm}${valItem}${emptySpace}/`;
                  this.columnNames.push(`${item.name} - ${valItem}`)
                }

              } else {
                if (ll === 0) {
                  listForm = `${listForm}${valItem}/`;
                  this.columnNames.push(`${item.name} - ${valItem}`)
                }
              }
            }
            listForm = listForm.slice(0, -1)
            listForm = `${listForm}@0@`
          }
        }
      } else if (affichDataType === '4' || (item.type === 5 && affichDataType === '5')) {
        let itemValue = item.name.split("[");
        if (itemValue.length > 1) {
          listForm = `${listForm}6@1@1@${item.GCOName}@0@?@0@?@0@`;
          this.columnNames.push(`${item.name}`)
        } else {
          listForm = `${listForm}${affichDataType}@4@1@${item.GCOName}@0@?@0@?@0@`;
          this.columnNames.push(`${item.name}`)
        }
      } else if (affichDataType === '3') {
        let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
        let foundElement = this.getGroupDate().find(el => this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.value}`) === itemValue);
        if (foundElement !== undefined) {
          listForm = `${listForm}${affichDataType}@${foundElement.index}@1@${item.GCOName}@0@?@0@?@0@`;
          this.columnNames.push(`${item.name}`);
          if (foundElement.value === "jours") {
            this.columnDateType.push(itemId);
          }
        } else {
          listForm = `${listForm}${affichDataType}@9@1@${item.GCOName}@0@?@0@${itemValue}@0@`;
          this.columnNames.push(`${item.name}`);
        }
      } else if (affichDataType === '5' && item.type === 99) {
        let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2))
        let delayIndex;
        const delayDateNames = item.name.split('-');
        const delayDateNames2 = delayDateNames[1].split('[')[0];
        let delayDateName1 = undefined;
        let delayDateName2 = undefined;
        if (item.delay === 0) {
          const criteriaFilterVal1 = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split('§', 2).length > 1 ? a.longLabel.split('§', 2)[this.getLangIndex(this.criteriaValues.lang)] === delayDateNames[0] : a.longLabel === delayDateNames[0])[0];
          delayDateName1 = criteriaFilterVal1.GCOName;
          const criteriaFilterVal2 = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split('§', 2).length > 1 ? a.longLabel.split('§', 2)[this.getLangIndex(this.criteriaValues.lang)] === delayDateNames2 : a.longLabel === delayDateNames2)[0];
          delayDateName2 = criteriaFilterVal2.GCOName;
        } else if (item.delay === 1 || item.delay === 2) {
          const criteriaFilterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split('§', 2).length > 1 ? a.longLabel.split('§', 2)[this.getLangIndex(this.criteriaValues.lang)] === delayDateNames2 : a.longLabel === delayDateNames2)[0];
          delayDateName1 = delayDateNames[0];
          delayDateName2 = criteriaFilterVal.GCOName;

        } else if (item.delay === 3 || item.delay === 4) {
          const criteriaFilterVal = this.criteriaValues.criteriaListOringinal.filter(a => a.longLabel.split('§', 2).length > 1 ? a.longLabel.split('§', 2)[this.getLangIndex(this.criteriaValues.lang)] === delayDateNames[0] : a.longLabel === delayDateNames[0])[0];
          delayDateName1 = criteriaFilterVal.GCOName;
          delayDateName2 = delayDateNames2;
        }
        for (let i = 0; i < this.getDataFormat().length; i++) {
          let el = this.getDataFormat()[i];
          // this.getDataFormat().forEach(el => {
          let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
          if (translatedText.toLowerCase() === itemValue.toLowerCase()) {
            delayIndex = el.index;
          }
        }
        if (itemValue.split(' ').length > 1 && !isNaN(itemValue.split(' ')[1])) {
          listForm += `5@7@1@${delayDateName1}@1@${delayDateName2}@?@0@${itemValue.split(' ')[1]}@0@`;
          this.columnNames.push(`${item.name}`)
        } else {
          listForm += `5@${delayIndex}@1@${delayDateName1}@1@${delayDateName2}@?@0@?@0@`;
          this.columnNames.push(`${item.name}`)
        }
      }
    }
    rtnValue += listForm;
    return rtnValue;
  }

  getDataFormat() {
    return [{ name: 'CONNUE', value: 'connue', index: 0 },
    { name: 'JOUR_SAVE', value: 'jour', index: 1 },
    { name: 'PROGRAM_SAVE', value: 'programme', index: 2 },
    { name: 'WEEK_SAVE', value: 'week', index: 2 },
    { name: 'INDUSTRIAL_SAVE', value: 'mois insustriel', index: 3 },
    { name: 'CALENDAR_SAVE', value: 'mois calendaire', index: 4 },
    { name: 'Y', value: 'année', index: 5 },
    { name: 'WORKING_SAVE', value: 'semaines', index: 6 },
    { name: 'FF_SAVE', value: 'Free Format', index: 7 },
    { name: 'ASL_SAVE', value: 'ASJ', index: 8 },
    { name: 'INCONNUE', value: 'inconnue', index: 0 },
    ];
  }

  getGroupDate() {
    return [{ name: 'DCMY', value: 'jours', index: 1 },
    { name: 'DWY', value: 'jour/prog./année', index: 8 },
    { name: 'PY', value: 'programmes', index: 2 },
    { name: 'IMY', value: 'mois industriels', index: 3 },
    { name: 'CMY', value: 'mois calendaires', index: 4 },
    { name: 'Y', value: 'année', index: 5 },
    { name: 'FF', value: 'Free Format', index: 9 },
    { name: 'FKD', value: 'flag', index: 0 }];
  }

  generateCommonLastExecute() {
    let rtnValue: string;
    rtnValue = "\n";
    let isFlagFound = false;
    if (this.vueValues.vueList.some(el => el.type === 4)) {
      this.vueValues.vueList.forEach(el => {
        let data = el.name.split('[');
        data = data.slice(0, -1);
        if (data.length > 0 && data[0].toLowerCase() === this.utilService.getTranslateName('COMMON.SUM').toLowerCase()) {
          isFlagFound = true;
        }
      })
    }
    /**** SONAR : Remove this conditional structure or edit its code blocks so that they're not all the same ****/ 
    // if (this.vueValues.vueList.some(el => el.type === 5) || isFlagFound) {
    //   rtnValue += `TSI@${this.tableValues.ipn}@${this.tableValues.selectedTable}@un100001@${this.tableValues.ipn}.CND@${this.tableValues.ipn}.GRP@N@N@`;
    // } else {
    //   rtnValue += `TSI@${this.tableValues.ipn}@${this.tableValues.selectedTable}@un100001@${this.tableValues.ipn}.CND@${this.tableValues.ipn}.GRP@N@N@`;
    // }
    rtnValue += `TSI@${this.tableValues.ipn}@${this.tableValues.selectedTable}@un100001@${this.tableValues.ipn}.CND@${this.tableValues.ipn}.GRP@N@N@`;
    rtnValue += '\n';
    rtnValue += `GET@${this.tableValues.ipn}@${this.tableValues.selectedTable}@un100002@0@0@1@`;
    return rtnValue;
  }

  alertErrorMsg(displayMsg): void {

    this.fileData = [];
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': displayMsg, 'isText': false, 'isAlert': true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.myFileInput.nativeElement.value = "";
        // this.dialogRef.close({ name: this.queryName, isPublic: this.isPublic });
        return;
      }
    })
  }


  searchFilter() {
    this.criteriaValues.displayCriteriaList = this.criteriaValues.criteriaList.filter(a => a.toUpperCase().includes(this.criteriaValues.searchText.toUpperCase()));
    this.criteriaValues.displayCriteriaList = this.criteriaValues.displayCriteriaList.sort(this.utilService.sortArray);
  }

  funSelectField(){
    this.moveRight()
  }

}
