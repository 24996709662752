import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';
import { BackendApiService } from '../services/backend-api/backend-api.service';
import { RequestService } from '../services/request/request.service';
import { UtilService } from '../services/util/util.service';
import { DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import * as XLSX from 'xlsx';
import moment from 'moment';


@Component({
  selector: 'app-list-connection',
  templateUrl: './list-connection.component.html',
  styleUrls: ['./list-connection.component.scss']
})
export class ListConnectionComponent implements OnInit {
  fileName = 'ExcelSheet.xlsx';
  alertMsg = [{ 'EUROPE': [] },
  { 'MERCOSUR': [] }];
  userValues = {
    "ipn": undefined, "region": undefined, "responseData": undefined,
    "tableData": undefined, "selectedRegion": undefined, "selectedTable": undefined, "selectedTableDetails": undefined,
    "dateDiff": undefined, "requestDatas": undefined, "roleBasedTableList": undefined
  };

  profile = this.utilService.getUserProfile();
  isAdmin = false;
  roleBasedGroupList;

  messageTypes = [];
  selectedMsgType;
  isPriority = false;
  dateConnection: any;
  blnQuickLink: boolean;
  textMsg: string;
  page = 1;
  pageSize = 5;
  items = [];
  listConnectionData;
  // = {
  //   "name": "ConnectionLog",
  //   "version": 1,
  //   "total": 3,
  //   "data": [
  //     {
  //       "userIpn": "z000715",
  //       "lastLoginDateTime": "2022-08-16T08:12:32.967",
  //       "clientVersion": "PIL-WEB-22.1.0",
  //       "createdAt": "2022-08-16T08:12:32.967"
  //     },
  //     {
  //       "userIpn": "z011111",
  //       "lastLoginDateTime": "2022-08-16T20:12:41.678",
  //       "clientVersion": "PIL-WEB-22.1.0",
  //       "createdAt": "2022-08-16T08:12:41.678"
  //     },
  //     {
  //       "userIpn": "z003454",
  //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //       "clientVersion": "PIL-WEB-22.1.1",
  //       "createdAt": "2022-08-16T08:12:32.967"
  //     },
  //     //     {
  //     //       "userIpn": "z003987",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z003784",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z003004",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z003154",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z003404",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z003564",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z002344",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z003784",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z002984",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     },
  //     //     {
  //     //       "userIpn": "z006784",
  //     //       "lastLoginDateTime": "2022-08-16T08:12:41.678",
  //     //       "clientVersion": "PIL-WEB-22.1.1",
  //     //       "createdAt": "2022-08-16T08:12:32.967"
  //     //     }
  //   ]
  // };
  constructor(private http: HttpClient, readonly router: Router, readonly activatedRoute: ActivatedRoute, public dialog: MatDialog,
    readonly apiService: BackendApiService, readonly utilService: UtilService,
    private translateService: TranslateService,
    readonly requestService: RequestService, readonly toastyService: ToastyService, public datepipe: DatePipe) {
    this.utilService.$showIcon$.next(false);
  }
  ngOnInit() {
    const role = this.profile['role-acc-pil-irn6804'];

    let regionRole = role.replace('[', '').replace(']', '');
    regionRole = regionRole.split(',');
    if (regionRole.length > 1) {
      this.userValues.region = ['Europe', 'Mercosur'];
      this.userValues.selectedRegion = "Europe";
    } else {
      if (regionRole[0] === 'acc_pil_europe') {
        this.userValues.region = ['Europe'];
        this.userValues.selectedRegion = "Europe";
      } else {
        this.userValues.region = ['Mercosur'];
        this.userValues.selectedRegion = "Mercosur";
      }

    }
    //   this.listConnectionData ={ data : []
    // }
    console.log("check", this.listConnectionData)
    this.apiService.getlostloginlist().subscribe(res => {
      console.log("test1", res)
      this.listConnectionData = res;

      for (let index = 0; index < this.listConnectionData.data.length; index++) {
        this.listConnectionData.data[index].lastLoginDateTime = this.listConnectionData.data[index].lastLoginDateTime.replace("T", " ") + " UTC";
      }

      // this.apiService.createuserlogin("https://int.pil.dev.aws.renault.com/pil-connection/loginentry").subscribe(res1 =>{
      //   console.log("test2",res1)
      // })
    },
      error => {
        console.log("error", error.message);
      });
  }
  clickClose() {
    const pathName = window.location.pathname;
    this.utilService.removeOpenedWindowName(pathName);
    this.utilService.$showIcon$.next(true);
    const openedWindowNameList = this.utilService.getOpenedWindowNameList()
    if (openedWindowNameList.length > 0) {
      this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
    } else {
      this.router.navigate(['home']);
    }
  }
  //   clickExport() {
  //     const dialogRef = this.dialog.open(ExportFileComponent, {
  //       disableClose: true,
  //       width: '600px',
  //       data: {'UserID':this.listConnectionData, 'Date connexion':this.listConnectionData, 'Version':this.listConnectionData }
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //       } else {
  //       }
  //     });
  // }
  clickExport() {
    if (!this.listConnectionData) {
      return;
    }
    /* pass here the table id */
    let element = document.getElementById('List');
    let mappedJson = [];
    mappedJson = this.listConnectionData.data.map(item => {
      return {
        User_ID: item.userIpn,
        Connection_Date: item.lastLoginDateTime ? moment(item.lastLoginDateTime).format('ddd MMM DD HH:mm:ss YYYY') : '',
        Version: item.clientVersion
      }
    }
    )

    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedJson);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    console.log("asdasd", wb, ws);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
}
