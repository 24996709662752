import { Component } from '@angular/core';
import { environment } from '../environments/environment.prod';
import { Idle } from 'idlejs/dist';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatomoInjector } from 'ngx-matomo';
import { RequestService } from './services/request/request.service';
import 'moment/min/locales.min';
import moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PILOTAGE';
  public constSilentRefreshIFrameName  = 'rnlt-angular-oauth-oidc-silent-refresh-iframe';
  constructor(private readonly translateService: TranslateService,
    readonly requestService: RequestService, private readonly oauthService: OAuthService, private readonly router: Router, private readonly matomoInjector: MatomoInjector) {
    let idpVersion = 're7';

    if ((window.location.hostname.indexOf('pil.app.renault.com') !== -1)
      || (window.location.hostname.indexOf('ope.pil.ope.aws.renault.com') !== -1)) {
      // Prod
      idpVersion = 'ope';
      this.oauthService.configure({
        useSilentRefresh: true,
        silentRefreshRedirectUri: environment.idp.silentRefreshRedirectUri,
        redirectUri: environment.idp.prod[idpVersion].redirect_uri,
        clientId: environment.idp.prod[idpVersion].client_id,
        loginUrl: environment.idp.prod[idpVersion].loginUrl,
        logoutUrl: environment.idp.prod[idpVersion].logoutUrl,
        userinfoEndpoint: environment.idp.prod[idpVersion].userinfoEndpoint,
        issuer: environment.idp.prod[idpVersion].issuer,
        scope: environment.idp.scope,
        showDebugInformation: false,
        clearHashAfterLogin: false,
        responseType: 'code',
        silentRefreshShowIFrame: true,
        silentRefreshIFrameName:this.constSilentRefreshIFrameName,
        sessionChecksEnabled: false,
        disableAtHashCheck: true
      });
    } else if (window.location.hostname.indexOf('re7.pil.re7.aws.renault.com') !== -1) {
        this.oauthService.configure({
        useSilentRefresh: true,
        silentRefreshRedirectUri: environment.idp.silentRefreshRedirectUri,
        redirectUri: environment.idp.prod[idpVersion].redirect_uri,
        clientId: environment.idp.prod[idpVersion].client_id,
        loginUrl: environment.idp.prod[idpVersion].loginUrl,
        logoutUrl: environment.idp.prod[idpVersion].logoutUrl,
        userinfoEndpoint: environment.idp.prod[idpVersion].userinfoEndpoint,
        issuer: environment.idp.prod[idpVersion].issuer,
        scope: environment.idp.scope,
        showDebugInformation: true,
        clearHashAfterLogin: false,
        responseType: 'code',
        silentRefreshShowIFrame: true,
        silentRefreshIFrameName: this.constSilentRefreshIFrameName,
        sessionChecksEnabled: false,
        disableAtHashCheck: true
      });
    } 
    else if (window.location.hostname.indexOf('int.pil.dev.aws.renault.com') !== -1) {
      idpVersion = 'dev';
      this.oauthService.configure({
        useSilentRefresh: true,
        silentRefreshRedirectUri: environment.idp.silentRefreshRedirectUri,
        redirectUri: environment.idp.prod[idpVersion].redirect_uri,
        clientId: environment.idp.prod[idpVersion].client_id,
        loginUrl: environment.idp.prod[idpVersion].loginUrl,
        logoutUrl: environment.idp.prod[idpVersion].logoutUrl,
        userinfoEndpoint: environment.idp.prod[idpVersion].userinfoEndpoint,
        issuer: environment.idp.prod[idpVersion].issuer,
        scope: environment.idp.scope,
        showDebugInformation: true,
        clearHashAfterLogin: false,
        responseType: 'code',
        silentRefreshShowIFrame: true,
        silentRefreshIFrameName: this.constSilentRefreshIFrameName,
        sessionChecksEnabled: false,
        disableAtHashCheck: true,
        // timeoutFactor: 0.10
      });
    } else {
      this.oauthService.configure({
        issuer: environment.idp.prod[idpVersion].issuer,
        loginUrl: environment.idp.prod[idpVersion].loginUrl,
        userinfoEndpoint: environment.idp.prod[idpVersion].userinfoEndpoint,
        clientId: environment.idp.prod[idpVersion].client_id,
        scope: environment.idp.scope,

        redirectUri: environment.idp.prod[idpVersion].redirect_uri,
        responseType: 'code',

        useSilentRefresh: true,
        silentRefreshRedirectUri: environment.idp.silentRefreshRedirectUri,
        silentRefreshShowIFrame: true,
        silentRefreshIFrameName: this.constSilentRefreshIFrameName,
        showDebugInformation: false,
        sessionChecksEnabled: false,
        clearHashAfterLogin: false
      });
    }
    this.oauthService.setupAutomaticSilentRefresh();
  }

  ngOnInit() {

    const selectedLang = this.requestService.getSelectedLang();
    const supportedLanguages = ['en', 'fr'];
    if (selectedLang !== undefined && selectedLang !== null && supportedLanguages.indexOf(selectedLang) > -1) {
      this.translateService.setDefaultLang(selectedLang);
    } else {
      this.translateService.setDefaultLang('fr');
      this.requestService.addLang('fr');
      this.translateService.use('fr');
    }
    if (this.translateService.currentLang) {
      const currentLanguage =
        supportedLanguages.indexOf(this.translateService.currentLang) >= 0 ?
          this.translateService.currentLang :
          this.translateService.getBrowserLang();
      this.translateService.addLangs(supportedLanguages);
      this.translateService.use(currentLanguage);
      moment.locale(currentLanguage);
    }
    const idle = new Idle()
      .whenNotInteractive()
      .within(environment.timeOutwhenNotInteractive)
      .do(() => {
        this.oauthService.silentRefresh();
      })
      .start().repeat(true);
  }
}
