import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastyService } from 'ng2-toasty';
import * as XLSX from 'xlsx';

import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { UtilService } from '../../../services/util/util.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { AlertConfirmationComponent } from '../../alert-confirmation/alert-confirmation.component';

import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
  styleUrls: ['./export-file.component.scss']
})
export class ExportFileComponent implements OnInit {
  userFileName: string;

  constructor(public dialogRef: MatDialogRef<ExportFileComponent>, readonly utilService: UtilService, private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, readonly apiService: BackendApiService, readonly toastyService: ToastyService, readonly loaderService: LoaderService) {
    if (this.data.queryName) {
      this.headerName = `[${this.data.table}] ${this.data.tcm}/${this.data.queryName}`;
    } else {
      this.headerName = `[${this.data.table}] ${this.data.tcm}/`;
    }
    let date = new Date();
    this.userFileName = moment.default(date).format('YYYYMMDD-hhmm') + '-' + this.getFavoriteRegion() + '-' + this.data.table + '--' + Math.floor(1000 + Math.random() * 90000);
  }
  profile = this.utilService.getUserProfile();
  headerName = '';
  chkHeaders = true;
  chkAdvanced = false;
  enableTextMsg = false;
  fileTypes = [{ "type": "Excel(*.xlsx)", "value": ".xlsx" },
  { "type": "Text(*.txt)", "value": ".txt" }];
  selectedFileType = ".xlsx";
  generateTypes = [{ "type": "New", "type2": "File", "value": "NEW", "name": "NEW", "name2": "FILE" },
  { "type": "Add at the", "type2": "end of file", "value": "APPEND", "name": "ADD", "name2": "END_FILE" },
  { "type": "Filling", "type2": "UNIX", "value": "TAR", "name": "FILLING", "name2": "UNIX" },
  { "type": "ZIP", "type2": "", "value": "ZIP", "name": "ZIP" }];

  transferTypes = [{ "type": "No transfer", "value": "No transfer", "name": "NO_TRANSFER" },
  { "type": "S3 Bucket", "value": "S3 Bucket", "name": "S3_BUCKET" },
  { "type": "CFT:", "value": "CFT", "name": "CFT" }];
  idf;
  part;

  patternTypes = [{ "type": "Variable", "value": "V", "name": "VARIABLE" },
  { "type": "Fixed completed with blanks", "value": "F", "name": "WITH_BLANK" },
  { "type": "Fixed completed with 0:", "value": "L", "name": "WITH_0" }];

  fixedLength;

  lineTypes = [{ "type": "LF", "value": "EOL", "name": "LF" },
  { "type": "CR/LF", "value": "CRLF", "name": "CR" },
  { "type": "Other:", "value": "other", "name": "OTHER" }];

  lineOther = "";
  selectedLineType = "CRLF";
  selectedFieldType = "\t";
  selectedTransferType = "S3 Bucket";
  selectedPatternType = "V";

  fieldTypes = [{ "type": "Without", "value": "", "name": "WITHOUT" },
  { "type": "Tab", "value": "\t", "name": "TAB" },
  { "type": "Other:", "value": "other", "name": "OTHER" }];

  fieldOther = "";

  generateType = "NEW";
  transferType = "S3 Bucket";
  patternType = "V";
  lineType = "CRLF";
  fieldType = "\t";
  detailPageFileName = '';
  fileName;
  isClicked: boolean = false;

  getGenerateType(generate) {

  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkSelectedCheckboxHeader(event) {
    // console.log('ffffffff', event);
    this.chkHeaders = event;
  }

  checkSelectedCheckboxAdvanced(event) {
    this.chkAdvanced = event;
  }

  clickDefaultSetting() {
    this.generateType = "NEW";
    this.transferType = "S3 Bucket";
    this.patternType = "V";
    this.lineType = "CRLF";
    this.fieldType = "\t"
  }

  getFavoriteRegion() {
    if (this.data.region === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getRoleForRadio() {
    // setTimeout(() => {
    if (this.data.region === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_cft') > -1) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_cft') > -1) {
        return false;
      } else {
        return true;
      }
    }
    // }, 0)
  }

  exportFunction() {
    let lineTypeValue = this.lineType;
    if (this.lineType === 'other') {
      lineTypeValue = this.lineOther;
    }

    let fieldTypeValue = this.fieldType;
    if (this.fieldType === 'other') {
      fieldTypeValue = this.fieldOther;
    }

    let strHeaders = "";
    if (this.chkHeaders) {
      for (const item of this.data.resultColumns) {
        strHeaders = `${strHeaders}${item.field}${fieldTypeValue}`;
      }
      // if (strHeaders !== "") {
      //   strHeaders = strHeaders + this.fieldType;
      // }
    }
    strHeaders = strHeaders.replace('RowNum' + `${fieldTypeValue}`, '');
    strHeaders = strHeaders.replace(/^\s/, '');
    let exportQuery;
    let uniqueNo = this.data.getResultInput.currentPage === 1 ? 'un100002' : 'un100003';
    let uniqueNoTrace;
    const traceCount = sessionStorage.getItem('traceCount');
    if (Number(traceCount) > 2) {
      uniqueNoTrace = this.data.getResultInput.currentPage === 1 ? 'un100002'.replace('un100002', 'un10000' + (Number(traceCount)).toString()) : 'un100003';
    } else {
      uniqueNoTrace = this.data.getResultInput.currentPage === 1 ? 'un100002' : 'un100003';
    }
    if (strHeaders !== '') {
      exportQuery = `DUMP@${this.data.ipn}@${this.data.table}@${this.generateType}@${uniqueNo}@${this.selectedFileType}@${fieldTypeValue}@${lineTypeValue}@${this.headerName}`;
      // if (this.data.queryName) {
      //   exportQuery = `${headerQuery}${this.data.queryName}`;
      // } else {
      //   exportQuery = `${headerQuery}`;
      // }
      exportQuery += "#";
      exportQuery += `${strHeaders}@0@-1@${this.patternType}@@@`;
    } else {
      exportQuery = `DUMP@${this.data.ipn}@${this.data.table}@${this.generateType}@${uniqueNo}@${this.selectedFileType}@${fieldTypeValue}@${lineTypeValue}@${this.headerName}@0@-1@${this.patternType}@@@`;
    }
    const queryParam = { "inputLists": `${this.data.executeQuery}\n${this.data.getResultInput.getEvent}\n${exportQuery}`.split('\n'), "region": this.getFavoriteRegion() };
    this.utilService.setTrace(`>A< svc_getmassif`);
    // console.log("this.headerName", this.headerName)
    this.utilService.setTrace(`>I< ${this.generateType}@${uniqueNoTrace}@C:\Users\__1__\OneDrive - Alliance\Bureau\tracesWeb${this.selectedFileType}@${fieldTypeValue}@${lineTypeValue}@${this.headerName}${strHeaders}@0@-1@${this.patternType}@@@`);
    this.utilService.setTrace(`>O< /pilre701/pil/tfi//pilo_dump1.txt@@`);
    // this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    // this.utilService.setTrace(`>A< svc_delhdle`);
    // this.utilService.setTrace(`>I< un100002@`);
    // this.utilService.setTrace(`>O< `);
    // this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    // this.utilService.setTrace(`>A< svc_delhdle`);
    // this.utilService.setTrace(`>I< un100001@`);
    // this.utilService.setTrace(`>O< `);
    // this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
    // this.utilService.setTrace(`>A< svc_delhdle`);
    // this.utilService.setTrace(`>I< un100000@`);
    // this.utilService.setTrace(`>O< `);
    this.apiService.exportResultObject(queryParam).subscribe(resp => {
      if (this.transferType === 'S3 Bucket') {
        sessionStorage.setItem('exportKey', resp.exportKey);
        // this.toastyService.warning(this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.FILE_GENERATE`));
        this.downloadFile();
        this.downloadResult(resp.exportKey, 0);
        this.enableTextMsg = true;
      } else {
        this.toastyService.success(this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.FILE_GENERATE_BACK`));
        this.onNoClick();
      }
    },
      error => {
        console.log("error", error.message);
      });
  }

  clickExport() {
    this.enableTextMsg = false;
    this.isClicked = true;
    if (this.data && this.data.getResultInput.currentPage !== 3) {
      if (this.data.totalRowsLength > 1048500) {
        const dialogRef = this.dialog.open(AlertConfirmationComponent, {
          disableClose: true,
          width: '550px',
          data: { 'display': this.utilService.getTranslateName(`ERROR.RESULT_OUT_RANGE`), 'isText': false }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.exportFunction();
            return;
          } else {
            this.isClicked = false;
            return;
          }
        })
      } else {
        this.exportFunction();
        return;
      }
    } else {
      /* first table */
      let tbl1 = document.getElementById('first-detail-execute-page')
      let tbl2 = document.getElementById('second-detail-execute-page')
      let tbl3 = document.getElementById('third-detail-execute-page')
      let tbl4 = document.getElementById('fourth-detail-execute-page')
      let worksheet_tmp1 = XLSX.utils.table_to_sheet(tbl1);
      let worksheet_tmp2 = XLSX.utils.table_to_sheet(tbl2);
      let worksheet_tmp3 = XLSX.utils.table_to_sheet(tbl3);
      let worksheet_tmp4 = XLSX.utils.table_to_sheet(tbl4);
      let a = XLSX.utils.sheet_to_json(worksheet_tmp1, { header: 1 });
      let b = XLSX.utils.sheet_to_json(worksheet_tmp2, { header: 1 })
      let c = XLSX.utils.sheet_to_json(worksheet_tmp3, { header: 1 })
      let d = XLSX.utils.sheet_to_json(worksheet_tmp4, { header: 1 })

      a.unshift([this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.COMMON`)]);
      b.unshift([this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.INITIAL`)]);
      c.unshift([this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.CURRENT`)]);
      d.unshift([this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.CLEAR_INITIAL`)]);

      a = a.concat(['\n']).concat(b).concat(['\n']).concat(c).concat(['\n']).concat(d);
      let worksheet = XLSX.utils.json_to_sheet(a);
      this.delete_row(worksheet, 0);
      let data = XLSX.utils.sheet_to_txt(worksheet);
      let date = new Date();
      // let formattedDate = moment.default(date).format('YYYYMMDD-hhmm') + '-' + this.getFavoriteRegion() + '-' + this.data.table + '--' + Math.floor(1000 + Math.random() * 90000);
      let formattedDate = this.userFileName
      if (this.selectedFileType === '.txt') {
        // data + '\n';
        // data = data.replace(/\n/,"\r\t\t\n");
        // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        // const excelBuffer: any = XLSX.write(workbook, { bookType: 'txt', type: 'array' });
        var blob = new Blob([data], { type: "text/plain" });
        FileSaver.saveAs(blob, formattedDate + ".txt");
      } else {
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "worksheet")
        XLSX.writeFile(new_workbook, formattedDate + '.xlsx');
      }
      this.onNoClick();
    }
  }

  delete_row(ws, row_index) {
    var variable = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < variable.e.r; ++R) {
      for (var C = variable.s.c; C <= variable.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)];
      }
    }
    variable.e.r--
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
  }

  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c });
  }

  create_gap_rows(ws, nrows) {
    var ref = XLSX.utils.decode_range(ws["!ref"]);       // get original range
    ref.e.r += nrows;                                    // add to ending row
    ws["!ref"] = XLSX.utils.encode_range(ref);           // reassign row
  }

  downloadFile() {
    if (this.fileName === undefined || this.fileName === '') {
      let interval;
      if (!interval) {
        interval = setInterval(() => {
          // this.toastyService.warning(this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.FILE_GENERATE`));
          const exportKey = sessionStorage.getItem("exportKey");
          if (exportKey !== null) {
            this.downloadResult(exportKey, interval);
          }
        }, 10000);
      }
    }
  }

  downloadFileNew(filename, url) {
    this.apiService.downloadFile(url)
      .subscribe(
        response => {
          let filename: string = this.fileName
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      )
  }

  downloadResult(exportKey, interval) {
    const queryParam = { "inputLists": `${exportKey}`.split('/'), "region": this.getFavoriteRegion() };
    this.apiService.exportResultObject(queryParam).subscribe(res => {
      this.fileName = res.fileName;
      let extenFile = res.fileName.split(".")[1];
      if (res.fileName !== "") {
        var tempFile = window.location.protocol + '//' + window.location.hostname + "/pil-api-s3/downloadfile/" + res.fileName;
        // this.downloadFileNew(res.fileName, window.location.protocol + '//' + window.location.hostname + "/pil-api-s3/downloadfile/")
        window.open(tempFile);
        // this.downloadFile2(tempFile).subscribe(response => {
        //   let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
        //   const url = window.URL.createObjectURL(blob);
        //   //window.open(url);
        //   //window.location.href = response.url;
        //   // FileSaver.saveAs(blob, 'employees.json');
        //   console.log("exte", extenFile, res);
        //   FileSaver.saveAs(blob, this.userFileName + '.' + extenFile);
        // }), error => console.log('Error downloading the file'),
        //   () => console.info('File downloaded successfully');


        sessionStorage.removeItem("exportKey");
        // this.toastyService.success(this.utilService.getTranslateName(`NEW_QUERY.EXECUTE.FILE_DOWNLOAD`));
        clearInterval(interval);
        interval = null;
        while (interval !== null) {
          interval = null;
        }
        this.enableTextMsg = false;
        this.onNoClick();
      } else {
        // this.loaderService.show();
      }
    },
      error => {
        console.log("error", error.message);
      });
  }

  downloadFile2(filename): any {
    return this.http.get(filename, { responseType: 'blob' });
  }

  alertErrorMsg(displayMsg): void {

    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': displayMsg, 'isText': false, 'isAlert': true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // this.dialogRef.close({ name: this.queryName, isPublic: this.isPublic });
        return;
      }
    })
  }

}
