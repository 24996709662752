import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastyService } from 'ng2-toasty';
import role from '../table/role.json';

import { BackendApiService } from '../../services/backend-api/backend-api.service';
import { UtilService } from '../../services/util/util.service';
import { RequestService } from '../../services/request/request.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertConfirmationComponent } from '../alert-confirmation/alert-confirmation.component';
import { GroupFormattingConditionComponent } from '../group-formatting-condition/group-formatting-condition.component';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-group-formatting',
  templateUrl: './group-formatting.component.html',
  styleUrls: ['./group-formatting.component.scss']
})
export class GroupFormattingComponent implements OnInit {

  role = role;
  alertMsg = [{ 'EUROPE': [] },
  { 'MERCOSUR': [] }];
  tableValues = {
    "ipn": undefined, "region": undefined, "responseData": undefined,
    "tableData": undefined, "selectedRegion": undefined, "selectedTable": undefined, "selectedTableDetails": undefined,
    "dateDiff": undefined, "requestDatas": undefined, "roleBasedTableList": undefined
  };
  profile = this.utilService.getUserProfile();
  isAdmin = false;
  roleBasedGroupList;
  filterCategory: any[];
  selectedCategory: 'All';
  categoryList: any[];
  displayCriteriaList: any[];
  criteriaList: any[];
  criteriaListOringinal: any;
  splitChar = "§";
  lang = 'fr';
  isShowHiddenList: boolean;
  strLock: any;
  rowIndex: any = -1;
  sLibelle: any = "";
  sLibelle_2: any = "";
  sLibCourt: any = "";
  sLibCourt_2: any = "";

  isEmpty: boolean;
  isModified: boolean;
  tableSelectionItem: any;
  isModifyCate: boolean;
  filterPrivateCategory: any[];

  group_list;
  selectedGroup: any;
  newGroupName: string;
  cList: any[];
  gSource: any[];
  gSourceDisplay: any[];

  ddlTS = [{ "name": "Tout", "value": "T" }, { "name": "Sauf", "value": "S" }];

  characterListIndex = ['=', 'Dans liste', '>=', '<=', 'Cont', 'position', '<>'];

  constructor(readonly router: Router, readonly activatedRoute: ActivatedRoute, public dialog: MatDialog,
    readonly apiService: BackendApiService, readonly utilService: UtilService,
    private translateService: TranslateService,
    readonly requestService: RequestService, readonly toastyService: ToastyService, private matomoTracker: MatomoTracker) {
    this.utilService.$showIcon$.next(false);
    this.lang = requestService.getSelectedLang();
  }

  ngOnInit() {
    const role = this.utilService.getUserProfile()['role-acc-pil-irn6804'];

    let isEuroAdmin = false;
    let isMercAdmin = false;
    if (this.profile['role-pil-irn6804'] || this.profile['role-pim-irn9207']) {
      if (this.profile['role-pil-irn6804'].indexOf('pil_admin') > -1) {
        isEuroAdmin = true;
      }
      if (this.profile['role-pim-irn9207'].indexOf('pim_admin') > -1) {
        isMercAdmin = true;
      }
    }

    let regionRole = role.replace('[', '').replace(']', '');
    regionRole = regionRole.split(',');
    if (regionRole.length > 1 && isEuroAdmin && isMercAdmin) {
      if (this.profile['role-gr-pil-irn6804'].split("_")[2].toLowerCase() === 'tout') {
        this.tableValues.region = ['Europe'];
        this.tableValues.selectedRegion = "Europe";
      }
      if (this.profile['role-gr-pim-irn9207'].split("_")[2].toLowerCase() === 'tout') {
        if (this.tableValues.region) {
          this.tableValues.region.push('Mercosur');
        } else {
          this.tableValues.region = ['Mercosur'];
        }
        if (this.profile['role-gr-pil-irn6804'].split("_")[2].toLowerCase() === 'tout') {
          this.tableValues.selectedRegion = "Europe";
        } else {
          this.tableValues.selectedRegion = "Mercosur";
        }
      }
      // this.tableValues.region = ['Europe', 'Mercosur'];
      // this.tableValues.selectedRegion = "Europe";
    } else {
      if (regionRole[0] === 'acc_pil_europe' && isEuroAdmin && this.profile['role-gr-pil-irn6804'].split("_")[2].toLowerCase() === 'tout') {
        this.tableValues.region = ['Europe'];
        this.tableValues.selectedRegion = "Europe";
      } else {
        if (isMercAdmin && this.profile['role-gr-pim-irn9207'].split("_")[2].toLowerCase() === 'tout') {
          this.tableValues.region = ['Mercosur'];
          this.tableValues.selectedRegion = "Mercosur";
        }
      }
    }
    this.tableValues.ipn = this.profile['uid']; // sessionStorage.getItem("ipn");

    if (sessionStorage.getItem("request1") === null) {
      sessionStorage.setItem("request1", undefined);
      sessionStorage.setItem("request2", undefined);
      sessionStorage.setItem("request3", undefined);
    }
    // this.tableValues.requestDatas = this.requestService.getAllRequestData();
    this.getChoiceTableDetails(this.tableValues.selectedRegion);
    this.matomoTracker.trackPageView(`Group formatting`);
    // this.showHiddenCriteriaList();
  }

  getRegionVal() {
    let region = this.requestService.getRegionMsg()
  }

  // allowAllHistoricalData() {
  //   if (this.tableValues.selectedRegion === "Europe") {
  //     if (this.profile['role-pil-irn6804'].indexOf('pil_admin') > -1) {
  //       this.isAdmin = true;
  //     }
  //   } else {
  //     if (this.profile['role-pim-irn9207'].indexOf('pim_admin') > -1) {
  //       this.isAdmin = true;
  //     }
  //   }
  // }

  getUserRole4TabeList() {
    let rtnValue;
    if (this.tableValues.selectedRegion === "Europe") {
      rtnValue = this.profile['role-gr-pil-irn6804'];
    } else {
      rtnValue = this.profile['role-gr-pim-irn9207'];
    }
    return rtnValue;
  }

  getFavoriteRegion() {
    if (this.tableValues.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getChoiceTableDetails(item) {
    this.tableValues.tableData = undefined;
    this.tableValues.selectedRegion = item;

    const queryParam = { "input": `MAJ@${this.tableValues.ipn}@@@0@11@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam).subscribe(res => {
      this.tableValues.responseData = res;
      if (this.tableValues.responseData.lists) {
        this.tableValues.selectedTable = String(this.tableValues.responseData.lists[0].table.toLocaleUpperCase());
        // let date = this.tableValues.responseData.lists[0].fileUpdtdDate.split(' ');
        // this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + String(date[1]) + this.utilService.getTranslateName(`NEW_QUERY.AT`) + String(date[3]);
        // const strUpdDate = String(this.tableValues.responseData.lists[0].fileUpdtdDate).substring(3, 13);
        // const strRecDate = String(this.tableValues.responseData.lists[0].fileRecvdDate).substring(3, 13);
        // const updDate = new Date(`${strUpdDate.substring(3, 5)}/${strUpdDate.substring(0, 2)}/${strUpdDate.substring(6, 10)}`);
        // const recDate = new Date(`${strRecDate.substring(3, 5)}/${strRecDate.substring(0, 2)}/${strRecDate.substring(6, 10)}`);

        // const strServerDate = String(this.tableValues.responseData.lists[0].serverDate);
        // const serverDate = new Date(`${strServerDate.substring(3, 5)}/${strServerDate.substring(0, 2)}/${strServerDate.substring(6, 10)}`);

        // const diff = Math.abs(updDate.getTime() - serverDate.getTime());
        // const diffDays = Math.floor(diff / (1000 * 3600 * 24));
        // console.log("server Date", diffDays, serverDate, updDate);
        // this.tableValues.dateDiff = diffDays;
        if (this.tableValues.responseData.noOfLists > 0) {
          this.getTableNamesList();
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  // getRoleBasedTableNamesList() {
  //   let role;
  //   this.tableValues.roleBasedTableList = [];
  //   if (this.getUserRole4TabeList()) {
  //     if (this.getUserRole4TabeList().split("_")[2].toLowerCase() !== 'tout') {
  //       role = this.getUserRole4TabeList().toLowerCase().replace(`gr_${this.getFavoriteRegion().toLowerCase()}_`, '');
  //       const queryParam1 = { "input": `PROFIL@system@profil@${role.toLocaleUpperCase()}@`, "region": this.getFavoriteRegion() };
  //       this.apiService.getObjectGet(queryParam1).subscribe(res => {
  //         if (res && res.data) {
  //           this.roleBasedGroupList = res.data;
  //           for (const item of res.data.split('§')) {
  //             if (item.split("@")[1] === "0") {
  //               this.tableValues.roleBasedTableList.push(item.split("@")[0]);
  //             }
  //           }
  //         }
  //       },
  //         error => {
  //           this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
  //           console.log("error", error.message);
  //         });
  //     } else {
  //       this.roleBasedGroupList = '';
  //     }
  //   }
  // }

  getTableNamesList() {
    const queryParam1 = { "input": `TABGEN@${this.tableValues.ipn}@@@0@${this.tableValues.responseData.noOfLists}@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam1).subscribe(res => {
      let tabData = [];
      tabData = res.lists;
      if (tabData) {
        this.tableValues.tableData = [];
        let i = 0;
        for (const item of tabData) {
          const td = this.tableValues.responseData.lists.filter(a => String(a.table).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          // const roleTable = this.tableValues.roleBasedTableList.filter(a => String(a).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          if (td.length > 0) {
            this.tableValues.tableData.push({ "name": String(item).toLocaleUpperCase(), "fileRecvdDate": td[0].fileRecvdDate, "fileUpdtdDate": td[0].fileUpdtdDate })
          }
          i = i + 1;
        }
        this.tableValues.tableData = this.tableValues.tableData.sort();
        // if (this.tableValues.tableData.length > 0) {
        //   if (this.tableValues.tableData.indexOf('BCV') < 0) {
        //     this.tableSelection(this.tableValues.tableData[0]);
        //   }
        // }
        this.cList = [];
        for (const item of this.tableValues.tableData) {
          this.cList.push({ 'table': item.name });
        }
        this.getFilterCategoryDetails();
        this.getFilterCriteriaListDetails();
        this.getGroupList();
        // console.log("table", this.tableValues.tableData, this.cList)
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  getGroupList() {
    this.group_list = undefined;
    const queryParam = { "input": "PROFIL@system@profil@@", "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(resG => {
      if (resG.noObjects > 0) {
        const queryParam1 = { "input": `PROFIL@system@profil@@0@${resG.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(resG1 => {
          this.group_list = resG1.lists;
          if (this.group_list.length > 0) {
            this.group_list = this.group_list.sort();
            this.selectedGroup = this.group_list[0];
            setTimeout(() => {
              this.getSelectedGroupDetails();
            }, 2000);
          }
          // console.log("resG1", resG1);
        },
          error => {
            console.log("error", error.message);
          });
      }
    },
      error => {
        console.log("error", error.message);
      });
  }

  delCondition(rowIndex) {
    if (this.rowIndex > -1) {
      const dialogRef = this.dialog.open(AlertConfirmationComponent, {
        disableClose: true,
        width: '550px',
        data: { 'display': this.utilService.getTranslateName(`GROUP_FORMATTING.DEL_COND`) }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.gSourceDisplay.splice(rowIndex, 1);
          // console.log("this.gSourceDisplay", this.gSourceDisplay);
          this.validation();
        }
      })
    }
  }

  validation() {
    let inpVal = "";
    let ii = 0;
    for (const tName of this.tableValues.tableData) {
      const fillVal = this.gSourceDisplay.filter(b => b.table === tName.name);
      ii = ii + 1;
      if (fillVal.length > 0) {
        if (inpVal === "") {
          inpVal = `${tName.name}@${fillVal[0].ts ? fillVal.length : 0}@0@0@`;
        } else {
          inpVal = inpVal + `§${tName.name}@${fillVal[0].ts ? fillVal.length : 0}@0@0@`;
        }
        for (const item of fillVal) {
          if (item.ts) {
            const cond = item.value.toString().split(',');
            const posi = item.index.toString().split(',');
            inpVal = inpVal + `${item.ts === 'T' ? 'B' : ''}@${item.ts}@${cond.length}@0@`
            for (let index = 0; index < cond.length; index++) {
              let valueItem;
              let indexValue = 0;
              if (posi[index] === "6") {
                indexValue = cond[index].split('[')[1].substr(1, cond[index].split('[')[1].indexOf(')') - 1);
                valueItem = cond[index].split('[')[1].substr(cond[index].split('[')[1].indexOf(')') + 2, cond[index].split('[')[1].indexOf(']')).substr(0, cond[index].split('[')[1].substr(cond[index].split('[')[1].indexOf(')') + 2, cond[index].split('[')[1].indexOf(']')).length - 1);
              } else if (posi[index] === "2") {
                valueItem = cond[index].split('[')[1].substr(0, cond[index].split('[')[1].indexOf(']'));
              } else {
                valueItem = cond[index].split('[')[1].substr(cond[index].split('[')[1].indexOf(' ') + 1, cond[index].split('[')[1].indexOf(']')).substr(0, cond[index].split('[')[1].substr(cond[index].split('[')[1].indexOf(' ') + 1, cond[index].split('[')[1].indexOf(']') - 1).length - 1);
              }
              // const value = cond[index].split('(')[1].substr(0, cond[index].split('(')[1].length - 1);
              inpVal = inpVal + `${posi[index]}@${indexValue}@${valueItem}@${item.size.toString().split(',')[index]}@${item.GCOName.split(',')[index]}_${item.type.toString().split(',')[index]}@${item.type.toString().split(',')[index]}@`;
            }
          }
        }
      }
      if (ii === this.tableValues.tableData.length) {
        const queryParam = {
          "input": `PROFIL@system@profil@${this.selectedGroup.toUpperCase()}@${inpVal}@`, "region": this.getFavoriteRegion()
        }
        this.apiService.saveObject(queryParam).subscribe(respSave => {
          // console.log("respSave", respSave);
          this.rowIndex = -1;
          this.getSelectedGroupDetails();
          // if (respSave.description === "SUCCESS") {
          //   this.getGroupList();
          // }
        },
          error => {
            console.log("error", error.message);
          });
        // console.log("inputVal", inpVal, queryParam);
      }
    }
  }

  updForbidden() {
    const fillVal = this.gSourceDisplay.filter(b => b.table === this.tableValues.selectedTable);
    if (fillVal.length > 0) {
      if (fillVal[0].ts) {
        this.alertPopup(this.utilService.translateService.instant(`GROUP_FORMATTING.FORB_VALUE`))
      } else {
        this.alertPopup(this.utilService.translateService.instant(`GROUP_FORMATTING.FORB_EXISTS`))
      }
    } else {
      this.gSourceDisplay.push({ "table": this.tableValues.selectedTable, "value": this.utilService.getTranslateName(`GROUP_FORMATTING.COND_FORB`) });
    }
    this.validation();
  }

  addGroup() {
    if (this.group_list.filter(a => a.toUpperCase() === this.newGroupName.toUpperCase()).length > 0) {
      this.alertPopup(this.translateService.instant(`GROUP_FORMATTING.GRP_NAME_EXISTS`));
      this.newGroupName = undefined;
    } else {
      const queryParam = `PROFIL@system@profil@${this.newGroupName.toUpperCase()}@@`
      this.apiService.saveObject({ "input": queryParam, "region": this.getFavoriteRegion() }).subscribe(respGRP => {
        // console.log("respGRP", respGRP);
        if (respGRP.description === "SUCCESS") {
          this.getGroupList();
          this.matomoTracker.trackEvent("Group formatting", `Add ${this.newGroupName.toUpperCase()} group`, this.profile['uid']);
        }
      },
        error => {
          console.log("error", error.message);
        });
    }
  }

  deleteGroup() {

    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`GROUP_FORMATTING.GRP_NAME_DELETE_ALT`) + this.selectedGroup.toUpperCase() }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        const queryParam = {
          "input": `PROFIL@system@profil@${this.selectedGroup.toUpperCase()}@@`, "region": this.getFavoriteRegion()
        }
        this.apiService.deleteObject(queryParam).subscribe(respGRPDel => {
          // console.log("respGRPDel", respGRPDel);
          if (respGRPDel.description === "SUCCESS") {
            this.getGroupList();
            this.matomoTracker.trackEvent("Group formatting", `Delete ${this.selectedGroup.toUpperCase()} group`, this.profile['uid']);
          }
        },
          error => {
            console.log("error", error.message);
          });
      }
    })
  }

  getSelectedGroupDetails() {
    this.gSource = [];
    this.gSourceDisplay = [];
    const queryParam = {
      "input": `PROFIL@system@profil@${this.selectedGroup.toUpperCase()}@`, "region": this.getFavoriteRegion()
    }
    this.apiService.getObjectGet(queryParam).subscribe(resGRPDetails => {
      // console.log("resGRPDetails", resGRPDetails);
      if (resGRPDetails.data.length > 0) {
        const sGVal = resGRPDetails.data.split('§');
        for (const item of sGVal) {
          let sIVal = item.split('@');
          if (sIVal[1] === "0") {
            this.gSource.push({ 'table': sIVal[0], "value": this.utilService.getTranslateName(`GROUP_FORMATTING.COND_FORB`) });
          }
          for (let index = 0; index < sIVal[1]; index++) {
            this.gSource.push({ 'table': sIVal[0] });
            let nextVal = 0;
            const loopValue = Number(sIVal[6]) + Number(sIVal[7]);
            for (let index1 = 0; index1 < loopValue; index1++) {
              if (isNaN(sIVal[12])) {
                const tList = this.cList.filter(x => x.table === sIVal[0]);
                if (tList.length > 0) {
                  const gcoList = tList[0].lists.filter(c => c.GCOName === sIVal[12 + nextVal].substr(0, sIVal[12 + nextVal].length - 2));
                  if (gcoList.length > 0) {
                    this.gSource[this.gSource.length - 1].ts = sIVal[5];
                    let valueItem;
                    if (sIVal[8 + nextVal] === "6") {
                      valueItem = `(${sIVal[9 + nextVal]})=${sIVal[10 + nextVal]}`;
                    } else if (sIVal[8 + nextVal] === "2") {
                      valueItem = `${sIVal[10 + nextVal]}`;
                    } else {
                      valueItem = `${this.characterListIndex[sIVal[8 + nextVal] - 1]} ${sIVal[10 + nextVal]}`;
                    }

                    if (index1 === 0) {
                      this.gSource[this.gSource.length - 1].GCOName = gcoList[0].GCOName;
                      this.gSource[this.gSource.length - 1].type = gcoList[0].typeOfField;
                      this.gSource[this.gSource.length - 1].size = gcoList[0].size;
                      this.gSource[this.gSource.length - 1].index = sIVal[8 + nextVal];
                      this.gSource[this.gSource.length - 1].value = `${gcoList[0].longLabel.split('§').length > 1 ? gcoList[0].longLabel.split('§')[this.getLangIndex(this.lang)] : gcoList[0].longLabel} = [${valueItem}],`;
                    } else {
                      this.gSource[this.gSource.length - 1].GCOName = this.gSource[this.gSource.length - 1].GCOName + ',' + gcoList[0].GCOName;
                      this.gSource[this.gSource.length - 1].type = this.gSource[this.gSource.length - 1].type + ',' + gcoList[0].typeOfField;
                      this.gSource[this.gSource.length - 1].size = this.gSource[this.gSource.length - 1].size + ',' + gcoList[0].size;
                      this.gSource[this.gSource.length - 1].index = this.gSource[this.gSource.length - 1].index + ',' + sIVal[8 + nextVal];
                      this.gSource[this.gSource.length - 1].value = this.gSource[this.gSource.length - 1].value + `${gcoList[0].longLabel.split('§').length > 1 ? gcoList[0].longLabel.split('§')[this.getLangIndex(this.lang)] : gcoList[0].longLabel} = [${valueItem}],`;
                    }
                  }
                }

                if (index1 === loopValue - 1) {
                  sIVal.splice(4, 10 + nextVal);
                  this.gSource[this.gSource.length - 1].value = this.gSource[this.gSource.length - 1].value.substr(0, this.gSource[this.gSource.length - 1].value.length - 1);
                } else {
                  if (sIVal[7] !== "0") {
                    sIVal.splice(8, 6);
                  }
                  nextVal = nextVal + 6;
                }
              } else {


                const tList = this.cList.filter(x => x.table === sIVal[0]);
                if (tList.length > 0) {
                  const gcoList = tList[0].lists.filter(c => c.GCOName === sIVal[10].substr(0, sIVal[10].length - 2));
                  if (gcoList.length > 0) {
                    this.gSource[this.gSource.length - 1].ts = sIVal[5];
                    let valueItem;

                    for (let vv = 0; vv < sIVal[12]; vv++) {
                      if (vv === 0) {
                        valueItem = sIVal[12 + 3];
                        sIVal.splice(12 + 1, 3);
                      } else {
                        valueItem = valueItem + '/' + sIVal[12 + 3];
                        sIVal.splice(12 + 1, 3);
                      }
                    }


                    // if (sIVal[8 + nextVal] === "6") {
                    //   valueItem = `(${sIVal[9 + nextVal]})=${sIVal[10 + nextVal]}`;
                    // } else if (sIVal[8 + nextVal] === "2") {
                    //   valueItem = `${sIVal[10 + nextVal]}`;
                    // } else {
                    //   valueItem = `${this.characterListIndex[sIVal[8 + nextVal] - 1]} ${sIVal[10 + nextVal]}`;
                    // }

                    if (index1 === 0) {
                      this.gSource[this.gSource.length - 1].GCOName = gcoList[0].GCOName;
                      this.gSource[this.gSource.length - 1].type = gcoList[0].typeOfField;
                      this.gSource[this.gSource.length - 1].size = gcoList[0].size;
                      this.gSource[this.gSource.length - 1].index = sIVal[8 + nextVal];
                      this.gSource[this.gSource.length - 1].value = `${gcoList[0].longLabel.split('§').length > 1 ? gcoList[0].longLabel.split('§')[this.getLangIndex(this.lang)] : gcoList[0].longLabel} = [${valueItem}],`;
                    } else {
                      this.gSource[this.gSource.length - 1].GCOName = this.gSource[this.gSource.length - 1].GCOName + ',' + gcoList[0].GCOName;
                      this.gSource[this.gSource.length - 1].type = this.gSource[this.gSource.length - 1].type + ',' + gcoList[0].typeOfField;
                      this.gSource[this.gSource.length - 1].size = this.gSource[this.gSource.length - 1].size + ',' + gcoList[0].size;
                      this.gSource[this.gSource.length - 1].index = this.gSource[this.gSource.length - 1].index + ',' + sIVal[8 + nextVal];
                      this.gSource[this.gSource.length - 1].value = this.gSource[this.gSource.length - 1].value + `${gcoList[0].longLabel.split('§').length > 1 ? gcoList[0].longLabel.split('§')[this.getLangIndex(this.lang)] : gcoList[0].longLabel} = [${valueItem}],`;
                    }
                  }
                }

                if (index1 === loopValue - 1) {
                  sIVal.splice(4, 4 + nextVal);
                  this.gSource[this.gSource.length - 1].value = this.gSource[this.gSource.length - 1].value.substr(0, this.gSource[this.gSource.length - 1].value.length - 1);
                } else {
                  sIVal.splice(8, 5);
                  nextVal = nextVal + 5;
                }


              }
            }
          }
        }
        // this.gSource = this.gSource.sort(this.utilService.sortArrayValueList);
        // for (let index = 0; index < this.tableValues.tableData.length; index++) {
        //   const element = this.tableValues.tableData[index];
        //   const filVal = this.gSource.filter(v => v.table === element.name);
        //   if (filVal.length > 0) {
        //     for (const item of filVal) {
        //       this.gSourceDisplay.push(item);
        //     }
        //   }
        // }

        this.gSource = this.gSource.sort(this.utilService.sortArrayValueList);
        this.gSourceDisplay = [];
        for (const item1 of this.gSource) {
          this.gSourceDisplay.push(item1);
        }
        // console.log("gSource", this.gSourceDisplay);
      }
    },
      error => {
        console.log("error", error.message);
      });
  }

  selectedRow(item, i) {
    this.rowIndex = i;
    // console.log("item", i, item);
  }

  tableSelection(item) {
    if (this.sLibelle || this.sLibelle_2 || this.sLibCourt || this.sLibCourt_2) {
      this.tableSelectionItem = item;
      this.isModified = true;
    } else {
      this.tableValues.dateDiff = undefined;
      this.tableValues.selectedTable = item.name;
      let region = this.requestService.getRegionMsg();

      this.getFilterCategoryDetails();
      this.CheckLock();
    }
  }

  getFilterCategoryDetails() {
    this.filterCategory = [];
    const queryParam = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          // this.filterCategory = res1.lists;
          this.filterCategory.push(this.utilService.getTranslateName(`COMMON.ALL`));
          this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
          this.getPrivateFilterCategoryDetails();
          // this.filterCategory = this.filterCategory.sort(this.utilService.sortArray);
          for (const item of res1.lists.sort(this.utilService.sortArray)) {
            this.filterCategory.push(item);
          }
        },
          error => {
            console.log("error", error.message);
          });
      } else {
        this.filterCategory.push(this.utilService.getTranslateName(`COMMON.ALL`));
        this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
        this.filterCategory = this.filterCategory.sort(this.utilService.sortArray);
      }
    },
      error => {
        console.log("error", error.message);
      });
  }



  getPrivateFilterCategoryDetails() {
    this.filterPrivateCategory = [];
    const queryParam = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res12 => {
          // console.log("res12", res12);
          this.filterPrivateCategory = res12.lists;
          if (res12.lists) {
            for (let index = 0; index < res12.lists.length; index++) {
              const element = res12.lists[index];
              this.filterCategory.push(element);
            }
          }
          // this.filterPrivateCategory = res12.lists;
          // this.filterPrivateCategory = this.filterPrivateCategory.sort(this.utilService.sortArray)
        },
          error => {
            console.log("error", error.message);
          });
      }
    },
      error => {
        console.log("error", error.message);
      });

  }

  getFilterCriteriaListDetails() {
    for (let i = 0; i < this.cList.length; i++) {
      const element = this.cList[i];

      this.displayCriteriaList = [];
      this.criteriaList = [];
      const queryParam = { "input": `CHAMP ETENDU@${this.tableValues.ipn}@${element.table}@@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectSize(queryParam).subscribe(res => {
        if (res.noObjects > 0) {
          const queryParam1 = { "input": `CHAMP ETENDU@${this.tableValues.ipn}@${element.table}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
          this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
            this.criteriaListOringinal = res1.lists;

            this.cList[i].lists = this.criteriaListOringinal;
          },
            error => {
              console.log("error", error.message);
            });
        }
      },
        error => {
          console.log("error", error.message);
        });
    }
  }

  getLangIndex(lang) {
    if (lang === 'en') {
      return 1;
    } else {
      return 0;
    }
  }

  showHiddenCriteriaList() {
    if (this.tableValues.region === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_obj_syst') > -1 && this.getFavoriteRegion() === 'PIL') {
        this.isShowHiddenList = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_obj_syst') > -1 && this.getFavoriteRegion() === 'PIM') {
        this.isShowHiddenList = true;
      }
    }
  }

  getSelectedList(selectedItem) {
    if (selectedItem === this.utilService.getTranslateName(`COMMON.ALL`)) {
      return true;
    } else {
      return false;
    }
  }


  clickClose() {
    const pathName = window.location.pathname;
    this.utilService.removeOpenedWindowName(pathName);
    this.utilService.$showIcon$.next(true);
    const openedWindowNameList = this.utilService.getOpenedWindowNameList()
    if (openedWindowNameList.length > 0) {
      this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
    } else {
      this.router.navigate(['home']);
    }
  }

  CheckLock() {
    this.strLock = "";
    const queryParam = {
      "input": "LIBELLE@system@libelle@lock@", "region": this.getFavoriteRegion()
    }
    this.apiService.getObjectGet(queryParam).subscribe(res => {
      if (res.data) {
        // console.log("substring", res, `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} ${res.data.substring(6, 8)}-
        // ${res.data.substring(4, 6)}-${res.data.substring(0, 4)} ${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} ${res.data.substring(8, 10)}:
        // ${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`);
        this.strLock = `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} `;
        this.strLock = this.strLock + `${res.data.substring(6, 8)}-${res.data.substring(4, 6)}-${res.data.substring(0, 4)} `;
        this.strLock = this.strLock + `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} `;
        this.strLock = this.strLock + `${res.data.substring(8, 10)}:${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`;
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  alertPopup(msg) {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': msg, isAlert: 'true' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.isEmpty = true;
      }
    })
  }

  addCondition() {
    const gTableDts = this.gSource.filter(vv => vv.table === this.tableValues.selectedTable);
    if (gTableDts.length === 1) {
      if (gTableDts[0].hasOwnProperty("ts")) {
        this.addConditionAfterChecking();
      } else {
        this.alertPopup(this.utilService.translateService.instant(`GROUP_FORMATTING.FORB_ADD`));
      }
    } else {
      this.addConditionAfterChecking();
    }
  }

  addConditionAfterChecking() {
    this.displayCriteriaList = [];
    const tableValueDet = this.cList.filter(bn => bn.table === this.tableValues.selectedTable);
    if (tableValueDet.length > 0) {
      for (const item of tableValueDet[0].lists) {
        if (item.typeOfField.toString() === "2" || item.typeOfField.toString() === "6" || item.typeOfField.toString() === "0") {
          this.displayCriteriaList.push({
            "label": `${item.longLabel.split('§').length > 1 ? item.longLabel.split('§')[this.getLangIndex(this.lang)] : item.longLabel}`,
            "longLabel": item.longLabel,
            "GCOName": item.GCOName,
            "type": item.typeOfField,
            "size": item.size
          })
        }
      }
      // this.displayCriteriaList.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaList)
      console.log("displayCriteriaList", this.cList);
      const dialogRef = this.dialog.open(GroupFormattingConditionComponent, {
        disableClose: true,
        width: '950px',
        data: { 'displayCriteriaList': this.displayCriteriaList, privateFilterCategory: this.filterPrivateCategory, filterCategory: this.filterCategory, "tableValues": this.tableValues, isAlert: 'true' }
      });

      dialogRef.afterClosed().subscribe(result => {
        // console.log("eventevent", result);
        if (result) {
          // console.log("eventevent", result);
          if (result.length > 0) {
            this.gSource.push({ "table": result[0].table, "ts": "T" })
            for (let index = 0; index < result.length; index++) {
              const element = result[index];
              if (index === 0) {
                this.gSource[this.gSource.length - 1].value = element.value;
                this.gSource[this.gSource.length - 1].GCOName = element.GCOName;
                this.gSource[this.gSource.length - 1].type = element.type;
                this.gSource[this.gSource.length - 1].size = element.size;
                this.gSource[this.gSource.length - 1].index = element.index;
              } else {
                this.gSource[this.gSource.length - 1].GCOName = this.gSource[this.gSource.length - 1].GCOName + ',' + element.GCOName;
                this.gSource[this.gSource.length - 1].type = this.gSource[this.gSource.length - 1].type + ',' + element.type;
                this.gSource[this.gSource.length - 1].size = this.gSource[this.gSource.length - 1].size + ',' + element.size;
                this.gSource[this.gSource.length - 1].index = this.gSource[this.gSource.length - 1].index + ',' + element.index;
                this.gSource[this.gSource.length - 1].value = this.gSource[this.gSource.length - 1].value + ',' + element.value;
              }
              // this.gSource = this.gSource.sort(this.utilService.sortArrayValueList);

              if (index === result.length - 1) {
                this.gSource = this.gSource.sort(this.utilService.sortArrayValueList);
                this.gSourceDisplay = [];
                // for (let index = 0; index < this.tableValues.tableData.length; index++) {
                //   const element = this.tableValues.tableData[index];
                //   const filVal = this.gSource.filter(v => v.table === element.name);
                //   if (filVal.length > 0) {
                //     for (const item of filVal) {
                //       this.gSourceDisplay.push(item);
                //     }
                //   }
                // }
                for (const item1 of this.gSource) {
                  this.gSourceDisplay.push(item1);
                }
                this.validation();
              }
            }
          }
          this.isEmpty = true;
        }
      })
    }
  }

  modifyCondition(rowIndex) {
    if (rowIndex > -1) {
      if (this.gSourceDisplay[rowIndex].hasOwnProperty('ts')) {
        const tabNameVal = this.gSourceDisplay[rowIndex].table;
        this.displayCriteriaList = [];
        const tableValueDet = this.cList.filter(bn => bn.table === tabNameVal);
        if (tableValueDet.length > 0) {
          for (const item of tableValueDet[0].lists) {
            if (item.typeOfField.toString() === "2" || item.typeOfField.toString() === "6" || item.typeOfField.toString() === "0") {
              this.displayCriteriaList.push({
                "label": `${item.longLabel.split('§').length > 1 ? item.longLabel.split('§')[this.getLangIndex(this.lang)] : item.longLabel}`,
                "longLabel": item.longLabel,
                "GCOName": item.GCOName,
                "type": item.typeOfField,
                "size": item.size
              })
            }
          }
          this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaList)
          const dialogRef = this.dialog.open(GroupFormattingConditionComponent, {
            disableClose: true,
            width: '950px',
            data: { 'displayCriteriaList': this.displayCriteriaList, privateFilterCategory: this.filterPrivateCategory, gPreSource: this.gSource[rowIndex], filterCategory: this.filterCategory, "tableValues": this.tableValues, isAlert: 'true' }
          });

          dialogRef.afterClosed().subscribe(result => {
            // console.log("eventevent", result);
            if (result) {
              // console.log("eventevent", result);
              if (result.length > 0) {
                // this.gSource.push({ "table": result[0].table, "ts": "T" })
                for (let index = 0; index < result.length; index++) {
                  const element = result[index];
                  if (index === 0) {
                    this.gSource[rowIndex].value = element.value;
                    this.gSource[rowIndex].GCOName = element.GCOName;
                    this.gSource[rowIndex].type = element.type;
                    this.gSource[rowIndex].size = element.size;
                    this.gSource[rowIndex].index = element.index;
                  } else {
                    this.gSource[rowIndex].GCOName = this.gSource[rowIndex].GCOName + ',' + element.GCOName;
                    this.gSource[rowIndex].type = this.gSource[rowIndex].type + ',' + element.type;
                    this.gSource[rowIndex].size = this.gSource[rowIndex].size + ',' + element.size;
                    this.gSource[rowIndex].index = this.gSource[rowIndex].index + ',' + element.index;
                    this.gSource[rowIndex].value = this.gSource[rowIndex].value + ',' + element.value;
                  }
                  // this.gSource = this.gSource.sort(this.utilService.sortArrayValueList);

                  if (index === result.length - 1) {
                    // this.gSourceDisplay = [];
                    // for (let index = 0; index < this.tableValues.tableData.length; index++) {
                    //   const element = this.tableValues.tableData[index];
                    //   const filVal = this.gSource.filter(v => v.table === element.name);
                    //   if (filVal.length > 0) {
                    //     for (const item of filVal) {
                    //       this.gSourceDisplay.push(item);
                    //     }
                    //   }
                    this.gSource = this.gSource.sort(this.utilService.sortArrayValueList);
                    this.gSourceDisplay = [];
                    for (const item1 of this.gSource) {
                      this.gSourceDisplay.push(item1);
                    }
                    this.validation();
                  }
                }
              }
              this.isEmpty = true;
            }
          })
        }
      } else {
        this.alertPopup(this.utilService.translateService.instant(`GROUP_FORMATTING.FORB_ADD`));
      }
    }
  }

  omit_special_char(event) {
    var k;
    // console.log("event", event.charCode, event)
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 95 || (k >= 48 && k <= 57));
  }
}
