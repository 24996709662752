import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastyService } from 'ng2-toasty';
import role from '../table/role.json';

import { BackendApiService } from '../../services/backend-api/backend-api.service';
import { UtilService } from '../../services/util/util.service';
import { RequestService } from '../../services/request/request.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertConfirmationComponent } from '../alert-confirmation/alert-confirmation.component';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-label-management',
  templateUrl: './label-management.component.html',
  styleUrls: ['./label-management.component.scss']
})
export class LabelManagementComponent implements OnInit {

  role = role;
  alertMsg = [{ 'EUROPE': [] },
  { 'MERCOSUR': [] }];
  tableValues = {
    "ipn": undefined, "region": undefined, "responseData": undefined,
    "tableData": undefined, "selectedRegion": undefined, "selectedTable": undefined, "selectedTableDetails": undefined,
    "dateDiff": undefined, "requestDatas": undefined, "roleBasedTableList": undefined
  };
  profile = this.utilService.getUserProfile();
  isAdmin = false;
  roleBasedGroupList;
  filterCategory: any[];
  selectedCategory: 'All';
  categoryList: any[];
  displayCriteriaList: any[];
  criteriaList: any[];
  criteriaListOringinal: any;
  splitChar = "§";
  lang = 'fr';
  isShowHiddenList: boolean;
  strLock: any;
  rowIndex: any = -1;
  sLibelle: any = "";
  sLibelle_2: any = "";
  sLibCourt: any = "";
  sLibCourt_2: any = "";

  isEmpty: boolean;
  isModified: boolean;
  tableSelectionItem: any;
  isModifyCate: boolean;
  filterPrivateCategory: any[];

  gcoNameSort = true;
  lFranceSort = false;
  lEnglishSort = true;
  sFranceSort = true;
  sEnglishSort = true;

  constructor(readonly router: Router, readonly activatedRoute: ActivatedRoute, public dialog: MatDialog,
    readonly apiService: BackendApiService, readonly utilService: UtilService,
    private translateService: TranslateService,
    readonly requestService: RequestService, readonly toastyService: ToastyService, private matomoTracker: MatomoTracker) {
    this.utilService.$showIcon$.next(false);
    this.lang = requestService.getSelectedLang();
  }

  ngOnInit() {
    const role = this.utilService.getUserProfile()['role-acc-pil-irn6804'];
    let isEuroAdmin = false;
    let isMercAdmin = false;
    if (this.profile['role-pil-irn6804'] || this.profile['role-pim-irn9207']) {
      if (this.profile['role-pil-irn6804'].indexOf('pil_admin') > -1) {
        isEuroAdmin = true;
      }
      if (this.profile['role-pim-irn9207'].indexOf('pim_admin') > -1) {
        isMercAdmin = true;
      }
    }
    let regionRole = role.replace('[', '').replace(']', '');
    regionRole = regionRole.split(',');
    // if (regionRole.length > 1 && isEuroAdmin && isMercAdmin) {
    //   this.tableValues.region = ['Europe', 'Mercosur'];
    //   this.tableValues.selectedRegion = "Europe";
    // } else {
    //   if (regionRole[0] === 'acc_pil_europe' && isEuroAdmin) {
    //     this.tableValues.region = ['Europe'];
    //     this.tableValues.selectedRegion = "Europe";
    //   } else {
    //     if (isMercAdmin) {
    //       this.tableValues.region = ['Mercosur'];
    //       this.tableValues.selectedRegion = "Mercosur";
    //     }
    //   }
    // }

    if (regionRole.length > 1 && isEuroAdmin && isMercAdmin) {
      if (this.profile['role-gr-pil-irn6804'].split("_")[2].toLowerCase() === 'tout') {
        this.tableValues.region = ['Europe'];
        this.tableValues.selectedRegion = "Europe";
      }
      if (this.profile['role-gr-pim-irn9207'].split("_")[2].toLowerCase() === 'tout') {
        if (this.tableValues.region) {
          this.tableValues.region.push('Mercosur');
        } else {
          this.tableValues.region = ['Mercosur'];
        }
        if (this.profile['role-gr-pil-irn6804'].split("_")[2].toLowerCase() === 'tout') {
          this.tableValues.selectedRegion = "Europe";
        } else {
          this.tableValues.selectedRegion = "Mercosur";
        }
      }
      // this.tableValues.region = ['Europe', 'Mercosur'];
      // this.tableValues.selectedRegion = "Europe";
    } else {
      if (regionRole[0] === 'acc_pil_europe' && isEuroAdmin && this.profile['role-gr-pil-irn6804'].split("_")[2].toLowerCase() === 'tout') {
        this.tableValues.region = ['Europe'];
        this.tableValues.selectedRegion = "Europe";
      } else {
        if (isMercAdmin && this.profile['role-gr-pim-irn9207'].split("_")[2].toLowerCase() === 'tout') {
          this.tableValues.region = ['Mercosur'];
          this.tableValues.selectedRegion = "Mercosur";
        }
      }
    }
    this.tableValues.ipn = this.profile['uid']; // sessionStorage.getItem("ipn");

    if (sessionStorage.getItem("request1") === null) {
      sessionStorage.setItem("request1", undefined);
      sessionStorage.setItem("request2", undefined);
      sessionStorage.setItem("request3", undefined);
    }
    this.tableValues.requestDatas = this.requestService.getAllRequestData();
    this.getChoiceTableDetails(this.tableValues.selectedRegion);
    this.showHiddenCriteriaList();
    this.matomoTracker.trackPageView(`Label manangement`);
  }

  getRegionVal() {
    let region = this.requestService.getRegionMsg()
  }

  allowAllHistoricalData() {
    if (this.tableValues.selectedRegion === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_admin') > -1) {
        this.isAdmin = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_admin') > -1) {
        this.isAdmin = true;
      }
    }
  }

  getUserRole4TabeList() {
    let rtnValue;
    if (this.tableValues.selectedRegion === "Europe") {
      rtnValue = this.profile['role-gr-pil-irn6804'];
    } else {
      rtnValue = this.profile['role-gr-pim-irn9207'];
    }
    return rtnValue;
  }

  getFavoriteRegion() {
    if (this.tableValues.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getChoiceTableDetails(item) {
    this.tableValues.tableData = undefined;
    this.tableValues.selectedRegion = item;
    this.allowAllHistoricalData();
    this.getRoleBasedTableNamesList();

    const queryParam = { "input": `MAJ@${this.tableValues.ipn}@@@0@11@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam).subscribe(res => {
      this.tableValues.responseData = res;
      if (this.tableValues.responseData.lists) {
        this.tableValues.selectedTable = String(this.tableValues.responseData.lists[0].table.toLocaleUpperCase());
        let date = this.tableValues.responseData.lists[0].fileUpdtdDate.split(' ');
        this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + String(date[1]) + this.utilService.getTranslateName(`NEW_QUERY.AT`) + String(date[3]);
        const strUpdDate = String(this.tableValues.responseData.lists[0].fileUpdtdDate).substring(3, 13);
        const strRecDate = String(this.tableValues.responseData.lists[0].fileRecvdDate).substring(3, 13);
        const updDate = new Date(`${strUpdDate.substring(3, 5)}/${strUpdDate.substring(0, 2)}/${strUpdDate.substring(6, 10)}`);
        const recDate = new Date(`${strRecDate.substring(3, 5)}/${strRecDate.substring(0, 2)}/${strRecDate.substring(6, 10)}`);

        const strServerDate = String(this.tableValues.responseData.lists[0].serverDate);
        const serverDate = new Date(`${strServerDate.substring(3, 5)}/${strServerDate.substring(0, 2)}/${strServerDate.substring(6, 10)}`);

        const diff = Math.abs(updDate.getTime() - serverDate.getTime());
        const diffDays = Math.floor(diff / (1000 * 3600 * 24));
        // console.log("server Date", diffDays, serverDate, updDate);
        this.tableValues.dateDiff = diffDays;
        if (this.tableValues.responseData.noOfLists > 0) {
          this.getTableNamesList();
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  getRoleBasedTableNamesList() {
    let role;
    this.tableValues.roleBasedTableList = [];
    if (this.getUserRole4TabeList()) {
      if (this.getUserRole4TabeList().split("_")[2].toLowerCase() !== 'tout') {
        role = this.getUserRole4TabeList().toLowerCase().replace(`gr_${this.getFavoriteRegion().toLowerCase()}_`, '');
        const queryParam1 = { "input": `PROFIL@system@profil@${role.toLocaleUpperCase()}@`, "region": this.getFavoriteRegion() };
        this.apiService.getObjectGet(queryParam1).subscribe(res => {
          if (res && res.data) {
            this.roleBasedGroupList = res.data;
            for (const item of res.data.split('§')) {
              if (item.split("@")[1] === "0") {
                this.tableValues.roleBasedTableList.push(item.split("@")[0]);
              }
            }
          }
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
            console.log("error", error.message);
          });
      } else {
        this.roleBasedGroupList = '';
      }
    }
  }

  getTableNamesList() {
    const queryParam1 = { "input": `TABGEN@${this.tableValues.ipn}@@@0@${this.tableValues.responseData.noOfLists}@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam1).subscribe(res => {
      let tabData = [];
      tabData = res.lists;
      if (tabData) {
        this.tableValues.tableData = [];
        let i = 0;
        for (const item of tabData) {
          const td = this.tableValues.responseData.lists.filter(a => String(a.table).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          const roleTable = this.tableValues.roleBasedTableList.filter(a => String(a).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          if (td.length > 0 && roleTable.length === 0) {
            this.tableValues.tableData.push({ "name": String(item).toLocaleUpperCase(), "fileRecvdDate": td[0].fileRecvdDate, "fileUpdtdDate": td[0].fileUpdtdDate })
          }
          i = i + 1;
        }
        this.tableValues.tableData = this.tableValues.tableData.sort();
        if (this.tableValues.tableData.length > 0) {
          if (this.tableValues.tableData.indexOf('BCV') < 0) {
            this.tableSelection(this.tableValues.tableData[0]);
          }
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  tableSelection(item) {
    if (this.sLibelle || this.sLibelle_2 || this.sLibCourt || this.sLibCourt_2) {
      this.tableSelectionItem = item;
      this.isModified = true;
      this.submit();
    } else {
      this.resetValue();
      this.tableValues.dateDiff = undefined;
      this.tableValues.selectedTable = item.name;
      let region = this.requestService.getRegionMsg();

      this.getFilterCategoryDetails();
      this.getFilterCriteriaListDetails();
      this.CheckLock();
    }
  }

  getFilterCategoryDetails() {
    this.resetValue();
    this.filterCategory = [];
    const queryParam = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@system@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          // this.filterCategory = res1.lists;
          this.filterCategory.push(this.utilService.getTranslateName(`COMMON.ALL`));
          this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
          this.getPrivateFilterCategoryDetails();
          // this.filterCategory = res1.lists.sort(this.utilService.sortArray);
          for (const item of res1.lists.sort(this.utilService.sortArray)) {
            this.filterCategory.push(item);
          }
        },
          error => {
            console.log("error", error.message);
          });
      } else {
        this.filterCategory.push(this.utilService.getTranslateName(`COMMON.ALL`));
        this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
        this.filterCategory = this.filterCategory.sort(this.utilService.sortArray);
      }
    },
      error => {
        console.log("error", error.message);
      });
  }



  getPrivateFilterCategoryDetails() {
    this.filterPrivateCategory = [];
    const queryParam = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res12 => {
          // console.log("res12", res12);
          this.filterPrivateCategory = res12.lists;
          if (res12.lists) {
            for (let index = 0; index < res12.lists.length; index++) {
              const element = res12.lists[index];
              this.filterCategory.push(element);
            }
          }
          // this.filterPrivateCategory = res12.lists;
          // this.filterPrivateCategory = this.filterPrivateCategory.sort(this.utilService.sortArray)
        },
          error => {
            console.log("error", error.message);
          });
      }
    },
      error => {
        console.log("error", error.message);
      });

  }

  getFilterCriteriaListDetails() {
    this.displayCriteriaList = [];
    this.criteriaList = [];
    const queryParam = { "input": `CHAMP ETENDU@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `CHAMP ETENDU@${this.tableValues.ipn}@${this.tableValues.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaListOringinal = res1.lists;
          // console.log('criteriaListOringinal', this.criteriaListOringinal);
          for (const item of this.criteriaListOringinal) {
            if (item.GCOName === "I" || item.GCOName === "A_Chassis" || item.GCOName === "A_Ident") {
            } else {
              if (item.longLabel.split(this.splitChar, 2).length > 1) {
                this.displayCriteriaList.push({
                  "GCOName": item.GCOName,
                  "longFR": item.longLabel.split(this.splitChar, 2)[0], "shortFR": item.shortLabel.split(this.splitChar, 2)[0],
                  "longEN": item.longLabel.split(this.splitChar, 2)[1], "shortEN": item.shortLabel.split(this.splitChar, 2)[1] ? item.shortLabel.split(this.splitChar, 2)[1] : item.shortLabel.split(this.splitChar, 2)[0],
                  "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                });
                this.criteriaList.push({
                  "GCOName": item.GCOName,
                  "longFR": item.longLabel.split(this.splitChar, 2)[0], "shortFR": item.shortLabel.split(this.splitChar, 2)[0],
                  "longEN": item.longLabel.split(this.splitChar, 2)[1], "shortEN": item.shortLabel.split(this.splitChar, 2)[1] ? item.shortLabel.split(this.splitChar, 2)[1] : item.shortLabel.split(this.splitChar, 2)[0],
                  "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                });
              } else {

                this.displayCriteriaList.push({
                  "GCOName": item.GCOName,
                  "longFR": item.longLabel, "shortFR": item.shortLabel,
                  "longEN": item.longLabel, "shortEN": item.shortLabel,
                  "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                });
                this.criteriaList.push({
                  "GCOName": item.GCOName,
                  "longFR": item.longLabel, "shortFR": item.shortLabel,
                  "longEN": item.longLabel, "shortEN": item.shortLabel,
                  "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                });

              }
            }
          }
          // this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);
          // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.GCOName < b.GCOName) ? -1 : 1);
          this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListLongFR);
        },
          error => {
            console.log("error", error.message);
          });
      }
    },
      error => {
        console.log("error", error.message);
      });
  }

  onCategoryChange(val, type) {
    if (val === '') {
      this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    } else {
      this.selectedCategory = val;
    }
    const privateCategory = this.filterPrivateCategory.filter(xy => xy === val);
    if (privateCategory.length > 0) {
      this.getSearchCategory(this.tableValues.ipn);
    } else {
      this.getSearchCategory('system');
    }
  }

  getLangIndex(lang) {
    if (lang === 'en') {
      return 1;
    } else {
      return 0;
    }
  }

  showHiddenCriteriaList() {
    if (this.tableValues.region === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_obj_syst') > -1 && this.getFavoriteRegion() === 'PIL') {
        this.isShowHiddenList = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_obj_syst') > -1 && this.getFavoriteRegion() === 'PIM') {
        this.isShowHiddenList = true;
      }
    }
  }

  getSelectedList(selectedItem) {
    if (selectedItem === this.utilService.getTranslateName(`COMMON.ALL`)) {
      return true;
    } else {
      return false;
    }
  }

  getSearchCategory(ipnValue) {
    if (this.sLibelle || this.sLibelle_2 || this.sLibCourt || this.sLibCourt_2) {
      this.isModifyCate = true;
      this.submit();
    } else {
      this.categoryList = [];
      this.displayCriteriaList = [];
      this.criteriaList = [];
      if (this.selectedCategory === this.utilService.getTranslateName(`COMMON.ALL`)) {
        for (const item of this.criteriaListOringinal) {
          // if (typeof (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]) !== 'undefined') {
          //   this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)])
          //   this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)])
          // }
          if (item.GCOName === "I" || item.GCOName === "A_Chassis" || item.GCOName === "A_Ident") {
          } else {
            if (item.longLabel.split(this.splitChar, 2).length > 1) {
              this.displayCriteriaList.push({
                "GCOName": item.GCOName,
                "longFR": item.longLabel.split(this.splitChar, 2)[0], "shortFR": item.shortLabel.split(this.splitChar, 2)[0],
                "longEN": item.longLabel.split(this.splitChar, 2)[1], "shortEN": item.shortLabel.split(this.splitChar, 2)[1] ? item.shortLabel.split(this.splitChar, 2)[1] : item.shortLabel.split(this.splitChar, 2)[0],
                "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
              });
              this.criteriaList.push({
                "GCOName": item.GCOName,
                "longFR": item.longLabel.split(this.splitChar, 2)[0], "shortFR": item.shortLabel.split(this.splitChar, 2)[0],
                "longEN": item.longLabel.split(this.splitChar, 2)[1], "shortEN": item.shortLabel.split(this.splitChar, 2)[1] ? item.shortLabel.split(this.splitChar, 2)[1] : item.shortLabel.split(this.splitChar, 2)[0],
                "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
              });
            } else {

              this.displayCriteriaList.push({
                "GCOName": item.GCOName,
                "longFR": item.longLabel, "shortFR": item.shortLabel,
                "longEN": item.longLabel, "shortEN": item.shortLabel,
                "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
              });
              this.criteriaList.push({
                "GCOName": item.GCOName,
                "longFR": item.longLabel, "shortFR": item.shortLabel,
                "longEN": item.longLabel, "shortEN": item.shortLabel,
                "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
              });

            }
          }

        }
        // this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);
        this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.GCOName < b.GCOName) ? -1 : 1);
      } else {
        const queryParam = { "input": `FILTRE@${ipnValue}@${this.tableValues.selectedTable}@${this.selectedCategory}@`, "region": this.getFavoriteRegion() };
        this.apiService.getObjectGet(queryParam).subscribe(res => {
          // console.log("res.data", res.data)
          const dataRes = res.data.split('@');
          for (let i = 0; i < dataRes.length; i = i + 4) {
            if (i > 0) {
              this.categoryList.push(dataRes[i - 1]);
            }
          }

          for (const iterator of this.categoryList) {
            const filterVal = this.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === iterator.substring(0, iterator.length - 2).toUpperCase() && a.typeOfField == iterator.slice(-1));
            for (const item of filterVal) {
              // if (typeof (iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]) !== 'undefined') {
              //   this.displayCriteriaList.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              //   this.criteriaList.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              // }
              if (item.GCOName === "I" || item.GCOName === "A_Chassis" || item.GCOName === "A_Ident") {
              } else {
                if (item.longLabel.split(this.splitChar, 2).length > 1) {
                  this.displayCriteriaList.push({
                    "GCOName": item.GCOName,
                    "longFR": item.longLabel.split(this.splitChar, 2)[0], "shortFR": item.shortLabel.split(this.splitChar, 2)[0],
                    "longEN": item.longLabel.split(this.splitChar, 2)[1], "shortEN": item.shortLabel.split(this.splitChar, 2)[1] ? item.shortLabel.split(this.splitChar, 2)[1] : item.shortLabel.split(this.splitChar, 2)[0],
                    "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                  });
                  this.criteriaList.push({
                    "GCOName": item.GCOName,
                    "longFR": item.longLabel.split(this.splitChar, 2)[0], "shortFR": item.shortLabel.split(this.splitChar, 2)[0],
                    "longEN": item.longLabel.split(this.splitChar, 2)[1], "shortEN": item.shortLabel.split(this.splitChar, 2)[1] ? item.shortLabel.split(this.splitChar, 2)[1] : item.shortLabel.split(this.splitChar, 2)[0],
                    "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                  });
                } else {

                  this.displayCriteriaList.push({
                    "GCOName": item.GCOName,
                    "longFR": item.longLabel, "shortFR": item.shortLabel,
                    "longEN": item.longLabel, "shortEN": item.shortLabel,
                    "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                  });
                  this.criteriaList.push({
                    "GCOName": item.GCOName,
                    "longFR": item.longLabel, "shortFR": item.shortLabel,
                    "longEN": item.longLabel, "shortEN": item.shortLabel,
                    "typeOfField": item.typeOfField, "size": item.size, "editStatus": false
                  });

                }
              }
            }
          }
          // this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);
          this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.GCOName < b.GCOName) ? -1 : 1);
        },
          error => {
            console.log("error", error.message);
          });
      }
    }
  }


  clickClose() {
    const pathName = window.location.pathname;
    this.utilService.removeOpenedWindowName(pathName);
    this.utilService.$showIcon$.next(true);
    const openedWindowNameList = this.utilService.getOpenedWindowNameList()
    if (openedWindowNameList.length > 0) {
      this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
    } else {
      this.router.navigate(['home']);
    }
  }

  CheckLock() {
    this.strLock = "";
    const queryParam = {
      "input": "LIBELLE@system@libelle@lock@", "region": this.getFavoriteRegion()
    }
    this.apiService.getObjectGet(queryParam).subscribe(res => {
      if (res.data) {
        // console.log("substring", res, `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} ${res.data.substring(6, 8)}-
        // ${res.data.substring(4, 6)}-${res.data.substring(0, 4)} ${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} ${res.data.substring(8, 10)}:
        // ${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`);
        this.strLock = `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} `;
        this.strLock = this.strLock + `${res.data.substring(6, 8)}-${res.data.substring(4, 6)}-${res.data.substring(0, 4)} `;
        this.strLock = this.strLock + `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} `;
        this.strLock = this.strLock + `${res.data.substring(8, 10)}:${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`;
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  rowEdited(item, i) {
    this.rowIndex = -1;
    const indexVal = this.criteriaList.findIndex(x => x.GCOName === item.GCOName);
    // console.log("filterVal", indexVal);
    if (item.longFR === "" || item.longEN === "" || item.shortFR === "" || item.shortEN === "") {
      this.alertPopup(this.utilService.getTranslateName(`LABEL_MANAGE.ONCELLEMPTY`));
    }
    if (indexVal !== -1) {
      if (this.criteriaList[indexVal].longFR !== item.longFR || this.criteriaList[indexVal].longEN !== item.longEN ||
        this.criteriaList[indexVal].shortFR !== item.shortFR || this.criteriaList[indexVal].shortEN !== item.shortEN) {
        const indexDisplayVal = this.displayCriteriaList.findIndex(x => x.GCOName === item.GCOName);
        this.displayCriteriaList[indexDisplayVal].editStatus = true;
        if (!this.sLibelle.includes(`${this.criteriaList[indexVal].GCOName}@${item.longFR}\n`)) {
          /************ SONAR : Remove this conditional structure they're  same. **************/ 
          // if (typeof (item.longFR) === "undefined") {
          //   this.sLibelle = this.sLibelle + `${this.criteriaList[indexVal].GCOName}@${item.longFR}\n`;
          // } else {
          //   this.sLibelle = this.sLibelle + `${this.criteriaList[indexVal].GCOName}@${item.longFR}\n`;
          // }
          this.sLibelle = this.sLibelle + `${this.criteriaList[indexVal].GCOName}@${item.longFR}\n`;
        }
        if (!this.sLibelle_2.includes(`${this.criteriaList[indexVal].GCOName}@${item.longEN}\n`)) {
          if (typeof (item.longEN) === "undefined") {
            this.sLibelle_2 = this.sLibelle_2 + `${this.criteriaList[indexVal].GCOName}@${item.longFR}\n`;
          } else {
            this.sLibelle_2 = this.sLibelle_2 + `${this.criteriaList[indexVal].GCOName}@${item.longEN}\n`;
          }
        }
        if (!this.sLibCourt.includes(`${this.criteriaList[indexVal].GCOName}@${item.shortFR}\n`)) {
          /************ SONAR : Remove this conditional structure they're  same. **************/ 
          // if (typeof (item.shortFR) === "undefined") {
          //   this.sLibCourt = this.sLibCourt + `${this.criteriaList[indexVal].GCOName}@${item.shortFR}\n`;
          // } else {
          //   this.sLibCourt = this.sLibCourt + `${this.criteriaList[indexVal].GCOName}@${item.shortFR}\n`;
          // }
          this.sLibCourt = this.sLibCourt + `${this.criteriaList[indexVal].GCOName}@${item.shortFR}\n`;
        }
        if (!this.sLibCourt_2.includes(`${this.criteriaList[indexVal].GCOName}@${item.shortEN}\n`)) {
          if (typeof (item.shortEN) === "undefined") {
            this.sLibCourt_2 = this.sLibCourt_2 + `${this.criteriaList[indexVal].GCOName}@${item.shortFR}\n`;
          } else {
            this.sLibCourt_2 = this.sLibCourt_2 + `${this.criteriaList[indexVal].GCOName}@${item.shortEN}\n`;
          }
        }
        // // if (this.criteriaList[indexVal].longFR !== item.longFR) {
        // //   // this.sLibelle = this.sLibelle + `${this.criteriaList[indexVal].longFR}@${item.longFR}\n`;
        // //   if (!this.sLibelle.includes(`${this.criteriaList[indexVal].GCOName}@${item.longFR}\n`)) {
        // //     this.sLibelle = this.sLibelle + `${this.criteriaList[indexVal].GCOName}@${item.longFR}\n`;
        // //   }
        // // }
        // // if (this.criteriaList[indexVal].longEN !== item.longEN) {
        // //   // this.sLibelle_2 = this.sLibelle_2 + `${this.criteriaList[indexVal].longEN}@${item.longEN}\n`;
        // //   if (!this.sLibelle_2.includes(`${this.criteriaList[indexVal].GCOName}@${item.longEN}\n`)) {
        // //     this.sLibelle_2 = this.sLibelle_2 + `${this.criteriaList[indexVal].GCOName}@${item.longEN}\n`;
        // //   }
        // // }
        // // if (this.criteriaList[indexVal].shortFR !== item.shortFR) {
        // //   // this.sLibCourt = this.sLibCourt + `${this.criteriaList[indexVal].shortFR}@${item.shortFR}\n`;
        // //   if (!this.sLibCourt.includes(`${this.criteriaList[indexVal].GCOName}@${item.shortFR}\n`)) {
        // //     this.sLibCourt = this.sLibCourt + `${this.criteriaList[indexVal].GCOName}@${item.shortFR}\n`;
        // //   }
        // // }
        // // if (this.criteriaList[indexVal].shortEN !== item.shortEN) {
        // //   // this.sLibCourt_2 = this.sLibCourt_2 + `${this.criteriaList[indexVal].shortEN}@${item.shortEN}\n`;
        // //   if (!this.sLibCourt_2.includes(`${this.criteriaList[indexVal].GCOName}@${item.shortEN}\n`)) {
        // //     this.sLibCourt_2 = this.sLibCourt_2 + `${this.criteriaList[indexVal].GCOName}@${item.shortEN}\n`;
        // //   }
        // // }
      }
    }
  }

  alertPopup(msg) {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': msg, isAlert: 'true' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.isEmpty = true;
      }
    })
  }

  checkEmptyField() {
    const editedRow = this.displayCriteriaList.filter(x => x.editStatus === true);
    // console.log("editedRow", editedRow)
    for (let index = 0; index < editedRow.length; index++) {
      const element = editedRow[index];
      if (element.longFR === "" || element.longEN === "" || element.shortFR === "" || element.shortEN === "") {
        return true;
      }
    }
  }

  submit() {
    this.isEmpty = false;
    if (this.checkEmptyField()) {
      this.alertPopup(this.utilService.getTranslateName(`LABEL_MANAGE.ONUPDATEEMPTY`))
      if (this.isEmpty) {
        return;
      }
    } else {
      this.sLibelle = '';
      this.sLibelle_2 = '';
      this.sLibCourt = '';
      this.sLibCourt_2 = '';
      const changedVal = this.displayCriteriaList.filter(cc => cc.editStatus === true);

      // console.log("changedVal", changedVal);
      for (let index = 0; index < changedVal.length; index++) {
        const element = changedVal[index];
        this.sLibelle = this.sLibelle + `${changedVal[index].GCOName}@${changedVal[index].longFR}§`;
        this.sLibelle_2 = this.sLibelle_2 + `${changedVal[index].GCOName}@${changedVal[index].longEN}§`;
        this.sLibCourt = this.sLibCourt + `${changedVal[index].GCOName}@${changedVal[index].shortFR}§`;
        if (typeof (changedVal[index].shortEN) === "undefined") {
          this.sLibCourt_2 = this.sLibCourt_2 + `${changedVal[index].GCOName}@${changedVal[index].shortFR}§`;
        } else {
          this.sLibCourt_2 = this.sLibCourt_2 + `${changedVal[index].GCOName}@${changedVal[index].shortEN}§`;
        }
        // this.sLibelle.push(`${this.displayCriteriaList[index].GCOName}@${this.displayCriteriaList[index].longFR}`);
        // this.sLibelle_2.push(`${this.displayCriteriaList[index].GCOName}@${this.displayCriteriaList[index].longEN}`);
        // this.sLibCourt.push(`${this.displayCriteriaList[index].GCOName}@${this.displayCriteriaList[index].shortFR}`);
        // this.sLibCourt_2.push(`${this.displayCriteriaList[index].GCOName}@${this.displayCriteriaList[index].shortEN}`);
      }
      // const queryParam = {
      //   "input": "LIBELLE@system@libelle@lock@", "region": this.getFavoriteRegion()
      // }
      // this.apiService.getObjectGet(queryParam).subscribe(res => {
      //   if (res.data) {
      //     console.log("substring", res, `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} ${res.data.substring(6, 8)}-
      //   ${res.data.substring(4, 6)}-${res.data.substring(0, 4)} ${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} ${res.data.substring(8, 10)}:
      //   ${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`);
      //     this.strLock = `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} `;
      //     this.strLock = this.strLock + `${res.data.substring(6, 8)}-${res.data.substring(4, 6)}-${res.data.substring(0, 4)} `;
      //     this.strLock = this.strLock + `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} `;
      //     this.strLock = this.strLock + `${res.data.substring(8, 10)}:${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`;
      //     this.alertPopup(this.utilService.getTranslateName(`LABEL_MANAGE.ALERT_PROCESSING`));
      //   } else {
      this.openConfirmDialog();
      //   }
      // },
      //   error => {
      //     this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
      //     console.log("error", error.message);
      //   });

      // // if (this.CheckLock) {
      // //   this.openConfirmDialog();
      // // } else {
      // //   this.alertPopup(this.utilService.getTranslateName(`LABEL_MANAGE.ALERT_PROCESSING`));
      // // }
    }
  }

  omit_special_char(event) {
    var k;
    // console.log("event", event.charCode, event)
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 95 || (k >= 48 && k <= 57));
  }

  openConfirmDialog() {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`LABEL_MANAGE.UPDATECONFIRM`), 'ync': true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        const queryParam = {
          "input": "LIBELLE@system@libelle@lock@", "region": this.getFavoriteRegion()
        }
        this.apiService.getObjectGet(queryParam).subscribe(res => {
          if (res.data) {
            //     console.log("substring", res, `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} ${res.data.substring(6, 8)}-
            // ${res.data.substring(4, 6)}-${res.data.substring(0, 4)} ${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} ${res.data.substring(8, 10)}:
            // ${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`);
            this.strLock = `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_1")} `;
            this.strLock = this.strLock + `${res.data.substring(6, 8)}-${res.data.substring(4, 6)}-${res.data.substring(0, 4)} `;
            this.strLock = this.strLock + `${this.utilService.getTranslateName("LABEL_MANAGE.REFRESH_LABEL_2")} `;
            this.strLock = this.strLock + `${res.data.substring(8, 10)}:${res.data.substring(10, 12)}:${res.data.substring(12, 14)}`;
            this.alertPopup(this.utilService.getTranslateName(`LABEL_MANAGE.ALERT_PROCESSING`));
          } else {
            let queryParam = "LIBELLE@system";
            // // if (this.sLibelle !== "") {
            // //   this.saveWordObject({ "input": queryParam + `@libelle@${this.tableValues.selectedTable}@${this.sLibelle}`, "region": this.getFavoriteRegion() }, 1);
            // // }
            // // if (this.sLibelle_2 !== "") {
            // //   this.saveWordObject({ "input": queryParam + `@libelle_2@${this.tableValues.selectedTable}@${this.sLibelle_2}`, "region": this.getFavoriteRegion() }, 2);
            // // }
            // // if (this.sLibCourt !== "") {
            // //   this.saveWordObject({ "input": queryParam + `@libcourt@${this.tableValues.selectedTable}@${this.sLibCourt}`, "region": this.getFavoriteRegion() }, 3);
            // // }
            // // if (this.sLibCourt_2 !== "") {
            // //   this.saveWordObject({ "input": queryParam + `@libcourt_2@${this.tableValues.selectedTable}@${this.sLibCourt_2}`, "region": this.getFavoriteRegion() }, 4);
            // // }
            this.sLibelle = this.sLibelle.substring(0, this.sLibelle.length - 1);
            this.sLibelle_2 = this.sLibelle_2.substring(0, this.sLibelle_2.length - 1);
            this.sLibCourt = this.sLibCourt.substring(0, this.sLibCourt.length - 1);
            this.sLibCourt_2 = this.sLibCourt_2.substring(0, this.sLibCourt_2.length - 1);


            this.apiService.saveObject({ "input": `LIBELLE@system@libelle@${this.tableValues.selectedTable}@${this.sLibelle}`, "region": this.getFavoriteRegion() }).subscribe(resp => {
              // console.log("respresp", resp);
              if (resp.description = "SUCCESS") {
                this.apiService.saveObject({ "input": `LIBELLE@system@libelle_2@${this.tableValues.selectedTable}@${this.sLibelle_2}`, "region": this.getFavoriteRegion() }).subscribe(resp1 => {
                  // console.log("respresp1", resp1);
                  if (resp1.description = "SUCCESS") {
                    this.apiService.saveObject({ "input": `LIBELLE@system@libcourt@${this.tableValues.selectedTable}@${this.sLibCourt}`, "region": this.getFavoriteRegion() }).subscribe(resp2 => {
                      // console.log("respresp2", resp2);
                      if (resp2.description = "SUCCESS") {
                        this.apiService.saveObject({ "input": `LIBELLE@system@libcourt_2@${this.tableValues.selectedTable}@${this.sLibCourt_2}`, "region": this.getFavoriteRegion() }).subscribe(resp3 => {
                          // console.log("respresp2", resp3);
                          if (resp3.description = "SUCCESS") {
                            this.sLibelle = "";
                            this.sLibelle_2 = "";
                            this.sLibCourt = "";
                            this.sLibCourt_2 = "";
                            this.majLebBase();
                          }
                          this.matomoTracker.trackEvent("Label manangement", `Update label`, this.profile['uid']);
                        },
                          error => {
                            console.log("error", error.message);
                          });
                      }
                    },
                      error => {
                        console.log("error", error.message);
                      });
                  }
                },
                  error => {
                    console.log("error", error.message);
                  });
              }
            },
              error => {
                console.log("error", error.message);
              });

            // for (let index = 0; index < this.sLibelle.length; index++) {
            //   const element = this.sLibelle[index];
            //   this.apiService.saveObject({ "input": `LIBELLE@system@libelle@${this.tableValues.selectedTable}@${this.sLibelle[index]}`, "region": this.getFavoriteRegion() }).subscribe(resp => {
            //     console.log("respresp", resp);
            //     if (resp.description = "SUCCESS") {
            //       this.apiService.saveObject({ "input": `LIBELLE@system@libelle_2@${this.tableValues.selectedTable}@${this.sLibelle_2[index]}`, "region": this.getFavoriteRegion() }).subscribe(resp1 => {
            //         console.log("respresp1", resp1);
            //         if (resp.description = "SUCCESS") {
            //           this.apiService.saveObject({ "input": `LIBELLE@system@libcourt@${this.tableValues.selectedTable}@${this.sLibCourt[index]}`, "region": this.getFavoriteRegion() }).subscribe(resp2 => {
            //             console.log("respresp2", resp2);
            //             if (resp.description = "SUCCESS") {
            //               this.apiService.saveObject({ "input": `LIBELLE@system@libcourt_2@${this.tableValues.selectedTable}@${this.sLibCourt_2[index]}`, "region": this.getFavoriteRegion() }).subscribe(resp3 => {
            //                 console.log("respresp3", resp3);
            //                 if (resp.description = "SUCCESS") {
            //                   if (index === this.sLibelle.length - 1) {
            //                     this.sLibelle = "";
            //                     this.sLibelle_2 = "";
            //                     this.sLibCourt = "";
            //                     this.sLibCourt_2 = "";
            //                     this.majLebBase();
            //                   }
            //                 }
            //               },
            //                 error => {
            //                   console.log("error", error.message);
            //                 });
            //             }
            //           },
            //             error => {
            //               console.log("error", error.message);
            //             });
            //         }
            //       },
            //         error => {
            //           console.log("error", error.message);
            //         });
            //     }
            //   },
            //     error => {
            //       console.log("error", error.message);
            //     });

            // }
          }
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
            console.log("error", error.message);
          });
      } else if (result === false) {
        for (let index = 0; index < this.displayCriteriaList.length; index++) {
          const element = this.criteriaList[index];
          this.displayCriteriaList[index].longFR = element.longFR;
          this.displayCriteriaList[index].shortFR = element.shortFR;
          this.displayCriteriaList[index].longEN = element.longEN;
          this.displayCriteriaList[index].shortEN = element.shortEN;
          this.displayCriteriaList[index].editStatus = element.editStatus;
        }
        this.resetValue();
        // this.getFilterCriteriaListDetails();
      }
      if (this.isModified) {
        this.isModified = false;
        this.resetValue();
        this.tableSelection(this.tableSelectionItem);
      }
      if (this.isModifyCate) {
        this.isModifyCate = false;
        this.resetValue();
        this.getSearchCategory("system");
      }
    });
  }

  saveWordObject(param, index) {
    this.apiService.saveObject(param).subscribe(resp => {
      if (index === 1) {
        this.sLibelle = "";
      }
      if (index === 2) {
        this.sLibelle_2 = "";
      }
      if (index === 3) {
        this.sLibCourt = "";
      }
      if (index === 4) {
        this.sLibCourt_2 = "";
      }
      // console.log("respresp", resp);
      if (resp.description = "SUCCESS") {
        if (this.sLibelle === "" && this.sLibelle_2 === "" && this.sLibCourt === "" && this.sLibCourt_2 === "") {
          this.majLebBase();
        }
      }
    },
      error => {
        console.log("error", error.message);
      });
  }

  majLebBase() {
    const param = { "input": `${this.tableValues.ipn}@LIB@MAJLIB@@@`, "region": this.getFavoriteRegion() };
    this.apiService.specialBases(param).subscribe(resp => {
      // console.log("specialBases", resp);

      this.CheckLock();
      // this.getFilterCriteriaListDetails();

      for (let index = 0; index < this.displayCriteriaList.length; index++) {
        const element = this.displayCriteriaList[index];
        this.criteriaList[index].longFR = element.longFR;
        this.criteriaList[index].shortFR = element.shortFR;
        this.criteriaList[index].longEN = element.longEN;
        this.criteriaList[index].shortEN = element.shortEN;
        this.displayCriteriaList[index].editStatus = false;
      }
    },
      error => {
        console.log("error", error.message);
      });
  }

  resetValue() {
    this.sLibelle = "";
    this.sLibelle_2 = "";
    this.sLibCourt = "";
    this.sLibCourt_2 = "";
    this.isEmpty = false;
    this.rowIndex = -1;
  }

  sortingTable(val) {
    this.gcoNameSort = !val;
    this.lFranceSort = false;
    this.lEnglishSort = false;
    this.sFranceSort = false;
    this.sEnglishSort = false;
    if (val) {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListGCOName);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.GCOName > b.GCOName) ? -1 : 1);
    } else {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListGCONameDesc);
    }
  }

  sortinglongFrance(val) {
    this.gcoNameSort = false;
    this.lFranceSort = !val;
    this.lEnglishSort = false;
    this.sFranceSort = false;
    this.sEnglishSort = false;
    if (val) {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListLongFR);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.longFR > b.longFR) ? -1 : 1);
    } else {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListLongFRDesc);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.longFR < b.longFR) ? -1 : 1);
    }
  }

  sortingShortFrance(val) {
    this.gcoNameSort = false;
    this.lFranceSort = false;
    this.lEnglishSort = false;
    this.sFranceSort = !val;
    this.sEnglishSort = false;
    if (val) {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListShortFR);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.shortFR > b.shortFR) ? -1 : 1);
    } else {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListShortFRDesc);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.shortFR < b.shortFR) ? -1 : 1);
    }
  }

  sortinglongEnglish(val) {
    this.gcoNameSort = false;
    this.lFranceSort = false;
    this.lEnglishSort = !val;
    this.sFranceSort = false;
    this.sEnglishSort = false;
    if (val) {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListLongEN);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.longEN > b.longEN) ? -1 : 1);
    } else {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListLongENDesc);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.longEN < b.longEN) ? -1 : 1);
    }
  }

  sortingShortEnglish(val) {
    this.gcoNameSort = false;
    this.lFranceSort = false;
    this.lEnglishSort = false;
    this.sFranceSort = false;
    this.sEnglishSort = !val;
    if (val) {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListShortEN);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.shortEN > b.shortEN) ? -1 : 1);
    } else {
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArrayCriteriaListShortENDesc);
      // this.displayCriteriaList = this.displayCriteriaList.sort((a, b) => (a.shortEN < b.shortEN) ? -1 : 1);
    }
  }

  openHelpFile() {
    window.open('https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Aide%20en%20ligne/maj_libelle.aspx', '_blank');
  }
}
