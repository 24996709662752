export const environment = {
  production: true,
  idp: {
    scope: 'openid arcaX role-pil-irn6804',
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    redirectUri: window.location.origin.replace('https://', 'http://'),
    prod: {
      openIdEndPoint: 'https://idp.renault.com/nidp/oauth/nam/.well-known/openid-configuration',
      loginUrl: 'https://idp.renault.com/nidp/oauth/nam/authz',
      logoutUrl: 'https://idp.renault.com/nidp/oauth/nam/authz',
      userinfoEndpoint: 'https://idp.renault.com/nidp/oauth/nam/userinfo',
      issuer: 'https://idp.renault.com/nidp/oauth/nam/authz',
      responseType: 'id_token token',
      postLoginRoute: '',
      forbiddenRoute: '',
      unauthorizedRoute: '',
      dev: {
        loginUrl: 'https://idp2.renault.com/nidp/oauth/nam/authz',
        logoutUrl: 'https://idp2.renault.com/nidp/app/logout',
        userinfoEndpoint: 'https://idp2.renault.com/nidp/oauth/nam/userinfo',
        issuer: 'https://idp2.renault.com/nidp/oauth/nam',
        client_id: 'ed066280-a724-4b16-aeba-b97f98d4f51e',
        redirect_uri: 'https://int.pil.dev.aws.renault.com/',
        baseUrl: 'https://int.pil.dev.aws.renault.com/',
        apiURL: 'https://apis-dev.renault.com/pil/v1/',
        apikey: 'XM789pzBwYkchgTWJwdk3In0veoqFUct',
        // apiURL: '/pil/',
        piwikID: 2342
      },
      re7: {
        loginUrl: 'https://idp2.renault.com/nidp/oauth/nam/authz',
        logoutUrl: 'https://idp2.renault.com/nidp/app/logout',
        userinfoEndpoint: 'https://idp2.renault.com/nidp/oauth/nam/userinfo',
        issuer: 'https://idp2.renault.com/nidp/oauth/nam',
        client_id: '3cafd93b-e110-4788-bc68-330f17a096ab',
        redirect_uri: 'https://re7.pil.re7.aws.renault.com/',
        baseUrl: 'https://re7.pil.re7.aws.renault.com/',
        // apiURL: '/pil/',
        apiURL: 'https://apis-qa.renault.com/pil/v1/',
        apikey: 'XM789pzBwYkchgTWJwdk3In0veoqFUct',
        piwikID: 2341
      },
      ope: {
        loginUrl: 'https://idp.renault.com/nidp/oauth/nam/authz',
        logoutUrl: 'https://idp.renault.com/nidp/app/logout',
        userinfoEndpoint: 'https://idp.renault.com/nidp/oauth/nam/userinfo',
        issuer: 'https://idp.renault.com/nidp/oauth/nam',
        client_id: 'b5572c06-4e2a-408c-8cab-a6c97ea96a2e',
        redirect_uri: 'https://pil.app.renault.com/',
        baseUrl: 'https://pil.app.renault.com/',
        apiURL: 'https://apis.renault.com/pil/v1/',
        apikey: 'eicXN1S2aTscGJrRfExQM5oA3M24IGMB',
        // apiURL: '/pil/',
        piwikID: 2343
      }
    }
  },
  timeOutwhenNotInteractive: 60,
  maxIdAllowed: 60,
};

