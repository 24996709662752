import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from '../services/request/request.service';
import { UtilService } from '../services/util/util.service'
import { MatDialog } from '@angular/material/dialog';
import { BackendApiService } from '../services/backend-api/backend-api.service';
import { AlertMessageComponent } from '../component/query/alert-message/alert-message.component';
import moment from 'moment';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
  supportedLangs = [
    { display: 'FRENCH', value: 'fr' },
    { display: 'ENGLISH', value: 'en' }
  ];
  selectedLang = "fr";
  profile = this.utilService.getUserProfile();
  favoriteConnecton: string;
  isTrace = false;
  isAdmin = false;

  constructor(readonly utilService: UtilService,
    private translateService: TranslateService, readonly apiService: BackendApiService,
    readonly requestService: RequestService, public dialog: MatDialog,
    readonly router: Router, readonly activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.utilService.$favoriteConnecton$.subscribe(urlLink => {
      this.favoriteConnecton = urlLink;
    });
    this.selectedLang = this.requestService.getSelectedLang();
    if (this.requestService.getAllRequestData().length > 0) {
      this.utilService.$showQuickLink$.next(true);
    }
    if (sessionStorage.getItem('trace') === '') {
      this.isTrace = true;
    }

    if (this.profile['role-pil-irn6804'] || this.profile['role-pim-irn9207']) {
      if (this.profile['role-pil-irn6804'].indexOf('pil_admin') > -1) {
        this.isAdmin = true;
      } else if (this.profile['role-pim-irn9207'].indexOf('pim_admin') > -1) {
        this.isAdmin = true;
      }
    }
  }

  createTrace() {
    sessionStorage.setItem('trace', "");
    sessionStorage.setItem('traceCount', "2");
    this.isTrace = true;
  }

  downloadTrace() {
    // // Get the data from each element on the form.
    // const name = document.getElementById('txtName');
    // const age = document.getElementById('txtAge');
    // const email = document.getElementById('txtEmail');
    // const country = document.getElementById('selCountry');
    // const msg = document.getElementById('msg');

    // This variable stores all the data.
    const traceCount = sessionStorage.getItem('traceCount');
    for (let index = Number(traceCount); index >= 0; index--) {
      this.utilService.setTrace(`#H ${moment.utc().format("HH:mm:ss")}`);
      this.utilService.setTrace(`>A< svc_delhdle`);
      this.utilService.setTrace(`>I< un10000${index}@`);
      this.utilService.setTrace(`>O< `);
      if (index === 0) {
        let data = sessionStorage.getItem('trace');
        // '\r Name: ' + name.value + ' \r\n ' +
        // 'Age: ' + age.value + ' \r\n ' +
        // 'Email: ' + email.value + ' \r\n ' +
        // 'Country: ' + country.value + ' \r\n ' +
        // 'Message: ' + msg.value;

        // Convert the text to BLOB.
        const textToBLOB = new Blob([data], { type: 'text/plain' });
        const sFileName = 'trace.txt';	   // The file to save the data.

        let newLink = document.createElement("a");
        newLink.download = sFileName;

        // if (window.webkitURL != null) {
        //   newLink.href = window.webkitURL.createObjectURL(textToBLOB);
        // }
        // else {
        newLink.href = window.URL.createObjectURL(textToBLOB);
        newLink.style.display = "none";
        document.body.appendChild(newLink);
        // }

        newLink.click();
        sessionStorage.removeItem('trace');
        sessionStorage.removeItem('traceCount');
        this.isTrace = false;

      }
    }
  }

  selectLang(lang: string) {
    // set current lang
    this.selectedLang = lang;
    this.requestService.addLang(this.selectedLang)
    location.reload();
  }

  ngOnDestroy() {
    this.utilService.$favoriteConnecton$.unsubscribe();
  }

  groupProfile() {
    let rtnValue;
    rtnValue = {
      'europe': this.profile['role-gr-pil-irn6804'] ? this.profile['role-gr-pil-irn6804'].replace('gr_pil_', '') : '',
      'mercosur': this.profile['role-gr-pim-irn9207'] ? this.profile['role-gr-pim-irn9207'].replace('gr_pim_', '') : ''
    }
    return rtnValue;
  }

  redirectUrl(page) {
    if (page === 'help') {
      window.open('https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Aide%20en%20ligne/Pilotage.aspx', '_blank');
    } else if (page === 'site') {
      window.open('https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Site%20Intranet/pilotage_menu.aspx', '_blank')
    } else if (page === 'msg') {
      if (this.favoriteConnecton.toLowerCase() === 'europe') {
        window.open('https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Site%20Intranet/messages.aspx', '_blank')
      } else if (this.favoriteConnecton.toLowerCase() === 'mercosur') {
        window.open('https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Site%20Intranet/messages_merco.aspx', '_blank')
      }
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(AlertMessageComponent, {
      disableClose: true,
      width: '380px',
      data: { type: 'pilotage' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  setOpenedWindow(windowPathName) {
    this.utilService.addOpenedWindowName(windowPathName);
  }
}
