import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  apiBaseURL: string;
  idpVersion;
  apiLastLoginURL: string;
  uploadURL: string;
  constructor(readonly http: HttpClient, readonly oauthService: OAuthService) {
    this.apiLastLoginURL = window.location.origin + "/pil-connection/";
    if ((window.location.hostname.indexOf('pil.app.renault.com') !== -1) ||
      (window.location.hostname.indexOf('ope.pil.ope.aws.renault.com') !== -1)) {
      // OPE
      this.idpVersion = 'ope';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      // this.apiLastLoginURL = "https://int.pil.dev.aws.renault.com/pil-connection/";
    } else if (window.location.hostname.indexOf('re7.pil.re7.aws.renault.com') !== -1) {
      // RE7
      this.idpVersion = 're7';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      // this.apiLastLoginURL = "https://re7.pil.re7.aws.renault.com/pil-connection/";
      this.uploadURL = "https://re7.pil.re7.aws.renault.com/pil-api-s3/uploadfile";
    } else if (window.location.hostname.indexOf('int.pil.dev.aws.renault.com') !== -1) {
      // DEV
      this.idpVersion = 'dev';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      this.uploadURL = "https://int.pil.dev.aws.renault.com/pil-api-s3/uploadfile";
      this.apiLastLoginURL = "https://int.pil.dev.aws.renault.com/pil-connection/";
    } else {
      // LOCALHOST
      this.idpVersion = 're7';
      this.apiBaseURL = environment.idp.prod[this.idpVersion].apiURL;
      this.uploadURL = "https://re7.pil.re7.aws.renault.com/pil-api-s3/uploadfile";
      this.apiLastLoginURL = "https://re7.pil.re7.aws.renault.com/pil-connection/";
      // this.uploadURL = "https://int.pil.dev.aws.renault.com/pil-api-s3/uploadfile";
      // this.apiLastLoginURL = "https://int.pil.dev.aws.renault.com/pil-connection/";
    }
  }

  headers() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'apikey': environment.idp.prod[this.idpVersion].apikey,
      'authorization': `Bearer ${sessionStorage.getItem('access_token')}`
    });
  }

  headers4FileUpload() {
    return new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'apikey': environment.idp.prod[this.idpVersion].apikey,
      'authorization': `Bearer ${sessionStorage.getItem('access_token')}`
    });
  }


  public getChoiceTableDetails(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objliste', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'object-lists', queryParam, { headers: this.headers() });
  }

  public getMessageObjectList(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objliste', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'object-lists', queryParam, { headers: this.headers() });
  }

  public getMessageObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objliste', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'getting-objects', queryParam, { headers: this.headers() });
  }

  public getExecuteData(queryParam: any): Observable<any> {
    return this.http.post<any>(this.apiBaseURL + 'results-lines', queryParam, { headers: this.headers() });
  }

  public getDetailExecuteData(queryParam: any): Observable<any> {
    return this.http.post<any>(this.apiBaseURL + 'results-vehicle-lists', queryParam, { headers: this.headers() });
  }

  public getVehicleDetailExecuteData(queryParam: any): Observable<any> {
    return this.http.post<any>(this.apiBaseURL + 'results-vehicle-details', queryParam, { headers: this.headers() });
  }

  public getObjectSize(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objnbre', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'object-numbers', queryParam, { headers: this.headers() });
  }

  public getSaveQueryName(queryParam: any): Observable<any> {
    return this.http.post<any>(this.apiBaseURL + 'object-numbers', queryParam, { headers: this.headers() });
  }

  public getSaveQueryNameList(queryParam: any): Observable<any> {
    return this.http.post<any>(this.apiBaseURL + 'object-lists', queryParam, { headers: this.headers() });
  }

  public getObjectGet(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objget', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'getting-objects', queryParam, { headers: this.headers() });
  }

  public saveObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'saving-objects', queryParam, { headers: this.headers() });
  }

  public deleteObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'deleting-objects', queryParam, { headers: this.headers() });
  }

  public memoryReleaseObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'memory-releases', queryParam, { headers: this.headers() });
  }

  public exportResultObject(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'export-results', queryParam, { headers: this.headers() });
  }

  downloadFile(url): Observable<any> {
    // return this.http.get(url, { responseType: 'blob' });
    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' })
  }

  uploadFile(queryParam: any): Observable<any> {
    // return this.http.get(url, { responseType: 'blob' });
    return this.http.post<any>(this.uploadURL, queryParam, { headers: this.headers4FileUpload() });
  }

  public specialBases(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'special-bases', queryParam, { headers: this.headers() });
  }

  public getlostloginlist(): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.get<any>(this.apiLastLoginURL + "lostloginlist", { headers: this.headers() });
  }

  public createuserlogin(queryParam: any): Observable<any> {
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiLastLoginURL + "loginentry", queryParam, { headers: this.headers() });
  }

  public writingLogs(queryParam: any): Observable<any> {
    console.log("writing log param", queryParam);
    // return this.http.post<any>(this.apiBaseURL + 'svc_objsave', queryParam, { headers: headers });
    return this.http.post<any>(this.apiBaseURL + 'writing-logs', queryParam, { headers: this.headers() });
  }

}

