import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, AfterViewInit, AfterContentChecked, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import 'moment/min/locales.min';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from 'ng2-toasty';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../services/util/date.adapter';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../services/util/util.service';
import { RequestService } from '../../../services/request/request.service';
import { AlertConfirmationComponent } from '../../alert-confirmation/alert-confirmation.component';
import data from './population-screen-table.json';
import { PreviewDialogComponent } from '../preview-dialog/preview-dialog.component';
import { SaveQueryNameComponent } from '../save-query-name/save-query-name.component';
import { MatSelectionList, MatSelectionListChange, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { ExportFileComponent } from '../export-file/export-file.component';
import { MatomoTracker } from 'ngx-matomo';
@Component({
  selector: 'app-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class QueryMainScreenComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked, AfterViewChecked {

  @ViewChild('characterListId', { static: false }) characterId: MatSelectionList;
  profile = this.utilService.getUserProfile();

  dataList = data;
  connections: string[] = ['Europe', 'Mercosur'];
  favoriteConnecton: string;

  bln: boolean;
  selectedTable;
  ipn;
  region;
  splitChar = "§";
  requestStoresData;
  selectedRequestStoresData;
  selectedCategory = "Tous";
  filterCategory: Array<string>;
  filterPrivateCategory: Array<string>;
  categoryList;
  searchText;
  displayFilterValues;
  criteriaList;
  criteriaListOringinal;
  displayCriteriaList: Array<string>;
  selectedCriterias: string[] = [];
  selectedGroupValues: string[] = [];
  decodeSaufData: string[] = [];
  arrowUpDisplay: boolean;
  reqActive;
  isExecute = false;
  getResultInput = {};
  populateExp = true;
  mnuOpen: boolean;
  mnuDeptOpen: boolean;
  mnuEntityOpen: boolean;
  mnuPaysOpen: boolean;
  mnuDateOpen: boolean;
  mnuDefinationOpen: boolean;
  displayGroupingForm = false;
  selectedId;
  disableExecuteSection = true;
  populateDeptList: Array<string>;
  populateEntityList: Array<string>;
  populatePaysList: Array<string>;
  populateDateList: Array<string>;
  selPopulateDept = 'Tous';
  selPopulateEntity: string;
  selPopulatePays: string;
  selPopulateDate: string;
  selPopulateDefination: string;
  navigateUrl: string;
  selectedItemValue: string;
  selectedGroupItemValue: string;
  selectedCharacterList: Object = {};
  selectedItemIndex: number;
  selectedItemType: number;
  selectedItemType4Color: number;
  selectedCharacterNumber: number;
  selectedKey: string;
  queryName: string;
  populateSection = true;
  groupSection: boolean;
  vueSection: boolean;
  blnGroupingEdit = false;
  groupingEditValue: string;
  groupingEditIndex: number;
  selectedGroupItemIndex: number;
  selectedIndexForMove: any;
  blnMoveRight = false;
  blnDelay = false;
  delayDate1Data;
  delayDate2Data;
  delayDate1Check1: boolean;
  delayDate1Check2: boolean;
  delayDate2Check1: boolean;
  delayDate2Check2: boolean;
  toggle = false;
  selectedDelayDate1;
  selectedDelayDate2;
  editGroupingDelay = false;
  groupingDelayInput = { date1: '', date2: '', value: '', ddValue: '', diffValue: '', diffTextValue: '' };
  delayDateTime1;
  delayDateTime2;
  daysData;
  isMove;
  tcm;
  groupList = [];
  populationList = [];
  characterList = {
    'characterListType1': { 'group0': [] },
    'characterListType2': { 'group0': [] },
    'characterListType3': { 'group0': [] },
  };
  dateTCMList = ['Les 3 derniers mois', 'Les 6 derniers mois', 'Les 12 derniers mois', "Tout l'historique"]
  groupingSelectedList = [];
  groupingDelaySelectOption = 0;
  groupingDelayCheckVal = 0;
  form: FormGroup;
  selectedQuery: string;
  roleBasedQuery: string;
  vueList = [];
  vueEditIndex: number;
  blnVueEdit = false;
  vueEditValue: string;
  GCOName: string;
  vuePrivateIPNDetails;
  selectedVueIPNDetail;
  blnVueIPNOpen;
  vueTypeSelect;
  detailCharacterList;
  detailGroupList;
  detailVueList;
  isAllowExecute = false;
  isAllowAllHisData = false;
  isShowHiddenList = false;
  isEnableAllTextBox = false;
  isCheckPopulationEmpty = false;
  lang = 'fr';
  executePreviewData: { table: any; population: any[]; characterList: { characterListType1: { group0: any[]; }; characterListType2: { group0: any[]; }; characterListType3: { group0: any[]; }; }; group: any[]; vue: any[]; };
  getLangIndex(lang) {
    if (lang === 'en') {
      return 1;
    } else {
      return 0;
    }
  }
  delayName;
  dateVueValue = ['flag', 'jours', 'programmes', 'mois industriels', 'mois calendaires', 'année', 'jours', 'jours', 'jour/prog./année'];
  dateReGroupValue = ['flag', 'jours', 'semaines', 'mois industriels', 'mois calendaires', 'année', 'jours', 'jours', 'jour/prog./année'];
  datePopulationValue = ['connue', 'jour', 'programme', 'mois insustriel', 'mois calendaire', 'année', '', '', 'ASJ', '', 'inconnue'];
  delayOptionValue = ['Jours', 'semaines', 'Mois Industriel', 'Mois Calendaire', 'year', 'Jours ouvres'];
  isVueDetailSave: boolean;
  previousVueList;
  isVueDisable: boolean;
  executeQuery;
  saveQueryList;
  detailExecute;
  executeBtn = false;
  blnExecuteDataStatus = false;
  rtnTotalRowsLength;
  rtnRsultColumns;
  groupExecuteTypeValue = "TSI";
  constructor(readonly router: Router, readonly activatedRoute: ActivatedRoute, public dialog: MatDialog, fb: FormBuilder,
    readonly apiService: BackendApiService, readonly utilService: UtilService, readonly requestService: RequestService,
    readonly changeDetector: ChangeDetectorRef, readonly translateService: TranslateService, readonly toastyService: ToastyService, private matomoTracker: MatomoTracker) {
    this.ipn = this.profile['uid'];
    this.lang = requestService.getSelectedLang();
    this.form = fb.group({
      day: [''],
      week: [''],
      year: ['']
    });
    setTimeout(() => {
      this.selPopulateDept = this.utilService.getTranslateName(`COMMON.ALL`);
      this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    })
    this.utilService.$showIcon$.next(false);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  getDateOfWeek(week, year) {
    const noOfDays = 7
    let d = (1 + (week - 1) * noOfDays); // 1st of January + 7 days for each week
    const checkFirstWeek = 10
    if (week < checkFirstWeek) {
      d = (1 + (week) * noOfDays); // 1st of January + 7 days for each week
      return new Date(year, 1, d);
    }
    return new Date(year, 0, d);
  }

  getWeekOfMonth(year, month_number) {
    return this.getWeek(year, month_number, 1);
  }

  getWeek(year, month, day) {
    if (month !== 1) {
      month = month - 1;
    }
    //lets calc weeknumber the cruel and hard way :D
    //Find JulianDay 
    month += 1; //use 1-12
    let a = Math.floor((14 - (month)) / 12);
    let y = year + 4800 - a;
    let m = (month) + (12 * a) - 3;
    let jd = day + Math.floor(((153 * m) + 2) / 5) +
      (365 * y) + Math.floor(y / 4) - Math.floor(y / 100) +
      Math.floor(y / 400) - 32045;      // (gregorian calendar)
    //var jd = (day+1)+Math.Round(((153*m)+2)/5)+(365+y) + 
    //                 Math.round(y/4)-32083;    // (julian calendar)

    //now calc weeknumber according to JD
    let d4 = (jd + 31741 - (jd % 7)) % 146097 % 36524 % 1461;
    let L = Math.floor(d4 / 1460);
    let d1 = ((d4 - L) % 365) + L;
    const NumberOfWeek = Math.floor(d1 / 7) + 1;
    if (month === 1) {
      return 1;
    }
    return NumberOfWeek;
  }

  getWeekOfDate(year, month, day) {
    return this.getWeek(year, month, 1) + Math.floor(day / 7);
  }

  groupingDelayIndex(event) {
    const previousIndex = event.previousIndex > -1 ? event.previousIndex : event.currentIndex;
    this.groupingDelaySelectOption = event.currentIndex;
    if (this.delayDateTime1) {
      if (this.groupingDelayCheckVal === 2) {
        if (this.groupingDelaySelectOption === 0 || this.groupingDelaySelectOption === 1 || this.groupingDelaySelectOption === 6) {
          if (previousIndex === 2) {
            const dateVal = this.getDateOfWeek(parseInt(this.delayDateTime1.split("/")[0]), parseInt(this.delayDateTime1.split("/")[1]));
            this.delayDateTime1 = (dateVal.getDate() < 10 ? '0' + dateVal.getDate() : dateVal.getDate()) + '/' + (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
          } else if (previousIndex === 3 || previousIndex === 4) {
            this.delayDateTime1 = '01/' + this.delayDateTime1.split("/")[0] + '/' + this.delayDateTime1.split("/")[1];
          } else if (previousIndex === 5) {
            this.delayDateTime1 = '01/01/' + this.delayDateTime1;
          }
        } else if (this.groupingDelaySelectOption === 3 || this.groupingDelaySelectOption === 4) {
          if (previousIndex === 0 || previousIndex === 1 || previousIndex === 6) {
            this.delayDateTime1 = this.delayDateTime1.split("/")[1] + '/' + this.delayDateTime1.split("/")[2];
          } else if (previousIndex === 2) {
            const dateVal = this.getDateOfWeek(parseInt(this.delayDateTime1.split("/")[0]), parseInt(this.delayDateTime1.split("/")[1]));
            this.delayDateTime1 = (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
          } else if (previousIndex === 5) {
            this.delayDateTime1 = '01/' + this.delayDateTime1;
          }
        } else if (this.groupingDelaySelectOption === 5) {
          if (previousIndex === 0 || previousIndex === 1 || previousIndex === 6) {
            this.delayDateTime1 = this.delayDateTime1.split("/")[2];
          } else if (previousIndex === 2 || previousIndex === 3 || previousIndex === 4) {
            this.delayDateTime1 = this.delayDateTime1.split("/")[1];
          }
        } else if (this.groupingDelaySelectOption === 2) {
          if (previousIndex === 3 || previousIndex === 4) {
            const val = this.getWeekOfMonth(this.delayDateTime1.split("/")[1], this.delayDateTime1.split("/")[0]);
            this.delayDateTime1 = (val < 10 ? '0' + val : val) + '/' + this.delayDateTime1.split("/")[1];
          } else if (previousIndex === 0 || previousIndex === 1 || previousIndex === 6) {
            const val = this.getWeekOfDate(this.delayDateTime1.split("/")[2], this.delayDateTime1.split("/")[1], this.delayDateTime1.split("/")[0]);
            this.delayDateTime1 = (val < 10 ? '0' + val : val) + '/' + this.delayDateTime1.split("/")[2];
          } else if (previousIndex === 5) {
            this.delayDateTime1 = '01/' + this.delayDateTime1;
          }
        }
      }
    }

    if (this.delayDateTime2) {
      if (this.groupingDelayCheckVal === 4) {
        if (this.groupingDelaySelectOption === 0 || this.groupingDelaySelectOption === 1 || this.groupingDelaySelectOption === 6) {
          if (previousIndex === 2) {
            const dateVal = this.getDateOfWeek(parseInt(this.delayDateTime2.split("/")[0]), parseInt(this.delayDateTime2.split("/")[1]));
            this.delayDateTime2 = (dateVal.getDate() < 10 ? '0' + dateVal.getDate() : dateVal.getDate()) + '/' + (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
          } else if (previousIndex === 3 || previousIndex === 4) {
            this.delayDateTime2 = '01/' + this.delayDateTime2.split("/")[0] + '/' + this.delayDateTime2.split("/")[1];
          } else if (previousIndex === 5) {
            this.delayDateTime2 = '01/01/' + this.delayDateTime2;
          }
        } else if (this.groupingDelaySelectOption === 3 || this.groupingDelaySelectOption === 4) {
          if (previousIndex === 0 || previousIndex === 1 || previousIndex === 6) {
            this.delayDateTime2 = this.delayDateTime2.split("/")[1] + '/' + this.delayDateTime2.split("/")[2];
          } else if (previousIndex === 2) {
            const dateVal = this.getDateOfWeek(parseInt(this.delayDateTime2.split("/")[0]), parseInt(this.delayDateTime2.split("/")[1]));
            this.delayDateTime2 = (dateVal.getMonth() < 10 ? '0' + dateVal.getMonth() : dateVal.getMonth()) + '/' + dateVal.getFullYear();
          } else if (previousIndex === 5) {
            this.delayDateTime2 = '01/' + this.delayDateTime2;
          }
        } else if (this.groupingDelaySelectOption === 5) {
          if (previousIndex === 0 || previousIndex === 1 || previousIndex === 6) {
            this.delayDateTime2 = this.delayDateTime2.split("/")[2];
          } else if (previousIndex === 2 || previousIndex === 3 || previousIndex === 4) {
            this.delayDateTime2 = this.delayDateTime2.split("/")[1];
          }
        } else if (this.groupingDelaySelectOption === 2) {
          if (previousIndex === 3 || previousIndex === 4) {
            const val = this.getWeekOfMonth(this.delayDateTime2.split("/")[1], this.delayDateTime2.split("/")[0]);
            this.delayDateTime2 = (val < 10 ? '0' + val : val) + '/' + this.delayDateTime2.split("/")[1];
          } else if (previousIndex === 0 || previousIndex === 1 || previousIndex === 6) {
            const val = this.getWeekOfDate(this.delayDateTime2.split("/")[2], this.delayDateTime2.split("/")[1], this.delayDateTime2.split("/")[0]);
            this.delayDateTime2 = (val < 10 ? '0' + val : val) + '/' + this.delayDateTime2.split("/")[2];
          } else if (previousIndex === 5) {
            this.delayDateTime2 = '01/' + this.delayDateTime2;
          }
        }
      }
    }
    // this.delayDateTime1 = undefined;
    // this.delayDateTime2 = undefined;
  }

  delayDate1TextChange(event) {
    this.delayDateTime1 = event;
    if (typeof (this.delayDateTime1) !== 'undefined') {
      if (this.delayDateTime1.length < 11 && (this.groupingDelaySelectOption === 0 || this.groupingDelaySelectOption === 1 || this.groupingDelaySelectOption === 6)) {
        this.groupingDelayInput.date1 = this.delayDateTime1
      } else if (this.delayDateTime1.length < 8 && (this.groupingDelaySelectOption === 2 || this.groupingDelaySelectOption === 3 || this.groupingDelaySelectOption === 4)) {
        this.groupingDelayInput.date1 = this.delayDateTime1
      } else if (this.delayDateTime1.length < 5) {
        this.groupingDelayInput.date1 = this.delayDateTime1
      }
    }
  }

  delayDate2TextChange(event) {
    this.delayDateTime2 = event;
    if (typeof (this.delayDateTime2) !== 'undefined') {
      if (this.delayDateTime2.length < 11 && (this.groupingDelaySelectOption === 0 || this.groupingDelaySelectOption === 1 || this.groupingDelaySelectOption === 6)) {
        this.groupingDelayInput.date2 = this.delayDateTime2
      } else if (this.delayDateTime2.length < 8 && (this.groupingDelaySelectOption === 2 || this.groupingDelaySelectOption === 3 || this.groupingDelaySelectOption === 4)) {
        this.groupingDelayInput.date2 = this.delayDateTime2
      } else if (this.delayDateTime2.length < 5) {
        this.groupingDelayInput.date2 = this.delayDateTime2
      }
    }
  }

  onDelay() {
    if (this.groupSection || this.populateSection) {
      this.editGroupingDelay = false;
      this.groupingDelayInput = { date1: '', date2: '', value: '', ddValue: '', diffValue: '', diffTextValue: '' };
      this.selectedDelayDate1 = undefined;
      this.selectedDelayDate2 = undefined;
      this.delayDate2Check1 = false;
      this.delayDate2Check2 = false;
      this.delayDate1Check1 = false;
      this.delayDate1Check2 = false;
      this.delayDateTime1 = undefined;
      this.delayDateTime2 = undefined;

      this.groupingDelaySelectOption = 0;
      this.groupingDelayCheckVal = 0;
      this.selectedItemValue = undefined;
      this.selectedItemValue = 'Delay';
      this.selectedItemType = undefined;

      setTimeout(() => {
        var objDiv = document.getElementById('scrollframe');
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 10);


      this.blnDelay = true;
    }
  }

  loadDelayData() {
    this.blnDelay = false;
    this.delayDate1Data = [];
    this.delayDate2Data = [];

    let delayDateList = [];

    const filterVal = this.criteriaListOringinal.filter(a => a.typeOfField === 3);
    for (const iterator of filterVal) {

      if (this.isShowHiddenList) {
        this.delayDate1Data.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
        this.delayDate2Data.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
      } else {
        if (iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)][0] !== '_') {
          this.delayDate1Data.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
          this.delayDate2Data.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
        }
      }
    }
    this.delayDate1Data = this.delayDate1Data.sort(this.utilService.sortArray);
    this.delayDate2Data = this.delayDate2Data.sort(this.utilService.sortArray);

    if (this.selectedTable !== 'LEX') {
      this.getDaysValue();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    // //console.log('event.container.id', event.container.id);
    // // //console.log("vent.previousContainer1111111", event.previousContainer.data, event.container.data);
    // //console.log('event.previousContainer === event.container', event.previousContainer === event.container, event.previousIndex, event.currentIndex, event.item)
    // if (event.previousContainer === event.container) {

    //   this.selectedItemValue = event.previousContainer.data[event.previousIndex];
    //   //console.log('this.selectedItemValue', this.selectedItemValue, typeof (this.selectedItemValue));
    //   if (typeof (this.selectedItemValue) === 'string') {
    //     this.moveRight();
    //   }
    //   // //console.log("vent.previousContainer", event.previousContainer, event.container);
    // } else {
    //   this.selectedItemValue = event.previousContainer.data[event.previousIndex];
    //   //console.log('this.selectedItemValuedddddddddd', this.selectedItemValue);
    //   // this.moveRight();
    // }
    // // //console.log("eventeventeventevent", event.container.data[event.previousIndex], this.criteriaListOringinal[event.previousIndex], event);
    // if (event.previousContainer === event.container) {
    //   // //console.log('clickasdddddddddsdssss', this.selectedItemValue, event.previousContainer.data[event.previousIndex], event.previousIndex);


    //   // //console.log('dataaaa', this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[0] === event.previousContainer.data[event.previousIndex])[0])
    //   // // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // }
  }

  // scrollBottom(id) {
  //   setTimeout(() => {
  //     var objDiv = document.getElementById('scrollframe');
  //     objDiv.scrollTop = objDiv.scrollHeight;
  //     return objDiv.scrollTop;
  //   },10);
  // }

  scrollEvent(index, id, key) {
    setTimeout(() => {
      if (this.groupingSelectedList.length)
        var objDiv = document.getElementById('scrollframe');
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 10);

    this.removeSelectedItem(index, id, key);
    this.selectedItemValue = undefined;
    this.selectedGroupItemValue = undefined;
    this.vueEditValue = undefined
    this.blnMoveRight = false;
  }

  dblClickVue(indexval) {
    this.scrollEvent(indexval, 'vueDetailList', '');
    if (this.vueList[indexval].name.indexOf('[') > -1) {
      this.selectedGroupItemValue = this.vueList[indexval].name.substr(0, this.vueList[indexval].name.indexOf('['));
      this.vueEditValue = this.vueList[indexval].name.substr(this.vueList[indexval].name.indexOf('[') + 1, (this.vueList[indexval].name.length - this.vueList[indexval].name.indexOf('[') - 2));
      // this.selectedItemType = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue)[0].typeOfField;
      this.selectedItemType = this.vueList[indexval].type;
      this.blnVueEdit = true;
      this.vueEditIndex = indexval;
      this.displayGrouping();
    } else {
      this.selectedGroupItemValue = this.vueList[indexval].name;
      this.vueEditValue = undefined;
      // this.selectedItemType = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue)[0].typeOfField;
      this.selectedItemType = this.vueList[indexval].type;
      if (this.selectedItemType === 4 || this.selectedItemType === 5) {
        this.selectedItemType = undefined;
        this.blnVueEdit = true;
        this.vueEditIndex = indexval;
      }
    }
  }

  dblClickCharacterList(indexval, num, key, ind, event) {
    // event.stopPropagation();
    let idx = num.toString() + ind.toString() + indexval.toString();
    this.scrollEvent(indexval, 'characterListId', idx);
    this.groupingEditValue = undefined;
    this.vueEditIndex = undefined;
    this.vueEditIndex = indexval;
    this.selectedKey = key;
    let characterType;
    if (num === 1) {
      characterType = 'characterListType1';
    } else if (num === 2) {
      characterType = 'characterListType2';
    } else {
      characterType = 'characterListType3';
    }

    let currentArray = this.characterList[characterType][this.selectedKey][indexval];

    if (currentArray.type === 99) {
      this.onDelay();
      this.groupingDelayCheckVal = currentArray.delay;
      this.blnDelay = true;
      this.editGroupingDelay = true;
      const delayName = currentArray.name;
      this.selectedItemValue = delayName;
      this.groupingDelayInput.date1 = delayName.split('-')[0];
      this.groupingDelayInput.date2 = delayName.split('-')[1];
      if (currentArray.delay === 1 || currentArray.delay === 2) {
        if (this.groupingDelayInput.date1 === 'DATEDUJOUR') {
          this.delayDate1Check1 = true;
        } else if (currentArray.delay !== 0) {
          this.delayDate1Check2 = true;
          this.delayDateTime1 = this.groupingDelayInput.date1;
        }
      } else if (currentArray.delay !== 3 || currentArray.delay !== 4) {
        this.selectedDelayDate1 = this.groupingDelayInput.date1;
      }
      if (currentArray.delay === 3 || currentArray.delay === 4) {
        if (this.groupingDelayInput.date2 === 'DATEDUJOUR') {
          this.delayDate2Check1 = true;
        } else if (currentArray.delay !== 0) {
          this.delayDate2Check2 = true;
          this.delayDateTime2 = this.groupingDelayInput.date2;
        }
      } else if (currentArray.delay !== 1 || currentArray.delay !== 2) {
        this.selectedDelayDate2 = this.groupingDelayInput.date2;
      }
      const delayValue = currentArray.value.split(' ');
      this.groupingDelayInput.value = delayValue;
      return;
    }
    this.selectedGroupItemValue = this.characterList[characterType][this.selectedKey][indexval].name;
    const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.GCOName === this.characterList[characterType][this.selectedKey][indexval].GCOName)[0];
    this.selectedItemType = criteriaFilterVal.typeOfField;
    this.GCOName = criteriaFilterVal.GCOName;
    this.getCharacterData(criteriaFilterVal);
    if (this.selectedItemType === 2 || this.selectedItemType === 1 || this.selectedItemType === 0 || this.selectedItemType === 6) {
      this.groupingEditValue = this.characterList[characterType][this.selectedKey][indexval];
    } else {
      this.groupingEditValue = this.characterList[characterType][this.selectedKey][indexval].value.split(' ');
    }
  }

  dblClickReGroupment(indexval) {
    this.delayDateTime1 = undefined;
    this.delayDateTime2 = undefined;
    this.blnMoveRight = false;
    this.selectedItemType = undefined;
    setTimeout(() => {
      var objDiv = document.getElementById('scrollframe');
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 10);
    this.removeSelectedItem(indexval, 'groupingList', '');
    if (this.groupList[indexval].type === 99) {
      this.groupingEditIndex = indexval;
      this.onDelay();
      this.groupingDelayCheckVal = this.groupList[indexval].delay;
      this.blnDelay = true;
      this.editGroupingDelay = true;
      const delayName = this.groupList[indexval].name.substr(0, this.groupList[indexval].name.indexOf('['));
      this.selectedItemValue = delayName;
      this.groupingDelayInput.date1 = delayName.split('-')[0];
      this.groupingDelayInput.date2 = delayName.split('-')[1];
      if (this.groupList[indexval].delay === 1 || this.groupList[indexval].delay === 2) {
        if (this.groupingDelayInput.date1 === 'DATEDUJOUR') {
          this.delayDate1Check1 = true;
        } else if (this.groupList[indexval].delay !== 0) {
          this.delayDate1Check2 = true;
          this.delayDateTime1 = this.groupingDelayInput.date1;
        }
      } else if (this.groupList[indexval].delay !== 3 || this.groupList[indexval].delay !== 4) {
        this.selectedDelayDate1 = this.groupingDelayInput.date1;
      }
      if (this.groupList[indexval].delay === 3 || this.groupList[indexval].delay === 4) {
        if (this.groupingDelayInput.date2 === 'DATEDUJOUR') {
          this.delayDate2Check1 = true;
        } else if (this.groupList[indexval].delay !== 0) {
          this.delayDate2Check2 = true;
          this.delayDateTime2 = this.groupingDelayInput.date2;
        }
      } else if (this.groupList[indexval].delay !== 1 || this.groupList[indexval].delay !== 2) {
        this.selectedDelayDate2 = this.groupingDelayInput.date2;
      }
      const delayValue = this.groupList[indexval].name.substr(this.groupList[indexval].name.indexOf('[') + 1, (this.groupList[indexval].name.length - this.groupList[indexval].name.indexOf('[') - 2));
      if (!isNaN(delayValue.split(' ')[1])) {
        this.groupingDelayInput.value = this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.JOUR_SAVE`);
        this.groupingDelayInput.ddValue = delayValue.split(' ')[1];
      } else {
        this.groupingDelayInput.value = delayValue;
        this.groupingDelayInput.ddValue = '';
      }

      return;
    }
    this.selectedItemValue = undefined;
    this.selectedGroupItemValue = undefined;
    this.groupingEditValue = undefined
    if (this.groupList[indexval].name.indexOf('[') > -1) {
      this.selectedGroupItemValue = this.groupList[indexval].name.substr(0, this.groupList[indexval].name.indexOf('['));
      this.groupingEditValue = this.groupList[indexval].name.substr(this.groupList[indexval].name.indexOf('[') + 1, (this.groupList[indexval].name.length - this.groupList[indexval].name.indexOf('[') - 2));
      // this.selectedItemType = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue)[0].typeOfField;
      this.selectedItemType = this.groupList[indexval].type;
      this.blnGroupingEdit = true;
      this.groupingEditIndex = indexval;
      this.blnMoveRight = false;
      this.displayGrouping();
    } else {
      this.selectedGroupItemValue = this.groupList[indexval].name;
      this.groupingEditValue = undefined;
      // this.selectedItemType = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue)[0].typeOfField;
      this.selectedItemType = this.groupList[indexval].type;
      if (this.selectedItemType === 4 || this.selectedItemType === 5) {
        this.blnGroupingEdit = true;
        this.groupingEditIndex = indexval;
      }
    }
  }

  removeSelectedItem(index, id, key) {
    if (key === '') {
      var selectList = document.getElementById(id);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      for (var i = 0; i < selectOptions.length; i++) {
        if (i !== index && selectOptions[i].getAttribute('aria-selected') === 'true') {
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click()
        } else if (i === index && selectOptions[i].getAttribute('aria-selected') === 'false') {
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click()
        }
      }
    } else {
      var selectList = document.getElementById(id);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      for (var i = 0; i < selectOptions.length; i++) {
        let num = selectOptions[i].getAttribute('id');
        let selectedIndex = 'character_data_' + key;
        if (num !== selectedIndex && selectOptions[i].getAttribute('aria-selected') === 'true') {
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click()
        } else if (num === selectedIndex && selectOptions[i].getAttribute('aria-selected') === 'false') {
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click()
          return;
        }
      }
    }
  }

  clickList(item, index, key, num, idx) {
    // let idx = num.toString() + ind.toString() + indexval.toString();
    if (this.characterId !== undefined) {
      this.characterId.selectionChange.subscribe((s: MatSelectionListChange) => {
        if (this.selectedId === undefined) {
          if (s.source._value.length > 0) {
            this.characterId.deselectAll();
            s.option.selected = true;
          } else {
            this.characterId.deselectAll();
          }
        }
      });
    }
    item.isSelected = false;
    this.selectedItemType = undefined;
    if (key === '') {
      if (this.groupingSelectedList.indexOf(item) > -1) {
        this.groupingSelectedList.splice(item, 1);
      } else {
        this.groupingSelectedList.push(item);
      }
    } else {
      this.groupingSelectedList = [];
      this.groupingSelectedList.push(item);
    }
    if (this.populateSection) {
      this.selectedIndexForMove = '';
      this.selectedIndexForMove = num.toString() + idx.toString() + index.toString();
    }
    this.blnDelay = false;
    // this.selectedItemValue = item.name;
    this.selectedKey = key;

    if (key !== '') {
      this.groupingSelectedList = [];
      // //console.log('this.groupingSelectedList11111111111', this.selectedCharacterNumber, key);
      let selectedId = 'character_data_' + this.selectedIndexForMove;
      setTimeout(() => {
        var selectList = document.getElementById('characterList_' + this.selectedCharacterNumber);
        var selectOptions = selectList.getElementsByTagName('mat-list-option');
        for (let i = 0; i < selectOptions.length; i++) {
          let div = selectOptions[i].getAttribute('id');
          if (div === selectedId && selectOptions[i].getAttribute('aria-selected') === 'true') {
            this.groupingSelectedList.push(item);
            // //console.log('this.groupingSelectedList', this.groupingSelectedList, this.selectedCharacterNumber);
            break;
          }
        }
      }, 0);
    }


    // if (key !== '') {
    //   //console.log("this.groupingSelectedList", this.groupingSelectedList);
    //   var selectList = document.getElementById('characterList');
    //   var selectOptions = selectList.getElementsByTagName('mat-list-option');
    //   for (var i = 0; i < selectOptions.length; i++) {


    //     if (selectOptions[i].getAttribute('aria-selected') === 'true') {
    //       let div = selectOptions[i].getAttribute('id');
    //       document.getElementById(div).click()
    //       // //console.log('selectOptions[i]', selectOptions.length, selectOptions[i], item)
    //       break
    //     }
    //   }
    // }

    if (this.groupList.indexOf(item) > -1) {
      // this.selectedItemValue = item;
      // this.selectedGroupItemValue = item.name;
      this.selectedItemIndex = index;
      this.selectedGroupItemValue = undefined;
      this.selectedItemValue = undefined
      this.groupingEditValue = undefined
      this.blnMoveRight = false;
    }
  }

  moveRight() {
    if (!this.blnDelay) {
      setTimeout(() => {
        var objDiv = document.getElementById('scrollframe');
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      }, 10);
      if (typeof (this.selectedItemValue) !== 'undefined') {
        const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedItemValue : a.longLabel === this.selectedItemValue)[0];
        this.GCOName = criteriaFilterVal.GCOName;

        if (this.populateSection) {
          if ((criteriaFilterVal.typeOfField === 0 || criteriaFilterVal.typeOfField === 6) && (this.GCOName === "I" || this.GCOName === "A_Chassis" || this.GCOName === "A_Ident")) {
            // //console.log("this.GCOName", this.GCOName, criteriaFilterVal.typeOfField);
            this.toastyService.warning(this.utilService.getTranslateName(`ERROR.VIRTUAL_COLUMNS`));
            return;
          }
        }

        this.selectedItemType = criteriaFilterVal.typeOfField;
        this.selectedGroupItemValue = this.selectedItemValue;
        this.blnMoveRight = true;
        this.getCharacterData(criteriaFilterVal);
        if (this.selectedItemType !== 3 && this.selectedItemType !== 1) {
          if (this.groupSection) {
            if (this.selectedItemValue === "Volume" || this.selectedItemValue === 'Volume prevu') {
              this.groupList.push({ "name": this.selectedItemValue + '[Sum]', "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, "size": criteriaFilterVal.size });
              this.requestService.addRequestReGroupment(this.groupList, this.reqActive)
            } else {
              this.groupList.push({ "name": this.selectedItemValue, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, "size": criteriaFilterVal.size });
              this.requestService.addRequestReGroupment(this.groupList, this.reqActive)
            }
          } else if (this.vueSection) {
            this.vueList.push({ "name": this.selectedItemValue, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, "size": criteriaFilterVal.size, "visible": true });
            this.requestService.addRequestVue(this.vueList, this.reqActive)
          }
          // this.selectedItemValue = undefined;
          // this.selectedItemType = undefined;
        }
        this.displayGrouping();
      }
      this.groupingEditValue = undefined
    }
  }

  getCharacterData(criteriaFilterVal) {
    this.selectedCharacterList = {
      'longLabel': criteriaFilterVal.longLabel,
      'size': criteriaFilterVal.size,
      'selectedTable': this.selectedTable,
      'ipn': this.ipn,
      'region': this.region,
      'selectedGroupItemValue': this.selectedGroupItemValue
    };
  }
  clickCriteriaList(item, index) {
    this.selectedItemValue = item; this.selectedItemIndex = index; this.selectedItemType = undefined;
    this.selectedItemType4Color = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedItemValue : a.longLabel === this.selectedItemValue)[0].typeOfField;
    const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === item : a.longLabel === item)[0];
    let selectedField = criteriaFilterVal.longLabel.split('§');
    selectedField = selectedField[this.getLangIndex(this.lang)] !== undefined && selectedField[this.getLangIndex(this.lang)] !== null ? selectedField[this.getLangIndex(this.lang)] : selectedField[0];
    if (selectedField.charAt(0) !== '_') {
      selectedField = selectedField.replace(' ', '%20');
      this.navigateUrl = 'https://grouperenault.sharepoint.com/sites/Doc_Pilotage/en-US/Pages/Aide%20en%20ligne/' + this.selectedTable + '/' + selectedField + '.aspx';
    } else {
      this.navigateUrl = '';
    }
  }

  navigateField() {
    if (this.navigateUrl !== '' && this.navigateUrl !== undefined) {
      window.open(this.navigateUrl, '_blank');
    }
  }

  moveLeft(event) {
    event.stopPropagation();
    if (!this.blnDelay) {
      if (this.groupingSelectedList.length > 0) {
        if (this.populateSection) {
          let selectedId = 'character_data_' + this.selectedIndexForMove;
          var selectList = document.getElementById('characterList_' + this.selectedCharacterNumber);
          var selectOptions = selectList.getElementsByTagName('mat-list-option');
          for (let i = 0; i < selectOptions.length; i++) {
            if (selectOptions[i].getAttribute('aria-selected') === 'true') {
              let div = selectOptions[i].getAttribute('id');
              if (div === selectedId) {
                document.getElementById(selectedId).click();
              }
            }
          }
        }
        for (const item of this.groupingSelectedList) {
          if (this.vueSection) {
            if (this.vueList.indexOf(item) > -1) {
              this.vueList.splice(this.vueList.indexOf(item), 1);
              this.requestService.addRequestVue(this.vueList, this.reqActive);
            }
          } else if (this.groupSection) {
            if (this.groupList.indexOf(item) > -1) {
              this.groupList.splice(this.groupList.indexOf(item), 1);
              this.requestService.addRequestReGroupment(this.groupList, this.reqActive);
            }
          } else {
            if (!item.isSaufGroup) {
              if (item.type === 1 || item.type === 2 || item.type === 0 || item.type === 6) {
                if (this.characterList.characterListType1[this.selectedKey].length > 1) {
                  let arr = this.characterList.characterListType1[this.selectedKey];
                  for (let i = 0; i < arr.length; i++) {
                    if (arr.includes(item)) {
                      let itemiInd = arr.indexOf(item);
                      if (itemiInd === 0) {
                        arr[1].conditionType = '2';
                        break;
                      }
                    }
                  }
                  this.characterList.characterListType1[this.selectedKey].splice(this.characterList.characterListType1[this.selectedKey].indexOf(item), 1);
                } else {
                  this.characterList.characterListType1 = this.joinArray(1, this.selectedKey, '', 'remove');
                }
                !this.characterList.characterListType1.hasOwnProperty('group0') ? this.characterList.characterListType1.group0 = [] : this.characterList.characterListType1;
                this.requestService.addRequestCharacter(this.characterList.characterListType1, this.reqActive, 1);
              } else if (item.type === 3 || item.type === 4 || item.type === 5) {
                if (this.characterList.characterListType2[this.selectedKey].length > 1) {
                  let arr = this.characterList.characterListType2[this.selectedKey];
                  for (let i = 0; i < arr.length; i++) {
                    if (arr.includes(item)) {
                      let itemiInd = arr.indexOf(item);
                      if (itemiInd === 0) {
                        arr[1].conditionType = '2';
                        break;
                      }
                    }
                  }
                  this.characterList.characterListType2[this.selectedKey].splice(this.characterList.characterListType2[this.selectedKey].indexOf(item), 1);
                } else {
                  this.characterList.characterListType2 = this.joinArray(2, this.selectedKey, '', 'remove');
                }
                !this.characterList.characterListType2.hasOwnProperty('group0') ? this.characterList.characterListType2.group0 = [] : this.characterList.characterListType2;
                this.requestService.addRequestCharacter(this.characterList['characterListType2'], this.reqActive, 2);
              } else {
                if (this.characterList.characterListType3[this.selectedKey].length > 1) {
                  let arr = this.characterList.characterListType3[this.selectedKey];
                  for (let i = 0; i < arr.length; i++) {
                    if (arr.includes(item)) {
                      let itemiInd = arr.indexOf(item);
                      if (itemiInd === 0) {
                        arr[1].conditionType = '2';
                        break;
                      }
                    }
                  }
                  this.characterList.characterListType3[this.selectedKey].splice(this.characterList.characterListType3[this.selectedKey].indexOf(item), 1);
                } else {
                  this.characterList.characterListType3 = this.joinArray(3, this.selectedKey, '', 'remove');
                }
                !this.characterList.characterListType3.hasOwnProperty('group0') ? this.characterList.characterListType3.group0 = [] : this.characterList.characterListType3;
                this.requestService.addRequestCharacter(this.characterList.characterListType3, this.reqActive, 3);
              }
            } else {
              this.toastyService.warning("This item has been grouped, so user can't remove");
            }
          }
        }
        this.selectedGroupItemValue = undefined;
        this.groupingEditValue = undefined;
        this.selectedGroupItemIndex = undefined;
        this.blnMoveRight = false;
        this.selectedItemType = undefined;
        this.vueEditValue = undefined;
        this.blnDelay = false;
        this.groupingSelectedList = [];
      }
    }
  }

  onGroupDateCancel(event) {
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.blnGroupingEdit = false;
    this.selectedGroupItemValue = undefined;
    this.groupingEditValue = undefined
    this.blnMoveRight = false;
    this.selectedGroupItemIndex = undefined;
    this.vueEditValue = undefined
    this.blnVueEdit = false;
  }

  createNewGroup(num) {
    let indexName, lastIndex;
    if (Object.keys(this.characterList['characterListType' + num]).length > 1) {
      lastIndex = Object.keys(this.characterList['characterListType' + num]).length - 1;
    } else {
      lastIndex = 'group0'.match(/\d/g)[0];
      lastIndex = parseInt(lastIndex);
    }
    let incrementNumber = lastIndex + 1;
    if (this.characterList['characterListType' + num]['group0'].length > 0) {
      indexName = 'group' + incrementNumber;
    } else {
      indexName = 'group0';
    }
    return indexName;
  }

  onGroupDateOk(event, section) {
    if (event.value !== '') {
      const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue : a.longLabel === this.selectedGroupItemValue)[0];
      if (section === 'regroupment') {
        this.displayGroupingForm = event.displayGroupingForm;
        if (event.value !== '') {
          if (!this.blnMoveRight) {
            if (typeof (event.value) !== "undefined") {
              this.groupList[this.groupingEditIndex].name = this.selectedGroupItemValue + `[${event.value}]`;
            } else {
              this.groupList[this.groupingEditIndex].name = this.selectedGroupItemValue + `[${event}]`;
            }
            this.requestService.addRequestReGroupment(this.groupList, this.reqActive)
          } else {
            if (typeof (event.value) !== "undefined") {
              this.groupList.push({ "name": this.selectedGroupItemValue + `[${event.value}]`, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size });
            } else {
              this.groupList.push({ "name": this.selectedGroupItemValue + `[${event}]`, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size });
            }
            this.requestService.addRequestReGroupment(this.groupList, this.reqActive)
          }
        }
      } else if (section === 'vue') {
        if (!this.blnMoveRight) {
          if (typeof (event.value) !== "undefined") {
            this.vueList[this.vueEditIndex].name = this.selectedGroupItemValue + `[${event.value}]`;
          } else {
            this.vueList[this.vueEditIndex].name = this.selectedGroupItemValue + `[${event}]`;
          }
          this.requestService.addRequestVue(this.vueList, this.reqActive)
        } else {
          if (typeof (event.value) !== "undefined") {
            this.vueList.push({ "name": this.selectedGroupItemValue + `[${event.value}]`, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size, "visible": true });
          } else {
            this.vueList.push({ "name": this.selectedGroupItemValue + `[${event}]`, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size, "visible": true });
          }
          this.requestService.addRequestVue(this.vueList, this.reqActive)
        }
      } else if (section === 'population') {
        let value, format;
        this.displayGroupingForm = event.displayGroupingForm;
        if (event.value !== '') {
          if (this.selectedItemType === 1 || this.selectedItemType === 2 || this.selectedItemType === 0 || this.selectedItemType === 6) {
            if (this.selectedItemType === 2 || this.selectedItemType === 0 || this.selectedItemType === 6) {
              value = !event.hasOwnProperty('format') ? event.value.join('/') : event.format + ' ' + event.value;
              // value = ' ' + value;
              value = value;
              format = event.formatType;
            } else {
              value = '[' + event.value.join('] ou [') + ']';
              format = '';
            }
            let lastIndex = this.createNewGroup(1);
            console.log("sri", this.characterList.characterListType1);
            if (!this.blnMoveRight) {
              console.log(value);
              this.characterList.characterListType1[this.selectedKey][this.vueEditIndex].value = value;
              this.characterList.characterListType1[this.selectedKey][this.vueEditIndex].format = format;
              this.requestService.addRequestCharacter(this.characterList.characterListType1, this.reqActive, 1)
            } else {
              this.characterList.characterListType1[lastIndex] = new Array();
              console.log(this.characterList.characterListType1[lastIndex]);
              this.characterList.characterListType1[lastIndex].push({ "name": this.selectedGroupItemValue, 'value': value, format: format, conditionType: '2', group: false, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size });
              console.log(this.characterList.characterListType1[lastIndex]);
              this.requestService.addRequestCharacter(this.characterList.characterListType1, this.reqActive, 1)
            }

          } else if (this.selectedItemType === 3) {
            let startDateVal: string;
            let endDateVal: string;
            if (event.date1) {
              startDateVal = `>=${event.date1}`;
              if (event.date2) {
                startDateVal = `${startDateVal} et `;
              }
            }
            if (event.date2) {
              endDateVal = `<=${event.date2}`;
            }
            let lastIndex = this.createNewGroup(2);
            let value = `${startDateVal ? startDateVal : ''}${endDateVal ? endDateVal : ''}`;
            if (!this.blnMoveRight) {
              // this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].conditionType = '2';
              // this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].group = false;
              this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].name = `${this.selectedGroupItemValue.substr(0, this.selectedGroupItemValue.indexOf('[') - 1)} [${event.value}]`;
              if (event.date1 || event.date2) {
                this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].value = value;
              } else {
                this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].value = `=${event.conneValue}`;
              }
              this.requestService.addRequestCharacter(this.characterList.characterListType2, this.reqActive, 2)
            } else {
              this.characterList.characterListType2[lastIndex] = new Array();
              if (event.date1 || event.date2) {
                this.characterList.characterListType2[lastIndex].push({ "name": `${this.selectedGroupItemValue} [${event.value}]`, 'value': value, conditionType: '2', group: false, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size });
                this.requestService.addRequestCharacter(this.characterList.characterListType2, this.reqActive, 2)
              } else {
                this.characterList.characterListType2[lastIndex].push({ "name": `${this.selectedGroupItemValue} [${event.value}]`, 'value': `=${event.conneValue}`, conditionType: '2', group: false, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size });
                this.requestService.addRequestCharacter(this.characterList.characterListType2, this.reqActive, 2)
              }
            }
          } else if (this.selectedItemType === 4 || this.selectedItemType === 5) {
            let lastIndex = this.createNewGroup(2);
            this.characterList.characterListType2[lastIndex] = new Array();
            let value = event.format ? ' ' + event.format + event.value : '= ' + event.value;
            if (!this.blnMoveRight) {
              // this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].conditionType = '2';
              // this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].group = false;
              this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].value = value;
              this.characterList.characterListType2[this.selectedKey][this.vueEditIndex].format = event.format;
              this.requestService.addRequestCharacter(this.characterList.characterListType2, this.reqActive, 2)
            } else {
              this.characterList.characterListType2[lastIndex] = new Array();
              this.characterList.characterListType2[lastIndex].push({ "name": this.selectedGroupItemValue, 'value': value, conditionType: '2', 'format': event.format, group: false, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size });
              this.requestService.addRequestCharacter(this.characterList.characterListType2, this.reqActive, 2)
            }
          } else {
            let lastIndex = this.createNewGroup(3);
            this.characterList.characterListType3[lastIndex] = new Array();
            let value = !event.hasOwnProperty('format') ? event.join('/') : event.format + ' ' + event.value;
            if (!this.blnMoveRight) {
              // this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].conditionType = '2';
              // this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].group = false;
              this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].value = value;
              this.requestService.addRequestCharacter(this.characterList.characterListType3, this.reqActive, 3)
            } else {
              this.characterList.characterListType2[lastIndex] = new Array();
              this.characterList.characterListType3[lastIndex].push({ "name": this.selectedGroupItemValue, 'value': value, conditionType: '2', group: false, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, 'size': criteriaFilterVal.size });
              this.requestService.addRequestCharacter(this.characterList.characterListType3, this.reqActive, 3)
            }
          }
        }
      }
    }
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.selectedGroupItemValue = undefined;
    this.blnMoveRight = false;
    this.groupingEditValue = undefined;
    this.vueEditValue = undefined;
    this.selectedGroupItemIndex = undefined;
  }

  resetAllValues() {
    this.blnMoveRight = false;
    this.searchText = undefined;
    this.selPopulateDept = this.utilService.getTranslateName(`COMMON.ALL`);
    this.selPopulateEntity = undefined;
    this.selPopulatePays = undefined;
    this.selPopulateDate = undefined;
    this.selPopulateDefination = undefined;
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.groupList = [];
    this.populationList = [];
    this.blnGroupingEdit = false;
    this.selectedGroupItemValue = undefined;
    this.groupingEditValue = undefined
    this.selectedGroupItemIndex = undefined;
    this.selectedVueIPNDetail = undefined;
    this.isExecute = false;
    this.toggle = false;
  }

  log(event) {
    this.arrowUpDisplay = event;
  }

  ngOnDestroy() {
  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  ngOnInit() {
    this.requestStoresData = undefined;
    this.requestStoresData = this.requestService.getAllRequestData();
    if (this.requestStoresData.length === 0) {
      this.router.navigateByUrl('/home');
    } else {
      this.onLoad();
    }
    this.utilService.$showQuickLink$.next(true);
  }

  onLoad() {
    if ((this.requestStoresData.length < 4)) {
      if (this.requestStoresData[this.requestStoresData.length - 1] != null) {
        this.reqActive = this.requestStoresData.length - 1;
        this.selectedTable = this.requestStoresData[this.requestStoresData.length - 1].table;
        this.region = this.requestStoresData[this.requestStoresData.length - 1].region;
        this.getSelectedStoreData(this.requestStoresData[this.requestStoresData.length - 1]);
      }
    }
  }

  characterListTypeStatus(num) {
    if (this.characterList && this.characterList['characterListType' + num]) {
      for (let key in this.characterList['characterListType' + num]) {
        if (this.characterList['characterListType' + num][key].length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  isEmptyCharacter() {
    // if (this.characterList['characterListType1'].hasOwnProperty('group0') && 
    // this.characterList['characterListType2'].hasOwnProperty('group0') && 
    // this.characterList['characterListType3'].hasOwnProperty('group0') && 
    // this.characterList['characterListType1'].group0.length ===0 && this.characterList['characterListType2'].group0.length ===0
    // && this.characterList['characterListType3'].group0.length ===0) {
    //   return true;
    // } else {
    //   return false;
    // }

    if (typeof (this.characterList['characterListType1'].group0) !== 'undefined') {
      if (this.characterList['characterListType1'].group0.length === 0 && this.characterList['characterListType2'].group0.length === 0
        && this.characterList['characterListType3'].group0.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  characterListTypeSeprateStatus(num) {
    if (num < 3) {
      if (this.characterList['characterListType1'].hasOwnProperty('group0') &&
        this.characterList['characterListType2'].hasOwnProperty('group0') &&
        this.characterList['characterListType3'].hasOwnProperty('group0')) {
        if (num === 1) {
          if (this.characterList['characterListType2']['group0'].length > 0 || this.characterList['characterListType3']['group0'].length > 0) {
            return true;
          }
        } else {
          if (this.characterList['characterListType' + (num + 1)]['group0'].length > 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  enableJoinCondition(array, key, previousNumber) {
    if (previousNumber < 0) {
      previousNumber = 0;
    }
    if ((array['group' + previousNumber].length === 1 && array['group' + previousNumber][0].sauf) ||
      (!array[key][0].hasOwnProperty('isSaufGroup') && array['group' + previousNumber][0].hasOwnProperty('isSaufGroup'))) {
      return false;
    } else {
      return true;
    }
  }

  enableCondition(item, event, type, condition, index, key) {
    let currentValueNumber;
    event.stopPropagation();
    // event.preventDefault();
    let currentArray = this.characterList['characterListType' + type];
    let previousIndex = index - 1;
    let currentNumber = parseInt(key.match(/\d/g));
    let previousNumber = currentNumber - 1;
    if (previousIndex < 0) {
      previousIndex = 0;
    }
    if (previousNumber < 0) {
      previousNumber = 0;
    }
    if (currentNumber > Object.keys(currentArray).length - 1) {
      currentNumber = Object.keys(currentArray).length - 1;
    }
    for (let i = currentNumber; i <= Object.keys(currentArray).length - 1; i++) {
      if (currentArray['group' + i].some(el => el.sauf)) {
        currentValueNumber = i;
        break;
      }
    }
    let previousIndexValue = currentArray['group' + previousNumber];
    let currentIndexValue = currentArray['group' + currentNumber];
    let conditionForArrayLengthOne = previousIndexValue.length === 1 && previousIndexValue[0].sauf && currentIndexValue.length === 1 && !currentIndexValue[0].sauf;
    let currentGroupLength = currentNumber === 0 && currentArray[key][index].sauf && !currentArray[key][previousIndex].sauf;
    let conditionForPreviousArray = previousIndexValue.length > 1 && previousIndexValue.some(el => el.conditionType === '3');
    let conditionForCurrentArray = currentIndexValue.some(el => el.group) && currentNumber <= currentValueNumber && index === 0;
    let conditionForCurrentArrayWithLengthTwo = currentIndexValue.length === 2 && !currentIndexValue[0].sauf && currentIndexValue[1] && currentIndexValue[1]['sauf'] && index !== 0;
    if (condition === 'and') {
      item['conditionType'] = '2';
      if (currentArray[key][index].sauf && currentArray[key][previousIndex].sauf) {
        item['conditionType'] = '2';
      } else {
        this.characterList['characterListType' + type] = this.splitArray(type, index, key, 0);
      }
    } else if (condition === 'or') {
      if (conditionForArrayLengthOne || currentGroupLength || (conditionForCurrentArray && conditionForPreviousArray) || conditionForCurrentArrayWithLengthTwo) {
        if (previousNumber !== 0 && currentNumber !== 0) {
          //console.log('locked');
        }
      } else if (currentArray['group' + currentNumber].length !== 1 && index !== 0 && currentArray['group' + currentNumber][index].sauf &&
        currentArray['group' + currentNumber][previousIndex].sauf) {
        if (item.conditionType === '1') {
          item['conditionType'] = '2';
        } else {
          item['conditionType'] = '1';
        }
      } else {
        if (item.conditionType === '1') {
          item['conditionType'] = '2';
        } else {
          item['conditionType'] = '1';
        }
        this.characterList['characterListType' + type] = this.joinArray(type, key, index, 'add');
      }
    }
    this.requestService.addRequestCharacter(this.characterList['characterListType' + type], this.reqActive, type)
  }

  joinArray(num, key, index, type) {
    let currentArray = this.characterList['characterListType' + num];
    let clonedArray = { ...currentArray };
    let currentNumber, previousValue, previousArray;
    currentNumber = parseInt(key.match(/\d/g));
    previousValue = currentNumber - 1;
    previousArray = currentArray['group' + previousValue];
    if (previousValue < 0) {
      previousValue = currentNumber;
    }
    if (index !== '') {
      let nextNumber = index + 1;
      if (nextNumber > currentArray[key].length - 1) {
        nextNumber = index;
      }
      if (currentArray[key][nextNumber].sauf) {
        currentArray[key][nextNumber].conditionType = '3';
      }
    }
    if (type !== 'remove') {
      if (currentArray['group' + previousValue].filter(el => el.sauf && el.conditionType === '3').length > 0) {
        if (currentArray['group' + currentNumber][0].sauf && currentArray['group' + currentNumber].length === 1) {
          currentArray['group' + currentNumber][0].conditionType = '2';
          currentArray[key].forEach(item => {
            currentArray['group' + previousValue].push(item);
          });
          currentArray['group' + previousValue].forEach(item => {
            item.group = true;
          })
        } else {
          currentArray[key].forEach(item => {
            item.conditionType = '1';
            item.isSaufGroup = !item.isSaufGroup;
            let index = currentArray['group' + previousValue].findIndex(el => el.conditionType === '3');
            item.group = true;
            currentArray['group' + previousValue].splice(index, 0, item);
          });
        }
        currentArray['group' + previousValue].forEach(element => {
          element.group = true;
        });
      } else {
        currentArray[key].forEach(item => {
          currentArray['group' + previousValue].push(item);
        });
        if (currentArray['group' + previousValue].some(el => el.sauf)) {
          currentArray['group' + previousValue].forEach(item => {
            item.group = true;
          })
        }
      }
      // }
      Object.entries(currentArray).forEach(([keyVal, value], objIndex) => {
        if (currentNumber === objIndex) {
          for (let i = objIndex; i < Object.keys(clonedArray).length - 1; i++) {
            currentArray['group' + i] = currentArray['group' + (i + 1)];
          }
        }
      });
      let lastIndex = Object.keys(clonedArray).length - 1;
      delete currentArray['group' + lastIndex];
      this.characterList['characterListType' + type] = this.enableSaufCheckbox(this.characterList['characterListType' + num], 'add');
    } else {
      Object.entries(currentArray).forEach(([keyVal, value], objIndex) => {
        if (currentNumber === objIndex) {
          for (let i = objIndex; i < Object.keys(clonedArray).length - 1; i++) {
            currentArray['group' + i] = currentArray['group' + (i + 1)];
          }
        }
      });
      let lastIndex = Object.keys(clonedArray).length - 1;
      delete currentArray['group' + lastIndex];
    }
    return currentArray;
  }

  getArray(type) {
    return Object.keys(type);
  }

  splitArray(type, index, key, code) {
    let currentArray = this.characterList['characterListType' + type];
    let clonedArray = { ...currentArray };
    let currentNumber = parseInt(key.match(/\d/g));
    let incrementNumber = currentNumber + 1;
    Object.entries(currentArray).forEach(([keyVal, value], objIndex) => {
      if (currentArray.hasOwnProperty('group' + incrementNumber)) {
        if (incrementNumber === objIndex) {
          for (let i = Object.keys(clonedArray).length - 1; i >= objIndex; i--) {
            currentArray['group' + (i + 1)] = currentArray['group' + i];
          }
          if ((index + code) < currentArray['group' + currentNumber].length) {
            currentArray['group' + objIndex] = currentArray['group' + currentNumber].splice(index + code);
          } else {
            currentArray['group' + objIndex] = currentArray['group' + currentNumber].splice(index - code);
          }
          currentArray['group' + objIndex][0]['conditionType'] = '2';
        }
      } else {
        if ((index + code) < currentArray['group' + currentNumber].length) {
          currentArray['group' + incrementNumber] = currentArray['group' + currentNumber].splice(index + code);
        } else {
          currentArray['group' + incrementNumber] = currentArray['group' + currentNumber].splice(index - code);
        }
        currentArray['group' + incrementNumber][0]['conditionType'] = '2';
      }
    });
    // this.characterList['characterListType' + type] = this.enableSaufCheckbox(this.characterList['characterListType' + type], 'add');
    return this.characterList['characterListType' + type];
  }

  getBorderClass(type, key, index) {
    let currentArray = this.characterList['characterListType' + type];
    let currentNumber = parseInt(key.match(/\d/g));
    let previousNumber = currentNumber - 1;
    let previousIndex = index - 1;
    if (previousNumber < 0) {
      previousNumber = 0;
    }
    if (previousIndex < 0) {
      previousIndex = 0;
    }
    if (index === 0) {
      if (currentArray['group' + currentNumber][0].group && currentArray['group' + previousNumber][0].group && currentArray['group' + previousNumber].filter(el => el.sauf).length === 0 && key !== 'group0') {
        return true;
      } else {
        return false;
      }
    } else if (currentArray[key][index].group && currentArray[key][previousIndex].group) {
      return true;
    } else {
      return false;
    }
  }

  getBorderBottomClass(type, key, index) {
    let currentArray = this.characterList['characterListType' + type];
    let currentNumber = parseInt(key.match(/\d/g));
    let nextNumber = currentNumber + 1;
    let nextIndex = index + 1;
    if (nextNumber > Object.keys(currentArray).length - 1) {
      nextNumber = Object.keys(currentArray).length - 1;
    }
    if (nextIndex > currentArray[key].length - 1) {
      nextIndex = currentArray[key].length - 1;
    }
    if (index === currentArray[key].length - 1) {
      if (currentArray['group' + currentNumber][0].group && currentArray['group' + nextNumber][0].group &&
        currentArray['group' + currentNumber].filter(el => el.sauf).length === 0
        && key !== Object.keys(currentArray)[Object.keys(currentArray).length - 1]) {
        return true;
      } else {
        return false;
      }
    } else if ((currentArray[key][index].group && currentArray[key][nextIndex].group)) {
      return true;
    } else {
      return false;
    }
  }

  enableToggle(item, index, type, key, idx) {
    // event.stopImmediatePropagation();
    // event.preventDefault();
    item['sauf'] = !item['sauf'];
    let currentArray = this.characterList['characterListType' + type][key];
    let clonedArray = { ...this.characterList['characterListType' + type] };
    let arrObj = this.characterList['characterListType' + type];
    let currentNumber = parseInt(key.match(/\d/g));
    let incrementNumber = currentNumber + 1;
    let previousNumber = currentNumber - 1;
    if (previousNumber < 0) {
      previousNumber = currentNumber;
    }
    let previousIndex = index - 1;
    let nextIndex = index + 1;
    if (previousIndex < 0) {
      previousIndex = index;
    }
    if (incrementNumber > Object.keys(arrObj).length) {
      incrementNumber = Object.keys(arrObj).length;
    }
    if (nextIndex > currentArray.length - 1) {
      nextIndex = currentArray.length - 1;
    }
    if (item['sauf']) {
      if (key === 'group0' && index === 0) {
        item.group = false;
        if (currentArray.length !== 1) {
          if (Object.keys(clonedArray).length === 1 && !currentArray[nextIndex].sauf) {
            arrObj['group' + incrementNumber] = this.characterList['characterListType' + type]['group' + currentNumber].splice(index + 1, currentArray.length - 1);
          } else if (currentArray[nextIndex].sauf && currentArray[index].sauf) {
            currentArray[index].conditionType = '2';
          } else {
            for (let i = Object.keys(clonedArray).length - 1; i >= incrementNumber; i--) {
              this.characterList['characterListType' + type]['group' + (i + 1)] = this.characterList['characterListType' + type]['group' + i];
            }
            arrObj['group' + incrementNumber] = this.characterList['characterListType' + type]['group' + currentNumber].splice(index + 1, 1);
          }
          arrObj['group' + incrementNumber][0].conditionType = '2';
        } else if (currentArray.length === 1 && item['sauf']) {
          item.conditionType = '2';
        } else {
          if (currentArray.filter(el => !el.sauf).length === 0) {
            currentArray.forEach(el => {
              el.group = false;
              el.conditionType = '1';
            })
          }
        }
        // clonedArray['group' + incrementNumber][0].conditionType = '2';
        // if (currentArray)
        // arrObj = this.splitArray(type, index, key, 1);
      } else {
        if (currentArray[previousIndex]['sauf'] && previousIndex !== 0) {
          item.conditionType = '2';
        } else if (clonedArray['group' + previousNumber].some(el => el.sauf) && currentArray.length === 1) {
          this.characterList['characterListType' + type] = this.joinArray(type, key, index, 'add');
        } else {
          if (item['conditionType'] !== '1') {
            if (currentArray.length === 1) {
              item['conditionType'] = '3';
              arrObj = this.joinArray(type, key, index, 'add');
              if (item.sauf && currentArray[nextIndex].sauf && currentArray.length > 1) {
                currentArray[nextIndex].conditionType = '2';
              }
            } else if (currentArray.length >= 2) {
              currentArray[nextIndex].conditionType = '2';
              let splicedData = currentArray.splice(index, 1);
              if (arrObj['group' + previousNumber][arrObj['group' + previousNumber].length - 1].sauf) {
                splicedData[0].conditionType = '2';
              } else {
                splicedData[0].conditionType = '3';
              }
              splicedData.forEach(item => {
                arrObj['group' + previousNumber].push(item);
              })
              if (currentArray.filter(el => !el.sauf).length === 0) {
                currentArray.forEach(el => {
                  el.group = false;
                  el.conditionType = '1';
                })
                currentArray[0].conditionType = '2';
              }
              arrObj['group' + previousNumber].forEach(el => el.group = true);
            }
          } else {
            if (currentArray.length === 2) {
              item['conditionType'] = '3';
            }
            if (currentArray.length > 2) {
              if (currentArray[index].sauf && currentArray[nextIndex].sauf) {
                if (index !== nextIndex) {
                  currentArray[index].conditionType = '3';
                  currentArray[nextIndex].conditionType = '2';
                } else {
                  currentArray[index].conditionType = '3';
                }
              } else {
                arrObj = this.splitArray(type, index, key, 1);
                if (arrObj[key][index].sauf) {
                  arrObj[key][index].conditionType = '3';
                }
              }
            }
            currentArray.forEach(el => {
              el.group = true;
            })
          }
        }
      }
    } else {
      if (key === 'group0' && index === 0) {
        item['conditionType'] = '2';
        if (clonedArray['group' + incrementNumber][0].sauf) {
          clonedArray['group' + incrementNumber][0].conditionType = '3';
        }
      } else {
        if (currentArray[index].sauf && currentArray[(index - 1) > 0 ? index - 1 : 0].sauf) {
          if (item.conditionType === '2') {
            item.conditionType = '1';
          } else {
            item['conditionType'] = '2';
            arrObj = this.splitArray(type, index, key, 0);
            if (arrObj['group' + incrementNumber][0].sauf) {
              arrObj['group' + incrementNumber][0].conditionType = '3';
            } else if (arrObj['group' + incrementNumber].length > 1 && arrObj['group' + incrementNumber][1].sauf && !arrObj['group' + incrementNumber][0].sauf) {
              arrObj['group' + incrementNumber][1].conditionType = '3';
            }
            if (currentArray.filter(el => el.sauf).length === 0) {
              currentArray.forEach(el => {
                el.group = false;
              })
            }
            if (arrObj['group' + incrementNumber].filter(el => el.sauf).length === 0) {
              arrObj['group' + incrementNumber].forEach(element => {
                element.group = false;
              });
            }
          }
          if (arrObj['group' + incrementNumber].filter(el => el.sauf).length === 0) {
            arrObj['group' + incrementNumber].forEach(element => {
              element.group = false;
            });
          }
        } else {
          if (currentArray[previousIndex].sauf || currentArray[nextIndex].sauf) {
            if (currentArray[previousIndex].sauf) {
              currentArray[nextIndex].conditionType = '2';
            } else {
              currentArray[nextIndex].conditionType = '3';
            }
            let splicedData = currentArray.splice(index, 1);
            splicedData[0].conditionType = '2';
            let clonedData = { ...this.characterList['characterListType' + type] };
            for (let i = Object.keys(clonedData).length - 1; i >= 0; i--) {
              if (clonedData['group' + i].some(el => el.sauf)) {
                key = i;
                break;
              }
            }
            for (let i = Object.keys(clonedData).length - 1; i >= incrementNumber; i--) {
              arrObj['group' + (i + 1)] = arrObj['group' + (i)];
            }
            arrObj['group' + incrementNumber] = splicedData;
            if (incrementNumber > key) {
              arrObj['group' + incrementNumber].forEach(data => {
                data.group = false;
                delete data.isSaufGroup;
              })
            }
          } else if (currentArray.length === 1) {
            item.conditionType = '2';
          } else {
            arrObj = this.splitArray(type, index, key, 0);
          }
        }
      }
    }
    let ind_val = type.toString() + idx.toString() + index.toString();
    this.clickSelectedToggle(ind_val);
    this.characterList['characterListType' + type] = this.enableSaufCheckbox(this.characterList['characterListType' + type], 'add');
    this.requestService.addRequestCharacter(this.characterList['characterListType' + type], this.reqActive, type);
  }

  clickSelectedToggle(ind_val) {
    var selectList = document.getElementById('characterListId');
    var selectOptions = selectList.getElementsByTagName('mat-list-option');
    for (let i = 0; i < selectOptions.length; i++) {
      let num = selectOptions[i].getAttribute('id');
      let selectedIndex = 'character_data_' + ind_val;
      if (num === selectedIndex && selectOptions[i].getAttribute('aria-selected') === 'true') {
        let div = selectOptions[i].getAttribute('id');
        document.getElementById(div).click();
        setTimeout(() => {
          selectOptions[i].setAttribute('aria-selected', 'false');
        }, 50);
      }
    }
  }

  enableSaufCheckbox(array, type) {
    let key;
    for (let i = Object.keys(array).length - 1; i >= 0; i--) {
      if (array['group' + i].some(el => el.sauf)) {
        key = i;
        break;
      }
    }
    Object.entries(array).forEach(([keyVal, value], objIndex) => {
      if (objIndex === key) {
        for (let i = objIndex; i >= 0; i--) {
          if (!array['group' + i][array['group' + i].length - 1]['group']) {
            array['group' + i].forEach(el => {
              el.isSaufGroup = false;
            });
          } else {
            array['group' + i].forEach(el => {
              el.isSaufGroup = true;
            });
          }
        }
        if ((objIndex + 1) <= (Object.keys(array).length - 1)) {
          for (let i = (objIndex + 1); i <= Object.keys(array).length - 1; i++) {
            if (array['group' + i].filter(el => !el.sauf).length > 0) {
              array['group' + i].forEach(item => {
                item.group = false;
                delete item.isSaufGroup
              })
            }
          }
          // } else {
          //   for (let i = (objIndex - 1); i >= 0; i--) {
          //     if (array['group' + i].filter(el => !el.sauf).length > 0) {
          //       array['group' + i].forEach(item => {
          //         item.group = false;
          //         // delete item.isSaufGroup
          //       });
          //     }
          //   }
        }
      }
      if (key === undefined) {
        for (let i = 0; i <= Object.keys(array).length - 1; i++) {
          array['group' + i].forEach(item => {
            item.group = false;
            delete item.isSaufGroup
          })
        }
      }
    });
    return array;
  }

  saufCheckBoxCondition(type, key) {
    let array = this.characterList['characterListType' + type];
    if (array[key].some(el => el.hasOwnProperty('isSaufGroup'))) {
      return true;
    } else {
      return false;
    }
  }

  checkSauf(num, key, event) {
    event.preventDefault();
    event.stopPropagation();
    let previousIndex, splicedData;
    let currentNumber = parseInt(key.match(/\d/g));
    let currentArray = this.characterList['characterListType' + num];
    let previousNumber = currentNumber - 1;
    let nextNumber = currentNumber + 1;
    if (previousNumber < 0) {
      previousNumber = 0;
    }
    if (nextNumber > Object.keys(currentArray).length) {
      nextNumber = Object.keys(currentArray).length;
    }
    currentArray[key].forEach(item => {
      item.isSaufGroup = !item.isSaufGroup;
      item.group = !item.group;
    });
    if (currentArray[key].filter(el => el.sauf).length > 0) {
      if (currentArray[key][0].isSaufGroup) {
        for (let i = 0; i < currentArray[key].length; i++) {
          previousIndex = i - 1;
          if (previousIndex < 0) {
            previousIndex = i;
          }
          let item = currentArray[key][i];
          if (item.sauf && !currentArray[key][previousIndex].sauf) {
            item.conditionType = '3';
          }
        }
      } else {
        if (currentNumber !== 0 && currentArray[key].filter(el => el.sauf).length > 0) {
          for (let i = 0; i < currentArray[key].length; i++) {
            if (currentArray[key][i].sauf) {
              splicedData = currentArray[key].splice(i, 1);
            } else {
              currentArray[key][i].group = false;
              currentArray[key][i].isSaufGroup = false;
            }
          }
          if (currentArray['group' + previousNumber].filter(el => el.sauf).length > 0) {
            splicedData.forEach(item => {
              item.conditionType = '1';
              item.group = true;
              item.isSaufGroup = true;
            });
          } else {
            splicedData.forEach(item => {
              item.conditionType = '3';
              item.group = true;
              item.isSaufGroup = true;
            });
          }
          if (currentArray['group' + previousNumber].filter(el => !el.sauf).length > 0) {
            currentArray['group' + previousNumber].forEach(el => {
              el.group = true;
              el.isSaufGroup = true;
            })
          }
          splicedData.forEach(item => {
            currentArray['group' + previousNumber].push(item);
          })
        } else {
          currentArray[key].forEach(item => {
            if (item.sauf) {
              item.conditionType = '2';
            }
          });
        }
        currentArray = this.disableSaufCheckBox(currentArray);
      }
    } else {

      let isGroupEnabled = false;
      const selectedGroup = Number(key.replace('group', ''));
      for (const grKey in currentArray) {
        if (Number(grKey.replace('group', '')) > selectedGroup && currentArray[grKey][0].group) {
          isGroupEnabled = true;
          break;
        }
      }

      if (isGroupEnabled && currentArray[key][0].group) {
        for (const grKey in currentArray) {
          if (Number(grKey.replace('group', '')) > selectedGroup) {
            const j = Number(grKey.replace('group', ''));
            if (!currentArray[grKey][0].group) {
              const currentGroup = currentArray[`group${j - 1}`];
              currentArray[`group${j - 1}`] = currentArray[`group${j}`];
              currentArray[`group${j}`] = currentGroup;
            } else {
              break;
            }
          }
        }
      } else if (isGroupEnabled && !currentArray[key][0].group) {
        for (let i = Object.keys(currentArray).length - 1; i >= 0; i--) {
          if (currentArray['group' + i].some(el => el.sauf)) {
            key = i;
            break;
          }
        }
        for (const grKey in currentArray) {
          if (Number(grKey.replace('group', '')) > selectedGroup) {
            const j = Number(grKey.replace('group', ''));
            if (j <= key) {
              const currentGroup = currentArray[`group${j - 1}`];
              currentArray[`group${j - 1}`] = currentArray[`group${j}`];
              currentArray[`group${j}`] = currentGroup;
              if (j >= key) {
                currentGroup.forEach(el => {
                  el.group = false;
                  delete el.isSaufGroup;
                });
              }
            } else {
              break;
            }
          }
        }
      }
    }
    this.requestService.addRequestCharacter(currentArray, this.reqActive, num);
  }

  disableSaufCheckBox(array) {
    let key;
    for (let i = Object.keys(array).length - 1; i >= 0; i--) {
      if (array['group' + i].some(el => el.sauf)) {
        key = i;
        break;
      }
    }
    Object.entries(array).forEach(([keyVal, value], objIndex) => {
      if (objIndex === key) {
        for (let i = objIndex; i >= 0; i--) {
          if (array['group' + i][array['group' + i].length - 1].conditionType === '3' && array['group' + i].length > 1) {
            array['group' + i].forEach(el => {
              el.isSaufGroup = true;
              key = i;
            });
          } else if (array['group' + key].filter(el => !el.group).length > 0) {
            array['group' + i].forEach(el => {
              el.isSaufGroup = false;
              el.group = false;
            });
          }
        }
      }
      if (key < objIndex) {
        for (let i = objIndex; i <= Object.keys(array).length - 1; i++) {
          if (array['group' + i].filter(el => el.sauf).length === 0) {
            array['group' + i].forEach(item => {
              item.group = false;
              delete item.isSaufGroup
            })
          }
        }
      }

    });
    return array;
  }

  getSelectedStoreData(req) {
    if (req) {
      //console.log(req)
      this.selectedTable = req.table;
      this.region = req.region;
      this.groupList = req.regroupment;
      this.populationList = req.populationList;
      this.queryName = req.queryName ? req.queryName : '';
      this.characterList = {
        'characterListType1': { 'group0': [] },
        'characterListType2': { 'group0': [] },
        'characterListType3': { 'group0': [] },
      };
      for (let key in this.characterList) {
        for (let key2 in req.characterList) {
          if (key === key2) {
            this.characterList[key] = req.characterList[key];
          }
        }
      }
      this.vueList = req.vue;
      this.selectedVueIPNDetail = req.vuePrivateIPNData;
    }
    if (typeof (this.groupList) === 'undefined') {
      this.groupList = [];
    }
    if (typeof (this.vueList) === 'undefined') {
      this.vueList = [];
    }
    if (typeof (this.populationList) === 'undefined') {
      this.populationList = [];
    }
    if (typeof (this.characterList) === 'undefined') {
      this.characterList = {
        'characterListType1': { 'group0': [] },
        'characterListType2': { 'group0': [] },
        'characterListType3': { 'group0': [] },
      };
    }
    this.utilService.$favoriteConnecton$.next(this.region);
    this.getFilterCategoryDetails();
    this.getFilterCriteriaListDetails();
    this.getPrivateFilterCategoryDetails();
    this.getPopulationSectionValues();
    this.getPrivateVueDetailsIPNs();
    this.enableExecuteButton();
    this.allowAllHistoricalData();
    this.showHiddenCriteriaList();
  }

  enableExecuteButton() {
    if (this.region === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_exec_req') > -1 && this.getFavoriteRegion() === 'PIL') {
        this.isAllowExecute = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_exec_req') > -1 && this.getFavoriteRegion() === 'PIM') {
        this.isAllowExecute = true;
      }
    }
  }

  allowAllHistoricalData() {
    if (this.region === "Europe") {
      if (this.profile['role-pil-irn6804'].includes('pil_toutes_pop') && this.getFavoriteRegion() === 'PIL') {
        this.isAllowAllHisData = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].includes('pim_toutes_pop') && this.getFavoriteRegion() === 'PIM') {
        this.isAllowAllHisData = true;
      }
    }
  }

  enableAllTextBoxInGrouping() {
    if (this.region === "Europe") {
      //   if (this.role['role-pil-irn6804'].includes('pil_toutes_pop') && this.role['role-pil-irn6804'].includes('pil_dump')
      //     && this.role['role-pil-irn6804'].includes('pil_obj_syst')) {
      //     this.isEnableAllTextBox = true;
      //   }
      // } else {
      //   if (this.role['role-pim-irn9207'].includes('pim_toutes_pop') && this.role['role-pim-irn9207'].includes('pim_dump')
      //     && this.role['role-pim-irn9207'].includes('pim_obj_syst')) {
      //     this.isEnableAllTextBox = true;
      //   }
      // }
      if (this.profile['role-pil-irn6804'].includes('pil_toutes_pop') && this.profile['role-pil-irn6804'].includes('pil_dump')
        && this.profile['role-pil-irn6804'].includes('pil_obj_syst') && this.getFavoriteRegion() === 'PIL') {
        this.isEnableAllTextBox = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].includes('pim_toutes_pop') && this.profile['role-pim-irn9207'].includes('pim_dump')
        && this.profile['role-pim-irn9207'].includes('pim_obj_syst') && this.getFavoriteRegion() === 'PIM') {
        this.isEnableAllTextBox = true;
      }
    }
  }

  showHiddenCriteriaList() {
    if (this.region === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_obj_syst') > -1 && this.getFavoriteRegion() === 'PIL') {
        this.isShowHiddenList = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_obj_syst') > -1 && this.getFavoriteRegion() === 'PIM') {
        this.isShowHiddenList = true;
      }
    }
  }

  getFavoriteRegion() {
    if (this.region === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getFilterCategoryDetails() {
    this.filterCategory = [];
    const queryParam = { "input": `FILTRE@system@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        this.delayName = this.utilService.getTranslateName("NEW_QUERY.DELAY");
        const queryParam1 = { "input": `FILTRE@system@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.filterCategory = res1.lists;
          this.filterCategory = this.filterCategory.sort(this.utilService.sortArray)
        },
          error => {
            //console.log("error", error.message);
          });
      }
    },
      error => {
        //console.log("error", error.message);
      });

  }

  getPrivateFilterCategoryDetails() {
    this.filterPrivateCategory = undefined;
    const queryParam = { "input": `FILTRE@${this.ipn}@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `FILTRE@${this.ipn}@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.filterPrivateCategory = res1.lists;
          this.filterPrivateCategory = this.filterPrivateCategory.sort(this.utilService.sortArray)
        },
          error => {
            //console.log("error", error.message);
          });
      }
    },
      error => {
        //console.log("error", error.message);
      });

  }

  dynamicHeight() {
    let height;
    let populationScreenDiv = document.getElementById('populationscreen');
    if (populationScreenDiv) {
      data.forEach(list => {
        if (list.name === this.selectedTable) {
          height = populationScreenDiv.clientHeight / list.fields.length * (list.fields.length + 1);
        }
      });
      return height;
    }
  }

  getPopulationSectionValues() {
    let all;
    setTimeout(() => {
      all = this.utilService.getTranslateName(`COMMON.ALL`);
      data.forEach(item => {
        if (item.name === this.selectedTable) {
          item.fields.forEach(fields => {
            if (fields.type !== 'textarea' && fields.field_name !== "TCM" && fields.field_name !== "DLC") {
              fields.selectedOption = this.getSelectedValues(fields.field_name, false) ? this.getSelectedValues(fields.field_name, false) : all;
            } else if (fields.type !== 'textarea') {
              fields.selectedOption = this.getSelectedValues(fields.field_name, false) ? this.getSelectedValues(fields.field_name, false) : this.utilService.getTranslateName(`NEW_QUERY.ALLDATA`);
            } else {
              fields.selectedOption = this.getSelectedValues(fields.field_name, false);
            }
            fields.isSelected = false;
            if (fields.type === 'dropdown' && fields.field_name !== "TCM" && fields.field_name !== "DLC") {
              if (typeof (fields.input) !== 'undefined') {
                const queryParam = { "input": `DICO@${this.ipn}${fields.input}`, "region": this.getFavoriteRegion() };
                this.apiService.getObjectSize(queryParam).subscribe(res => {
                  if (res.noObjects > 0) {
                    const queryParam1 = { "input": `DICO@${this.ipn}${fields.input}0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
                    this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
                      fields['options'] = res1.lists;
                      // const sorter = this.utilService.MySort(' ?*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz');
                      fields['options'] = fields['options'].sort(this.utilService.sortArraySpecial);
                    },
                      error => {
                        //console.log("error", error.message);
                      });
                  }
                },
                  error => {
                    //console.log("error", error.message);
                  });
              }
            } else if (fields.field_name === "TCM" || fields.field_name === "DLC") {
              fields['options'] = [this.utilService.getTranslateName(`NEW_QUERY.3MONTHS`),
              this.utilService.getTranslateName(`NEW_QUERY.6MONTHS`),
              this.utilService.getTranslateName(`NEW_QUERY.12MONTHS`),
              this.utilService.getTranslateName(`NEW_QUERY.ALLDATA`)];
            }
          })
        }
      })
    }, 0)
  }

  getSelectedValues(field, getrequest) {
    let selectedValue;
    this.populationList.forEach((list, index) => {
      for (var key in list) {
        if (field === key && !getrequest) {
          selectedValue = list[key];
        } else if (field === key && getrequest) {
          selectedValue = index;
        }
      }
    })
    return selectedValue;
  }

  selectPopulationCriteria(field, item) {
    if (item === '') {
      item = this.utilService.getTranslateName(`COMMON.ALL`);
    } else {
      item = item;
    }
    setTimeout(() => {
      if (field.type !== 'textarea') {
        field.selectedOption = item;
      }
      let index = this.getSelectedValues(field.field_name, true);
      if (index >= 0) {
        this.populationList[index][field.field_name] = field.selectedOption.toUpperCase();
      } else {
        this.populationList.push({ [field.field_name]: field.selectedOption.toUpperCase() });
      }
      this.requestService.addPopulationRequest(this.populationList, this.reqActive);
    }, 0)
  }

  getCriteriaName(label, lang) {
    if (label.split(this.splitChar, 2).length > 1) {
      return label.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
    } else {
      return label;
    }
  }

  editDateVal(name) {
    let dateVal;
    if (name !== 'CONNUE' && name !== 'INCONNUE' && name !== 'Y') {
      dateVal = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${name}_SAVE`);
    } else {
      dateVal = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${name}`);
    }
    return dateVal;
  }

  groupDateLangData() {
    return [{ 'en': 'days', 'fr': 'jours' },
    { 'en': 'day/week./year', 'fr': 'jour/prog./année' },
    { 'en': 'program', 'fr': 'programmes' },
    { 'en': 'industrial month', 'fr': 'mois industriels' },
    { 'en': 'calendar month', 'fr': 'mois calendaires' },
    { 'en': 'year', 'fr': 'année' },
    { 'en': 'flag', 'fr': 'flag' }]
  }
  groupDelayLangData() {
    return [{ 'en': 'Known', 'fr': 'Connue' },
    { 'en': 'Unknown', 'fr': 'Inconnue' },
    { 'en': 'Days', 'fr': 'Jours' },
    { 'en': 'Day', 'fr': 'Jour' },
    { 'en': 'ASL', 'fr': 'ASL' },
    { 'en': 'Program', 'fr': 'programme' },
    { 'en': 'Industrial month', 'fr': 'Mois industriel' },
    { 'en': 'Calendar month', 'fr': 'Mois calendaire' },
    { 'en': 'Year', 'fr': 'Année' },
    { 'en': 'Working days', 'fr': 'Jour ouver' },
    { 'en': 'Week', 'fr': 'Semaine' }]
  }
  languageChanged() {
    let reqData = this.requestStoresData[this.reqActive];
    if (reqData.lang !== this.lang) {
      if (this.criteriaListOringinal) {
        const preLang = reqData.lang;
        reqData.lang = this.lang;
        this.requestService.updateReqDataLang(this.lang, this.reqActive);
        if (reqData.regroupment) {
          this.groupList = reqData.regroupment;
          for (const item of this.groupList) {
            const preName = item.name.substring(0, item.name.indexOf('['));

            // //console.log('prename', preName);
            if (item.type !== 99) {
              // //console.log('prename111', item);
              const filterVal = this.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === item.GCOName.toUpperCase() && a.typeOfField == item.type);
              for (const iterator of filterVal) {
                if (item.type === 3) {
                  let operator = item.name.split('[')[1];
                  operator = operator.slice(0, -1);
                  for (let i = 0; i < this.groupDateLangData().length; i++) {
                    let el = this.groupDateLangData()[i];
                    for (const [key, value] of Object.entries(el)) {
                      if (key !== this.lang) {
                        if (el[key].toLowerCase() === operator.toLowerCase()) {
                          item.name = item.name.replace(operator, el[this.lang]);
                          break;
                        }
                      }
                    }
                  }
                }
                if (item.name.indexOf('[') > -1) {
                  let changeVal;
                  if (this.lang === 'fr') {
                    changeVal = 'Somme';
                  } else {
                    changeVal = 'Sum';
                  }
                  if (item.type === 1) {

                    const enLangValue = ["Compact Code", "All", "Index"];
                    const frLangValue = ["Code Compact", "Tous", "Indice"];
                    const preVal = item.name.substring(item.name.indexOf('[') + 1, item.name.indexOf(']')).split(",")[0];
                    let changeVal;
                    if (enLangValue.includes(preVal) || frLangValue.includes(preVal)) {
                      if (this.lang === 'fr') {
                        changeVal = frLangValue[enLangValue.indexOf(preVal)];
                      } else {
                        changeVal = enLangValue[frLangValue.indexOf(preVal)];
                      }
                      if (typeof (changeVal) !== 'undefined') {
                        item.name = item.name.replace(preVal, changeVal);
                        // //console.log("item.name.indexOf('[')", preVal, changeVal, item.name.substring(item.name.indexOf('[') + 1, item.name.indexOf('[') + 1), item.name.substring(item.name.indexOf('[') + 1, item.name.indexOf(']')));
                      }
                    }
                  } else {
                    let operator = item.name.split('[')[1];
                    operator = operator.slice(0, -1);
                    item.name = item.name.replace(preName, this.getCriteriaName(iterator.longLabel, this.lang));
                    item.name = item.name.replace(operator, changeVal);
                  }
                } else {
                  item.name = this.getCriteriaName(iterator.longLabel, this.lang);
                }
              }
            } else {
              let dateName1, dateName2, dateDelayName1, dateDelayName2;
              dateName1 = item.name.split('-')[0];
              dateName2 = item.name.split('-')[1].substring(0, item.name.split('-')[1].indexOf('['));
              item.name = this.getLangValueChange(item, 'group');
              if (item.delay === 0) {
                dateDelayName1 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName1 : a.longLabel === dateName1)[0]['longLabel'], this.lang);
                dateDelayName2 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName2 : a.longLabel === dateName2)[0]['longLabel'], this.lang);
                // //console.log('delay1111', dateDelayName1, dateDelayName2);
                if (dateDelayName1) {
                  item.name = item.name.replace(dateName1, dateDelayName1);
                  item.name = item.name.replace(dateName2, dateDelayName2);
                }
              } else {
                if (item.delay === 1 || item.delay === 2) {
                  dateDelayName1 = dateName1;
                  dateDelayName2 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName2 : a.longLabel === dateName2)[0]['longLabel'], this.lang);
                } else {
                  dateDelayName1 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName1 : a.longLabel === dateName1)[0]['longLabel'], this.lang);
                  dateDelayName2 = dateName2;
                }
                if (dateDelayName1) {
                  item.name = item.name.replace(dateName1, dateDelayName1);
                  item.name = item.name.replace(dateName2, dateDelayName2);
                }
              }
              // //console.log('prelang', dateDelayName1);

            }
            this.requestService.addRequestReGroupment(this.groupList, this.reqActive);
          }
        }
        if (reqData.vue) {
          this.vueList = reqData.vue;
          for (const item of this.vueList) {
            const preName = item.name.substring(0, item.name.indexOf('['));
            const filterVal = this.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === item.GCOName.toUpperCase() && a.typeOfField == item.type);
            for (const iterator of filterVal) {
              if (item.name.indexOf('[') > -1) {
                if (item.type === 3) {
                  let operator = item.name.split('[')[1];
                  operator = operator.slice(0, -1);
                  for (let i = 0; i < this.groupDateLangData().length; i++) {
                    let el = this.groupDateLangData()[i];
                    for (const [key, value] of Object.entries(el)) {
                      if (key !== this.lang) {
                        if (el[key].toLowerCase() === operator.toLowerCase()) {
                          item.name = item.name.replace(operator, el[this.lang]);
                        }
                      }
                    }
                  }
                }
                if (item.type === 1) {

                  const enLangValue = ["Compact Code", "All", "Index"];
                  const frLangValue = ["Code Compact", "Tous", "Indice"];
                  const preVal = item.name.substring(item.name.indexOf('[') + 1, item.name.indexOf(']')).split(",")[0];
                  let changeVal;
                  if (this.lang === 'fr') {
                    changeVal = frLangValue[enLangValue.indexOf(preVal)];
                  } else {
                    changeVal = enLangValue[frLangValue.indexOf(preVal)];
                  }
                  if (typeof (changeVal) !== 'undefined') {
                    item.name = item.name.replace(preVal, changeVal);
                    // //console.log("item.name.indexOf('[')", preVal, changeVal, item.name.substring(item.name.indexOf('[') + 1, item.name.indexOf('[') + 1), item.name.substring(item.name.indexOf('[') + 1, item.name.indexOf(']')));
                  }
                }
                item.name = item.name.replace(preName, this.getCriteriaName(iterator.longLabel, this.lang));
              } else {
                item.name = this.getCriteriaName(iterator.longLabel, this.lang);
              }
            }
            this.requestService.addRequestVue(this.vueList, this.reqActive);
          }
        }

        if (reqData.characterList) {
          this.characterList = reqData.characterList;
          // //console.log('iterator', this.characterList);

          let k = 0;
          for (let key in this.characterList) {
            k = k + 1;
            if (key !== 'characterListType3') {
              for (let subKey1 in this.characterList[key]) {
                // //console.log('key1', subKey1);
                for (let subKey2 in this.characterList[key][subKey1]) {
                  const preName = this.characterList[key][subKey1][subKey2].name.substring(0, this.characterList[key][subKey1][subKey2].name.indexOf('['));
                  // //console.log('key2', this.characterList[key][subKey1][subKey2]);

                  const filterVal = this.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === this.characterList[key][subKey1][subKey2].GCOName.toUpperCase() && a.typeOfField == this.characterList[key][subKey1][subKey2].type);
                  if (this.getLangValueChange(this.characterList[key][subKey1][subKey2], 'group')) {
                    this.characterList[key][subKey1][subKey2].name = this.getLangValueChange(this.characterList[key][subKey1][subKey2], 'group');
                  }
                  if (preName !== '') {
                    for (const iterator of filterVal) {
                      this.characterList[key][subKey1][subKey2].name = this.characterList[key][subKey1][subKey2].name.replace(preName, this.getCriteriaName(iterator.longLabel, this.lang));
                    }
                  } else {
                    for (const iterator of filterVal) {
                      this.characterList[key][subKey1][subKey2].name = this.getCriteriaName(iterator.longLabel, this.lang);
                    }
                  }
                }
              }
            } else {
              for (let subKey1 in this.characterList[key]) {
                // //console.log('key1Delay', subKey1);
                for (let subKey2 in this.characterList[key][subKey1]) {
                  // //console.log('key2Delay', this.characterList[key][subKey1][subKey2]);



                  let dateName1, dateName2, dateDelayName1, dateDelayName2;

                  dateName1 = this.characterList[key][subKey1][subKey2].name.split('-')[0];
                  dateName2 = this.characterList[key][subKey1][subKey2].name.split('-')[1];
                  this.characterList[key][subKey1][subKey2].value = this.getLangValueChange(this.characterList[key][subKey1][subKey2], 'condition');
                  // //console.log('delay', dateName1, dateName2);
                  if (this.characterList[key][subKey1][subKey2].delay === 0) {
                    dateDelayName1 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName1 : a.longLabel === dateName1)[0]['longLabel'], this.lang);
                    dateDelayName2 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName2 : a.longLabel === dateName2)[0]['longLabel'], this.lang);
                    // //console.log('delay1111', dateDelayName1, dateDelayName2);
                    if (dateDelayName1) {
                      this.characterList[key][subKey1][subKey2].name = this.characterList[key][subKey1][subKey2].name.replace(dateName1, dateDelayName1);
                      this.characterList[key][subKey1][subKey2].name = this.characterList[key][subKey1][subKey2].name.replace(dateName2, dateDelayName2);
                    }
                  } else {
                    if (this.characterList[key][subKey1][subKey2].delay === 1 || this.characterList[key][subKey1][subKey2].delay === 2) {
                      dateDelayName1 = dateName1;
                      dateDelayName2 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName2 : a.longLabel === dateName2)[0]['longLabel'], this.lang);
                    } else {
                      dateDelayName1 = this.getCriteriaName(this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(preLang)] === dateName1 : a.longLabel === dateName1)[0]['longLabel'], this.lang);
                      dateDelayName2 = dateName2;
                    }
                    if (dateDelayName1) {
                      this.characterList[key][subKey1][subKey2].name = this.characterList[key][subKey1][subKey2].name.replace(dateName1, dateDelayName1);
                      this.characterList[key][subKey1][subKey2].name = this.characterList[key][subKey1][subKey2].name.replace(dateName2, dateDelayName2);
                    }
                  }
                  // //console.log('prelang', dateDelayName1);


                }
              }



            }

            this.requestService.addRequestCharacter(this.characterList[key], this.reqActive, k);

          }
        }

      }
    }
  }

  getLangValueChange(item, type) {
    if (item.name.includes('[') && type !== 'condition') {
      let operator = item.name.split('[')[1];
      operator = operator.slice(0, -1);
      // //console.log('delay', dateName1, dateName2);
      for (let i = 0; i < this.groupDelayLangData().length; i++) {
        let el = this.groupDelayLangData()[i];
        for (const [key, value] of Object.entries(el)) {
          if (key !== this.lang) {
            if (el[key].toLowerCase() === operator.toLowerCase()) {
              item.name = item.name.replace(operator, el[this.lang]);
            }
          }
        }
      }
      return item.name;
    } else if (type === 'condition') {
      for (let i = 0; i < this.groupDelayLangData().length; i++) {
        let el = this.groupDelayLangData()[i];
        for (const [key, value] of Object.entries(el)) {
          if (key !== this.lang) {
            let operator = item.value.split(' ');
            if (operator[3] && isNaN(operator[3]) && operator.length > 2) {
              if (el[key].toLowerCase() === `${operator[2]} ${operator[3]}`.toLowerCase()) {
                item.value = item.value.replace(`${operator[2]} ${operator[3]}`, el[this.lang]);
              }
            } else if (el[key].toLowerCase() === operator[2].toLowerCase()) {
              item.value = item.value.replace(operator[2], el[this.lang]);
            }
          }
        }
      }
      return item.value;
    }
  }

  getFilterCriteriaListDetails() {
    this.displayCriteriaList = [];
    this.criteriaList = [];
    const queryParam = { "input": `CHAMP ETENDU@${this.ipn}@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `CHAMP ETENDU@${this.ipn}@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.criteriaListOringinal = res1.lists;
          this.selectedQuery = this.requestStoresData[this.requestStoresData.length - 1].selectedQuery;
          let roleQuery = this.requestStoresData[this.requestStoresData.length - 1].roleBasedQuery;
          this.enableAllTextBoxInGrouping();
          if (roleQuery !== undefined && roleQuery !== '') {
            this.roleBasedQuery = roleQuery;
          } else {
            this.roleBasedQuery = '';
          }
          for (const item of this.criteriaListOringinal) {
            if (typeof (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]) === 'undefined') {
              item.longLabel = `${item.longLabel}${this.splitChar}${item.longLabel}`;

              if (this.isShowHiddenList) {
                this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              } else {
                if (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)][0] !== '_') {
                  this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                  this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                }
              }


            } else {
              // this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              // this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);

              if (this.isShowHiddenList) {
                this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              } else {
                if (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)][0] !== '_') {
                  this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                  this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                }
              }

            }
          }
          if (this.selectedQuery !== '' && this.selectedQuery !== undefined) {
            this.formSelectedQueryRequest();
          }
          this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);
          this.languageChanged();

          //Load Delay Data
          this.loadDelayData();
        },
          error => {
            //console.log("error", error.message);
          });
      }
    },
      error => {
        //console.log("error", error.message);
      });
  }

  getSearchCategory(ipnValue) {
    this.searchText = undefined;
    this.categoryList = [];
    this.displayCriteriaList = [];
    this.criteriaList = [];
    if (this.selectedCategory === this.utilService.getTranslateName(`COMMON.ALL`)) {
      for (const item of this.criteriaListOringinal) {
        // if (typeof (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]) !== 'undefined') {
        //   this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)])
        //   this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)])
        // }

        if (typeof (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]) === 'undefined') {
          item.longLabel = `${item.longLabel}${this.splitChar}${item.longLabel}`;

          if (this.isShowHiddenList) {
            this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
            this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
          } else {
            if (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)][0] !== '_') {
              this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
            }
          }


        } else {
          // this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
          // this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);

          if (this.isShowHiddenList) {
            this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
            this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
          } else {
            if (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)][0] !== '_') {
              this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
            }
          }

        }


      }
      this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);
    } else {
      const queryParam = { "input": `FILTRE@${ipnValue}@${this.selectedTable}@${this.selectedCategory}@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectGet(queryParam).subscribe(res => {

        const dataRes = res.data.split('@');
        for (let i = 0; i < dataRes.length; i = i + 4) {
          if (i > 0) {
            this.categoryList.push(dataRes[i - 1]);
          }
        }

        for (const iterator of this.categoryList) {
          const filterVal = this.criteriaListOringinal.filter(a => a.GCOName.toUpperCase() === iterator.substring(0, iterator.length - 2).toUpperCase() && a.typeOfField == iterator.slice(-1));
          for (const item of filterVal) {
            // if (typeof (iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]) !== 'undefined') {
            //   this.displayCriteriaList.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
            //   this.criteriaList.push(iterator.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
            // }

            if (typeof (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]) === 'undefined') {
              item.longLabel = `${item.longLabel}${this.splitChar}${item.longLabel}`;

              if (this.isShowHiddenList) {
                this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              } else {
                if (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)][0] !== '_') {
                  this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                  this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                }
              }


            } else {
              // this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              // this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);

              if (this.isShowHiddenList) {
                this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
              } else {
                if (item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)][0] !== '_') {
                  this.displayCriteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                  this.criteriaList.push(item.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)]);
                }
              }

            }

          }
        }
        this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);

      },
        error => {
          //console.log("error", error.message);
        });
    }
  }

  onCategoryChange(val, type) {
    if (val === '') {
      this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    } else {
      this.selectedCategory = val;
    }
    if (type === 'private') {
      this.getSearchCategory(this.ipn);
    } else {
      this.getSearchCategory('system');
    }
  }

  getSelectedList(selectedItem) {
    if (selectedItem === this.utilService.getTranslateName(`COMMON.ALL`)) {
      return true;
    } else {
      return false;
    }
  }
  searchFilter() {
    this.displayCriteriaList = this.criteriaList.filter(a => a.toUpperCase().includes(this.searchText.toUpperCase()));
    this.displayCriteriaList = this.displayCriteriaList.sort(this.utilService.sortArray);
  }

  requestClose(req, index) {
    this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`ERROR.CLOSE_QUERY`), 'isText': false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isExecute = false;
        if (this.requestStoresData.length === 0) {
          localStorage.removeItem("region")
          this.utilService.$favoriteConnecton$.next("");

          const pathName = window.location.pathname;
          this.utilService.removeOpenedWindowName(pathName);
          const openedWindowNameList = this.utilService.getOpenedWindowNameList()
          if (openedWindowNameList.length > 0) {
            this.requestStoresData = this.requestService.getAllRequestData();
            if (this.requestStoresData.length === 0) {
              this.utilService.$showQuickLink$.next(false);
            }
            this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
          } else {
            this.utilService.$showQuickLink$.next(false);
            this.router.navigateByUrl('/home');
          }
          // this.router.navigateByUrl('/home');
        } else {
          this.saveExecuteQuery('close-tab', index);
        }
      } else {
        this.isExecute = false;
        this.selectedTable = undefined;
        this.requestStoresData.splice(index, 1);
        this.requestService.removeRequestData(index + 1);
        this.reqActive = this.requestStoresData.length - 1;
        if ((this.requestStoresData.length < 4)) {
          this.getSelectedStoreData(this.requestStoresData[this.requestStoresData.length - 1]);
        }
        if (this.requestStoresData.length === 0) {
          localStorage.removeItem("region")
          this.utilService.$favoriteConnecton$.next("");

          const pathName = window.location.pathname;
          this.utilService.removeOpenedWindowName(pathName);
          const openedWindowNameList = this.utilService.getOpenedWindowNameList()
          if (openedWindowNameList.length > 0) {
            this.requestStoresData = this.requestService.getAllRequestData();
            if (this.requestStoresData.length === 0) {
              this.utilService.$showQuickLink$.next(false);
            }
            this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
          } else {
            this.utilService.$showQuickLink$.next(false);
            this.router.navigateByUrl('/home');
          }
          // this.router.navigateByUrl('/home');
        }
      }
    });
  }

  getSelectedRequest(req, index) {
    if (this.reqActive !== index) {
      this.resetAllValues();
      this.reqActive = index;
      this.selectedTable = undefined;
      this.selectedCategory = this.utilService.getTranslateName(`COMMON.ALL`);
      this.requestStoresData = this.requestService.getAllRequestData();
      this.getSelectedStoreData(this.requestStoresData[index]);
    }
  }

  moveUp(id, element, section_id) {
    if (this.groupingSelectedList.length > 0) {
      let array, request;
      if (element === 'groupList') {
        array = this.groupList;
        request = 'addRequestReGroupment';
      } else {
        array = this.vueList;
        request = 'addRequestVue';
      }
      document.getElementById(id).scrollBy(0, -10);
      var selectList = document.getElementById(section_id);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      for (var i = 0; i < array.length; i++) {
        if (i > 0) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let old_value = array.splice(i, 1)[0];
            array.splice(i - 1, 0, old_value);
          }
        } else if (i === 0 && selectOptions[i].getAttribute('aria-selected') === 'true') {
          let old_value = array.splice(i, 1)[0];
          array.splice(i, 0, old_value);
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click();
        }
      }
      this.requestService[request](array, this.reqActive)
    }
  }

  moveDown(id, element, section_id) {
    if (this.groupingSelectedList.length > 0) {
      let array, request;
      if (element === 'groupList') {
        array = this.groupList;
        request = 'addRequestReGroupment';
      } else {
        array = this.vueList;
        request = 'addRequestVue';
      }
      document.getElementById(id).scrollBy(0, 10);
      var selectList = document.getElementById(section_id);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      for (var i = array.length - 1; i >= 0; i--) {
        if (i < array.length - 1) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let old_value = array.splice(i, 1)[0];
            array.splice(i + 1, 0, old_value);
          }
        } else if (i === array.length - 1 && selectOptions[i].getAttribute('aria-selected') === 'true') {
          let old_value = array.splice(i, 1)[0];
          array.splice(i, 0, old_value);
          let div = selectOptions[i].getAttribute('id');
          document.getElementById(div).click();
        }
      }
      this.requestService[request](array, this.reqActive)
    }
  }

  move(arr, new_index, old_index) {
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  moveUpConditionBlock(id) {
    if (this.groupingSelectedList.length > 0) {
      var selectList = document.getElementById('characterList_' + this.selectedCharacterNumber);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      let array = this.characterList['characterListType' + this.selectedCharacterNumber];
      let i = 0;
      let selectedIndex;
      let selectedId;
      let isGroup = false;
      let isSAUF = false;
      for (let key in array) {
        for (let j = 0; j < array[key].length; j++) {
          const item = array[key][j]
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            document.getElementById(id).scrollBy(0, -75);
            selectedIndex = Number(key.replace('group', ''));
            // selectedId = selectOptions[i].getAttribute('id');

            if (item['group']) {
              isSAUF = true;
            } else {
              if (array[key].length > 1 && array[key][array[key].length - 1].conditionType !== "1") {
                isSAUF = true;
              }
            }

            break;
          }
          i += 1;
        }
        if (selectedIndex >= 0) {
          break;
        }
      }

      let arrangedArray = new Array;
      let arrayInd = 0;
      let currentElement;
      for (let key in array) {

        // if (selectedIndex === 0) {

        //   for (let j = 0; j < array[key].length; j++) {
        //     if (selectOptions[i].getAttribute('aria-selected') === 'true') {
        //       let div = selectOptions[i].getAttribute('id');
        //       document.getElementById(div).click();
        //       break;
        //     }
        //   }
        //   this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
        //   return
        // }

        if (!isSAUF) {
          // //console.log('arrangedArray1', arrangedArray, selectedIndex);
          if (arrayInd + 1 === selectedIndex) {
            if (array[key][0].group && !array[`group${selectedIndex}`][0].group) {
              for (let index = selectedIndex; index >= 0; index--) {
                if (array[`group${index - 1}`]) {
                  const element1 = array[`group${index - 1}`];
                  if (element1[0].group) {
                    // //console.log('h22222', index, selectedIndex)
                    array[`group${index - 1}`] = new Array;
                    array[`group${index - 1}`] = array[`group${index}`];

                    if (element1.length === 1) {
                      if (element1[0].sauf) {
                        element1[0].conditionType = "2";
                      }
                    }

                    // if (element1[element1.length - 1].sauf) {
                    //   element1[element1.length - 1].conditionType = 2;
                    // }

                    array[`group${index}`] = new Array;
                    array[`group${index}`] = element1;
                  } else {
                    // //console.log('hillll', index, selectedIndex, element1, array[`group${index}`])
                    const updateElement1 = array[`group${index}`];
                    // updateElement1[0].isSaufGroup = false;
                    for (let b = 0; b < updateElement1.length; b++) {
                      updateElement1[b].isSaufGroup = false;
                    }
                    array[`group${index}`] = new Array;
                    array[`group${index}`] = updateElement1;
                    this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
                    index = -1;
                    break;
                  }
                } else {
                  const updateElement1 = array[`group${index}`];
                  for (let b = 0; b < updateElement1.length; b++) {
                    updateElement1[b].isSaufGroup = false;
                    // //console.log('updateElemen222', updateElement1[b])
                  }
                  this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
                  // return;
                }
              }
            } else {
              currentElement = array[key];

              const updateElement1 = array[`group${selectedIndex}`];
              // if (array[key][array[key].length - 1].sauf){
              if (this.saufCheckBoxCondition(this.selectedCharacterNumber, key)) {
                // updateElement1[0].isSaufGroup = false;
                for (let b = 0; b < updateElement1.length; b++) {
                  updateElement1[b].isSaufGroup = false;
                }
              }

              array[key] = new Array;
              array[key] = updateElement1; // array[`group${selectedIndex}`];

              if (currentElement.length === 1) {
                if (currentElement[0].sauf) {
                  currentElement[0].conditionType = "2";
                }
              }

              // if (currentElement[currentElement.length - 1].sauf) {
              //   currentElement[currentElement.length - 1].conditionType = 2;
              // }

              array[`group${selectedIndex}`] = new Array;
              array[`group${selectedIndex}`] = currentElement;
              // //console.log('arrangedArraysss', array, selectedIndex);
              this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);

              // if (Number(key.replace('group', '')) === 0) {
              //   for (let j = 0; j < array[key].length; j++) {
              //     if (selectOptions[i].getAttribute('aria-selected') === 'true') {
              //       let div = selectOptions[i].getAttribute('id');
              //       document.getElementById(div).click();
              //     }
              //   }
              // }

              break;
            }
          }
        } else {
          // //console.log('arrangedArray2', arrangedArray, selectedIndex);
          if (arrayInd === selectedIndex) {
            if (array[`group${selectedIndex}`][0].group || array[`group${selectedIndex}`][array[`group${selectedIndex}`].length - 1].sauf) {
              this.toastyService.warning(this.utilService.getTranslateName(`ERROR.MOVE_BLOCK`));
              // //console.log("array[key][0]hasown", array[key][0])
              // for (let j = 0; j < array[key].length; j++) {
              //   if (selectOptions[i].getAttribute('aria-selected') === 'true') {
              //     let div = selectOptions[i].getAttribute('id');
              //     document.getElementById(div).click();
              //   }
              // }
            } else {
              // //console.log("array[key][0]", key, array[`group${selectedIndex}`].length, array[key])
              if (array[key][0].group && array[`group${selectedIndex}`][0].group) {
                currentElement = array[key];
                array[key] = new Array;
                array[key] = array[`group${selectedIndex}`];

                if (currentElement.length === 1) {
                  if (currentElement[0].sauf) {
                    currentElement[0].conditionType = "2";
                  }
                }

                // if (currentElement[currentElement.length - 1].sauf) {
                //   currentElement[currentElement.length - 1].conditionType = 2;
                // }

                array[`group${selectedIndex}`] = new Array;
                array[`group${selectedIndex}`] = currentElement;
                // //console.log('arrangedArrayfff', array, selectedIndex);
                this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
                break;
              } else {
                for (let j = 0; j < array[key].length; j++) {
                  if (selectOptions[i].getAttribute('aria-selected') === 'true') {
                    let div = selectOptions[i].getAttribute('id');
                    document.getElementById(div).click();
                  }
                }
              }
            }
          }
        }
        arrayInd += 1;
      }
      if (selectedIndex === 0) {
        // for (let j = 0; j < array[`group${selectedIndex}`].length; j++) {
        //   if (selectOptions[selectedIndex].getAttribute('aria-selected') === 'true') {
        //     let div = selectOptions[selectedIndex].getAttribute('id');
        //     document.getElementById(div).click();
        //   }
        // }
        for (let i = 0; i < selectOptions.length; i++) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let div = selectOptions[i].getAttribute('id');
            document.getElementById(div).click()
          }
        }
      }
      // let indx;
      setTimeout(() => {
        let selectedIndex = this.selectedIndexForMove.split('');
        selectedIndex[1] = parseInt(selectedIndex[1]) - 1 > 0 ? parseInt(selectedIndex[1]) - 1 : 0;
        this.selectedIndexForMove = selectedIndex.join('');
        this.selectedId = 'character_data_' + selectedIndex.join('');
        for (let i = 0; i < selectOptions.length; i++) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let div = selectOptions[i].getAttribute('id');
            if (div !== this.selectedId && selectOptions[i].getAttribute('aria-selected') === 'true') {
              // selectOptions[i].setAttribute('aria-selected', 'false');
              document.getElementById(div).click();
            }
          }
        }
        this.selectedId = undefined;
      }, 0);
    }
  }

  deSelectSelectedOptions(list) {
    let selectedNumber = this.selectedCharacterNumber;
    [1, 2, 3].forEach(item => {
      if (item !== this.selectedCharacterNumber) {
        var selectList = document.getElementById('characterList_' + item);
        var selectOptions = selectList.getElementsByTagName('mat-list-option');
        let array = this.characterList['characterListType' + item];
        if (list === 1) {
          for (var i = 0; i < array.length; i++) {
            if (selectOptions[i].getAttribute('aria-selected') === 'true') {
              let div = selectOptions[i].getAttribute('id');
              document.getElementById(div).click();
              this.selectedCharacterNumber = selectedNumber;
            }
          }
        } else {
          for (var i = array.length - 1; i >= 0; i--) {
            if (selectOptions[i].getAttribute('aria-selected') === 'true') {
              let div = selectOptions[i].getAttribute('id');
              document.getElementById(div).click();
              this.selectedCharacterNumber = selectedNumber;
            }
          }
        }
      }
    })
  }

  moveDownConditionBlock(id) {
    if (this.groupingSelectedList.length > 0) {
      var selectList = document.getElementById('characterList_' + this.selectedCharacterNumber);
      var selectOptions = selectList.getElementsByTagName('mat-list-option');
      let array = this.characterList['characterListType' + this.selectedCharacterNumber];
      let i = 0;
      let selectedIndex, selectedId;
      let isGroup = false;
      let isSAUF = false;
      for (let key in array) {
        for (let j = 0; j < array[key].length; j++) {
          const item = array[key][j]
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            document.getElementById(id).scrollBy(0, 75);
            selectedIndex = Number(key.replace('group', ''));
            // selectedId = selectOptions[i].getAttribute('id');

            if (item['group']) {
              isSAUF = true;
            } else {
              // if (this.saufCheckBoxCondition(this.selectedCharacterNumber, key)) {
              if (array[key].length > 1 && array[key][array[key].length - 1].conditionType !== "1") {
                isSAUF = true;
              }
            }

            break;
          }
          i += 1;
        }
        if (selectedIndex >= 0) {
          break;
        }
      }

      let arrangedArray = new Array;
      let arrayInd = 0;
      let currentElement;
      for (let key in array) {
        // //console.log('selectedIndex', selectedIndex, array[`group${selectedIndex + 1}`])

        // if (!array[`group${selectedIndex + 1}`]) {

        //   for (let j = 0; j < array[key].length; j++) {
        //     if (selectOptions[i].getAttribute('aria-selected') === 'true') {
        //       let div = selectOptions[i].getAttribute('id');
        //       document.getElementById(div).click();
        //     }
        //   }
        //   this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
        //   return
        // }


        if (!isSAUF) {
          if (array[`group${Number(key.replace('group', '')) + 1}`]) {
            if (arrayInd === selectedIndex) {
              // //console.log('fffffffffffffffffff', `group${Number(key.replace('group', '')) + 1}`, selectedIndex)
              if (array[`group${Number(key.replace('group', '')) + 1}`][0].group && !array[`group${selectedIndex}`][0].group) {
                for (let index = selectedIndex; index <= 1000; index++) {
                  if (array[`group${index + 1}`]) {
                    const element1 = array[`group${index + 1}`];
                    if (element1[0].group) {
                      // //console.log('h22222', index, selectedIndex)
                      array[`group${index + 1}`] = new Array;
                      array[`group${index + 1}`] = array[`group${index}`];
                      array[`group${index}`] = new Array;
                      array[`group${index}`] = element1;
                    } else {
                      // //console.log('hillll', index, selectedIndex, element1, array[`group${index}`])
                      const updateElement1 = array[`group${index}`];
                      if (!element1[0].hasOwnProperty("isSaufGroup")) {
                        for (let b = 0; b < updateElement1.length; b++) {
                          delete updateElement1[b]['isSaufGroup']
                          // //console.log('updateElement1', updateElement1[b])
                        }
                      }
                      array[`group${index}`] = new Array;
                      array[`group${index}`] = updateElement1;
                      this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
                      index = -1;
                      break;
                    }
                  } else {
                    // for (let j = 0; j < array[key].length; j++) {
                    //   if (selectOptions[i].getAttribute('aria-selected') === 'true') {
                    //     let div = selectOptions[i].getAttribute('id');
                    //     document.getElementById(div).click();
                    //   }
                    // }


                    for (let i = 0; i < selectOptions.length; i++) {
                      if (selectOptions[i].getAttribute('aria-selected') === 'true') {
                        selectedId = selectOptions[i].getAttribute('id');
                      }
                    }
                    setTimeout(() => {
                      for (let i = 0; i < selectOptions.length; i++) {
                        if (selectOptions[i].getAttribute('aria-selected') === 'true') {
                          let div = selectOptions[i].getAttribute('id');
                          if (selectedId !== div) {
                            document.getElementById(div).click()
                            // selectOptions[i].setAttribute('aria-selected', 'false');
                          }
                        }
                      }
                    }, 0)


                    const updateElement1 = array[`group${index}`];
                    for (let b = 0; b < updateElement1.length; b++) {
                      delete updateElement1[b]['isSaufGroup']
                      // //console.log('updateElement111', updateElement1[b])
                    }
                    this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
                    // return;
                  }
                }
              } else {
                const checkElement = array[`group${Number(key.replace('group', '')) + 2}`];
                currentElement = array[`group${Number(key.replace('group', '')) + 1}`];

                const updateElement1 = array[`group${selectedIndex}`];
                if (typeof (checkElement) !== 'undefined') {
                  if (!checkElement[0].hasOwnProperty("isSaufGroup")) {
                    for (let b = 0; b < updateElement1.length; b++) {
                      delete updateElement1[b]['isSaufGroup']
                      // //console.log('updateElement1', updateElement1[b])
                    }
                  }
                } else {
                  for (let b = 0; b < updateElement1.length; b++) {
                    delete updateElement1[b]['isSaufGroup']
                    // //console.log('updateElement1', updateElement1[b])
                  }
                }

                array[`group${Number(key.replace('group', '')) + 1}`] = new Array;
                array[`group${Number(key.replace('group', '')) + 1}`] = array[`group${selectedIndex}`];
                array[key] = new Array;
                array[key] = currentElement;
                // //console.log('arrangedArraysss', key, array, selectedIndex);
                this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);

                // if (Number(key.replace('group', '')) === 0) {
                //   for (let j = 0; j < array[key].length; j++) {
                //     if (selectOptions[i].getAttribute('aria-selected') === 'true') {
                //       let div = selectOptions[i].getAttribute('id');
                //       document.getElementById(div).click();
                //     }
                //   }
                // }

                break;
              }
            }
          }
        } else {
          if (array[`group${Number(key.replace('group', '')) + 1}`]) {
            // //console.log('arrangedArray2', arrangedArray, selectedIndex);
            if (arrayInd === selectedIndex) {
              if (array[`group${selectedIndex}`][0].group || array[`group${selectedIndex}`][array[`group${selectedIndex}`].length - 1].sauf) {
                this.toastyService.warning(this.utilService.getTranslateName(`ERROR.MOVE_BLOCK`));
                // //console.log("array[key][0]hasown", array[key][0])
                // for (let j = 0; j < array[key].length; j++) {
                //   if (selectOptions[i].getAttribute('aria-selected') === 'true') {
                //     let div = selectOptions[i].getAttribute('id');
                //     document.getElementById(div).click();
                //   }
                // }
              } else {
                // //console.log("array[key][0]", key, array[`group${selectedIndex}`].length, array[key])
                if (array[`group${Number(key.replace('group', '')) + 1}`][0].group && array[`group${selectedIndex}`][0].group) {
                  currentElement = array[`group${Number(key.replace('group', '')) + 1}`];
                  array[`group${Number(key.replace('group', '')) + 1}`] = new Array;
                  array[`group${Number(key.replace('group', '')) + 1}`] = array[`group${selectedIndex}`];
                  array[key] = new Array;
                  array[key] = currentElement;
                  // //console.log('arrangedArrayfff', array, selectedIndex);
                  this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
                  break;
                } else {
                  for (let j = 0; j < array[key].length; j++) {
                    if (selectOptions[i].getAttribute('aria-selected') === 'true') {
                      let div = selectOptions[i].getAttribute('id');
                      document.getElementById(div).click();
                    }
                  }

                }
              }
            }
          }
        }
        arrayInd += 1;
      }

      if (arrayInd - 1 === selectedIndex) {
        // for (let j = 0; j < array[`group${selectedIndex}`].length; j++) {
        //   if (selectOptions[selectedIndex].getAttribute('aria-selected') === 'true') {
        //     let div = selectOptions[selectedIndex].getAttribute('id');
        //     document.getElementById(div).click();
        //   }
        // }
        for (let i = 0; i < selectOptions.length; i++) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let div = selectOptions[i].getAttribute('id');
            document.getElementById(div).click()
          }
        }
      }
      let indx;
      setTimeout(() => {
        let selectedIndex = this.selectedIndexForMove.split('');
        selectedIndex[1] = parseInt(selectedIndex[1]) + 1 < Object.keys(array).length ? parseInt(selectedIndex[1]) + 1 : Object.keys(array).length - 1;
        this.selectedIndexForMove = selectedIndex.join('');
        this.selectedId = 'character_data_' + selectedIndex.join('');
        for (let i = 0; i < selectOptions.length; i++) {
          if (selectOptions[i].getAttribute('aria-selected') === 'true') {
            let div = selectOptions[i].getAttribute('id');
            if (div !== this.selectedId && selectOptions[i].getAttribute('aria-selected') === 'true') {
              // selectOptions[i].setAttribute('aria-selected', 'false');
              document.getElementById(div).click();
            }
          }
        }
        this.selectedId = undefined;
      }, 0);
    }
  }

  // moveDownConditionBlock(id) {
  //   document.getElementById(id).scrollBy(0, 10);
  //   var selectList = document.getElementById('characterList_' + this.selectedCharacterNumber);
  //   var selectOptions = selectList.getElementsByTagName('mat-list-option');
  //   let array = this.characterList['characterListType' + this.selectedCharacterNumber];
  //   for (var i = array.length - 1; i >= 0; i--) {
  //     if (i < array.length - 1) {
  //       if (selectOptions[i].getAttribute('aria-selected') === 'true') {
  //         let old_value = array.splice(i, 1)[0];
  //         array.splice(i + 1, 0, old_value);
  //       }
  //     } else if (i === array.length - 1 && selectOptions[i].getAttribute('aria-selected') === 'true') {
  //       let old_value = array.splice(i, 1)[0];
  //       array.splice(i, 0, old_value);
  //       let div = selectOptions[i].getAttribute('id');
  //       document.getElementById(div).click();
  //     }
  //   }
  //   setTimeout(() => {
  //     this.deSelectSelectedOptions(-1);
  //   }, 0);
  //   this.requestService.addRequestCharacter(array, this.reqActive, this.selectedCharacterNumber);
  // }

  displayGrouping() {
    if (this.selectedItemType === 1) {
      this.displayGroupingForm = true;
    }
    return this.displayGroupingForm;
  }

  updateSomme(event) {
    const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === this.selectedGroupItemValue : a.longLabel === this.selectedGroupItemValue)[0];
    if (!event.blnCancel) {
      if (this.groupSection) {
        if (!this.blnMoveRight) {
          this.groupList[this.groupingEditIndex].name = this.selectedGroupItemValue + `${event.value ? `[${this.utilService.getTranslateName('COMMON.SUM')}]` : ''}`;
          this.requestService.addRequestReGroupment(this.groupList, this.reqActive)
        } else {
          this.groupList.push({ "name": this.selectedGroupItemValue, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, "size": criteriaFilterVal.size });
          this.requestService.addRequestReGroupment(this.groupList, this.reqActive)
        }
      } else if (this.vueSection) {
        if (!this.blnMoveRight) {
          this.vueList[this.vueEditIndex].name = this.selectedGroupItemValue + `${event.value ? `[${this.utilService.getTranslateName('COMMON.SUM')}]` : ''}`;
          this.requestService.addRequestVue(this.vueList, this.reqActive)
        } else {
          this.vueList.push({ "name": this.selectedGroupItemValue, "type": this.selectedItemType, 'GCOName': criteriaFilterVal.GCOName, "size": criteriaFilterVal.size, "visible": true });
          this.requestService.addRequestVue(this.vueList, this.reqActive)
        }
      }
    }
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.selectedGroupItemValue = undefined;
    this.blnMoveRight = false;
    this.groupingEditValue = undefined;
    this.vueEditValue = undefined;
    this.selectedGroupItemIndex = undefined;
  }

  checkPopulationEmpty() {
    this.isCheckPopulationEmpty = true;
    // this.isExecute = false;
    if (!this.isAllowAllHisData) {
      for (let elem of this.dataList) {
        if (elem.name === this.selectedTable) {
          for (const items of elem.fields) {
            const selOption = items.selectedOption;
            if (selOption === 'ALL' || selOption === 'TOUS'
              || selOption === '' || typeof (selOption) === 'undefined'
              || selOption === 'ALL HISTORIC DATA'
              || selOption === `TOUT L'HISTORIQUE`
              || selOption === 'All' || selOption === 'Tous'
              || selOption === '' || typeof (selOption) === 'undefined'
              || selOption === 'All Historic Data'
              || selOption === `Tout l'historique`) {
              this.isCheckPopulationEmpty = false;
            } else {
              this.isCheckPopulationEmpty = true;
              break;
            }
          }
        }
      }
    }
  }

  executeGetEvent(event) {
    this.getResultInput['getEvent'] = event;
  }

  currentResultPage(event) {
    this.getResultInput['currentPage'] = event;
  }

  emitSecondPageData(event) {
    this.getResultInput['secondPageData'] = event;
  }

  clickExport() {
    const dialogRef = this.dialog.open(ExportFileComponent, {
      disableClose: true,
      width: '600px',
      data: { 'table': this.selectedTable, 'tcm': this.tcm, queryName: this.queryName, 'getResultInput': this.getResultInput, 'executeQuery': this.executeQuery, 'ipn': this.ipn, 'region': this.region, 'totalRowsLength': this.rtnTotalRowsLength, 'resultColumns': this.rtnRsultColumns }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      } else {
      }
    });
  }

  execute(event) {
    if (this.isVueDisable) {
      if (this.groupList.length < 2) {
        // this.toastyService.warning(this.utilService.getTranslateName(`ERROR.TTB_GROUPBY_MIN_LIST_LENGTH`));
        this.alertErrorMsg(this.utilService.getTranslateName(`ERROR.TTB_GROUPBY_MIN_LIST_LENGTH`))
        return;
      }
    }
    this.executeBtn = true;
    event.stopPropagation();
    this.checkPopulationEmpty();
    if (!this.isCheckPopulationEmpty) {
      this.toastyService.warning(this.utilService.getTranslateName(`ERROR.POPULATION_RIGHTS`));
      return;
    }
    let isDateFieldFound = true;
    let isVolumeFieldFound = true;
    let allowExecute = true;
    isDateFieldFound = this.groupList.some(el => el.type === 3);
    isVolumeFieldFound = this.groupList.some(el => el.type === 5);
    if (this.region === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_toutes_pop') > -1 && this.getFavoriteRegion() === 'PIL') {
        allowExecute = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_toutes_pop') > -1 && this.getFavoriteRegion() === 'PIM') {
        allowExecute = true;
      }
    }
    if (this.selectedTable.toLowerCase() === 'carnets') {
      if (!isDateFieldFound) {
        // return this.toastyService.warning(this.utilService.getTranslateName(`ERROR.DATE_FIELD_MISSING`));
        this.toastyService.warning(this.utilService.getTranslateName(`ERROR.DATE_FIELD_MISSING`));
      }
      if (!isVolumeFieldFound) {
        // return this.toastyService.warning(this.utilService.getTranslateName(`ERROR.VOLUME_FIELD_MISSING`));
        this.toastyService.warning(this.utilService.getTranslateName(`ERROR.VOLUME_FIELD_MISSING`));
      }
      if (!isDateFieldFound && !isVolumeFieldFound) {
        // return this.toastyService.warning(this.utilService.getTranslateName(`ERROR.DATE_AND_VOLUME_MISSING`));
        this.toastyService.warning(this.utilService.getTranslateName(`ERROR.DATE_AND_VOLUME_MISSING`));
      }
      if (isDateFieldFound && isVolumeFieldFound) {
        this.isExecute = true;
        this.disableExecuteSection = true;
        this.detailCharacterList = JSON.parse(JSON.stringify(this.characterList));
        this.detailGroupList = JSON.parse(JSON.stringify(this.groupList));
        this.detailVueList = JSON.parse(JSON.stringify(this.vueList));
        this.executeQuery = `${this.generateCommonFirstExecute()}${this.generateConditionExecute()}${this.generateGroupExecute('group')}${this.generateCommonLastExecute()}`;
        this.detailExecute = `${this.generateGroupExecute('vue')}`;
      }
    }
    data.forEach(item => {
      if (item.name === this.selectedTable) {
        item.fields.forEach(fields => {
          if (fields.field_name === "TCM") {
            this.tcm = fields.selectedOption;
          }
        });
      }
    });
    if ((this.selectedTable.toLowerCase() === 'pdfv' || this.selectedTable.toLowerCase() === 'pdft') && allowExecute) {
      // if (!isVolumeFieldFound) {
      //   // return this.toastyService.warning(this.utilService.getTranslateName(`ERROR.VOLUME_FIELD_MSG`));
      //   this.toastyService.warning(this.utilService.getTranslateName(`ERROR.VOLUME_FIELD_MSG`));
      // }
      // if (isVolumeFieldFound) {
      this.isExecute = true;
      this.disableExecuteSection = true;
      this.detailCharacterList = JSON.parse(JSON.stringify(this.characterList));
      this.detailGroupList = JSON.parse(JSON.stringify(this.groupList));
      this.detailVueList = JSON.parse(JSON.stringify(this.vueList));
      this.executeQuery = `${this.generateCommonFirstExecute()}${this.generateConditionExecute()}${this.generateGroupExecute('group')}${this.generateCommonLastExecute()}`;
      this.detailExecute = `${this.generateGroupExecute('vue')}`;
      // }
    } else {
      this.isExecute = true;
      this.disableExecuteSection = true;
      this.detailCharacterList = JSON.parse(JSON.stringify(this.characterList));
      this.detailGroupList = JSON.parse(JSON.stringify(this.groupList));
      this.detailVueList = JSON.parse(JSON.stringify(this.vueList));
      this.executeQuery = `${this.generateCommonFirstExecute()}${this.generateConditionExecute()}${this.generateGroupExecute('group')}${this.generateCommonLastExecute()}`;
      this.detailExecute = `${this.generateGroupExecute('vue')}`;
    }

    this.executePreviewData = {
      table: this.selectedTable,
      population: this.populationList,
      characterList: this.characterList,
      group: this.groupList,
      vue: this.vueList
    }
    this.matomoTracker.trackEvent("Query", `Execute ${this.region} - ${this.selectedTable} query`, this.profile['uid']);
  }

  generateCommonFirstExecute() {
    let rtnValue: string;
    rtnValue = `${this.generatePopulationExecute()}`;
    rtnValue += "\n";
    rtnValue += `TPO@${this.ipn}@${this.selectedTable}@@${this.ipn}.EXP@`;
    return rtnValue;
  }

  generateRoleBasedBracket(popData) {
    // let actualArr, arrCount;
    // if (this.roleBasedQuery !== '') {
    //   let splitCount = this.roleBasedQuery.split('@');
    //   let counting = splitCount.slice(0, 8);
    //   actualArr = splitCount.slice(8);
    //   let emptySelectedOptionArr = [...actualArr];
    //   arrCount = parseInt(counting[1]);
    //   for (let i = 0; i < arrCount; i++) {
    //     for (const item of popData.fields) {
    //       if (emptySelectedOptionArr[4] === (item.GCOName + '_' + item.GCOType)) {
    //         item['isRole'] = true;
    //       }
    //     }
    //     emptySelectedOptionArr = emptySelectedOptionArr.slice(10);
    //   }
    // }
    // let data = {
    //   'actualArr': actualArr,
    //   'count': arrCount,
    //   'popData': popData
    // }
    // return data;

    let actualArr, arrCount;
    if (this.roleBasedQuery !== '') {
      let splitCount = this.roleBasedQuery.split('@');
      let counting = splitCount.slice(0, 4);
      actualArr = splitCount.slice(4);
      let emptySelectedOptionArr = [...actualArr];
      arrCount = parseInt(counting[1]);
      for (let i = 0; i < arrCount; i++) {
        if (emptySelectedOptionArr[2] !== "0") {
          const innerArrCount = emptySelectedOptionArr[2];
          emptySelectedOptionArr = emptySelectedOptionArr.slice(4);
          for (let j = 0; j < innerArrCount; j++) {
            for (const item of popData.fields) {
              if (emptySelectedOptionArr[4] === (item.GCOName + '_' + item.GCOType)) {
                item['isRole'] = true;
              }
            }
            emptySelectedOptionArr = emptySelectedOptionArr.slice(6);
          }
          // emptySelectedOptionArr = emptySelectedOptionArr.slice(10);
        } else {
          const innerArrCount = emptySelectedOptionArr[3];
          emptySelectedOptionArr = emptySelectedOptionArr.slice(4);
          for (let j = 0; j < innerArrCount; j++) {
            for (const item of popData.fields) {
              if (emptySelectedOptionArr[2] === (item.GCOName + '_' + item.GCOType)) {
                item['isRole'] = true;
              }
            }
            const intCount = Number(emptySelectedOptionArr[4]);
            emptySelectedOptionArr = emptySelectedOptionArr.slice(5 + (intCount * 3));
          }
        }
      }
    }
    let data = {
      'actualArr': actualArr,
      'count': arrCount,
      'popData': popData
    }
    return data;
  }

  generatePopulationExecute() {
    let popData = this.dataList.filter(a => a.name === this.selectedTable)[0];
    popData = this.generateRoleBasedBracket(popData).popData;
    let date, count = 0;
    let string = `@(@(@(@(@(`;
    let index = 0;
    let rtnValue = `TEMP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@`;
    for (const item of popData.fields) {
      if (item.selectedOption !== undefined && item.selectedOption !== '' && item.selectedOption.toLowerCase() !== this.utilService.getTranslateName(`COMMON.ALL`).toLowerCase() && item.field_name !== 'TCM' && item.field_name !== 'DLC') {
        index = index + 1;
        if (item.GCOType !== '1') {
          string += `@(@${index - 1}@)@E`
        } else {
          string += `@(@(@${index - 1}@)@)@E`
        }
      }
    }
    if (string.charAt(string.length - 1) === 'E') {
      string = string.slice(0, -2);
    }
    if (popData.name === 'BCV' || popData.name === 'CDE' || popData.name === 'VEH') {
      for (const item of popData.fields) {
        if (item.field_name === 'TCM' || item.field_name === 'DLC') {
          if (item.selectedOption) {
            if (item.selectedOption.indexOf('3') > -1) {
              date = moment.default().subtract(3, 'months');
              date = moment.default(date).format('DD/MM/YYYY')
            } else if (item.selectedOption.indexOf('6') > -1) {
              date = moment.default().subtract(6, 'months');
              date = moment.default(date).format('DD/MM/YYYY')
            } else if (item.selectedOption.indexOf('12') > -1) {
              date = moment.default().subtract(12, 'months');
              date = moment.default(date).format('DD/MM/YYYY')
            } else {
              date = undefined;
            }
            if (date !== undefined && index === 0) {
              if (item.selectedOption && (item.selectedOption.includes('3') || item.selectedOption.includes('6') || item.selectedOption.includes('12'))) {
                index = index + 1;
                string += `@(@(@${index - 1}@)@)@)@)@O@(@(@(@(@${(index + 1) - 1}@)@E@(@${(index + 2) - 1}@)@)@)@)@)@)@)`;
                index = index + 2;
              }
            } else if (date !== undefined && index !== 0) {
              index = index + 1;
              string += `@)@)@)@)@E@(@(@(@(@(@(@${index - 1}@)@)@)@)@O@(@(@(@(@${(index + 1) - 1}@)@E@(@${(index + 2) - 1}@)@)@)@)@)@)@)`;
              index = index + 2;
            } else if (date === undefined) {
              string += `@)@)@)@)@)`;
            }
          }
        }
      }
    } else {
      string += `@)@)@)@)@)`;
    }
    let actualArr = this.generateRoleBasedBracket(popData).actualArr;
    let arrCount = this.generateRoleBasedBracket(popData).count;
    if (actualArr !== undefined) {
      let emptySelectedOptionArr = [...this.generateRoleBasedBracket(popData).actualArr];
      if (index === 0) {
        string = `@(@)${this.roleBasedExecuteBracketsCount(emptySelectedOptionArr, arrCount, index)}`
      } else {
        string += this.roleBasedExecuteBracketsCount(emptySelectedOptionArr, arrCount, index);
      }
    }
    let atCount = string.match(/@/gi).length;
    rtnValue += `${atCount}${string}`;
    string = '';
    for (let item of popData.fields) {
      if (item.selectedOption !== undefined && item.selectedOption !== '' && item.selectedOption.toLowerCase() !== this.utilService.getTranslateName(`COMMON.ALL`).toLowerCase() &&
        item.selectedOption.toLowerCase() !== this.utilService.getTranslateName(`NEW_QUERY.ALLDATA`).toLowerCase()) {
        const affichDataType = item.GCOType === '1' ? "2" : item.GCOType === '3' ? '3' : '1';
        if (affichDataType === '1') {
          count = count + 1;
          if (string === '') {
            string += `@`;
          }
          string += `${affichDataType}@1@1@${item.GCOName}@0@E@0@${item.selectedOption}@0@`;
        } else if (affichDataType === '2') {
          count = count + 1;
          if (string === '') {
            string += `@`;
          }
          string += `${affichDataType}@0@1@${item.GCOName}@0@?@0@${item.selectedOption}@0@`;
        } else if (affichDataType === '3') {
          if (string === '') {
            string += `@`;
          }
          count = count + 1;
          string += `${affichDataType}@1@1@${item.GCOName}@0@s@0@${date}@0@`;
          if (item.field_name === 'TCM') {
            count = count + 1;
            string += `3@0@1@D_Tcm@0@s@0@0@0@`;
            count = count + 1;
            string += `3@0@1@D_Tcm@0@i@0@0@0@`;
          } else if (item.field_name === 'DLC') {
            count = count + 1;
            string += `3@0@1@D_LivClt@0@s@0@0@0@`;
            count = count + 1;
            string += `3@0@1@D_LivClt@0@i@0@0@0@`;
          }
        }
      }
    }
    if (actualArr !== undefined) {
      let emptySelectedOptionArr = [...actualArr];
      if (string === '') {
        string += `@`
      }
      string += `${this.getRoleBasedValues(emptySelectedOptionArr, arrCount, popData.fields, count).string}`;
      count = this.getRoleBasedValues(emptySelectedOptionArr, arrCount, popData.fields, count).count;
    }
    if (string !== '') {
      rtnValue += `@${count}${string}`;
      // rtnValue = `TEMP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@2@(@)@0@`;
    } else {
      if (this.roleBasedQuery === '') {
        rtnValue = `TEMP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@2@(@)@0@`;
      }
    }
    return rtnValue;
  }
  getTypeForPop(arr) {
    let type;
    if (arr[0] === '2' || arr[0] === '6') {
      type = 1;
    } else if (arr[0] === '4') {
      type = 6;
    } else if (arr[0] === '3') {
      type = 3;
    } else if (arr[0] === '1') {
      type = 2;
    } else if (arr[0] === '99') {
      type = 5;
    } else {
      type = 4;
    }
    return type;
  }

  getRoleBasedValues(arr, count, actualArr, noCount) {
    let type, string = '';
    let totalInnerArrCount = 0;
    for (let i = 0; i < count; i++) {
      if (arr[2] !== "0") {
        const innerArrCount = Number(arr[2]) + Number(arr[3]);
        totalInnerArrCount = totalInnerArrCount + Number(arr[2]);
        arr = arr.slice(4);
        for (let j = 0; j < innerArrCount; j++) {
          if (j === 0) {
            const gcoName = arr[4];
            let isFound = actualArr.some(obj => (obj.GCOName + '_' + obj.GCOType) === arr[4]);
            type = this.getTypeForPop(arr);
            if (isFound) {
              for (const item of actualArr) {
                if (item['isRole'] !== undefined && item['isRole'] && item['GCOName'] + '_' + item['GCOType'] === gcoName) {
                  noCount = noCount + 1;
                  if (arr[0] === '1') {
                    string += `${arr[0]}@1@1@${item.GCOName}@0@E@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
                  } else if (arr[0] === '2') {
                    arr[2] += `/`;
                    string += `${type}@3@1@${item.GCOName}@0@?@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
                  }
                }
              }
            } else {
              let replacableTxt = '_' + arr[5];
              let gcoName = arr[4].replace(replacableTxt, '');
              noCount = noCount + 1;
              if (arr[0] === '1') {
                string += `${arr[0]}@1@1@${gcoName}@0@E@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
              } else if (arr[0] === '2') {
                arr[2] += `/`;
                string += `${type}@3@1@${gcoName}@0@?@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
              }
            }
            arr = arr.slice(6);
          } else {
            if (isNaN(arr[4])) {
              const gcoName = arr[4];
              let isFound = actualArr.some(obj => (obj.GCOName + '_' + obj.GCOType) === arr[4]);
              type = this.getTypeForPop(arr);
              if (isFound) {
                for (const item of actualArr) {
                  if (item['isRole'] !== undefined && item['isRole'] && item['GCOName'] + '_' + item['GCOType'] === gcoName) {
                    noCount = noCount + 1;
                    if (arr[0] === '1') {
                      string += `${arr[0]}@1@1@${item.GCOName}@0@E@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
                    } else if (arr[0] === '2') {
                      arr[2] += `/`;
                      string += `${type}@3@1@${item.GCOName}@0@?@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
                    }
                  }
                }
              } else {
                let replacableTxt = '_' + arr[5];
                let gcoName = arr[4].replace(replacableTxt, '');
                noCount = noCount + 1;
                if (arr[0] === '1') {
                  string += `${arr[0]}@1@1@${gcoName}@0@E@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
                } else if (arr[0] === '2') {
                  arr[2] += `/`;
                  string += `${type}@3@1@${gcoName}@0@?@${arr[2].split("/").length - 1}@${arr[2]}@0@`;
                }
              }
              arr = arr.slice(6);
            } else {


              let isFound = actualArr.some(obj => (obj.GCOName + '_' + obj.GCOType) === arr[2]);
              const gcoName = arr[2];
              const intCount = Number(arr[4]);
              totalInnerArrCount = totalInnerArrCount + Number(arr[4]);
              type = this.getTypeForPop(arr);
              const arrType = arr[0];
              if (isFound) {
                arr = arr.slice(5);
                for (const item of actualArr) {
                  if (item['isRole'] !== undefined && item['isRole'] && item['GCOName'] + '_' + item['GCOType'] === gcoName) {
                    for (let k = 0; k < intCount; k++) {
                      noCount = noCount + 1;
                      if (arrType === '1') {
                        string += `${arrType}@0@1@${item.GCOName}@0@E@0@${arr[2]}@0@`;
                      } else if (arrType === '2') {
                        string += `${arrType}@0@1@${item.GCOName}@0@?@0@${arr[2]}@0@`;
                      }
                      arr = arr.slice(3);
                    }
                  }
                }
              } else {
                let replacableTxt = '_' + arr[3];
                let gcoName = arr[2].replace(replacableTxt, '');
                arr = arr.slice(5);
                for (let k = 0; k < intCount; k++) {
                  noCount = noCount + 1;
                  if (arrType === '1') {
                    string += `${arrType}@0@1@${gcoName}@0@E@0@${arr[2]}@0@`;
                  } else if (arrType === '2') {
                    string += `${arrType}@0@1@${gcoName}@0@?@0@${arr[2]}@0@`;
                  }
                  arr = arr.slice(3);
                }
              }


            }
          }
        }
        // arr = arr.slice(10);
      } else {
        const innerArrCount = arr[3];
        arr = arr.slice(4);
        for (let j = 0; j < innerArrCount; j++) {
          let isFound = actualArr.some(obj => (obj.GCOName + '_' + obj.GCOType) === arr[2]);
          const gcoName = arr[2];
          const intCount = Number(arr[4]);
          totalInnerArrCount = totalInnerArrCount + Number(arr[4]);
          type = this.getTypeForPop(arr);
          const arrType = arr[0];
          if (isFound) {
            arr = arr.slice(5);
            for (const item of actualArr) {
              if (item['isRole'] !== undefined && item['isRole'] && item['GCOName'] + '_' + item['GCOType'] === gcoName) {
                for (let k = 0; k < intCount; k++) {
                  noCount = noCount + 1;
                  if (arrType === '1') {
                    string += `${arrType}@0@1@${item.GCOName}@0@E@0@${arr[2]}@0@`;
                  } else if (arrType === '2') {
                    string += `${arrType}@0@1@${item.GCOName}@0@?@0@${arr[2]}@0@`;
                  }
                  arr = arr.slice(3);
                }
              }
            }
          } else {
            let replacableTxt = '_' + arr[3];
            let gcoName = arr[2].replace(replacableTxt, '');
            arr = arr.slice(5);
            for (let k = 0; k < intCount; k++) {
              noCount = noCount + 1;
              if (arrType === '1') {
                string += `${arrType}@0@1@${gcoName}@0@E@0@${arr[2]}@0@`;
              } else if (arrType === '2') {
                string += `${arrType}@0@1@${gcoName}@0@?@0@${arr[2]}@0@`;
              }
              arr = arr.slice(3);
            }
          }
        }
      }
    }
    let data = {
      'string': string,
      // 'count': totalInnerArrCount
      'count': noCount
    }
    return data;
  }

  roleBasedExecuteBracketsCount(roleArr, count, index) {
    // let type;
    // type = this.getTypeForPop(roleArr);
    // let string = `@E@(@(`;
    // for (let i = 0; i < count; i++) {
    //   string += `@(@(@(`;
    //   index = index + 1;
    //   if (type !== '1') {
    //     string += `@(@${index - 1}@)@)@)@)@O`
    //   } else {
    //     string += `@(@(@${index - 1}@)@)@)@)@)@O`
    //   }
    //   roleArr = roleArr.slice(10);
    // }
    // if (string.charAt(string.length - 1) === 'O') {
    //   string = string.slice(0, -2);
    // }
    // string += `@)@)`;
    // return string;

    let type;
    let string = `@E@(@(`;
    // index = 0;
    let isSauf = 'T';
    for (let i = 0; i < count; i++) {
      if (roleArr[2] !== "0") {
        const innerArrCount = Number(roleArr[2]) + Number(roleArr[3]);
        if (isSauf === 'T') {
          isSauf = roleArr[1];
        }
        if (roleArr[1] === "S") {
          if (string.charAt(string.length - 1) === 'O') {
            if (type !== 1) {
              string = string.slice(0, -4);
              string += `@E@(@N@(`;
            } else {
              string = string.slice(0, -4);
              string += `@E@(@N@(`;
            }
          }
        } else {
          string += `@(@(@(`;
        }
        roleArr = roleArr.slice(4);
        for (let j = 0; j < innerArrCount; j++) {
          type = this.getTypeForPop(roleArr);
          if (j === 0) {
            index = index + 1;
            if (type !== 1) {
              if (j < innerArrCount - 1) {
                string += `@(@${index - 1}@)@E`;
              } else {
                string += `@(@${index - 1}@)@)@)@)@O`;
              }
            } else {
              if (j < innerArrCount - 1) {
                string += `@(@${index - 1}@)@E`;
              } else {
                string += `@(@${index - 1}@)@)@)@)@O`;
              }
            }
            roleArr = roleArr.slice(6);
          } else {
            if (isNaN(roleArr[4])) {
              index = index + 1;
              if (type !== 1) {
                if (j < innerArrCount - 1) {
                  string += `@(@${index - 1}@)@E`;
                } else {
                  string += `@(@${index - 1}@)@)@)@)@O`;
                }
              } else {
                if (j < innerArrCount - 1) {
                  string += `@(@${index - 1}@)@E`;
                } else {
                  string += `@(@${index - 1}@)@)@)@)@O`;
                }
              }
              roleArr = roleArr.slice(6);
            } else {

              const intCount = roleArr[4];
              roleArr = roleArr.slice(5);
              // string += `@(`;
              for (let k = 0; k < intCount; k++) {
                index = index + 1;
                if (isSauf !== "S") {
                  if (k === intCount - 1 && j !== innerArrCount - 1) {
                    string += `@(@${index - 1}@)@)@E`;
                  } else {
                    string += `@(@${index - 1}@)@O`;
                  }
                } else {
                  if (k === intCount - 1 && j !== innerArrCount - 1) {
                    string += `@(@${index - 1}@)@)@E`;
                  } else {
                    if (k === 0 && j !== 0) {
                      string += `@(@(@${index - 1}@)@O`;
                    } else {
                      string += `@(@${index - 1}@)@O`;
                    }
                  }
                }
                roleArr = roleArr.slice(3);
              }
              if (j === innerArrCount - 1) {
                if (string.charAt(string.length - 1) === 'O') {
                  string = string.slice(0, -2);
                }
                string += `@)@)@)@)@O`;
              }


            }
          }
        }
        // roleArr = roleArr.slice(10);
      } else {
        const innerArrCount = roleArr[3];
        if (isSauf === 'T') {
          isSauf = roleArr[1];
        }
        if (roleArr[1] === "S") {
          if (string.charAt(string.length - 1) === 'O') {
            if (type !== 1) {
              string = string.slice(0, -4);
              string += `@E@(@N@(@(`;
            } else {
              string = string.slice(0, -4);
              string += `@E@(@N@(@(`;
            }
          }
        } else {
          if (type !== 1) {
            string += `@(@(@(@(`;
          } else {
            string += `@(@(@(@(`;
          }
        }
        roleArr = roleArr.slice(4);
        for (let j = 0; j < innerArrCount; j++) {
          type = this.getTypeForPop(roleArr);
          const intCount = roleArr[4];
          roleArr = roleArr.slice(5);
          // string += `@(`;
          for (let k = 0; k < intCount; k++) {
            index = index + 1;
            if (isSauf !== "S") {
              if (k === intCount - 1 && j !== innerArrCount - 1) {
                string += `@(@(@${index - 1}@)@)@E`;
              } else {
                // string += `@(@${index - 1}@)@O`;
                if (k === 0 && j !== 0) {
                  string += `@(@(@${index - 1}@)@O`;
                } else {
                  string += `@(@${index - 1}@)@O`;
                }
              }
            } else {
              if (k === intCount - 1 && j !== innerArrCount - 1) {
                string += `@(@${index - 1}@)@)@E`;
              } else {
                if (k === 0 && j !== 0) {
                  string += `@(@(@${index - 1}@)@O`;
                } else {
                  string += `@(@${index - 1}@)@O`;
                }
              }
            }
            roleArr = roleArr.slice(3);
          }
          if (j === innerArrCount - 1) {
            if (string.charAt(string.length - 1) === 'O') {
              string = string.slice(0, -2);
            }
            string += `@)@)@)@)@O`;
          }
        }
      }
    }
    if (string.charAt(string.length - 1) === 'O') {
      string = string.slice(0, -2);
    }
    string += `@)@)`;
    return string;
  }

  getConditionBlock() {
    let rtnValue = '@(', index = 0, count;
    // string += `@(`
    //console.log("sri",this.characterList.characterListType1.group0)
    if (this.characterList.characterListType1.group0.length !== 0) {
      let result = this.getConditionStringData(this.characterList.characterListType1, index);
      rtnValue += `${result.val}`;
      index = result.idx;
    }
    if (this.characterList.characterListType2.group0.length !== 0) {
      let result = this.getConditionStringData(this.characterList.characterListType2, index);
      rtnValue += `${result.val}`;
      index = result.idx
    }
    if (this.characterList.characterListType3.group0.length !== 0) {
      let result = this.getConditionStringData(this.characterList.characterListType3, index);
      index = result.idx
      rtnValue += `${result.val}`;
    }
    if (rtnValue.charAt(rtnValue.length - 1) === 'E') {
      rtnValue = rtnValue.slice(0, -2);
    }
    if (this.characterList.characterListType1.group0.length === 0 && this.characterList.characterListType2.group0.length === 0 &&
      this.characterList.characterListType3.group0.length === 0 && this.roleBasedQuery === '') {
      rtnValue = `@(@)`;
    } else {
      rtnValue += '@)';
    }
    let popData = this.dataList.filter(a => a.name === this.selectedTable)[0];
    let actualArr = this.generateRoleBasedBracket(popData).actualArr;
    let arrCount = this.generateRoleBasedBracket(popData).count;
    popData = this.generateRoleBasedBracket(popData).popData;
    if (actualArr !== undefined) {
      let emptySelectedOptionArr = [...this.generateRoleBasedBracket(popData).actualArr];
      if (this.characterList.characterListType1.group0.length === 0 && this.characterList.characterListType2.group0.length === 0 &&
        this.characterList.characterListType3.group0.length === 0 && this.roleBasedQuery !== '') {
        rtnValue = `@(@)${this.roleBasedExecuteBracketsCount(emptySelectedOptionArr, arrCount, index)}`
      } else {
        rtnValue += this.roleBasedExecuteBracketsCount(emptySelectedOptionArr, arrCount, index);
      }
    }
    count = rtnValue.match(/@/gi).length;
    let data = {
      'val': `${count}${rtnValue}`,
      'index': index
    }
    return data;
  }

  getConditionStringData(input, index) {
    let firstDate, string = '';
    string += `@(@(`
    for (let item in input) {
      let iterator = input[item];
      iterator.forEach((el, idx) => {
        index = index + 1;
        if (el.conditionType === '2' && !el.sauf) {
          string += `@(@(`;
        } else if (el.sauf) {
          // debugger
          //Sh Changes
          // if (string.charAt(string.length - 1) === 'E' && el.sauf && el.conditionType !== '1') {
          //   string = string.slice(0, -2)
          // }
          // if (el.conditionType === '2' && !iterator[iterator.length - 1].sauf && idx !== 0) {
          //   string += `@)@)@)@O@(@E@(@N@(`;
          // } else if (el.conditionType !== '1' && idx !== 0) {
          //   string += `@)@)@E@(@N@(`;
          // }
          // // else if (el.conditionType === '1' && idx !== 0) {
          // //   string += `@)`;
          // // }
          // if (idx === 0 && el.conditionType === '2') {
          //   string += `@(@N@(@(`
          // }
          //Sh Changes




          if (string.charAt(string.length - 1) === 'E' && el.sauf && el.conditionType !== '1') {
            string = string.slice(0, -2)
          }
          // if (el.conditionType === '2' && !iterator[iterator.length - 1].sauf && idx !== 0) {
          if (el.conditionType === '2' && idx !== 0 && !iterator[idx - 1].sauf) {
            string += `@)@)@)@O@(@E@(@N@(`;
            // } else if (el.conditionType === '3' && !iterator[idx - 1].sauf && idx !== 0) {
            //   string += `@)@)@)@O@(@E@(@N@(`;
          } else if (el.conditionType !== '1' && idx !== 0) {
            string += `@)@)@E@(@N@(`;
          }
          // else if (el.conditionType === '1' && idx !== 0) {
          //   string += `@)`;
          // }
          if (idx === 0 && el.conditionType === '2') {
            // string += `@(@N@(@(`
            string += `@(@N@(`
          }

          if (idx === 0 && el.conditionType === '3') {
            // string += `@(@N@(@(`
            string += `@(@N@(`
          }
          // if (idx === 0 && el.conditionType === '1' && iterator.length === 1) {
          //   string += `@(@N@(`
          // }
        }
        if (el.type === 1) {
          let value = el.value.split('ou');
          let ind;
          string += `@(`
          value.forEach((item, idx) => {
            ind = idx + (index - 1);
            string += `@(@${ind}@)@O`;
          })
          index = ind + 1;
          if (string.charAt(string.length - 1) === 'O') {
            string = string.slice(0, -2);
          }
          string += `@)@E`;
        } else if (el.type === 3) {
          let val = el.name.split('[');
          val = val[1].replace(']', '');
          let dateVal = el.value.split('et');
          if (val === this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.CONNUE`) || val === this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.INCONNUE`) || dateVal.length > 1) {
            firstDate = index + 1;
            string += `@(@(@${index - 1}@)@E@(@${firstDate - 1}@)@)@E`;
            index = firstDate;
          } else if (el.conditionType === '1') {
            string += `@(@(@${index - 1}@)@)@E`;
          } else if (el.conditionType === '2') {
            string += `@(@(@${index - 1}@)@)@E`;
          }
        } else {
          string += `@(@${index - 1}@)@E`;
        }
      })
      if (string.charAt(string.length - 1) === 'E') {
        string = string.slice(0, -2)
      }
      string += `@)@)@O`;
    }
    if (string.charAt(string.length - 1) === 'O') {
      string = string.slice(0, -2);
    }
    string += `@)@)@E`;
    let data = {
      val: string,
      idx: index
    }
    return data;
  }

  generateConditionExecute() {
    let rtnValue: string;
    rtnValue = "\n";
    rtnValue += `TEMP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@${this.ipn}@${this.selectedTable}@${this.ipn}.EXP@${this.getConditionBlock().val}@`;
    //1@1@3@1@A_Modele @0@?@4@CL2/CL3/CL4 / CL5 /@0@

    const condCharacterValue = [
      { "cond": "=", "format": "1", "comprator": "E" },
      { "cond": "Dans liste", "format": "3", "comprator": "?" },
      { "cond": ">=", "format": "1", "comprator": "s" },
      { "cond": "<=", "format": "1", "comprator": "i" },
      { "cond": "Cont", "format": "5", "comprator": "?" },
      { "cond": "position", "format": "2", "comprator": "E" },
      { "cond": "<>", "format": "1", "comprator": "D" },
    ];


    let rtnOutput = '';
    // for (let key in this.characterList) {
    //   if (key !== 'characterListType3') {
    for (let item in this.characterList.characterListType1) {
      let listForm = "";
      let iterator = this.characterList.characterListType1[item];
      // ////console.log('key1', subKey1);
      iterator.forEach(el => {
        const affichDataType = el.type === 1 ? "2" : el.type === 3 ? el.type.toString() : (el.type === 4 || el.type === 5) ? el.type.toString() : el.type === 99 ? "5" : "1";
        if (affichDataType === '1') {
          const contFormat = condCharacterValue.filter(f => f.cond === el.format);
          if (contFormat[0].format === "2") {
            let value, emptySpace = ' ';
            value = el.value.split(' ')[2].trim();
            for (let i = 0; i < el.size; i++) {
              if (value[i] === '' || value[i] === undefined) {
                value += `${emptySpace}`;
              }
            }
            listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@1@${value}@0@`;
          } else if (contFormat[0].format === "5") {
            let value, emptySpace = ' ';
            value = el.value.split('Cont')[1].trim();
            for (let i = 0; i < el.size; i++) {
              if (value[i] === '' || value[i] === undefined) {
                value += `${emptySpace}`;
              }
            }
            listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@0@${value}@0@`;
          } else if (contFormat[0].format === "3") {
            if (el.value.length > 1) {
              if (el.value.substr(0, 2) === "  " && el.value.substr(0, 3) !== "   ") {
                listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@${el.value.split("/").length}@${(el.value.replace("  ", "") + '/')}@0@`;
              } else if (el.value.substr(0, 1) === " " && el.value.substr(0, 2) !== "  ") {
                listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@${el.value.split("/").length}@${(el.value.replace(" ", "") + '/')}@0@`;
              } else {
                listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@${el.value.split("/").length}@${(el.value + '/')}@0@`;
              }
            } else {
              listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@${el.value.split("/").length}@${(el.value + '/')}@0@`;
            }
            // listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@${el.value.split("/").length}@${(el.value.replace("  ", "") + '/')}@0@`;
          } else {
            let value, emptySpace = ' ';
            value = el.value.split(el.format)[1].trim();
            for (let i = 0; i < el.size; i++) {
              if (value[i] === '' || value[i] === undefined) {
                value += `${emptySpace}`;
              }
            }
            listForm += `${affichDataType}@${contFormat[0].format}@1@${el.GCOName}@0@${contFormat[0].comprator}@0@${value}@0@`;
          }
        } else if (affichDataType === '2') {
          let arr = el.value.split('ou');
          for (let item of arr) {
            let replaceData = item.replace(/\[/g, '').replace(/\]/g, '');
            listForm += `${affichDataType}@0@1@${el.GCOName}@0@?@0@${replaceData.trim()}@0@`;
          }
        }
      });
      rtnOutput += `${listForm}`;
    }
    for (let item in this.characterList.characterListType2) {
      let listForm = "";
      let iterator = this.characterList.characterListType2[item];
      // //console.log('key1', subKey1);
      iterator.forEach(el => {
        const affichDataType = el.type === 1 ? "2" : el.type === 3 ? el.type.toString() : (el.type === 4 || el.type === 5) ? el.type.toString() : el.type === 99 ? "6" : "1";
        const contFormat = condCharacterValue.filter(f => f.cond === el.format);
        if (affichDataType === '4') {
          if (el.GCOName.substring(0, 2) !== 'N_') {
            listForm += `${affichDataType}@0@1@${el.GCOName}@0@E@${el.value.split(" ")[1].trim() === "1" ? "1" : "0"}@?@0@`;
          } else {
            listForm += `${affichDataType}@0@1@${el.GCOName}@0@${contFormat[0].comprator}@${el.value.split(contFormat[0].cond)[1].trim()}@?@0@`;
          }
        } else if (affichDataType === '3') {
          let condItemValue;
          const itemValue = el.name.substr(el.name.indexOf('[') + 1, (el.name.length - el.name.indexOf('[') - 2));
          for (let i = 0; i < this.getDataFormat().length; i++) {
            let date = this.getDataFormat()[i];
            let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${date.name}`);
            if (translatedText.toLowerCase() === itemValue.toLowerCase()) {
              condItemValue = `${date.index}`;
              break;
            }
          }
          const condValue = el.value;
          const condValueSplit = el.value.split(' ');
          if (condValue === '=0' || condValue === '=1') {
            listForm += `${affichDataType}@${condItemValue}@1@${el.GCOName}@0@s@0@${condValue.replace('=', '')}@0@`;
            listForm += `${affichDataType}@${condItemValue}@1@${el.GCOName}@0@i@0@${condValue.replace('=', '')}@0@`;
          } else if (condValueSplit.length === 1) {
            if (condValue.split('=')[0] === '>') {
              listForm += `${affichDataType}@${condItemValue}@1@${el.GCOName}@0@s@0@${condValue.replace('>=', '')}@0@`;
            } else if (condValue.split('=')[0] === '<') {
              listForm += `${affichDataType}@${condItemValue}@1@${el.GCOName}@0@i@0@${condValue.replace('<=', '')}@0@`;
            }
          } else if (condValueSplit.length > 1) {
            listForm += `${affichDataType}@${condItemValue}@1@${el.GCOName}@0@s@0@${condValueSplit[0].replace('>=', '')}@0@`;
            listForm += `${affichDataType}@${condItemValue}@1@${el.GCOName}@0@i@0@${condValueSplit[2].replace('<=', '')}@0@`;
          }
        }
      });
      rtnOutput += `${listForm}`;
    }
    //   }
    // } else if (key === 'characterListType3') {
    for (let item in this.characterList.characterListType3) {
      let listForm = '';
      let iterator = this.characterList.characterListType3[item];
      // //console.log('key1', subKey1);
      iterator.forEach(el => {
        const delayDateNames = el.name.split('-');
        const delayValue = el.value.split(' ');
        const contFormat = condCharacterValue.filter(f => f.cond === delayValue[0]);
        let delayDateName1 = undefined;
        let delayDateName2 = undefined;
        if (el.delay === 0) {
          const criteriaFilterVal1 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames[0] : a.longLabel === delayDateNames[0])[0];
          delayDateName1 = criteriaFilterVal1.GCOName;
          const criteriaFilterVal2 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames[1] : a.longLabel === delayDateNames[1])[0];
          delayDateName2 = criteriaFilterVal2.GCOName;
        } else if (el.delay === 1 || el.delay === 2) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames[1] : a.longLabel === delayDateNames[1])[0];
          delayDateName1 = delayDateNames[0];
          delayDateName2 = criteriaFilterVal.GCOName;
        } else if (el.delay === 3 || el.delay === 4) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames[0] : a.longLabel === delayDateNames[0])[0];
          delayDateName1 = criteriaFilterVal.GCOName;
          delayDateName2 = delayDateNames[1];
        }
        for (let i = 0; i < this.getDataFormat().length; i++) {
          let text = this.getDataFormat()[i];
          // this.getDataFormat().forEach(el => {
          let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${text.name}`);
          if (translatedText.toLowerCase() === el.format.toLowerCase()) {
            if (delayValue[3] && isNaN(delayValue[3]) && delayValue.length > 2) {
              listForm += `5@${text.index}@1@${delayDateName1}@1@${delayDateName2}@${contFormat[0].comprator}@${delayValue[1]}@?@0@`;
              break;
            } else if (text.value === 'Free Format' && delayValue.length > 2) {
              listForm += `5@${text.index}@1@${delayDateName1}@1@${delayDateName2}@${contFormat[0].comprator}@${delayValue[1]}@${delayValue[3]}@0@`;
              break;
            } else {
              listForm += `5@${text.index}@1@${delayDateName1}@1@${delayDateName2}@${contFormat[0].comprator}@${delayValue[1]}@?@0@`;
              break;
            }
          }
        }
        // if (delayValue.length > 4) {
        //   listForm += `5@7@1@${delayDateName1}@1@$${delayDateName2}@${contFormat[0].comprator}@${delayValue[1]}@${delayValue[4]}@0@`;
        // } else if (delayValue.length === 4) {
        //   const delayIndex = this.delayOptionValue.indexOf(`${delayValue[2]} ${delayValue[3]}`);
        //   listForm += `5@${delayIndex + 1}@1@${delayDateName1}@1@$${delayDateName2}@${contFormat[0].comprator}@${delayValue[1]}@?@0@`;
        // } else if (delayValue.length < 4) {
        //   const delayIndex = this.delayOptionValue.indexOf(`${delayValue[2]}`);
        //   listForm += `5@${delayIndex + 1}@1@${delayDateName1}@1@$${delayDateName2}@${contFormat[0].comprator}@${delayValue[1]}@?@0@`;
        // }
      })
      rtnOutput += `${listForm}`;
    }
    // }
    let popData = this.dataList.filter(a => a.name === this.selectedTable)[0];
    let actualArr = this.generateRoleBasedBracket(popData).actualArr;
    let arrCount = this.generateRoleBasedBracket(popData).count;
    popData = this.generateRoleBasedBracket(popData).popData;
    let count = this.getConditionBlock().index;
    if (actualArr !== undefined) {
      let emptySelectedOptionArr = [...actualArr];
      rtnOutput += `${this.getRoleBasedValues(emptySelectedOptionArr, arrCount, popData.fields, count).string}`;
      count = this.getRoleBasedValues(emptySelectedOptionArr, arrCount, popData.fields, count).count;
    }
    if (this.characterList.characterListType1.group0.length === 0 && this.characterList.characterListType2.group0.length === 0 &&
      this.characterList.characterListType3.group0.length === 0 && this.roleBasedQuery === '') {
      rtnValue += `0@`;
    } else {
      rtnValue += `${count}@${rtnOutput}`;
    }

    rtnValue += "\n";
    rtnValue += `TPO@${this.ipn}@${this.selectedTable}@un100000@${this.ipn}.EXP@`;
    rtnValue += "\n";
    rtnValue += `TEMP@${this.ipn}@${this.selectedTable}@${this.ipn}.CND@${this.ipn}@${this.selectedTable}@${this.ipn}.CND@2@(@)@0@`;
    return rtnValue;
  }

  generateGroupExecute(type) {
    let rtnValue: string;
    let noList, array;
    if (type === 'group') {
      array = this.groupList;
      noList = this.groupList.length;
      rtnValue = "\n";
      rtnValue += `TEMP@${this.ipn}@${this.selectedTable}@${this.ipn}.GRP@${this.ipn}@${this.selectedTable}@${this.ipn}.GRP@${noList}@`;
    } else {
      array = this.vueList;
      noList = this.vueList.length;
      rtnValue = "\n";
      rtnValue += `TEMP@${this.ipn}@${this.selectedTable}@${this.ipn}.AFF@${this.ipn}@${this.selectedTable}@${this.ipn}.AFF@${noList}@`;
    }

    let listForm = "";
    for (const item of array) {
      const affichDataType = item.type === 1 ? "2" : item.type === 3 ? item.type.toString() : (item.type === 4 || item.type === 5) ? item.type.toString() : item.type === 99 ? "5" : "1";

      if (affichDataType === '1') {
        listForm = `${listForm}${affichDataType}@1@1@${item.GCOName}@0@?@0@?@0@`;
      } else if (affichDataType === '2') {
        let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
        // //console.log('itemValue.split(",")1111111', itemValue.split(","), itemValue.split(",")[1], itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`));
        if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`)) {
          listForm = `${listForm}${affichDataType}@2@1@${item.GCOName}@1@A_MilBE@?@0@007@0@`;
        } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`)) {
          listForm = `${listForm}${affichDataType}@3@1@${item.GCOName}@0@?@0@@0@`;
        } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)) {
          if (itemValue.split(',').length > 1) {
            // //console.log('itemValue.split(",")', itemValue.split(","), itemValue.split(",")[1]);
            listForm = `${listForm}${affichDataType}@1@1@${item.GCOName}@0@?@${itemValue.split(",")[1]}@@0@`;
          } else {
            listForm = `${listForm}${affichDataType}@1@1@${item.GCOName}@0@?@0@@0@`;
          }
        } else {
          if (itemValue.split(',').length > 0) {
            let ll = 0;
            listForm = `${listForm}${affichDataType}@0@1@${item.GCOName}@0@?@0@`;
            for (const valItem of itemValue.split(',')) {
              if (valItem.length !== 6) {
                let emptySpace = '';
                for (let x = 0; x < (6 - valItem.length); x++) {
                  emptySpace = `${emptySpace} `;
                }
                if (ll === 0) {
                  listForm = `${listForm}${valItem}${emptySpace}/`;
                }

              } else {
                if (ll === 0) {
                  listForm = `${listForm}${valItem}/`;
                }
              }
            }
            listForm = listForm.slice(0, -1)
            listForm = `${listForm}@0@`
          }
        }
      } else if (affichDataType === '4' || (item.type === 5 && affichDataType === '5')) {
        let itemValue = item.name.split("[");
        if (itemValue.length > 1) {
          listForm = `${listForm}6@1@1@${item.GCOName}@0@?@0@?@0@`;
        } else {
          if ((item.GCOName === 'N_Volume' || item.GCOName === 'N_VolumePrev') && affichDataType === '5') {
            listForm = `${listForm}4@4@1@${item.GCOName}@0@?@0@?@0@`;
          } else {
            listForm = `${listForm}${affichDataType}@4@1@${item.GCOName}@0@?@0@?@0@`;
          }
        }
      } else if (affichDataType === '3') {
        // let indexval;
        let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
        let foundElement = this.getGroupDate().find(el => this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.value}`) === itemValue);
        if (foundElement !== undefined) {
          listForm = `${listForm}${affichDataType}@${foundElement.index}@1@${item.GCOName}@0@?@0@?@0@`;
        } else {
          listForm = `${listForm}${affichDataType}@9@1@${item.GCOName}@0@?@0@${itemValue}@0@`;
        }
        // for (let i = 0; i < this.getGroupDate().length; i++) {
        //   let el = this.getGroupDate()[i];
        //   if (this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.value}`) === itemValue && isNaN(itemValue)) {
        //     listForm = `${listForm}${affichDataType}@${el.index}@1@${item.GCOName}@0@?@0@?@0@`;
        //     break;
        //   } else if (!isNaN(itemValue)) {
        //     listForm = `${listForm}${affichDataType}@9@1@${item.GCOName}@0@?@0@${itemValue}@0@`;
        //     break;
        //   }
        // }
      } else if (affichDataType === '5' && item.type === 99) {
        let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2))
        let delayIndex;
        const delayDateNames = item.name.split('-');
        const delayDateNames2 = delayDateNames[1].split('[')[0];
        let delayDateName1 = undefined;
        let delayDateName2 = undefined;
        if (item.delay === 0) {
          const criteriaFilterVal1 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames[0] : a.longLabel === delayDateNames[0])[0];
          delayDateName1 = criteriaFilterVal1.GCOName;
          const criteriaFilterVal2 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames2 : a.longLabel === delayDateNames2)[0];
          delayDateName2 = criteriaFilterVal2.GCOName;
        } else if (item.delay === 1 || item.delay === 2) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames2 : a.longLabel === delayDateNames2)[0];
          delayDateName1 = delayDateNames[0];
          delayDateName2 = criteriaFilterVal.GCOName;
        } else if (item.delay === 3 || item.delay === 4) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === delayDateNames[0] : a.longLabel === delayDateNames[0])[0];
          delayDateName1 = criteriaFilterVal.GCOName;
          delayDateName2 = delayDateNames2;
        }
        for (let i = 0; i < this.getDataFormat().length; i++) {
          let el = this.getDataFormat()[i];
          // this.getDataFormat().forEach(el => {
          let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
          if (translatedText.toLowerCase() === itemValue.toLowerCase()) {
            delayIndex = el.index;
          }
        }
        if (itemValue.split(' ').length > 1 && !isNaN(itemValue.split(' ')[1])) {
          listForm += `5@7@1@${delayDateName1}@1@${delayDateName2}@?@0@${itemValue.split(' ')[1]}@0@`;
        } else {
          listForm += `5@${delayIndex}@1@${delayDateName1}@1@${delayDateName2}@?@0@?@0@`;
        }
      }
    }
    // rtnValue += "\n";
    rtnValue += listForm;
    // rtnValue += "\n";
    // rtnValue += `TSI@${this.ipn}@${this.selectedTable}@un100001@${this.ipn}.CND@${this.ipn}.GRP@0@0@`;
    // //console.log('Group Value', rtnValue);
    return rtnValue;
  }

  generateCommonLastExecute() {
    let rtnValue: string;
    rtnValue = "\n";
    let isFlagFound = false;
    if (this.groupList.some(el => el.type === 4)) {
      this.groupList.forEach(el => {
        let data = el.name.split('[');
        data = data.slice(0, -1);
        if (data.length > 0 && data[0].toLowerCase() === this.utilService.getTranslateName('COMMON.SUM').toLowerCase()) {
          isFlagFound = true;
        }
      })
    }

    if (this.groupList.some(el => el.type === 5) || isFlagFound) {
      rtnValue += `${this.groupExecuteTypeValue}@${this.ipn}@${this.selectedTable}@un100001@${this.ipn}.CND@${this.ipn}.GRP@N@N@`;
    } else {
      rtnValue += `${this.groupExecuteTypeValue}@${this.ipn}@${this.selectedTable}@un100001@${this.ipn}.CND@${this.ipn}.GRP@O@O@`;
    }
    return rtnValue;
  }

  checkDelay1CheckboxOptions(chk) {
    this.groupingDelayCheckVal = 0;
    this.groupingDelayInput.date1 = '';
    if (chk === 1 && this.delayDate1Check1) {
      this.groupingDelayCheckVal = 1;
      this.groupingDelayInput.date1 = 'DATEDUJOUR'
      if (this.delayDate2Check1 || this.delayDate2Check2) {
        this.groupingDelayInput.date2 = '';
      }
      this.selectedDelayDate1 = undefined;
      this.delayDate1Check2 = false;
      this.delayDate2Check1 = false;
      this.delayDate2Check2 = false;
    } else if (chk === 2 && this.delayDate1Check2) {
      this.groupingDelayCheckVal = 2;
      this.groupingDelayInput.date1 = this.delayDateTime1;
      if (this.delayDate2Check1 || this.delayDate2Check2) {
        this.groupingDelayInput.date2 = '';
      }
      this.selectedDelayDate1 = undefined;
      this.delayDate1Check1 = false;
      this.delayDate2Check1 = false;
      this.delayDate2Check2 = false;
    }
  }

  checkDelay2CheckboxOptions(chk) {
    this.groupingDelayCheckVal = 0;
    this.groupingDelayInput.date2 = '';
    if (chk === 1 && this.delayDate2Check1) {
      this.groupingDelayCheckVal = 3;
      this.groupingDelayInput.date2 = 'DATEDUJOUR'
      if (this.delayDate1Check1 || this.delayDate1Check2) {
        this.groupingDelayInput.date1 = '';
      }
      this.selectedDelayDate2 = undefined;
      this.delayDate2Check2 = false;
      this.delayDate1Check1 = false;
      this.delayDate1Check2 = false;
    } else if (chk === 2 && this.delayDate2Check2) {
      this.groupingDelayCheckVal = 4;
      this.groupingDelayInput.date2 = this.delayDateTime2;
      if (this.delayDate1Check1 || this.delayDate1Check2) {
        this.groupingDelayInput.date1 = '';
      }
      this.selectedDelayDate2 = undefined;
      this.delayDate2Check1 = false;
      this.delayDate1Check1 = false;
      this.delayDate1Check2 = false;
    }
  }

  updateGroupingDelay(event) {
    if (event.value !== '') {
      let lastIndex = this.createNewGroup(3);
      if (this.groupSection) {
        if (this.editGroupingDelay) {
          this.groupList[this.groupingEditIndex].name = `${event.date1}-${event.date2}[${event.value}]`;
          this.groupList[this.groupingEditIndex].delay = this.groupingDelayCheckVal;
          this.groupList[this.groupingEditIndex].format = event.value;
          this.requestService.addRequestReGroupment(this.groupList, this.reqActive);
          this.editGroupingDelay = false;
        } else {
          this.groupList.push({ "name": `${event.date1}-${event.date2}[${event.value}]`, "type": 99, "delay": this.groupingDelayCheckVal, "format": event.value });
          this.requestService.addRequestReGroupment(this.groupList, this.reqActive);
        }
      } else if (this.populateSection) {
        if (this.editGroupingDelay) {
          this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].name = `${event.date1}-${event.date2}`;
          this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].value = `${event.diffValue} ${event.diffTextVal} ${event.value}`;
          this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].delay = this.groupingDelayCheckVal;
          this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].format = event.format;
          // this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].conditionType = '2';
          // this.characterList.characterListType3[this.selectedKey][this.vueEditIndex].group = false;
          this.requestService.addRequestCharacter(this.characterList.characterListType3, this.reqActive, 3);
          this.editGroupingDelay = false;
        } else {
          this.characterList.characterListType3[lastIndex] = new Array();
          this.characterList.characterListType3[lastIndex].push({ "name": `${event.date1}-${event.date2}`, conditionType: '2', group: false, "delay": this.groupingDelayCheckVal, "format": event.format, "type": 99, value: `${event.diffValue} ${event.diffTextVal} ${event.value}` });
          this.requestService.addRequestCharacter(this.characterList.characterListType3, this.reqActive, 3);
        }
      }
    } else {
      this.blnDelay = false;
    }
    this.selectedItemIndex = undefined;
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.selectedGroupItemValue = undefined;
    this.blnMoveRight = false;
    this.groupingEditValue = undefined
    this.selectedGroupItemIndex = undefined;
    this.blnDelay = false;
    this.groupingDelayInput = { date1: '', date2: '', value: '', ddValue: '', diffValue: '', diffTextValue: '' };
    this.selectedDelayDate1 = undefined;
    this.selectedDelayDate2 = undefined;
    this.delayDate2Check1 = false;
    this.delayDate2Check2 = false;
    this.delayDate1Check1 = false;
    this.delayDate1Check2 = false;
    this.delayDateTime1 = undefined;
    this.delayDateTime2 = undefined;
    this.editGroupingDelay = false;
  }

  addDelayDateEvent(event: MatDatepickerInputEvent<Date>) {
    const dateVal = ((event.value.getDate() < 10) ? '0' + event.value.getDate().toString() : event.value.getDate().toString()) + '/' + ((event.value.getMonth() < 10) ? '0' + event.value.getMonth() : event.value.getMonth()) + '/' + event.value.getFullYear();
    if (this.delayDate1Check2) {
      this.groupingDelayInput.date1 = dateVal;
    } else {
      this.groupingDelayInput.date2 = dateVal;
    }
  }

  getDaysValue() {
    const queryParam = { "input": `CAL_DPTM@${this.ipn}@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `CAL_DPTM@${this.ipn}@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.daysData = res1.lists;
          const sorter = this.utilService.MySort(' ?*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz');
          this.daysData = this.daysData.sort(sorter);
        },
          error => {
            //console.log("error", error.message);
          });
      }
    },
      error => {
        //console.log("error", error.message);
      });
  }

  showPreview() {
    let previewData = {
      table: this.selectedTable,
      population: this.populationList,
      characterList: this.characterList,
      group: this.groupList,
      vue: this.vueList
    }

    // xfvxc
    const dialogRef = this.dialog.open(PreviewDialogComponent, {
      width: '450px',
      data: { value: previewData }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  getPrivateVueDetailsIPNs() {
    this.vuePrivateIPNDetails = undefined;
    const queryParam = { "input": `AFFICH@${this.ipn}@${this.selectedTable}@@`, "region": this.getFavoriteRegion() };
    this.apiService.getObjectSize(queryParam).subscribe(res => {
      if (res.noObjects > 0) {
        const queryParam1 = { "input": `AFFICH@${this.ipn}@${this.selectedTable}@@0@${res.noObjects}@`, "region": this.getFavoriteRegion() };
        this.apiService.getChoiceTableDetails(queryParam1).subscribe(res1 => {
          this.vuePrivateIPNDetails = res1.lists;
          // const sorter = this.utilService.MySort(' ?*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz');
          this.vuePrivateIPNDetails = this.vuePrivateIPNDetails.sort(this.utilService.sortArray);
          if (this.selectedVueIPNDetail) {
            if (this.vuePrivateIPNDetails.indexOf(this.selectedVueIPNDetail) === -1) {
              this.selectedVueIPNDetail = undefined;
              this.vueList = [];
              this.previousVueList = [];
            }
          }
        },
          error => {
            //console.log("error", error.message);
          });
      }
    },
      error => {
        //console.log("error", error.message);
      });
  }

  openDialog(item): void {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '350px',
      data: { 'display': this.utilService.getTranslateName(`NEW_QUERY.DISPLAY_NAME`), 'isText': true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const queryParam = { "input": `AFFICH@${this.ipn}@${this.selectedTable}@${result}@${this.saveVueDetailsData()}`, "region": this.getFavoriteRegion() };
        this.apiService.saveObject(queryParam).subscribe(resp => {
          if (!this.isVueDetailSave) {
            this.loadVuePrivateIPNData(item);
          } else {
            this.isVueDetailSave = false;
            this.selectedVueIPNDetail = result;
            this.getPrivateVueDetailsIPNs();
            this.loadVuePrivateIPNData(this.selectedVueIPNDetail);
          }
        },
          error => {
            //console.log("error", error.message);
          });
      } else {
        if (!this.isVueDetailSave) {
          this.loadVuePrivateIPNData(item);
        }
        this.isVueDetailSave = false;
      }
    });
  }

  openDialogUpdate(item): void {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '350px',
      // data: { 'display': "This is valid changes?", 'isText': false }
      data: { 'display': `${this.utilService.getTranslateName(`NAVBAR.VUEMODIFY`)}"${this.selectedVueIPNDetail}"${this.utilService.getTranslateName(`NAVBAR.VUEUPDATE`)}`, 'isText': false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const queryParam = { "input": `AFFICH@${this.ipn}@${this.selectedTable}@${this.selectedVueIPNDetail}@${this.saveVueDetailsData()}`, "region": this.getFavoriteRegion() };
        this.apiService.saveObject(queryParam).subscribe(resp => {
          if (!this.isVueDetailSave) {
            this.loadVuePrivateIPNData(item);
          }
          this.isVueDetailSave = false;
        },
          error => {
            //console.log("error", error.message);
          });
      } else {
        if (!this.isVueDetailSave && item !== this.selectedVueIPNDetail) {
          this.loadVuePrivateIPNData(item);
        }
        this.isVueDetailSave = false;
      }
    });
  }

  loadVuePrivateIPNData(item) {
    this.selectedVueIPNDetail = item;
    if (this.selectedVueIPNDetail !== '') {
      this.vueList = [];
      this.previousVueList = [];
      const queryParam = { "input": `AFFICH@${this.ipn}@${this.selectedTable}@${this.selectedVueIPNDetail}@`, "region": this.getFavoriteRegion() };
      this.apiService.getObjectGet(queryParam).subscribe(res => {
        const data = this.dataSplitFromViewSavedQuery(res.data);
        for (const item of data) {
          if (typeof (item.value) === 'undefined') {
            this.vueList.push({ 'name': item[this.lang], 'type': item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
            this.previousVueList.push({ 'name': item[this.lang], 'type': item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
          } else {
            if (item.typeOfField === 3) {


              for (let i = 0; i < this.groupDateLangData().length; i++) {
                let el = this.groupDateLangData()[i];
                for (const [key, value] of Object.entries(el)) {
                  if (key !== this.lang) {
                    if (el[key].toLowerCase() === item['value'].toLowerCase()) {
                      item['value'] = item['value'].replace(item['value'], el[this.lang]);
                      break;
                    }
                  }
                }
              }

              // this.vueList.push({ "name": item[this.lang] + `[${this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.` + item['value'])}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
              // this.previousVueList.push({ "name": item[this.lang] + `[${this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.` + item['value'])}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
              this.vueList.push({ "name": item[this.lang] + `[${item['value']}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
              this.previousVueList.push({ "name": item[this.lang] + `[${item['value']}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
            }
            if (item.typeOfField === 1) {
              const enLangValue = ["Compact Code", "All", "Index"];
              const frLangValue = ["Code Compact", "Tous", "Indice"];
              // const preVal = item.value.substring(item.name.indexOf('[') + 1, item.name.indexOf(']')).split(",")[0];
              let changeVal;
              if (this.lang === 'fr') {
                changeVal = frLangValue[enLangValue.indexOf(item.value)];
              } else {
                changeVal = enLangValue[frLangValue.indexOf(item.value)];
              }
              if (typeof (changeVal) !== 'undefined') {
                item.value = changeVal;
              }
              this.vueList.push({ "name": item[this.lang] + `[${item['value']}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
              this.previousVueList.push({ "name": item[this.lang] + `[${item['value']}]`, "type": item.typeOfField, 'GCOName': item.GCOName, 'size': item.size, visible: true });
            }
          }
        }
        this.requestService.addRequestVue(this.vueList, this.reqActive);
        this.requestService.addRequestVuePrivateIPNData(this.selectedVueIPNDetail, this.reqActive);
      },
        error => {
          //console.log("error", error.message);
        });
    } else if (this.selectedVueIPNDetail === '') {
      this.vueList = [];
      this.requestService.addRequestVue(this.vueList, this.reqActive);
      this.requestService.addRequestVuePrivateIPNData(undefined, this.reqActive);
    }
  }

  selectVuePrivateIPN(item) {
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.selectedItemIndex = undefined;
    this.vueEditIndex = undefined;
    this.vueEditValue = undefined;

    if (this.selectedVueIPNDetail !== item && this.vueList.length > 0) {
      if (!_.isEqual(this.vueList, this.previousVueList)) {
        this.openDialogUpdate(item)
      } else if (this.selectedVueIPNDetail) {
        this.loadVuePrivateIPNData(item);
      }
    } else {
      this.loadVuePrivateIPNData(item);
    }
  }

  checkVueTypeSeelct(event) {
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.selectedItemIndex = undefined;
    this.vueEditIndex = undefined;
    this.vueEditValue = undefined;
    this.executeBtn = false;
    this.isExecute = false;
    this.isVueDisable = event.checked;
    this.groupExecuteTypeValue = "TSI";
    if (event.checked) {
      this.groupExecuteTypeValue = "TTB";
    }
    if (event.checked) {
      this.vueList.some(list => {
        list.visible = false;
      });
    } else {
      this.vueList.some(list => {
        list.visible = true;
      });
    }
    this.requestService.addRequestVue(this.vueList, this.reqActive);
    this.requestService.addRequestVueAffichStatus(!event.checked, this.reqActive);
  }

  dataSplitFromViewSavedQuery(queryData) {
    let i: number;
    let returnVal = [];
    if (queryData) {
      const splitData = queryData.split("@");
      for (i = 1; i < splitData.length; i++) {
        if (splitData[i] === '1') {
          i = i + 3;
          let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
          returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
          returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.splitChar)[1];
          returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.splitChar)[0];
          returnVal[returnVal.length - 1]['value'] = undefined;
          returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
          i = i + 1;
        }
        else if (splitData[i] === '2') {
          i = i + 2;
          let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
          returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
          returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.splitChar)[1];
          returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.splitChar)[0];
          let listVal = undefined;
          returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
          i = i + 2;
          const subType = splitData[i];
          if (subType === '0') {
            i = i + 1;
            listVal = [];
            const loopVal = splitData[i];
            criteriaListVal['value'] = [];
            for (let k = 0; k < loopVal; k++) {
              i = i + 3;
              listVal.push(splitData[i].trim());
            }
            returnVal[returnVal.length - 1]['value'] = listVal;
          } else if (subType === '1') {
            returnVal[returnVal.length - 1]['value'] = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`) + ',0';
          } else if (subType === '2') {
            returnVal[returnVal.length - 1]['value'] = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`);
          } else if (subType === '3') {
            returnVal[returnVal.length - 1]['value'] = this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`);
          }
        }
        else if (splitData[i] === '3') {
          i = i + 1;
          const dateFormat = splitData[i];
          if (dateFormat === '9') {
            i = i + 2;
            let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
            returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
            returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.splitChar)[1];
            returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.splitChar)[0];
            returnVal[returnVal.length - 1]['value'] = splitData[i - 1];
            returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
            i = i + 1;
          } else {
            i = i + 2;
            let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
            returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
            returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.splitChar)[1];
            returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.splitChar)[0];
            returnVal[returnVal.length - 1]['value'] = this.dateVueValue[dateFormat];
            returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
            i = i + 1;
          }
        }
        else if (splitData[i] === '4') {
          i = i + 2;
          let criteriaListVal = this.getCriteriaDetailsFromriteriaListOriginal(splitData[i].substring(0, splitData[i].length - 2), splitData[i].substr(splitData[i].length - 1, 1));
          returnVal.push({ 'GCOName': criteriaListVal.GCOName, 'longLabel': criteriaListVal.longLabel, 'typeOfField': criteriaListVal.typeOfField, 'size': criteriaListVal.size, 'shortLabel': criteriaListVal.shortLabel });
          returnVal[returnVal.length - 1]['en'] = criteriaListVal.longLabel.split(this.splitChar)[1];
          returnVal[returnVal.length - 1]['fr'] = criteriaListVal.longLabel.split(this.splitChar)[0];
          returnVal[returnVal.length - 1]['value'] = undefined;
          returnVal[returnVal.length - 1]['size'] = criteriaListVal.size;
          i = i + 1;
        }
      }
    }
    return returnVal;
  }

  getCriteriaDetailsFromriteriaListOriginal(data, type) {
    const filterData = this.criteriaListOringinal.filter(z => z.GCOName === data && z.typeOfField.toString() === type.toString());
    if (filterData.length > 0) {
      return filterData[0];
    }
  }

  saveVueDetails() {

    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.selectedItemIndex = undefined;
    this.vueEditIndex = undefined;
    this.vueEditValue = undefined;

    this.isVueDetailSave = true;
    this.openDialog(undefined);
  }

  updateVueDetails() {

    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.selectedItemIndex = undefined;
    this.vueEditIndex = undefined;
    this.vueEditValue = undefined;

    this.openDialogUpdate(this.selectedVueIPNDetail);
  }

  saveVueDetailsData(): string {
    let saveQuery: string;
    if (this.vueList.length > 0) {
      const listCount = this.vueList.length;
      saveQuery = listCount.toString();
      for (const item of this.vueList) {
        const affichDataType = item.type === 1 ? "2" : item.type === 3 ? item.type.toString() : (item.type === 4 || item.type === 5) ? item.type.toString() : "1";
        if (affichDataType === '1') {
          saveQuery = `${saveQuery}@${affichDataType}@0@${item.size}@${item.GCOName}_${item.type}@${item.type}`;
        } else if (affichDataType === '2') {
          let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
          if (itemValue === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`)) {
            saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@2@0`;
          } else if (itemValue === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`)) {
            saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@3@0`;
          } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)) {
            saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@1@0`;
          } else {
            if (itemValue.split(',').length > 0) {
              saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_1@1@0@${itemValue.split(',').length}`;
              for (const valItem of itemValue.split(',')) {
                if (valItem.length !== 6) {
                  let emptySpace = '';
                  for (let x = 0; x < (6 - valItem.length); x++) {
                    emptySpace = `${emptySpace} `;
                  }
                  saveQuery = `${saveQuery}@0@0@${valItem}${emptySpace}`;
                } else {
                  saveQuery = `${saveQuery}@0@0@${valItem}`;
                }
              }
            }
          }
        } else if (affichDataType === '4') {
          saveQuery = `${saveQuery}@${affichDataType}@0@${item.GCOName}_4@4`;
        } else if (affichDataType === '3') {
          let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2))
          const indexval = this.dateVueValue.indexOf(itemValue);
          if (indexval !== -1) {
            saveQuery = `${saveQuery}@${affichDataType}@${indexval}@0@${item.GCOName}_3@3`;
          } else {
            saveQuery = `${saveQuery}@${affichDataType}@9@${itemValue}@${item.GCOName}_3@3`;
          }
        }
      }
      if (saveQuery.length > 5) {
        saveQuery = `${saveQuery}@`;
        return saveQuery;
      }
    }
  }

  clickRegroupmentSec(event) {
    event.stopPropagation();
    this.executeBtn = false;
    this.checkPopulationEmpty();
    if (!this.isCheckPopulationEmpty) {
      this.toastyService.warning(this.utilService.getTranslateName(`ERROR.POPULATION_RIGHTS`));
      return;
    }
    this.disableExecuteSection = false;
    this.populateSection = false;
    this.groupSection = true;
    this.vueSection = false;
    this.blnDelay = false;
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.groupingSelectedList = [];
    this.groupList = [];
    this.requestStoresData = this.requestService.getAllRequestData();
    if (typeof (this.requestStoresData[this.reqActive].regroupment) !== 'undefined') {
      this.groupList = this.requestStoresData[this.reqActive].regroupment;
    }
  }

  clickPopulationSec(event) {
    event.stopPropagation();
    this.populateSection = true;
    this.groupSection = false;
    this.vueSection = false;
    this.blnDelay = false;
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.groupingSelectedList = [];
  }

  clickVueSec(event) {
    event.stopPropagation();
    this.executeBtn = false;
    this.checkPopulationEmpty();
    if (!this.isCheckPopulationEmpty) {
      this.toastyService.warning(this.utilService.getTranslateName(`ERROR.POPULATION_RIGHTS`));
      return;
    }
    this.disableExecuteSection = false;
    this.populateSection = false;
    this.groupSection = false;
    this.vueSection = true;
    this.blnDelay = false;
    this.selectedItemType = undefined;
    this.selectedItemValue = undefined;
    this.groupingSelectedList = [];
    this.vueList = [];
    this.requestStoresData = this.requestService.getAllRequestData();
    if (typeof (this.requestStoresData[this.requestStoresData.length - 1].vue) !== 'undefined') {
      this.vueList = this.requestStoresData[this.requestStoresData.length - 1].vue;
    }
  }

  getImageData() {
    if (!this.toggle) {
      return '../../assets/images/maximize.png';
    } else {
      return '../../assets/images/minimize.png';
    }
  }

  close() {
    this.isExecute = false;
    this.toggle = false;
  }

  saveExecuteQuery(type, index) {
    if (this.queryName === '' || this.queryName === undefined || type === 'saveas') {
      let saveQuery = {
        ipn: this.ipn,
        table: this.selectedTable,
        region: this.getFavoriteRegion(),
        isPublic: this.getPublicList()
      }
      const dialogRef = this.dialog.open(SaveQueryNameComponent, {
        width: '450px',
        data: { value: saveQuery }
      });
      //console.log(dialogRef,saveQuery);
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          if (res.name !== '') {
            this.queryName = res.name;
            this.saveQuery(res.name, res.isPublic);
            this.requestService.setTabName(this.queryName, this.reqActive);
            this.requestStoresData = this.requestService.getAllRequestData();
            //console.log(this.requestStoresData)
            if (type === 'close-tab') {
              this.selectedTable = undefined;
              this.isExecute = false;
              this.requestStoresData.splice(index, 1);
              this.requestService.removeRequestData(index + 1);
              this.reqActive = this.requestStoresData.length - 1;
              //console.log(this.requestStoresData);
              if ((this.requestStoresData.length < 4)) {
                this.getSelectedStoreData(this.requestStoresData[this.requestStoresData.length - 1]);
              }
              if (this.requestStoresData.length === 0) {
                localStorage.removeItem("region")
                this.utilService.$favoriteConnecton$.next("");



                const pathName = window.location.pathname;
                this.utilService.removeOpenedWindowName(pathName);
                const openedWindowNameList = this.utilService.getOpenedWindowNameList()
                if (openedWindowNameList.length > 0) {
                  this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
                } else {
                  this.router.navigateByUrl('/home');
                }

                // this.router.navigateByUrl('/home');
              }
            }
          }
        }
      });
    } else {
      this.saveQuery(this.queryName, false);
      if (type === 'close-tab') {
        this.selectedTable = undefined;
        this.requestStoresData.splice(index, 1);
        this.requestService.removeRequestData(index + 1);
        this.reqActive = this.requestStoresData.length - 1;
        if ((this.requestStoresData.length < 4)) {
          this.getSelectedStoreData(this.requestStoresData[this.requestStoresData.length - 1]);
        }
        if (this.requestStoresData.length === 0) {
          localStorage.removeItem("region")
          this.utilService.$favoriteConnecton$.next("");

          const pathName = window.location.pathname;
          this.utilService.removeOpenedWindowName(pathName);
          const openedWindowNameList = this.utilService.getOpenedWindowNameList()
          if (openedWindowNameList.length > 0) {
            this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
          } else {
            this.router.navigateByUrl('/home');
          }
          // this.router.navigateByUrl('/home');
        }
      }
    }
  }

  getPublicList() {
    if (this.region === 'Europe') {
      if (this.profile['role-pil-irn6804'].includes('pil_save_public') && this.getFavoriteRegion() === 'PIL') {
        return true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].includes('pim_save_public') && this.getFavoriteRegion() === 'PIM') {
        return true;
      }
    }
  }

  saveQuery(name, isPublic) {
    this.saveQueryList = `${this.generatePopulationSectionSaveQuery(name, isPublic)}${this.generateConditionSaveQuery()}${this.generateGroupSaveQuery()}`;

    let queryParam = { "input": this.saveQueryList, "region": this.getFavoriteRegion() };

    this.apiService.saveObject(queryParam).subscribe(res => {
      this.matomoTracker.trackEvent("Query", `Save ${this.region} - ${this.selectedTable} query name ${name}`, this.profile['uid']);

    })
  }

  formSelectedQueryRequest() {
    let group = this.selectedQuery.split('CGroupBy')[1];
    let conditionGroup = this.selectedQuery.split('CGroupBy')[0];
    let condition = conditionGroup.split('CCond')[1];
    let populationGroup = conditionGroup.split('CCond')[0];
    let popfields = populationGroup.split('CPreReq')[1];
    let populationarr = popfields.split('@');
    const popData = this.dataList.filter(a => a.name === this.selectedTable)[0];

    for (const item of popData.fields) {
      let index = populationarr.indexOf(item.GCOName + '_' + item.GCOType);
      if (index > -1) {
        let value = populationarr[index + 1];
        if (item.field_name === 'TCM' || item.field_name === 'DLC') {
          if (value === '3') {
            value = this.utilService.getTranslateName(`NEW_QUERY.3MONTHS`);
          } else if (value === '6') {
            value = this.utilService.getTranslateName(`NEW_QUERY.6MONTHS`);
          } else if (value === '12') {
            value = this.utilService.getTranslateName(`NEW_QUERY.12MONTHS`);
          } else {
            value = this.utilService.getTranslateName(`NEW_QUERY.ALLDATA`);
          }
        }
        if (value !== '' && value !== undefined && value !== this.utilService.getTranslateName(`NEW_QUERY.ALLDATA`)) {
          item.selectedOption = value;
          this.populationList.push({ [item.field_name]: value.toUpperCase() });
        }
      }
    }
    this.characterList.characterListType1 = { 'group0': [] };
    this.characterList.characterListType2 = { 'group0': [] };
    this.characterList.characterListType3 = { 'group0': [] };

    this.requestService.addPopulationRequest(this.populationList, this.reqActive);
    let conditionArr = condition.split('@');
    conditionArr.splice(conditionArr.length - 2);
    let characterCount = conditionArr[2];
    let flagCount = conditionArr[3];
    let delayCount = conditionArr[4];
    conditionArr = conditionArr.slice(5);
    this.commonArr(conditionArr);
    if (group !== undefined && group !== '') {
      this.decodeGroupByData(group);
    }
    this.requestService.selectedQuery('', this.reqActive);
    this.requestService.duplicateSelectedQuery(this.selectedQuery, this.reqActive);
    this.selectedQuery = '';
  }

  commonArr(arr) {
    if (arr.length > 1) {
      let groupedData = arr.slice(0, 4);
      arr = arr.slice(4);
      let arrLen = parseInt(groupedData[2]) + parseInt(groupedData[3]);
      if (arr[4].includes('_2') || arr[2].includes('_1')) {
        this.commonConditionCharType(arr, arrLen, groupedData);
      } else if (arr[3].includes('_4') || arr[2].includes('_3')) {
        this.commonConditionFlagType(arr, arrLen, groupedData);
      } else if (arr[3].toLowerCase() === 'nchamp' || arr[3].toLowerCase() === 'ndate') {
        arrLen = parseInt(groupedData[2]);
        this.commonConditionDelayType(arr, arrLen, groupedData);
      }
    }
  }

  characterMTC() {
    return [{ name: 'EQUAL', value: '=' },
    { name: 'DANLIST', value: 'Dans liste' },
    { name: 'SUPERIOR', value: '>=' },
    { name: 'INFERIOR', value: '<=' },
    { name: 'CONTENT', value: 'Cont' },
    { name: 'POSITION', value: 'position' },
    { name: 'DIFFERENT', value: '<>' }];
  }

  commonConditionCharType(arr, arrLen, groupData) {
    let saufGroup = false;
    let lastIndex;
    if (groupData[1] !== 'S') {
      lastIndex = this.createNewGroup(1);
      this.characterList.characterListType1[lastIndex] = new Array();
    } else {
      saufGroup = true;
      lastIndex = Object.keys(this.characterList.characterListType1)[Object.keys(this.characterList.characterListType1).length - 1]
    }
    // let lastIndex = this.createNewGroup(1);
    // this.characterList.characterListType1[lastIndex] = new Array();
    for (let i = 0; i < arrLen; i++) {
      if ((arr[0] === '' || arr[0] === 'B') && (arr[1] === 'T' || arr[1] === 'S')) {
        groupData = arr.slice(0, 4);
        arr = arr.slice(4);
        saufGroup = true;
      }
      let value = '', format;
      if (arr[4].includes('_2')) {
        let obj = {
          operator: arr[0],
          posLen: arr[1],
          val: arr[2],
          size: arr[3],
          gcoName: arr[4],
          type: arr[5]
        }
        this.characterMTC().forEach((el, index) => {
          if (index + 1 === parseInt(obj.operator)) {
            format = el.value;
          }
        })
        obj.gcoName = obj.gcoName.replace('_2', '');
        let item = this.criteriaListOringinal.filter(el => obj.gcoName === el.GCOName);
        let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
        if (format === 'position') {
          value = `(${obj.posLen})= ${obj.val}`;
        } else if (format === 'Dans liste') {
          value = `${obj.val}`;
          const valueLenStr = `${obj.val}`;
          // for (let index5 = 0; index5 < obj.val.split("/")[0].length; index5++) {
          //   value = value.replace(" ", "");
          // }
          if (valueLenStr.split("/")[0].length > Number(obj.size)) {
            for (let index5 = 0; index5 < valueLenStr.split("/")[0].length - Number(obj.size); index5++) {
              value = value.replace(" ", "");
            }
          } else {
            let fValue = valueLenStr.split("/")[0];
            for (let index = fValue.length; index < Number(obj.size); index++) {
              fValue = fValue + " ";
              value = fValue;
              for (let i = 1; i < valueLenStr.split("/").length; i++) {
                const element = valueLenStr.split("/")[i];
                value = value + "/" + element;
              }
            }
          }
        } else {
          value = `${obj.val}`;
        }
        let isPreviousSauf = false;
        if (this.decodeSaufData[1] === 'S' && i === 0) {
          isPreviousSauf = true;
        } else {
          isPreviousSauf = false;
        }
        this.characterList.characterListType1[lastIndex].push({ "name": name, 'value': format !== 'Dans liste' && format !== 'position' ? format + ' ' + value : value, format: format, conditionType: (groupData[0] === '' && groupData[1] === 'S' && saufGroup && !isPreviousSauf) ? '3' : ((groupData[0] === 'B' && groupData[1] === 'S') || i === 0 || isPreviousSauf) ? '2' : (groupData[0] === '' && groupData[1] === 'S') ? '1' : '1', group: (groupData[0] === 'B' || (groupData[0] === '' && groupData[1] === 'S')) ? true : false, sauf: groupData[1] === 'T' ? false : true, "type": parseInt(obj.type), 'GCOName': item[0].GCOName, 'size': parseInt(obj.size) })
        this.decodeSaufData = groupData;
        saufGroup = false;
        arr = arr.slice(6);
      } else {
        let obj = {
          operator: arr[0],
          posLen: arr[1],
          gcoName: arr[2],
          type: arr[3]
        }
        let index = 4;
        for (let j = 0; j < arr[4]; j++) {
          value += ` [${arr[index + 3]}] ou`;
          index += 3;
        }
        if (value.slice(-2) === 'ou') {
          value = value.slice(0, -3)
        }
        obj.gcoName = obj.gcoName.replace('_1', '');
        let item = this.criteriaListOringinal.filter(el => obj.gcoName === el.GCOName);
        let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
        let isPreviousSauf = false;
        if (this.decodeSaufData[1] === 'S' && i === 0) {
          isPreviousSauf = true;
        } else {
          isPreviousSauf = false;
        }
        console.log(value);
        this.characterList.characterListType1[lastIndex].push({ "name": name, 'value': value, format: '', conditionType: (groupData[0] === '' && groupData[1] === 'S' && saufGroup && !isPreviousSauf) ? '3' : ((groupData[0] === 'B' && groupData[1] === 'S') || i === 0 || isPreviousSauf) ? '2' : (groupData[0] === '' && groupData[1] === 'S') ? '1' : '1', group: (groupData[0] === 'B' || (groupData[0] === '' && groupData[1] === 'S')) ? true : false, sauf: groupData[1] === 'T' ? false : true, "type": parseInt(obj.type), 'GCOName': item[0].GCOName, 'size': 0 })
        this.decodeSaufData = groupData;
        saufGroup = false;
        arr = arr.slice(index + 1);
      }
    }
    if (arr.length > 1) {
      this.commonArr(arr);
    }
    this.characterList.characterListType1 = this.enableSaufCheckbox(this.characterList.characterListType1, 'add');
    this.requestService.addRequestCharacter(this.characterList.characterListType1, this.reqActive, 1);
  }

  commonConditionFlagType(arr, arrLen, groupData) {
    let lastIndex, saufGroup = false;
    if (groupData[1] !== 'S') {
      lastIndex = this.createNewGroup(2);
      this.characterList.characterListType2[lastIndex] = new Array();
    } else {
      saufGroup = true;
      lastIndex = Object.keys(this.characterList.characterListType2)[Object.keys(this.characterList.characterListType2).length - 1]
    }
    for (let i = 0; i < arrLen; i++) {
      if ((arr[0] === '' || arr[0] === 'B') && (arr[1] === 'T' || arr[1] === 'S')) {
        groupData = arr.slice(0, 4);
        arr = arr.slice(4);
        saufGroup = true;
      }
      let value = '', formatVal = '', format;
      if (arr[3].includes('_4')) {
        let obj = {
          operator: arr[0],
          val: arr[1],
          size: arr[2],
          gcoName: arr[3],
          type: arr[4]
        }
        obj.gcoName = obj.gcoName.replace('_4', '');
        this.characterMTC().forEach((el, index) => {
          if (index + 1 === parseInt(obj.operator)) {
            format = el.value;
          }
        })
        if (obj.gcoName.charAt(0) !== 'N') {
          value = `= ${obj.val}`;
        } else {
          value = format + ' ' + obj.val;
        }
        let item = this.criteriaListOringinal.filter(el => obj.gcoName === el.GCOName);
        let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
        let isPreviousSauf = false;
        if (this.decodeSaufData[1] === 'S' && i === 0) {
          isPreviousSauf = true;
        } else {
          isPreviousSauf = false;
        }
        this.characterList.characterListType2[lastIndex].push({ "name": name, 'value': value, format: format, conditionType: (groupData[0] === '' && groupData[1] === 'S' && saufGroup && !isPreviousSauf) ? '3' : ((groupData[0] === 'B' && groupData[1] === 'S') || i === 0 || isPreviousSauf) ? '2' : (groupData[0] === '' && groupData[1] === 'S') ? '1' : '1', group: (groupData[0] === 'B' || (groupData[0] === '' && groupData[1] === 'S')) ? true : false, sauf: groupData[1] === 'T' ? false : true, "type": parseInt(obj.type), 'GCOName': item[0].GCOName, 'size': parseInt(obj.size) })
        this.decodeSaufData = groupData;
        saufGroup = false;
        arr = arr.slice(5);
      } else {
        let obj = {
          operator: arr[0],
          size: arr[1],
          gcoName: arr[2],
          type: arr[3]
        }
        if (arr[4] !== '') {
          obj['startday'] = arr[4].trim();
          obj['startweek'] = arr[5].trim();
          obj['startmonth'] = arr[6].trim();
          obj['startindMonth'] = arr[7].trim();
          obj['startyear'] = arr[8].trim();
        }
        if (arr[4] === '') {
          obj['endday'] = arr[5].trim();
          obj['endweek'] = arr[6].trim();
          obj['endmonth'] = arr[7].trim();
          obj['endindMonth'] = arr[8].trim();
          obj['endyear'] = arr[9].trim();
        }
        if (arr[4] !== '' && arr[9] !== '') {
          obj['endday'] = arr[9].trim();
          obj['endweek'] = arr[10].trim();
          obj['endmonth'] = arr[11].trim();
          obj['endindMonth'] = arr[12].trim();
          obj['endyear'] = arr[13].trim();
        }
        obj.gcoName = obj.gcoName.replace('_3', '');
        this.getDataFormat().forEach(el => {
          if (el.value !== 'week') {
            if (el.index === parseInt(obj.operator)) {
              if (parseInt(obj.operator) === 0) {
                if (obj['startday'] === '1') {
                  format = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.CONNUE`);
                  formatVal = 'connue';
                } else if (obj['startday'] === '0') {
                  format = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.INCONNUE`);
                  formatVal = 'inconnue';
                }
              } else {
                format = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
                formatVal = el.value;
              }
            }
          }
        })
        value = this.decodeDateFormat(formatVal, obj, 3);
        let item = this.criteriaListOringinal.filter(el => obj.gcoName === el.GCOName);
        let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
        let isPreviousSauf = false;
        if (this.decodeSaufData[1] === 'S' && i === 0) {
          isPreviousSauf = true;
        } else {
          isPreviousSauf = false;
        }
        this.characterList.characterListType2[lastIndex].push({ "name": name + ` [${format}]`, 'value': value, conditionType: (groupData[0] === '' && groupData[1] === 'S' && saufGroup && !isPreviousSauf) ? '3' : ((groupData[0] === 'B' && groupData[1] === 'S') || i === 0 || isPreviousSauf) ? '2' : (groupData[0] === '' && groupData[1] === 'S') ? '1' : '1', group: (groupData[0] === 'B' || (groupData[0] === '' && groupData[1] === 'S')) ? true : false, sauf: groupData[1] === 'T' ? false : true, "type": parseInt(obj.type), 'GCOName': item[0].GCOName, 'size': parseInt(obj.size) });
        saufGroup = false;
        this.decodeSaufData = groupData;
        if (arr[4] !== '' && arr[9] !== '') {
          arr = arr.slice(14);
        } else {
          arr = arr.slice(10);
        }
      }
    }
    if (arr.length > 1) {
      this.commonArr(arr);
    }
    this.characterList.characterListType2 = this.enableSaufCheckbox(this.characterList.characterListType2, 'add');
    this.requestService.addRequestCharacter(this.characterList.characterListType2, this.reqActive, 2);
  }

  commonConditionDelayType(arr, arrLen, groupData) {
    let lastIndex;
    let saufGroup = false;
    if (groupData[1] !== 'S') {
      lastIndex = this.createNewGroup(3);
      this.characterList.characterListType3[lastIndex] = new Array();
    } else {
      saufGroup = true;
      lastIndex = Object.keys(this.characterList.characterListType3)[Object.keys(this.characterList.characterListType3).length - 1]
    }
    // let lastIndex = this.createNewGroup(3);
    // this.characterList.characterListType3[lastIndex] = new Array();
    for (let i = 0; i < arrLen; i++) {
      if ((arr[0] === '' || arr[0] === 'B') && (arr[1] === 'T' || arr[1] === 'S')) {
        groupData = arr.slice(0, 4);
        arr = arr.slice(4);
        saufGroup = true;
      }
      if (arr[0] === 'NClDelai_V2') {
        arr = arr.slice(1);
      }
      let value = '', operator = '', formatVal = '', format, name;
      let obj = {};
      this.characterMTC().forEach((el, index) => {
        if ((index + 1) === parseInt(arr[0])) {
          operator = el.value;
        }
      })
      for (let i = 0; i < this.getDataFormat().length; i++) {
        let el = this.getDataFormat()[i];
        if (el.value !== 'programme') {
          if (el.index === parseInt(arr[1])) {
            format = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
            formatVal = el.value;
          }
        }
      }
      obj = this.delayObjFormat(arr, obj, formatVal);
      obj['operator'] = arr[0];
      obj['format'] = arr[1];
      obj['val'] = arr[2];
      let startName, endName;
      if (obj['startgcoName']) {
        obj['startgcoName'] = obj['startgcoName'].replace('_3', '');
        let item = this.criteriaListOringinal.filter(el => obj['startgcoName'] === el.GCOName);
        startName = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
      }
      if (obj['endgcoName']) {
        obj['endgcoName'] = obj['endgcoName'].replace('_3', '');
        let item = this.criteriaListOringinal.filter(el => obj['endgcoName'] === el.GCOName);
        endName = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
      }
      value = this.decodeDateFormat(formatVal, obj, 99);
      if (obj['starttext'] && obj['endtext']) {
        name = `${startName}-${endName}`;
      } else if (obj['starttext'] && obj['endday'] && obj['endday'] !== 'jj') {
        name = `${startName}-${value}`;
      } else if ((obj['starttext'] && obj['endday'] === 'jj')) {
        name = `${startName}-DATEDUJOUR`;
      } else if (obj['startday'] && obj['startday'] !== 'jj' && obj['endtext']) {
        name = `${value}-${endName}`;
      } else if (obj['startday'] === 'jj' && obj['endtext']) {
        name = `DATEDUJOUR-${endName}`;
      }
      let delayValue = operator + ' ' + obj['val'] + ' ' + format;
      if (formatVal === 'Free Format') {
        delayValue += ` ${obj['dayVal']}`;
      }
      let isPreviousSauf = false;
      if (this.decodeSaufData[1] === 'S' && i === 0) {
        isPreviousSauf = true;
      } else {
        isPreviousSauf = false;
      }
      this.characterList.characterListType3[lastIndex].push({ "name": name, 'value': delayValue, format: format, conditionType: (groupData[0] === '' && groupData[1] === 'S' && saufGroup && !isPreviousSauf) ? '3' : ((groupData[0] === 'B' && groupData[1] === 'S') || i === 0 || isPreviousSauf) ? '2' : (groupData[0] === '' && groupData[1] === 'S') ? '1' : '1', group: (groupData[0] === 'B' || (groupData[0] === '' && groupData[1] === 'S')) ? true : false, sauf: groupData[1] === 'T' ? false : true, 'delay': obj['delay'], type: 99 })
      this.decodeSaufData = groupData;
      if (arr[3].toLowerCase() === 'nchamp' && arr[7].toLowerCase() === 'nchamp' && formatVal === 'Free Format') {
        arr = arr.slice(12);
      } else if (arr[3].toLowerCase() === 'nchamp' && arr[7].toLowerCase() === 'nchamp' && formatVal !== 'Free Format') {
        arr = arr.slice(12);
      } else if ((arr[3].toLowerCase() === 'nchamp' && arr[7].toLowerCase() === 'ndate' || arr[3].toLowerCase() === 'ndate' && arr[9].toLowerCase() === 'nchamp') && formatVal === 'Free Format') {
        arr = arr.slice(15);
      } else if ((arr[3].toLowerCase() === 'nchamp' && arr[7].toLowerCase() === 'ndate' || arr[3].toLowerCase() === 'ndate' && arr[9].toLowerCase() === 'nchamp') && formatVal !== 'Free Format') {
        arr = arr.slice(14);
      }
    }
    saufGroup = false;
    if (arr.length > 1) {
      this.commonArr(arr);
    }
    this.characterList.characterListType3 = this.enableSaufCheckbox(this.characterList.characterListType3, 'add');
    this.requestService.addRequestCharacter(this.characterList.characterListType3, this.reqActive, 3);
  }

  decodeGroupByData(group) {
    let arrGroup = group.split('@');
    let groupLen = arrGroup.length / 5; // parseInt(arrGroup[2]);
    let actualGroupArr = arrGroup.slice(3);
    for (let i = 0; i < groupLen; i++) {
      let obj = {};
      if (actualGroupArr[0] === '1' || actualGroupArr[0] === '6') {
        obj = {
          'gcoName': actualGroupArr[3],
          'size': parseInt(actualGroupArr[2]),
          'type': parseInt(actualGroupArr[4])
        }
        if (actualGroupArr[4] === '2') {
          obj['gcoName'] = obj['gcoName'].replace('_2', '');
        } else if (actualGroupArr[4] === '6') {
          obj['gcoName'] = obj['gcoName'].replace('_6', '');
        } else if (actualGroupArr[4] === '5') {
          obj['gcoName'] = obj['gcoName'].replace('_5', '');
        } else {
          obj['gcoName'] = obj['gcoName'].replace('_4', '');
        }
        let item = this.criteriaListOringinal.filter(el => obj['gcoName'] === el.GCOName);
        let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
        if (actualGroupArr[0] === '6') {
          if (actualGroupArr[1] === '1') {
            name += `[${this.utilService.getTranslateName('COMMON.SUM')}]`;
          }
        }
        this.groupList.push({ 'GCOName': obj['gcoName'], 'name': name, size: obj['size'], type: obj['type'] });
        actualGroupArr = actualGroupArr.slice(5);
      } else if (actualGroupArr[0] === '3') {
        let format;
        if (parseInt(actualGroupArr[1]) !== 9) {
          obj = {
            'gcoName': actualGroupArr[3],
            'operator': actualGroupArr[1],
            'size': parseInt(actualGroupArr[2]),
            'type': parseInt(actualGroupArr[4])
          }
        } else {
          obj = {
            'gcoName': actualGroupArr[4],
            'operator': actualGroupArr[1],
            'size': parseInt(actualGroupArr[3]),
            'type': parseInt(actualGroupArr[5]),
            'val': actualGroupArr[2]
          }
        }
        this.getGroupDate().forEach(el => {
          if (el.index === parseInt(obj['operator'])) {
            format = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.value}`);
          }
          if (parseInt(obj['operator']) === 9) {
            format = 'Free Format';
          }
        })
        obj['gcoName'] = obj['gcoName'].replace('_3', '');
        if (obj['gcoName'].length > 1) {
          let item = this.criteriaListOringinal.filter(el => obj['gcoName'] === el.GCOName);
          if (item.length > 0) {
            let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
            if (format === 'Free Format') {
              name += `[${obj['val']}]`;
            } else {
              name += `[${format}]`;
            }
            this.groupList.push({ 'GCOName': obj['gcoName'], 'name': name, size: obj['size'], type: obj['type'] });
          }
        }
        if (format === 'Free Format') {
          actualGroupArr = actualGroupArr.slice(6);
        } else {
          actualGroupArr = actualGroupArr.slice(5);
        }
      } else if (actualGroupArr[0] === '2') {
        let index;
        obj = {
          'gcoName': actualGroupArr[2],
          'size': parseInt(actualGroupArr[1]),
          'type': parseInt(actualGroupArr[3])
        }
        obj['gcoName'] = obj['gcoName'].replace('_1', '');
        let item = this.criteriaListOringinal.filter(el => obj['gcoName'] === el.GCOName);

        if (actualGroupArr[4] !== '0') {
          if (item.length > 0) {
            let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
            if (actualGroupArr[4] === '1') {
              if (!this.isEnableAllTextBox) {
                name += `[${this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)},10]`;
              } else {
                if (actualGroupArr[5] === "0") {
                  name += `[${this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)}]`;
                } else {
                  name += `[${this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)},${actualGroupArr[5]}]`;
                }
              }
            } else if (actualGroupArr[4] === '2') {
              name += `[${this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`)}]`;
            } else {
              name += `[${this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`)}]`;
            }
            this.groupList.push({ 'GCOName': obj['gcoName'], 'name': name, size: obj['size'], type: obj['type'] });
          }
        } else {
          index = 5;
          if (item.length > 0) {
            let name = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
            name += `[`;
            for (let i = 0; i < parseInt(actualGroupArr[5]); i++) {
              name += `${actualGroupArr[index + 3]},`;
              index += 3;
            }
            if (name.slice(-1) === ',') {
              name = name.slice(0, -1)
            }
            name += `]`;
            this.groupList.push({ 'GCOName': obj['gcoName'], 'name': name, size: obj['size'], type: obj['type'] });
          }
          if (index) {
            actualGroupArr = actualGroupArr.slice(index + 1);
          }
        }
        if (index === undefined) {
          actualGroupArr = actualGroupArr.slice(6);
        }
      } else if (actualGroupArr[0] === '5') {
        let format, formatVal, value, name;
        for (let i = 0; i < this.getDataFormat().length; i++) {
          let el = this.getDataFormat()[i];
          if (el.value !== 'programme') {
            if (el.index === parseInt(actualGroupArr[2])) {
              format = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
              formatVal = el.value;
            }
          }
        }
        obj = this.delayObjFormat(actualGroupArr, obj, formatVal);
        obj['format'] = actualGroupArr[2];
        let startName, endName;
        if (obj['startgcoName']) {
          obj['startgcoName'] = obj['startgcoName'].replace('_3', '');
          let item = this.criteriaListOringinal.filter(el => obj['startgcoName'] === el.GCOName);
          startName = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
        }
        if (obj['endgcoName']) {
          obj['endgcoName'] = obj['endgcoName'].replace('_3', '');
          let item = this.criteriaListOringinal.filter(el => obj['endgcoName'] === el.GCOName);
          endName = item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === undefined ? item[0].longLabel : item[0].longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)];
        }
        value = this.decodeDateFormat(formatVal, obj, 99);
        if (obj['starttext'] && obj['endtext']) {
          name = `${startName}-${endName}`;
        } else if (obj['starttext'] && obj['endday'] && obj['endday'] !== 'jj') {
          name = `${startName}-${value}`;
        } else if ((obj['starttext'] && obj['endday'] === 'jj')) {
          name = `${startName}-DATEDUJOUR`;
        } else if (obj['startday'] && obj['startday'] !== 'jj' && obj['endtext']) {
          name = `${value}-${endName}`;
        } else if (obj['startday'] === 'jj' && obj['endtext']) {
          name = `DATEDUJOUR-${endName}`;

        }
        if (formatVal === 'Free Format') {
          name += `[${format} ${obj['dayVal']}]`;
          format = format + ' ' + obj['dayVal'];
        } else {
          name += `[${format}]`;
        }
        this.groupList.push({ delay: obj['delay'], format: format, isSelected: false, name: name, type: 99 });
        if (actualGroupArr[3].toLowerCase() === 'nchamp' && actualGroupArr[7].toLowerCase() === 'nchamp' && formatVal === 'Free Format') {
          actualGroupArr = actualGroupArr.slice(12);
        } else if (actualGroupArr[3].toLowerCase() === 'nchamp' && actualGroupArr[7].toLowerCase() === 'nchamp' && formatVal !== 'Free Format') {
          actualGroupArr = actualGroupArr.slice(12);
        } else if ((actualGroupArr[3].toLowerCase() === 'nchamp' && actualGroupArr[7].toLowerCase() === 'ndate' || actualGroupArr[3].toLowerCase() === 'ndate' && actualGroupArr[9].toLowerCase() === 'nchamp') && formatVal === 'Free Format') {
          actualGroupArr = actualGroupArr.slice(15);
        } else if ((actualGroupArr[3].toLowerCase() === 'nchamp' && actualGroupArr[7].toLowerCase() === 'ndate' || actualGroupArr[3].toLowerCase() === 'ndate' && actualGroupArr[9].toLowerCase() === 'nchamp') && formatVal !== 'Free Format') {
          actualGroupArr = actualGroupArr.slice(14);
        }
      }
    }
    this.requestService.addRequestReGroupment(this.groupList, this.reqActive);
  }

  delayObjFormat(arr, obj, formatVal) {
    if (arr[3].toLowerCase() === 'nchamp' && arr[7].toLowerCase() === 'nchamp') {
      obj['endtext'] = arr[4].trim();
      obj['endgcoName'] = arr[5].trim();
      obj['endtype'] = arr[6].trim();
      obj['starttext'] = arr[8].trim();
      obj['startgcoName'] = arr[9].trim();
      obj['starttype'] = arr[10].trim();
      obj['delay'] = 0;
      if (formatVal === 'Free Format') {
        obj['dayVal'] = arr[11].trim();
      }
    }

    if (arr[3].toLowerCase() === 'nchamp' && arr[7].toLowerCase() === 'ndate') {
      obj['endtext'] = arr[4].trim();
      obj['endgcoName'] = arr[5].trim();
      obj['endtype'] = arr[6].trim();
      obj['startday'] = arr[8].trim();
      obj['startweek'] = arr[9].trim();
      obj['startmonth'] = arr[10].trim();
      obj['startindMonth'] = arr[11].trim();
      obj['startyear'] = arr[12].trim();
      obj['delay'] = 2;
      if (formatVal === 'Free Format') {
        obj['dayVal'] = arr[13].trim();
      }
    }

    if (arr[3].toLowerCase() === 'ndate' && arr[9].toLowerCase() === 'nchamp') {
      obj['endday'] = arr[4].trim();
      obj['endweek'] = arr[5].trim();
      obj['endmonth'] = arr[6].trim();
      obj['endindMonth'] = arr[7].trim();
      obj['endyear'] = arr[8].trim();
      obj['starttext'] = arr[10].trim();
      obj['startgcoName'] = arr[11].trim();
      obj['starttype'] = arr[12].trim();
      if (formatVal === 'Free Format') {
        obj['dayVal'] = arr[13].trim();
      }
      obj['delay'] = 4;
    }
    if (obj['startday'] === 'jj') {
      obj['delay'] = 1;
    }
    if (obj['endday'] === 'jj') {
      obj['delay'] = 3;
    }
    return obj;
  }

  decodeDateFormat(formatVal, obj, type) {
    let value = '', startOp, endOp;
    if (type === 3) {
      startOp = '>=';
      endOp = '<='
    } else {
      startOp = '';
      endOp = '';
    }
    if (formatVal === 'connue') {
      value = '=1';
    } else if (formatVal === 'inconnue') {
      value = '=0';
    } else if (formatVal === 'jour') {
      value = obj['startday'] !== undefined ? `${startOp}${obj['startday']}/${obj['startmonth']}/${obj['startyear']}` : '';
      value += obj['startday'] !== undefined && obj['endday'] !== undefined ? ' et ' : '';
      value += obj['endday'] !== undefined ? `${endOp}${obj['endday']}/${obj['endmonth']}/${obj['endyear']}` : '';
    } else if (formatVal === 'ASJ') {
      value = obj['startday'] !== undefined ? `${startOp}${obj['startday']}/${obj['startweek']}/${obj['startyear']}` : '';
      value += obj['startday'] !== undefined && obj['endday'] !== undefined ? ' et ' : '';
      value += obj['endday'] !== undefined ? `${endOp}${obj['endday']}/${obj['endweek']}/${obj['endyear']}` : '';
    } else if (formatVal === 'programme' || formatVal === 'week') {
      value = obj['startday'] !== undefined ? `${startOp}${obj['startweek']}/${obj['startyear']}` : '';
      value += obj['startday'] !== undefined && obj['endday'] !== undefined ? ' et ' : '';
      value += obj['endday'] !== undefined ? `${endOp}${obj['endweek']}/${obj['endyear']}` : '';
    } else if (formatVal === 'mois insustriel') {
      value = obj['startday'] !== undefined ? `${startOp}${obj['startindMonth']}/${obj['startyear']}` : '';
      value += obj['startday'] !== undefined && obj['endday'] !== undefined ? ' et ' : '';
      value += obj['endday'] !== undefined ? `${endOp}${obj['endindMonth']}/${obj['endyear']}` : '';
    } else if (formatVal === 'mois calendaire' || formatVal === 'semaines') {
      value = obj['startday'] !== undefined ? `${startOp}${obj['startmonth']}/${obj['startyear']}` : '';
      value += obj['startday'] !== undefined && obj['endday'] !== undefined ? ' et ' : '';
      value += obj['endday'] !== undefined ? `${endOp}${obj['endmonth']}/${obj['endyear']}` : '';
    } else if (formatVal === 'année') {
      value = obj['startday'] !== undefined ? `${startOp}${obj['startyear']}` : '';
      value += obj['startday'] !== undefined && obj['endday'] !== undefined ? ' et ' : '';
      value += obj['endday'] !== undefined ? `${endOp}${obj['endyear']}` : '';
    }
    return value;
  }

  generatePopulationSectionSaveQuery(name, isPublic) {
    const popData = this.dataList.filter(a => a.name === this.selectedTable)[0];
    let textVal, ipn;
    if (isPublic) {
      ipn = 'system';
    } else {
      ipn = this.ipn;
    }

    let rtnQuery = `REQU@${ipn}@${this.selectedTable}@${name}@CReq@3@CPreReq@`;
    let popQuery = `@${this.ipn}@${this.selectedTable}@1@${popData.fields.length}@`;
    for (const item of popData.fields) {
      if (item.field_name === 'TCM' || item.field_name === 'DLC') {
        if (item.selectedOption) {
          if (item.selectedOption.indexOf('3') > -1) {
            textVal = 3;
          } else if (item.selectedOption.indexOf('6') > -1) {
            textVal = 6;
          } else if (item.selectedOption.indexOf('12') > -1) {
            textVal = 12;
          } else {
            textVal = 0;
          }
        }
      }
      if (item.selectedOption === undefined || item.selectedOption.toLowerCase() === this.utilService.getTranslateName(`COMMON.ALL`).toLowerCase() && item.field_name !== 'TCM' && item.field_name !== 'DLC') {
        textVal = '';
      } else if (item.field_name !== 'TCM' && item.field_name !== 'DLC') {
        textVal = item.selectedOption;
      }
      popQuery += `${item.GCOName}_${item.GCOType}@${textVal}@`;
    }
    rtnQuery += `${5 + (popData.fields.length * 2)}${popQuery}`;

    return rtnQuery;
  }

  getCount(arr, count, type) {
    let firstCount = 0, secondCount = 0, data = {};
    if (count < arr.length) {
      for (let i = count; i < arr.length; i++) {
        if (arr[i].sauf) {
          count = i;
          break;
        }
        if (arr[i].type !== type && (i === 0 || arr[i].conditionType === '1')) {
          firstCount += 1;
        } else if (arr[i].type === type && (i === 0 || arr[i].conditionType === '1')) {
          secondCount += 1;
        }
      }
      data = {
        'firstCount': firstCount,
        'secondCount': secondCount,
        'iterateItem': count
      }
      return data;
    }
  }

  getSaufCount(arr, count, type) {
    let firstCount = 0, secondCount = 0, data = {};
    if (count < arr.length) {
      for (let i = count; i < arr.length; i++) {
        if (!arr[i].sauf || (arr[i].conditionType === '2' && i !== count)) {
          if (count === arr.length - 1) {
            count = i + 1;
          } else {
            count = i;
          }
          break;
        }
        if (arr[i].type !== type && arr[i].sauf) {
          firstCount += 1;
        } else if (arr[i].type === type && arr[i].sauf) {
          secondCount += 1;
        }
      }
      data = {
        'firstCount': firstCount,
        'secondCount': secondCount,
        'iterateItem': count
      }
      return data;
    }
  }

  generateConditionSaveQuery() {
    const characterMTC = [{ name: 'EQUAL', value: '=' },
    { name: 'DANLIST', value: 'Dans liste' },
    { name: 'SUPERIOR', value: '>=' },
    { name: 'INFERIOR', value: '<=' },
    { name: 'CONTENT', value: 'Cont' },
    { name: 'POSITION', value: 'position' },
    { name: 'DIFFERENT', value: '<>' }];

    let rtnValue = "";
    console.log(this.characterList.characterListType1);
    for (let item in this.characterList.characterListType1) {
      let commonList = "";
      let countChar = 0;
      let countMTC = 0;
      let count = 0;
      // for (let i = 0;i < this.characterList.characterListType1[item].length; i++) {
      //   let iterator = this.characterList.characterListType1[item][i];
      //   if (iterator.type !== 1) {
      //     countChar += 1;
      //   } else {
      //     countMTC += 1;
      //   }
      // }
      //console.log(this.characterList.characterListType1[item]);
      for (let i = 0; i < this.characterList.characterListType1[item].length; i++) {
        let iterator = this.characterList.characterListType1[item][i];
        console.log("srii", "tyie1", this.characterList.characterListType1);
        console.log(this.characterList.characterListType1[item][i])
        if (iterator.conditionType === '2' && !iterator.sauf) {
          commonList = '';
          commonList = `${!iterator.isSaufGroup || iterator.isSaufGroup === undefined ? '' : 'B'}@${!iterator.sauf || iterator.sauf === undefined ? 'T' : 'S'}@${this.getCount(this.characterList.characterListType1[item], count, 1)['firstCount']}@${this.getCount(this.characterList.characterListType1[item], count, 1)['secondCount']}`;
          count = this.getCount(this.characterList.characterListType1[item], count, 1)['iterateItem'];
        }
        if (iterator.sauf) {
          commonList = `${!iterator.isSaufGroup && iterator.conditionType === '2' ? 'B' : ''}@${'S'}@${this.getSaufCount(this.characterList.characterListType1[item], count, 1)['firstCount']}@${this.getSaufCount(this.characterList.characterListType1[item], count, 1)['secondCount']}`;
          count = this.getSaufCount(this.characterList.characterListType1[item], count, 1)['iterateItem'];
        }
        let listForm = "";
        let operatorVal;
        if (iterator.type !== 1) {
          characterMTC.forEach((el, index) => {
            if (iterator.format === el.value) {
              listForm += `${index + 1}@`;
              operatorVal = el.value;
            }
          })
          if (iterator.format === 'Dans liste') {
            let val = iterator.value.split('/');
            listForm += `${val.length}@`;
            listForm += `${iterator.value}@${iterator.size}@${iterator.GCOName}_${iterator.type}@${iterator.type}@`;

          } else if (iterator.format === 'position') {
            let val = iterator.value.split(' ');
            // let pos = val[1].match(/\((.*)\)/)[1].replace('=', '');
            let pos = val[0].match(/\((.*)\)/)[0].replace('=', '').replace('(', '').replace(')', '');
            listForm += `${pos}@`;
            let value, emptySpace = ' ';
            // value = iterator.value.split(' ')[2].trim();
            value = iterator.value.split(' ')[1];
            for (let i = 0; i < iterator.size; i++) {
              if (value[i] === '' || value[i] === undefined) {
                value += `${emptySpace}`;
              }
            }
            listForm += `${value}@${iterator.size}@${iterator.GCOName}_${iterator.type}@${iterator.type}@`;

          } else {
            listForm += `0@`;
            let value, emptySpace = ' ';
            value = iterator.value.split(iterator.format)[1].trim();
            for (let i = 0; i < iterator.size; i++) {
              if (value[i] === '' || value[i] === undefined) {
                value += `${emptySpace}`;
              }
            }


            listForm += `${value}@${iterator.size}@${iterator.GCOName}_${iterator.type}@${iterator.type}@`;
          }
        } else {
          let arr = iterator.value.split('ou');
          listForm += `2@0@${iterator.GCOName}_${iterator.type}@${iterator.type}@${arr.length}@`
          for (let el of arr) {
            let replaceData = el.replace(/\[/g, '').replace(/\]/g, '');
            listForm += `0@0@${replaceData}@`;
          }
        }
        if (iterator.conditionType === '2' || iterator.conditionType === '3') {
          rtnValue += `${commonList}@${listForm}`;
        } else {
          rtnValue += `${listForm}`;
        }
      }
    }
    for (let item in this.characterList.characterListType2) {
      let commonList = "";
      let count = 0;
      for (let i = 0; i < this.characterList.characterListType2[item].length; i++) {
        let iterator = this.characterList.characterListType2[item][i];
        if (iterator.conditionType === '2' && !iterator.sauf) {
          commonList = '';
          commonList = `${!iterator.isSaufGroup || iterator.isSaufGroup === undefined ? '' : 'B'}@${!iterator.sauf || iterator.sauf === undefined ? 'T' : 'S'}@${this.getCount(this.characterList.characterListType2[item], count, 3)['secondCount']}@${this.getCount(this.characterList.characterListType2[item], count, 3)['firstCount']}`;
          count = this.getCount(this.characterList.characterListType2[item], count, 3)['iterateItem'];
        }
        if (iterator.sauf) {
          commonList = `${!iterator.isSaufGroup && iterator.conditionType === '2' ? 'B' : ''}@${'S'}@${this.getSaufCount(this.characterList.characterListType2[item], count, 3)['secondCount']}@${this.getSaufCount(this.characterList.characterListType2[item], count, 3)['firstCount']}`;
          count = this.getSaufCount(this.characterList.characterListType2[item], count, 3)['iterateItem'];
        }
        let listForm = "";
        if (iterator.GCOName.charAt(0) !== 'N' && iterator.type === 4) {
          let condVal = iterator.value.split(' ')[1];
          listForm += `1@${condVal}@0@${iterator.GCOName}_${iterator.type}@${iterator.type}@`;
        } else if (iterator.GCOName.charAt(0) === 'N') {
          let operatorVal;
          characterMTC.forEach((el, index) => {
            if (iterator.format === el.value) {
              listForm += `${index + 1}@`;
              operatorVal = el.value;
            }
          })
          let condVal = iterator.value.split(operatorVal)[1];
          listForm += `${condVal}@0@${iterator.GCOName}_${iterator.type}@${iterator.type}@`;
        } else {
          let date, startDate, endDate;
          let val = iterator.name.split('[');
          val = val[1].slice(0, -1);
          for (let i = 0; i < this.getDataFormat().length; i++) {
            let el = this.getDataFormat()[i];
            if (val === this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`)) {
              listForm += `${el.index}@`;
              break;
            }
          }
          listForm += `0@${iterator.GCOName}_${iterator.type}@${iterator.type}@`
          date = iterator.value.split(' et ');
          if (date.length > 1) {
            startDate = date[0].split('>=')[1];
            startDate = this.getFormatedDate(startDate, val);
            endDate = date[1].split('<=')[1];
            endDate = this.getFormatedDate(endDate, val);
          } else {
            if (date[0][0] === '>') {
              startDate = date[0].split('>=')[1];
              startDate = this.getFormatedDate(startDate, val);
            } else if (date[0][0] === '<') {
              endDate = date[0].split('<=')[1];
              endDate = this.getFormatedDate(endDate, val);
            } else {
              startDate = date[0].split('=')[1];
              startDate = endDate = this.getFormatedDate(startDate, val);
            }
          }
          listForm += `${startDate !== undefined ? startDate : ``}@${endDate === undefined ? `@` : `${endDate}@`}`;
        }
        if (iterator.conditionType === '2' || iterator.conditionType === '3') {
          rtnValue += `${commonList}@${listForm}`;
        } else {
          rtnValue += `${listForm}`;
        }
      }
    }
    for (let item in this.characterList.characterListType3) {
      let commonList = "";
      let count = 0;
      for (let i = 0; i < this.characterList.characterListType3[item].length; i++) {
        let iterator = this.characterList.characterListType3[item][i];
        commonList = '';
        if (item !== 'group0') {
          commonList += `@`;
        }
        if (iterator.conditionType === '2' && !iterator.sauf) {
          if (item === 'group0') {
            commonList += `${!iterator.isSaufGroup || iterator.isSaufGroup === undefined ? 'B' : 'B'}@${!iterator.sauf || iterator.sauf === undefined ? 'T' : 'S'}@${this.getCount(this.characterList.characterListType3[item], count, 99)['secondCount']}`;
          } else {
            commonList += `${!iterator.isSaufGroup || iterator.isSaufGroup === undefined ? '' : 'B'}@${!iterator.sauf || iterator.sauf === undefined ? 'T' : 'S'}@${this.getCount(this.characterList.characterListType3[item], count, 99)['secondCount']}`;
          }
          count = this.getCount(this.characterList.characterListType3[item], count, 99)['iterateItem'];
        }
        if (iterator.sauf) {
          commonList += `${!iterator.isSaufGroup && iterator.conditionType === '2' ? 'B' : ''}@${'S'}@${this.getSaufCount(this.characterList.characterListType3[item], count, 99)['secondCount']}`;
          count = this.getSaufCount(this.characterList.characterListType3[item], count, 99)['iterateItem'];
        }
        let listForm = "";
        let delayDateName1 = undefined;
        let delayDateName2 = undefined;
        let freeFormat = false;
        let valueData = iterator.value.split(' ');
        characterMTC.forEach((el, index) => {
          if (valueData[0] === el.value) {
            listForm += `${index + 1}@`
          }
        })
        for (let i = 0; i < this.getDataFormat().length; i++) {
          let el = this.getDataFormat()[i];
          // this.getDataFormat().forEach(el => {
          let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
          if (translatedText.toLowerCase() === iterator.format.toLowerCase()) {
            if (valueData[3] && isNaN(valueData[3]) && valueData.length > 2) {
              listForm += `${el.index}@`;
              break;
            } else if (el.value === 'Free Format' && valueData.length > 2) {
              freeFormat = true;
              listForm += `${el.index}@`;
              break;
            } else {
              listForm += `${el.index}@`;
              break;
            }
          }
        }
        listForm += `${valueData[1]}@`;
        let fieldText, startDate, endDate;
        startDate = iterator.name.split('-')[0];
        endDate = iterator.name.split('-')[1];
        if (iterator.delay === 0) {
          const criteriaFilterVal1 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === startDate : a.longLabel === startDate)[0];
          delayDateName1 = criteriaFilterVal1.GCOName;
          const criteriaFilterVal2 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === endDate : a.longLabel === endDate)[0];
          delayDateName2 = criteriaFilterVal2.GCOName;
        } else if (iterator.delay === 1 || iterator.delay === 2) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === endDate : a.longLabel === endDate)[0];
          delayDateName1 = startDate;
          delayDateName2 = criteriaFilterVal.GCOName;
        } else if (iterator.delay === 3 || iterator.delay === 4) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === startDate : a.longLabel === startDate)[0];
          delayDateName1 = criteriaFilterVal.GCOName;
          delayDateName2 = endDate;
        }
        if (endDate.includes('/') || endDate === 'DATEDUJOUR') {
          fieldText = 'NDate';
          endDate = this.getFormatedDate(endDate, iterator.format);
        } else {
          fieldText = 'NChamp';
          listForm += `${fieldText}@0@${delayDateName2}_3@3@`;
        }
        if (fieldText === 'NDate') {
          listForm += `${fieldText}@${endDate}@`;
        }
        if (startDate.includes('/') || startDate === 'DATEDUJOUR') {
          fieldText = 'NDate';
          startDate = this.getFormatedDate(startDate, iterator.format);
        } else {
          fieldText = 'NChamp';
          listForm += `${fieldText}@0@${delayDateName1}_3@3@`;
        }
        if (fieldText === 'NDate') {
          listForm += `${fieldText}@${startDate}@`;
        }
        if (freeFormat) {
          // listForm += `${valueData[3]}@`;
          listForm += `${valueData[3]}`;
        }
        if (iterator.conditionType === '2' || iterator.conditionType === '3') {
          rtnValue += `${commonList}@NClDelai_V2@${listForm}`;
        } else {
          rtnValue += `@NClDelai_V2@${listForm}`;
        }
      }
    }
    rtnValue += `@`;
    let commonCondQuery;
    if (rtnValue !== '') {
      let count = rtnValue.match(/@/gi).length;
      // if (this.this.characterList.characterListType1.group0)
      commonCondQuery = `@CCond@${count + 4}@${this.getCharacterLength()}`;
      commonCondQuery += rtnValue;
    } else {
      commonCondQuery = `@CCond@${4}@${this.getCharacterLength()}`;
    }
    return commonCondQuery;
  }

  // getSaufData(array) {
  //   let type = array.filter(el => el.conditionType === '3' || el.sauf);
  //   if (type.length && type[0].conditionType === '3') {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  getCharacterLength() {
    let listType = '';
    if (Object.keys(this.characterList.characterListType1).length === 1 && this.characterList.characterListType1.group0.length === 0) {
      listType += `0@`;
    } else {
      listType += `${this.generateCharCount(this.characterList.characterListType1)}@`;
    }
    if (Object.keys(this.characterList.characterListType2).length === 1 && this.characterList.characterListType2.group0.length === 0) {
      listType += `0@`;
    } else {
      listType += `${this.generateCharCount(this.characterList.characterListType2)}@`;
    }
    if (Object.keys(this.characterList.characterListType3).length === 1 && this.characterList.characterListType3.group0.length === 0) {
      listType += `0@`;
    } else {
      listType += `${this.generateCharCount(this.characterList.characterListType3)}@`;
    }
    return listType;
  }
  generateCharCount(iterator) {
    let count = 0;
    for (let item in iterator) {
      for (let i = 0; i < iterator[item].length; i++) {
        let elem = iterator[item][i];
        if (elem.conditionType === '2' || elem.conditionType === '3') {
          count += 1;
        }
      }
    }
    return count;
  }
  getFormatedDate(dateFormat, val) {
    let formatedDate;
    dateFormat = dateFormat.split('/');
    for (let i = 0; i < this.getDataFormat().length; i++) {
      let el = this.getDataFormat()[i];
      let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
      if (val.toLowerCase() === translatedText.toLowerCase()) {
        if (el.value === 'connue') {
          formatedDate = `${dateFormat}@0@0@0@0`;
          break;
        } else if (el.value === 'inconnue') {
          formatedDate = `${dateFormat}@0@0@0@0`;
          break;
        } else if (el.value === 'année' && dateFormat[0] !== 'DATEDUJOUR') {
          formatedDate = `0@0@0@0@${dateFormat[0]}`;
          break;
        } else if (el.value === 'mois insustriel' && dateFormat[0] !== 'DATEDUJOUR') {
          formatedDate = `0@0@0@${dateFormat[0]}@${dateFormat[1]}`;
          break;
        } else if (el.value === 'mois calendaire' && dateFormat[0] !== 'DATEDUJOUR') {
          formatedDate = `0@0@${dateFormat[0]}@0@${dateFormat[1]}`;
          break;
        } else if ((el.value === 'programme' || el.value === 'week') && dateFormat[0] !== 'DATEDUJOUR') {
          formatedDate = `0@${dateFormat[0]}@0@0@${dateFormat[1]}`;
          break;
        } if (el.value === 'ASJ' && dateFormat[0] !== 'DATEDUJOUR') {
          formatedDate = `${dateFormat[0]}@${dateFormat[1]}@0@0@${dateFormat[2]}`;
          break;
        } else if (dateFormat[0] === 'DATEDUJOUR') {
          formatedDate = `jj@ss@mm@mm@aaaa`;
          break;
        } else if (dateFormat[0] !== 'DATEDUJOUR') {
          formatedDate = `${dateFormat[0]}@0@${dateFormat[1]}@0@${dateFormat[2]}`;
          break;
        }
      }
    }
    return formatedDate;
  }

  getDataFormat() {
    return [{ name: 'CONNUE', value: 'connue', index: 0 },
    { name: 'JOUR_SAVE', value: 'jour', index: 1 },
    { name: 'PROGRAM_SAVE', value: 'programme', index: 2 },
    { name: 'WEEK_SAVE', value: 'week', index: 2 },
    { name: 'INDUSTRIAL_SAVE', value: 'mois insustriel', index: 3 },
    { name: 'CALENDAR_SAVE', value: 'mois calendaire', index: 4 },
    { name: 'Y', value: 'année', index: 5 },
    { name: 'WORKING_SAVE', value: 'semaines', index: 6 },
    { name: 'FF_SAVE', value: 'Free Format', index: 7 },
    { name: 'ASL_SAVE', value: 'ASJ', index: 8 },
    { name: 'INCONNUE', value: 'inconnue', index: 0 },
    ];
  }

  getGroupDate() {
    return [{ name: 'DCMY', value: 'jours', index: 1 },
    { name: 'DWY', value: 'jour/prog./année', index: 8 },
    { name: 'PY', value: 'programmes', index: 2 },
    { name: 'IMY', value: 'mois industriels', index: 3 },
    { name: 'CMY', value: 'mois calendaires', index: 4 },
    { name: 'Y', value: 'année', index: 5 },
    { name: 'FF', value: 'Free Format', index: 9 },
    { name: 'FKD', value: 'flag', index: 0 }];
  }


  generateGroupSaveQuery() {
    let type, listForm = "";
    for (let item of this.groupList) {
      if (item.type === 2 || item.type === 6) {
        type = 1;
      } else if (item.type === 4 || item.type === 5) {
        type = 6;
      } else if (item.type === 3) {
        type = 3;
      } else if (item.type === 1) {
        type = 2;
      } else if (item.type === 99) {
        type = 5;
      } else {
        type = 4;
      }
      let data, fieldType;
      if (item.type === 2 || item.type === 6) {
        listForm += `${type}@0@${item.size}@${item.GCOName}_${item.type}@${item.type}@`;
      } else if (item.type === 4 || item.type === 5) {
        data = item.name.split('[');
        if (data.length > 1 && (data[1].toLowerCase().includes(this.utilService.getTranslateName('COMMON.SUM').toLowerCase()))) {
          fieldType = 1;
        } else {
          fieldType = -1
        }
        listForm += `${type}@${fieldType}@${item.size}@${item.GCOName}_${item.type}@${item.type}@`;
      } else if (item.type === 3) {
        data = item.name.split('[');
        data = data[1].replace(']', '');
        let foundElement = this.getGroupDate().find(el => this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.value}`) === data);
        if (foundElement !== undefined) {
          fieldType = foundElement.index;
          listForm += `${type}@${fieldType}@${item.size}@${item.GCOName}_${item.type}@${item.type}@`;
        } else {
          fieldType = '9';
          listForm += `${type}@${fieldType}@${data}@${item.size}@${item.GCOName}_${item.type}@${item.type}@`;
        }
        // for (let i = 0; i < this.getGroupDate().length; i++) {
        //   let el = this.getGroupDate()[i];
        //   if (this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.value}`) === data) {
        //     fieldType = el.index;
        //     listForm += `${type}@${fieldType}@${item.size}@${item.GCOName}_${item.type}@${item.type}@`;
        //     break;
        //   } else if (this.getGroupDate().some(el => this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.value}`) !== data)) {

        //     break;
        //   }
        // }
      } else if (item.type === 1) {
        let itemValue = item.name.substr(item.name.indexOf('[') + 1, (item.name.length - item.name.indexOf('[') - 2));
        listForm += `${type}@${item.size}@${item.GCOName}_${item.type}@${item.type}`;
        if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.COMPACT`)) {
          listForm += `@2@0@`;
        } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.INDEX`)) {
          listForm += `@3@0@`;
        } else if (itemValue.split(',')[0] === this.utilService.getTranslateName(`NEW_QUERY.CHARACTER_LIST.ALL`)) {
          if (typeof (itemValue.split(',')[1]) === 'undefined') {
            listForm += `@1@0@`;
          } else {
            listForm += `@1@${itemValue.split(',')[1]}@`;
          }
        } else if (itemValue.split(',').length > 0) {
          data = itemValue.split(',');
          listForm += `@0@${data.length}@`;
          for (let item of data) {
            listForm += `0@0@${item}@`
          }
        }
      } else if (item.type === 99) {
        let isFreeFormat = false, dayVal, formattedType;
        listForm += `${type}@NGDelai_V1@`;
        for (let i = 0; i < this.getDataFormat().length; i++) {
          let el = this.getDataFormat()[i];
          let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
          dayVal = item.format.split(' ');
          if (!isNaN(dayVal[1])) {
            formattedType = dayVal[0];
          } else {
            formattedType = item.format;
          }
          if (translatedText.toLowerCase() === formattedType.toLowerCase() && isNaN(dayVal[1])) {
            listForm += `${el.index}@`;
            break;
          } else if (translatedText.toLowerCase() === formattedType.toLowerCase() && !isNaN(dayVal[1])) {
            isFreeFormat = true;
            listForm += `${el.index}@`;
            break;
          }
        }
        // this.getDataFormat().forEach(el => {
        //   let translatedText = this.utilService.getTranslateName(`NEW_QUERY.DATE_GROUPING.${el.name}`);
        //   if (translatedText.toLowerCase() === item.format.toLowerCase()) {
        //     listForm += `${el.index}@`;
        //     break;
        //   }
        // });
        let fieldText, startDate, endDate, delayDateName1, delayDateName2;
        startDate = item.name.split('-')[0];
        endDate = item.name.split('-')[1];
        endDate = endDate.split('[')[0];
        if (item.delay === 0) {
          const criteriaFilterVal1 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === startDate : a.longLabel === startDate)[0];
          delayDateName1 = criteriaFilterVal1.GCOName;
          const criteriaFilterVal2 = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === endDate : a.longLabel === endDate)[0];
          delayDateName2 = criteriaFilterVal2.GCOName;
        } else if (item.delay === 1 || item.delay === 2) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === endDate : a.longLabel === endDate)[0];
          delayDateName1 = startDate;
          delayDateName2 = criteriaFilterVal.GCOName;
        } else if (item.delay === 3 || item.delay === 4) {
          const criteriaFilterVal = this.criteriaListOringinal.filter(a => a.longLabel.split(this.splitChar, 2).length > 1 ? a.longLabel.split(this.splitChar, 2)[this.getLangIndex(this.lang)] === startDate : a.longLabel === startDate)[0];
          delayDateName1 = criteriaFilterVal.GCOName;
          delayDateName2 = endDate;
        }
        if (endDate.includes('/') || endDate === 'DATEDUJOUR' || (item.format.toLowerCase() === 'year' && !isNaN(endDate))) {
          fieldText = 'NDate';
          endDate = this.getFormatedDate(endDate, item.format);
        } else {
          fieldText = 'NChamp';
          listForm += `${fieldText}@0@${delayDateName2}_3@3@`;
        }
        if (fieldText === 'NDate') {
          listForm += `${fieldText}@${endDate}@`;
        }
        if (startDate.includes('/') || startDate === 'DATEDUJOUR' || (item.format.toLowerCase() === 'year' && !isNaN(startDate))) {
          fieldText = 'NDate';
          startDate = this.getFormatedDate(startDate, item.format);
        } else {
          fieldText = 'NChamp';
          if (dayVal.length > 1) {
            listForm += `${fieldText}@0@${delayDateName1}_3@3@`;
          } else {
            listForm += `${fieldText}@0@${delayDateName1}_3@3@@`;
          }
        }
        if (fieldText === 'NDate') {
          listForm += `${fieldText}@${startDate}@@`;
        }
        if (isFreeFormat) {
          listForm += `${dayVal[1]}@`;
        }
      }
    }
    let commonCondQuery;
    if (this.groupList.length > 0) {
      let count = listForm.match(/@/gi).length;
      commonCondQuery = `@CGroupBy@${count + 2}@${this.groupList.length}@${listForm}@`;
    } else {
      commonCondQuery = `@CGroupBy@${2}@${this.groupList.length}@@`;
    }
    return commonCondQuery;
  }

  rtnDataStatus(event) {
    this.blnExecuteDataStatus = event;
  }

  rtnTotalRowsData(event) {
    this.rtnTotalRowsLength = event;
  }

  rtnResultColumns(event) {
    this.rtnRsultColumns = event;
  }

  rtnExecErr(event) {
    if (event) {
      this.isExecute = false;
    }
  }

  alertErrorMsg(displayMsg): void {

    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': displayMsg, 'isText': false, 'isAlert': true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // this.dialogRef.close({ name: this.queryName, isPublic: this.isPublic });
        return;
      }
    })
  }


  moveRtSection() {
    //console.log("double click");
    this.moveRight()
  }
}
