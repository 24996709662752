import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastyService } from 'ng2-toasty';

import { BackendApiService } from '../../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-delay-grouping',
  templateUrl: './delay-grouping.component.html',
  styleUrls: ['./delay-grouping.component.scss']
})
export class DelayGroupingComponent implements OnInit {
  @Input() delayInput;
  @Input() daysData;
  @Input() delayCheckVal;
  @Output() selectedIndex = new EventEmitter();
  @Output() delayOutput = new EventEmitter();

  profile = this.utilService.getUserProfile();
  ipn;

  selectedOption;
  ddValue: string;
  isSubmitted: boolean = false;
  delayOptionSelected = 0;

  delayOptions = [{ name: 'DAYS', value: 'days', selected: true },
  { name: 'WORKING_DAYS', value: 'working days', selected: false },
  { name: 'WEEK', value: 'prog', selected: false },
  { name: 'CALENDAR', value: 'cal month', selected: false },
  { name: 'INDUSTRIAL', value: 'indus month', selected: false },
  { name: 'Y', value: 'year', selected: false },
  { name: 'JOUR_SAVE', value: 'Free Format', selected: false }];

  constructor(readonly apiService: BackendApiService, readonly utilService: UtilService, readonly toastyService: ToastyService) {
    this.ipn = this.profile['uid']; // sessionStorage.getItem("ipn");
  }

  ngOnInit() {
  }

  ngOnChanges() {
    let i = 0;
    this.ddValue = undefined;
    if (this.delayInput.value) {
      for (const item of this.delayOptions) {
        if (`${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${item.name}`)}` === this.delayInput.value) {
          item.selected = true;
          this.selectedOption = item.value;
          this.ddValue = this.delayInput.ddValue
          this.isSubmitted = true;
          this.delayOptionSelected = i;
          const val = { "currentIndex": i, "previousIndex": i }
          this.selectedIndex.emit(val);
        }
        i = i + 1;
      }
    } else {
      if (this.delayOptionSelected === 0) {
        this.selectedOption = 'days';
      }
    }
  }

  selectOption(index) {
    const previousIndex = this.delayOptionSelected;
    this.delayOptionSelected = index;
    const val = { "currentIndex": index, "previousIndex": previousIndex }
    this.selectedIndex.emit(val);
  }

  checkSelectedCheckboxOptions(list) {
    if (list.selected && this.delayInput.date1 !== '' && this.delayInput.date2 !== '') {
      this.isSubmitted = true;
      this.ddValue = list.value;
    }

    this.delayOptions.some(delay => {
      if (delay.name !== list.name) {
        delay.selected = false;
      }
    });
  }

  isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  daysOfAYear(year) {

    return this.isLeapYear(year) ? 366 : 365;
  }

  lastWeeksInYear(year) {
    return Math.ceil(this.daysOfAYear(year) / 7);
  }

  submit() {
    let retValue;
    const curYearVal = new Date().getFullYear() + 10;
    this.delayOptions.forEach(el => {
      if (this.selectedOption === el.value) {
        if (el.value === 'Free Format') {
          retValue = `${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${el.name}`)}` + ' ' + this.ddValue;
        } else {
          retValue = `${this.utilService.getTranslateName(`NEW_QUERY.DELAY_LIST.${el.name}`)}`;
        }
      }
    })
    if (this.delayCheckVal === 2) {
      if (this.delayOptionSelected === 0 || this.delayOptionSelected === 1 || this.delayOptionSelected === 6) {
        let val = this.delayInput.date1.split('/');
        const dateVal = new Date(`${val[2]}/${val[1]}/01`);
        const lastDayOfMonth = new Date(dateVal.getFullYear(), dateVal.getMonth() + 1, 0);
        if (parseInt(val[0]) > lastDayOfMonth.getDate() || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Date");
          return;
        } else if (parseInt(val[1]) > 12 || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[2].length !== 4 || parseInt(val[2]) > curYearVal || parseInt(val[2]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
        let val = this.delayInput.date1.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > 12 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 2) {
        let val = this.delayInput.date1.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > this.lastWeeksInYear(val[1]) || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Week");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 5) {
        let val = this.delayInput.date1;
        if (val.length !== 4 || parseInt(val) > curYearVal || parseInt(val) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      }
    }

    if (this.delayCheckVal === 4) {
      if (this.delayOptionSelected === 0 || this.delayOptionSelected === 1 || this.delayOptionSelected === 6) {
        let val = this.delayInput.date2.split('/');
        const dateVal = new Date(`${val[2]}/${val[1]}/01`);
        const lastDayOfMonth = new Date(dateVal.getFullYear(), dateVal.getMonth() + 1, 0);
        if (parseInt(val[0]) > lastDayOfMonth.getDate() || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Date");
          return;
        } else if (parseInt(val[1]) > 12 || parseInt(val[1]) < 1 || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[2].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[2]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 3 || this.delayOptionSelected === 4) {
        let val = this.delayInput.date2.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > 12 || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Month");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 2) {
        let val = this.delayInput.date2.split('/');
        let dateVal = new Date(`${val[2]}/${val[1]}/${val[0]}`);
        if (parseInt(val[0]) > this.lastWeeksInYear(val[1]) || parseInt(val[0]) < 1) {
          this.toastyService.warning("Invalid Week");
          return;
        } else if (val[1].length !== 4 || parseInt(val[1]) > curYearVal || parseInt(val[1]) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      } else if (this.delayOptionSelected === 5) {
        let val = this.delayInput.date2;
        if (val.length !== 4 || parseInt(val) > curYearVal || parseInt(val) < 1) {
          this.toastyService.warning("Invalid Year");
          return;
        }
      }
    }

    let value = {
      date1: this.delayInput.date1,
      date2: this.delayInput.date2,
      value: retValue,
      ddValue: this.ddValue
    };
    this.delayOutput.emit(value);
    this.selectedOption = undefined;
    this.ddValue = undefined;
  }

  cancel() {
    let value = {
      value: '',
      ddValue: ''
    };
    this.delayOutput.emit(value);
  }



}
